import React from "react";
import {useAuthState} from "../auth/auth";
import CompaniesList from "../components/CompaniesList/CompaniesList";
import {Box} from "@mui/material";

const CompaniesListPage = (props) => {
    const {authState} = useAuthState();

    return (
        <Box sx={{margin: "0 auto", overflowY:"scroll", height:"100%"}}>
            <CompaniesList companies={authState?.userCompanies}/>
        </Box>
    );
};

export default CompaniesListPage;
