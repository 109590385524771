import React from 'react';
import YouTube from 'react-youtube';
import {omit} from 'lodash';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
  },
  youtube: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  nonYouTube: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}))

const YOUTUBE_REGEXP = /^(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=)?([\w-]{10,})/i;
const YOUTUBE_OPTS = { };

const getYoutubeId = (url) => {
  const match = url.match(YOUTUBE_REGEXP);
  return match && match[1];
}

const EmbeddedMedia = (props) => {
  const youtubeId = getYoutubeId(props.src);
  const classes = useStyles();
  const [youtubeLoaded, setYoutubeLoaded] = React.useState(false);

  if (!youtubeId) {
    return <div style={{position: "relative", paddingBottom: "56.25%", height: 0}}><iframe className={classes.nonYouTube} {...props} /></div>
  }

  const opts = omit(props, ['src']);

  return (
    <div className={classes.container}>
      {!youtubeLoaded && (<Box display="flex" justifyContent="center"><CircularProgress/></Box>)}
      <YouTube
        className={classes.youtube}
        videoId={youtubeId}
        onReady={() => {setYoutubeLoaded(true)}}
        opts={{...YOUTUBE_OPTS, ...opts}}
      />
    </div>
  )
}

export default EmbeddedMedia;
