import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {footerHeight} from "./Navigation";
import {StyledAppBar} from "./AppBar";

const Footer = ({drawerRender, drawerOpen}) => {
    return (
        <StyledAppBar
            position="fixed"
            color="primary"
            sx={{top: 'auto', bottom: 0}}
            open={drawerOpen}
            render={drawerRender ? 1 : 0}
            height={footerHeight}
            elevation={0}
            square>
            <Box m="0.2rem">
                <Typography
                    align="center"
                    variant="caption"
                    display="block"
                    gutterBottom
                >
                    Proudly made by PopHire
                </Typography>
            </Box>
        </StyledAppBar>
    );
};

export default Footer;

