import React, {useMemo} from 'react';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import makeStyles from '@mui/styles/makeStyles';
import {format} from "date-fns";
import {debounce} from "lodash";

const useStyles = makeStyles((theme) => (
        {
            datePickerRoot: {
                paddingTop: theme.spacing(2.7),
                paddingBottom: theme.spacing(2),
                height: 100
            },
        }
    )
);

export const maxDate = new Date("2099-12-31");
export const minDate = new Date("1900-01-01");

export const FormikDatePicker = (props) => {

    const {
        formik,
        id, label, getShouldDisableDateError,
        ...other
    } = props;

    const classes = useStyles();

    const currentError = formik.errors[id];
    const handleDateChange = (date) => {
        formik.setFieldValue(id, date, false);
    };

    const debounceHandleDateChange = useMemo(() => debounce((event) => handleDateChange(event), 100), []);

    return (
        <div className={classes.datePickerRoot}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    id={id}
                    name={id}
                    autoComplete={id}
                    value={formik.values[id]}
                    onAccept={(date) => {
                        formik.setStatus({state: "touched"});
                        formik.setFieldTouched(id, true);
                    }}
                    onChange={(date) => {
                        formik.setFieldValue(id, date, false);
                    }}
                    onError={(reason, value) => {
                        switch (reason) {
                            case "invalidDate":
                                formik.setFieldError(id, `Invalid ${label} format`);
                                break;

                            case "disablePast":
                                formik.setFieldError(id, `Values in the past are not allowed for ${label}`);
                                break;

                            case "maxDate":
                                formik.setFieldError(id, `${label} should not be after ${format(maxDate, "P")}`);
                                break;

                            case "minDate":
                                formik.setFieldError(id, `${label} should not be before ${format(minDate, "P")}`);
                                break;

                            case "shouldDisableDate":
                                // shouldDisableDate returned true, render custom message according to the `shouldDisableDate` logic
                                formik.setFieldError(id, getShouldDisableDateError(value));
                                break;

                            default:
                                formik.setFieldTouched(id, true);
                                formik.setStatus({state: "touched"});
                                formik.setErrors({
                                    ...formik.errors,
                                    [id]: null,
                                });
                                break;
                        }
                    }}
                    renderInput={
                        (props) =>
                            <TextField
                                {...props}
                                error={Boolean(currentError)}
                                helperText={Boolean(currentError) ? currentError : undefined}
                                label={label}
                            />
                    }
                    {...other}
                />
            </LocalizationProvider>
        </div>
    )
}
