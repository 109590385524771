import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import React from "react";
import Button from "@mui/material/Button";
import CustomDialogTitle from "../../shared/Dialog/CustomDialogTitle";
import CustomAutocomplete from "../../shared/Autocomplete/CustomAutocomplete";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import {roles} from "./CompanyCrewInfoTable";
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

const useStyles = makeStyles((theme) => (
        {
            updateUserDialogActions: {
                padding: theme.spacing(3),
                paddingTop: theme.spacing(1),
                justifyContent: "space-between",
            }
        }
    )
);
const renderRoleOptions = () => {
    return (
        roles.map(role => (
            <MenuItem key={role.id} value={role.id}>{role.value}</MenuItem>
        ))
    );
}

const createUserToUpdate = (selectedUser, selectedRole, authState) => (
    {newCompanyUser: {id: selectedUser.userId, role: selectedRole}, authState: authState}
);

const UpdateCompanyCrewMemberDialog = (props) => {
    const classes = useStyles();

    const {openDialog, handleCloseDialog, updateCompanyUser, deleteCompanyUser, selectedUser,} = props;
    let userEmail = selectedUser? selectedUser.email: "";
    let existingRole = 2;
    if (selectedUser != null) {
        var role = roles.find(role => role.value === selectedUser.role);
        if (role != null)
            existingRole = role.id;
    }
    const [selectedRole, setSelectedRole] = React.useState(existingRole);
    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    }

    return (
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            scroll="body"
            fullWidth
            maxWidth="sm"
            aria-labelledby="invite-crew-member-dialog"
        >
            <CustomDialogTitle
                id="invite-crew-member-dialog"
                onClose={handleCloseDialog}
            >
                Edit Team Member
            </CustomDialogTitle>
            <DialogContent>
                <Grid sx={{mt:1}} container spacing={2}>
                    <Grid item xs={12} sm={12}>
                    <TextField
                            id="user"
                            label="User Email"
                            fullWidth
                            disabled={true}
                            value={userEmail}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                            <InputLabel id="user-role-label">User Role</InputLabel>
                            <Select
                                id="user-role"
                                labelId="user-role-label"
                                label="User Role"
                                value={selectedRole}
                                onChange={handleRoleChange}
                                
                            >
                                {renderRoleOptions()}
                                
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.updateUserDialogActions}>
                <Button
                    onClick={() => {
                        deleteCompanyUser(selectedUser.id);
                        handleCloseDialog();
                    }}
                    variant="contained"
                    color="secondary">
                    Delete User
                </Button>
                <Button
                    onClick={() => {
                        updateCompanyUser(selectedUser.userId, selectedRole);
                        handleCloseDialog();
                    }}
                    variant="contained"
                    color="primary">
                    Update User Role
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateCompanyCrewMemberDialog
