import {Box, Typography} from "@mui/material";
import CandidateFormPreviewBackground from "./CandidateFormPreviewBackground";
import Grid from "@mui/material/Grid";
import ContactForm from "../../../../../Contacts/ContactForm";
import React from "react";
import {getCandidateInterestLevelDescription} from "../CandidateForm";

const SelfCandidateFormPreview = ({formik, companyName}) => {

    const readonlyView = (labelTitle, value) => {
        return (
            <Box>
                <Typography sx={{fontSize: 14, fontWeight: 500, color: 'text.primary'}} component='span'>
                    {labelTitle}:
                </Typography>
                <Typography sx={{fontSize: 12}} component='span'>
                    {value}
                </Typography>
            </Box>
        );
    }

    const makeLink = (url) => {
        return <a href={url}> {url}</a>
    }

    const getAttachmentName = () => {
        const hasPendingUpload = formik.values.candidate_resume_files !== null && formik.values.candidate_resume_files.length > 0;
        const hasExistingUpload = formik.values.candidate_resume_url !== "" && formik.values.candidate_resume_url !== null;

        if (hasPendingUpload) {
            return readonlyView("Resume", formik.values.candidate_resume_files[0].file.name);
        } else if (hasExistingUpload) {
            // get the file name from the existing upload
            const filename = formik.values.candidate_resume_url;
            // get everything past the last slash
            const name = filename.replace(/^.*[\\\/]/, '');
            return readonlyView("Resume", name);
        }
        return "";
        // formik.values.candidate_resume_files !== null && formik.values.candidate_resume_files.length > 0?
        //                     readonlyView("Resume", formik.values.candidate_resume_files[0].file.name):""}
    }

    const getVideo = (description, value) => {
        if (value !== "" && value !== null) {
            return <Box>
                <Typography
                    sx={{fontSize: 14, fontWeight: 500, color: 'text.primary'}}
                    component='div'
                >
                    {description}
                </Typography>
                <iframe
                    src={formik.values.about_candidate_url}
                    frameBorder="0"
                    allowFullScreen
                    style={{"height": "100%"}}
                />
            </Box>
        }

        return "";
    }

    const getIamTheCandidate = () => {
        if (formik.values.referrer_is_candidate) {
            return readonlyView('I am the Candidate', "Yes")
        }

        return "";
    }

    return (
        <Box>
            <CandidateFormPreviewBackground>
                <Grid container direction='column' rowSpacing={1}>
                    {/* <Grid item ml={6}>
                        {getIamTheCandidate()}
                    </Grid> */}
                    <Grid item ml={6}>
                        {readonlyView('Name', formik.values.candidate_name)}
                    </Grid>
                    <Grid item ml={6}>
                        {readonlyView("Link to professional profile", makeLink(formik.values.candidate_url))}
                    </Grid>
                    <Grid item ml={6}>
                        {getAttachmentName()}
                    </Grid>
                    {/* <Grid item ml={6}>
                        {readonlyView('How you know the candidate', formik.values.how_do_you_know_candidate)}
                    </Grid> */}
                    {/* <Grid item ml={6}>
                        {readonlyView("Candidate's level of interest", getCandidateInterestLevelDescription(formik.values.candidate_level_of_interest))}
                    </Grid> */}
                    <Grid item ml={6}>
                        {readonlyView('Tell us about your self', formik.values.about_candidate_text)}
                    </Grid>
                    <Grid item ml={6}>
                        {getVideo('Video Message', formik.values.about_candidate_url)}
                    </Grid>
                    <Grid item ml={6}>
                        <ContactForm
                            formik={formik}
                            reviewMode={true}
                            prefix="candidate_"
                            title={<Typography sx={{
                                fontSize: 16,
                                pb: 1,
                                pt: 1,
                                fontWeight: 400,
                                color: 'text.primary'
                            }}>{`How can ${companyName} contact you?`}</Typography>}
                        />
                    </Grid>
                </Grid>
            </CandidateFormPreviewBackground>
        </Box>
    );
};

export default SelfCandidateFormPreview;
