import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {Rnd} from "react-rnd";
import {toPng} from 'html-to-image';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import Grid from '@mui/material/Grid';
import {useAuthState} from "./auth/auth";
import axios from 'axios';
import FontPicker from "font-picker-react";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import {SketchPicker} from 'react-color';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import './fonts/QuantifyBold.ttf'
import {debounce} from "lodash";
import {CardActionArea, Link} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {setUseWhatChange, useWhatChanged,} from '@simbathesailor/use-what-changed';

setUseWhatChange(process.env.NODE_ENV === 'development');

const template = {
    "image-id": "8_1_cropped.jpg",
    "isSvg": false,
    "attention-font-size": 38,
    "attention-font-color": "#201818",
    "attention-font-type": "Montserrat",
    "attention-text": "{company} is seeking a {title}",
    "attention-x": 60,
    "attention-y": 90,
    "attention-width": 500,
    "attention-height": 200,
    "attention-additional-style":
        {
            "fontStyle": "italic",
            "fontWeight": "bold",
        },
    "sub-font-size": 28,
    "sub-font-color": "#201818",
    "sub-font-type": "Montserrat",
    "sub-text": "Refer someone you know and get {warm_amt}!",
    "sub-x": 60,
    "sub-y": 232,
    "sub-width": 595,
    "sub-height": 200,
    "sub-additional-style":
        {
            "fontStyle": "italic",
        },
    "company-logo-position-x": 250,
    "company-logo-position-y": 4,
    "footer-x": 490,
    "footer-y": 327,
    "footer-font-size": 16,
    "footer-font-color": "#000000",
}
const template2 = {
    "image-id": "1_cropped.jpg",
    "isSvg": true,
    "attention-font-size": 30,
    "attention-font-color": "#4B908D",
    "attention-font-type": "QuantifyBold",
    "attention-text": "EARN {warm_amt}",
    "attention-x": 11,
    "attention-y": 63,
    "attention-width": 380,
    "attention-height": 200,
    "attention-additional-style":
        {
            "fontStyle": "italic",
            "stopColor1": "#62C9C5",
            "offset1": "5%",
            "stopColor2": "#000000",
            "offset2": "90%"
        },
    "sub-font-size": 31,
    "sub-font-color": "#0e7470",
    "sub-font-type": "FM Bolyar Sans Pro",
    "sub-text": "Refer a {title} to us today",
    "sub-x": 11,
    "sub-y": 215,
    "sub-width": 425,
    "sub-height": 500,
    "sub-additional-style":
        {
            "fontStyle": "italic",
        },
    "company-logo-position-x": 1,
    "company-logo-position-y": -2,
    "footer-x": 490,
    "footer-y": 327,
    "footer-font-size": 16,
    "footer-font-color": "#000000",
}
const template3 = {
    "image-id": "7_cropped.jpg",
    "isSvg": true,
    "attention-font-size": 42,
    "attention-font-color": "#4B908D",
    "attention-font-type": "Bebas Neue",
    "attention-text": "EARN {warm_amt}",
    "attention-x": 11,
    "attention-y": 63,
    "attention-width": 380,
    "attention-height": 200,
    "attention-additional-style":
    // {
    //   background: "-webkit-linear-gradient(#eee, #333)",
    //   -webkit-background-clip: text;
    //   -webkit-text-fill-color: transparent;
    // }
        {
            //   "backgroundColor": "#f3ec78",
            // "backgroundImage": "linear-gradient(90deg, #62C9C5, #000000)",
            // "backgroundSize": "100%",
            // "WebkitBackgroundClip": "text",
            // "MozBackgroundClip": "text",
            //  "WebkitTextFillColor": "transparent",
            "fontStyle": "italic",
            "stopColor1": "#2F353F",
            "offset1": "5%",
            "stopColor2": "#E0BA25",
            "offset2": "88%"
            // "mozTextFillColor": "transparent"
        },
    "sub-font-size": 40,
    "sub-font-color": "#2F353F",
    "sub-font-type": "Bebas Neue",
    // "sub-font-type": "Montserrat",
    "sub-text": "Refer a {title} to us today",
    "sub-x": 11,
    "sub-y": 215,
    "sub-width": 425,
    "sub-height": 500,
    "sub-additional-style":
        {
            //  "backgroundColor": "#f3ec78",
            //"color": "#62C9C5",
            // "backgroundImage": "linear-gradient(90deg, #62C9C5, #000000)",
            // "backgroundSize": "100%",
            // "WebkitBackgroundClip": "text",
            // "MozBackgroundClip": "text",
            // "WebkitTextFillColor": "transparent",
            // "color":"transparent",
            "fontStyle": "italic",
            // "MozTextFillColor": "transparent"
        },
    "company-logo-position-x": 250,
    "company-logo-position-y": -2,
    "footer-x": 490,
    "footer-y": 327,
    "footer-font-size": 16,
    "footer-font-color": "#000000",
}
const images = [
    {
        original: 'https://iopophirepublicprod.s3.amazonaws.com/template_images/8_1_cropped.jpg',
        thumbnail: 'https://iopophirepublicprod.s3.amazonaws.com/template_images/8_1_cropped.jpg',
        template: template
    },
    {
        original: 'https://iopophirepublicprod.s3.amazonaws.com/template_images/1_cropped.jpg',
        thumbnail: 'https://iopophirepublicprod.s3.amazonaws.com/template_images/1_cropped.jpg',
        template: template2
    },
    {
        original: 'https://iopophirepublicprod.s3.amazonaws.com/template_images/7_cropped.jpg',
        thumbnail: 'https://iopophirepublicprod.s3.amazonaws.com/template_images/7_cropped.jpg',
        template: template3
    },
    {
        original: 'https://iopophirepublicprod.s3.amazonaws.com/template_images/643380.png',
        thumbnail: 'https://iopophirepublicprod.s3.amazonaws.com/template_images/643380.png',
    },
    {
        original: 'https://iopophirepublicprod.s3.amazonaws.com/template_images/1393353.jpg',
        thumbnail: 'https://iopophirepublicprod.s3.amazonaws.com/template_images/1393353.jpg'
    },
    {
        original: 'https://iopophirepublicprod.s3.amazonaws.com/template_images/643475.png',
        thumbnail: 'https://iopophirepublicprod.s3.amazonaws.com/template_images/643475.png'
    }
]

const useStyles = makeStyles((theme) => ({
    attention: {
        fontSize: 10,
    },
    hoverbox: {
        display: "flex",

        "&:hover": {
            border: "dotted 1px #ddd",
        }
    },
    attentionText: {
        width: "100%",
        boxSizing: "border-box"
    },
}));

const GenerateSocialMediaTemplate = ({imageData, onImageChange, onImageRendered}) => {
    const classes = useStyles();

    const [text1, setText1] = useState('Job Alert! {company} is seeking a {title} for immediate hire!');
    const [text2, setText2] = useState('Refer someone you know and get {warm_amt}');
    const [selectedBackground, setSelectedBackground] = useState("https://iopophirepublicprod.s3.amazonaws.com/template_images/643380.png");
    const {positionId} = useParams();

    const [activeFontFamily, setActiveFontFamily] = useState('Open Sans');
    const [fontColor, setFontColor] = useState('#FFFFFF');
    const [fontSize, setFontSize] = useState(20);
    const [attentionX, setAttentionX] = useState(0);
    const [attentionY, setAttentionY] = useState(70);
    const [attentionWidth, setAttentionWidth] = useState(475);
    const [attentionHeight, setAttentionHeight] = useState(200);
    const [additionalStyle, setAdditionalStyle] = useState({});
    const [subAdditionalStyle, setSubAdditionalStyle] = useState({});
    const [subStyle, setSubStyle] = useState({});
    const [subX, setSubX] = useState(0);
    const [subY, setSubY] = useState(200);
    const [footerX, setFooterX] = useState(348);
    const [footerY, setFooterY] = useState(322);
    const [companyLogoX, setCompanyLogoX] = useState(0);
    const [companyLogoY, setCompanyLogoY] = useState(0);
    const [subWidth, setSubWidth] = useState(500);
    const [subHeight, setSubHeight] = useState(200);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [activeFontFamily2, setActiveFontFamily2] = useState('Open Sans');
    const [fontColor2, setFontColor2] = useState('#FFFFFF');
    const [fontSize2, setFontSize2] = useState(20);
    const [footerColor, setFooterColor] = useState('#FFFFFF');
    const [footerFontSize, setFooterFontSize] = useState(16);
    const [displayColorPicker2, setDisplayColorPicker2] = useState(false);
    const [svg, setIsSvg] = useState(false);
    const [anElementPositionChanged, setAnElementPositionChanged] = useState("");
    const [anElementSizeChanged, setAnElementSizeChanged] = useState("");
    const [imageIndex, setImageIndex] = useState(0);

    // const [includeLogo, setIncludeLogo] = useState(false);

    let imageGallery, attentionBox, subBox, logoBox, footerBox;

    const ref = React.createRef();
    const {authState} = useAuthState();
    let {companyId} = useParams();
    const headers = authState?.headers;

    const firstUpdate = useRef(true);
    const secondUpdate = useRef(true);

    useEffect(() => {
        if (imageData.featured_image_template_data) {
            loadSavedTemplate(imageData.featured_image_template_data);
        }
    }, []);

    useWhatChanged([text1, text2, selectedBackground, activeFontFamily, fontColor, fontSize, attentionX, attentionY, additionalStyle, subAdditionalStyle, subStyle, subX, subY, activeFontFamily2, fontColor2, fontSize2, footerColor, footerFontSize, anElementPositionChanged, anElementSizeChanged]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (secondUpdate.current) {
            secondUpdate.current = false;
            return;
        }

        const template = getTemplatePayload();
        updateMainForm(ref.current, template);

    }, [text1, text2, selectedBackground, activeFontFamily, fontColor, fontSize, attentionX, attentionY, additionalStyle, subAdditionalStyle, subStyle, subX, subY, activeFontFamily2, fontColor2, fontSize2, footerColor, footerFontSize, anElementPositionChanged, anElementSizeChanged]);

    const updateMainForm = useMemo(() =>
            debounce((current, template) => renderImage(current)
                    .then((dataUrl) => onImageChange(dataUrl, template))
                    .finally(() => onImageRendered()),
                3000),
        []);

    // helper function: generate a new file from base64 String
    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n) {
            u8arr[n - 1] = bstr.charCodeAt(n - 1)
            n -= 1 // to make eslint happy
        }
        return new File([u8arr], filename, {type: mime})
    }

    const getTemplatePayload = () => {
        return {
            "image-id": imageIndex,
            "isSvg": svg,
            "attention-font-size": fontSize,
            "attention-font-color": fontColor,
            "attention-font-type": activeFontFamily,
            "attention-text": text1,
            "attention-x": attentionX,
            "attention-y": attentionY,
            "attention-width": attentionWidth,
            "attention-height": attentionHeight,
            "attention-additional-style": additionalStyle,
            "sub-font-size": fontSize2,
            "sub-font-color": fontColor2,
            "sub-font-type": activeFontFamily2,
            "sub-text": text2,
            "sub-x": subX,
            "sub-y": subY,
            "sub-width": subWidth,
            "sub-height": subHeight,
            "sub-additional-style": subAdditionalStyle,
            "company-logo-position-x": companyLogoX,
            "company-logo-position-y": companyLogoY,
            "footer-x": footerX,
            "footer-y": footerY,
            "footer-font-size": footerFontSize,
            "footer-font-color": footerColor,
        }
    };

    const renderImage = (current) => {
        if (current === null) {
            return Promise.resolve("");
        }

        return toPng(current, {cacheBust: true,})

        // .then((dataUrl) => {
        //   const config = {
        //     headers: headers
        //   }
        //   config.headers['Content-Type'] = 'multipart/form-data';
        //   const file = dataURLtoFile(dataUrl);
        //   const data = new FormData()
        //   data.append('img', file, file.name)
        //   axios.post('/api/company/'+companyId+'/positions/' + positionId + '/uploadImage', data, config).then(response => {
        //     console.log(response.data)
        //   })
        //   //const link = document.createElement('a')
        //   //link.download = 'my-image-name.png'
        //   //link.href = dataUrl
        //   //link.click() //uncomment this to see the generated image (useful for debugging)
        // })
        // .catch((err) => {
        //   console.log(err)
        // })
    }

    const onButtonClick = useCallback(async () => {
        if (ref.current === null) {
            return
        }
        toPng(ref.current, {cacheBust: true,})
            .then((dataUrl) => {
                const config = {
                    headers: headers
                }
                config.headers['Content-Type'] = 'multipart/form-data';
                const file = dataURLtoFile(dataUrl);
                const data = new FormData()
                data.append('img', file, file.name)
                axios.post('/api/company/' + companyId + '/positions/' + positionId + '/uploadImage', data, config).then(response => {
                    console.log(response.data)
                })
                //const link = document.createElement('a')
                //link.download = 'my-image-name.png'
                //link.href = dataUrl
                //link.click() //uncomment this to see the generated image (useful for debugging)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [ref])

    function increaseFontSize(e) {
        setFontSize(fontSize + 1)
    }

    function decreaseFontSize(e) {
        setFontSize(fontSize - 1)
    }

    function handleChange(e) {
        setText1(e.target.value);
    }

    function handlePositionChange(e, d) {
        const hash = `${d.x}-${d.y}`;
        setAnElementPositionChanged(hash);
    }

    function handleSizeChange(ref) {
        const hash = `${ref.style.width}-${ref.style.height}`;
        setAnElementSizeChanged(hash);
    }

    function handleChangeSub(e) {
        setText2(e.target.value);
    }

    // function handleChangeLogo(e) {
    //     setIncludeLogo(e.target.value);
    // }

    function changeBackground(e) {
        // set preview background to selected item
        setSelectedBackground(e.target.src)
        // if there is a template associated, apply the template
        const index = imageGallery.getCurrentIndex();

        const result = images[index];
        setImageIndex(index);
        if (result !== undefined && result.template != null) {
            // get the template if applicable
            setImageContextFromTemplate(result.template)
            setIsSvg(result.template.isSvg)
        }
    }

    function loadSavedTemplate(template) {
        const imageId = template["image-id"];
        const imageItem = images[imageId];
        imageGallery.slideToIndex(imageId);
        setSelectedBackground(imageItem.original);
        setImageIndex(imageId);
        setImageContextFromTemplate(template);
        setIsSvg(template["isSvg"]);
    }

    function setImageContextFromTemplate(template) {
        setText1(template["attention-text"]);
        setFontColor(template["attention-font-color"]);
        setFontSize(template["attention-font-size"]);
        setActiveFontFamily(template["attention-font-type"]);
        setAttentionX(template["attention-x"]);
        setAttentionY(template["attention-y"]);
        setAttentionWidth(template["attention-width"]);
        setAttentionHeight(template["attention-height"]);
        if (attentionBox != null) {
            attentionBox.updatePosition({x: template["attention-x"], y: template["attention-y"]})
            attentionBox.updateSize({width: template["attention-width"], height: template["attention-height"]})
        }
        setAdditionalStyle(template["attention-additional-style"]);
        setSubAdditionalStyle(template["sub-additional-style"]);

        setText2(template["sub-text"]);
        setFontColor2(template["sub-font-color"]);
        setFontSize2(template["sub-font-size"]);
        setActiveFontFamily2(template["sub-font-type"]);
        setSubX(template["sub-x"]);
        setSubY(template["sub-y"]);
        setSubWidth(template["sub-width"]);
        setSubHeight(template["sub-height"]);
        if (subBox != null) {
            subBox.updatePosition({x: template["sub-x"], y: template["sub-y"]})
            subBox.updateSize({width: template["sub-width"], height: template["sub-height"]})
        }
        setCompanyLogoX(template["company-logo-position-x"]);
        setCompanyLogoY(template["company-logo-position-y"]);

        if (logoBox != null) {
            logoBox.updatePosition({x: template["company-logo-position-x"], y: template["company-logo-position-y"]})
        }
        setFooterX(template["footer-x"]);
        setFooterY(template["footer-y"]);
        if (footerBox != null) {
            footerBox.updatePosition({x: template["footer-x"], y: template["footer-y"]})
        }
        setFooterColor(template["footer-font-color"]);
        setFooterFontSize(template["footer-font-size"]);

    }

    function handleColorChangeComplete(color) {
        // set preview background to selected item
        setFontColor(color.hex)
    }

    const pickFontColor = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleColorClose = () => {
        setDisplayColorPicker(false);
    };

    function increaseFontSize2(e) {
        setFontSize2(fontSize2 + 1)
    }

    function decreaseFontSize2(e) {
        setFontSize2(fontSize2 - 1)
    }

    function handleColorChangeComplete2(color) {
        // set preview background to selected item
        setFontColor2(color.hex)
    }

    const pickFontColor2 = () => {
        setDisplayColorPicker2(!displayColorPicker2);
    };

    const handleColorClose2 = () => {
        setDisplayColorPicker2(false);
    };

    function format(text) {
        const title = new RegExp('{title}', 'gi');
        text = text.replace(title, imageData.title)
        const desc = new RegExp('{desc}', 'gi');
        text = text.replace(desc, "Job is awesome!")
        const warm = new RegExp('{warm_amt}', 'gi');
        text = text.replace(warm, getFormattedCurrency(imageData.warm));
        const company = new RegExp('{company}', 'gi');
        text = text.replace(company, imageData.companyName);
        return text;
    }

    function getFormattedCurrency(amt) {
        // Create our number formatter.
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        return formatter.format(amt); /* $2,500.00 */
    }

    const popover = {
        position: 'relative',
        zIndex: '2',
    }
    const cover = {
        position: 'absolute',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    return (
        <div className={classes.root} style={{height: '100%', overflowY: 'auto'}}>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={3}>
                        <Box p={1} pl={0}>
                            <Typography variant="h6">Social Media Post Generator</Typography>
                        </Box>
                        <Box sx={{backgroundColor: "#DEEBFF", alignItems: "start", display: 'flex', mb: 1, mt: 1}}>
                            <InfoIcon sx={{mt: 1, mr: 1, ml: 1, color: "#4B72C2", verticalAlign: ''}}/>
                            <Typography sx={{mt: 1, mb: 1, mr: 1, fontSize: 14}} component="span"> This section defines the link preview image that is shown
                                when your position is shared on social media and other communication platforms (like LinkedIn, WhatsApp, Facebook, Twitter, and
                                more).

                                To learn more, <Link href='#'>watch this quick video</Link>
                            </Typography>
                        </Box>
                        {/* <Box p={1}>
              <Typography variant="h7">Use this section to generate a highlight image to promote your open position. This image will also be used in link previews when you share the link on social media.
              </Typography>
            </Box> */}
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <h3>Preview</h3>
                    <Box sx={{overflowX: "scroll"}}>
                        <Box ref={ref} id="preview" sx={{width:740, margin: "auto"}}>
                            {/* DOM nodes you want to convert to PNG */
                                <Card sx={{display: 'flex', flexWrap: 'wrap',}}>
                                    <CardActionArea sx={{minWidth:740}}>
                                        <CardMedia
                                            sx={{
                                                height: 380,
                                                width: 740,
                                            }}
                                            image={selectedBackground}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '20px',
                                                left: '20px',
                                                color: 'white',
                                            }}
                                        >
                                            <Rnd
                                                onDragStop={(e, d) => {
                                                    setAttentionX(d.x);
                                                    setAttentionY(d.y);
                                                    handlePositionChange(e, d);
                                                }}
                                                onResizeStop={(e, direction, ref, delta, position) => {
                                                    handleSizeChange(ref);
                                                    setAttentionWidth(ref.style.width);
                                                    setAttentionHeight(ref.style.height);
                                                }}
                                                className={classes.hoverbox}
                                                ref={c => {
                                                    attentionBox = c;
                                                }}
                                                default={{
                                                    x: attentionX,
                                                    y: attentionY,
                                                    width: attentionWidth,
                                                    height: attentionHeight,
                                                }}
                                            >
                                                {
                                                    svg ? (
                                                        <svg viewBox="0 -5 185 175">
                                                            <defs>
                                                                <linearGradient
                                                                    id="rainbow"
                                                                    x1="0" x2="100%" y1="0" y2="0"
                                                                    gradientUnits="userSpaceOnUse"
                                                                >
                                                                    <stop stopColor={additionalStyle.stopColor1} offset={additionalStyle.offset1}/>
                                                                    <stop stopColor={additionalStyle.stopColor2} offset={additionalStyle.offset2}/>
                                                                </linearGradient>
                                                            </defs>
                                                            <text fill="url(#rainbow)">
                                                                <tspan
                                                                    x="0"
                                                                    y="30"
                                                                    style={Object.assign({}, {
                                                                        fontSize: fontSize,
                                                                        color: fontColor,
                                                                        fontFamily: activeFontFamily
                                                                    }, additionalStyle)} className="apply-font-attention"
                                                                >
                                                                    {format(text1)}
                                                                </tspan>
                                                            </text>
                                                        </svg>
                                                    ) : (
                                                        <div className={classes.attention}>
                                                            <div
                                                                style={Object.assign({}, {
                                                                    fontSize: fontSize,
                                                                    color: fontColor,
                                                                    fontFamily: activeFontFamily
                                                                }, additionalStyle)}
                                                                className="apply-font-attention"
                                                            >
                                                                {format(text1)}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {/*<div className={classes.attention}>*/}
                                                {/*    <div */}
                                                {/*        style={Object.assign({}, {*/}
                                                {/*        fontSize: fontSize,*/}
                                                {/*        color: fontColor,*/}
                                                {/*        fontFamily: activeFontFamily*/}
                                                {/*    }, additionalStyle)}*/}
                                                {/*        className="apply-font-attention"*/}
                                                {/*    >*/}
                                                {/*        {format(text1)}*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </Rnd>
                                            <Rnd
                                                onDragStop={(e, d) => {
                                                    setSubX(d.x);
                                                    setSubY(d.y);
                                                    handlePositionChange(e, d);
                                                }}
                                                onResizeStop={(e, direction, ref, delta, position) => {
                                                    handleSizeChange(ref);
                                                    setSubWidth(ref.style.width);
                                                    setSubHeight(ref.style.height);
                                                }}
                                                className={classes.hoverbox}
                                                ref={c => {
                                                    subBox = c;
                                                }}
                                                default={{
                                                    x: subX,
                                                    y: subY,
                                                    width: subWidth,
                                                    height: subHeight,
                                                }}
                                                // onDragStop = {(e,d) => {handlePositionChange(e,d)}}
                                                // onResizeStop={(e, direction, ref, delta, position) => {handleSizeChange(ref)}}
                                                //   className={classes.hoverbox}
                                                //   ref={c => { subBox = c; }}
                                                //   default={{
                                                //     x: subX,
                                                //     y: subY,
                                                //     width: 500,
                                                //     height: 200,
                                                //   }}
                                            >
                                                <Typography
                                                    style={
                                                        Object.assign({}, subAdditionalStyle, {
                                                            fontSize: fontSize2,
                                                            color: fontColor2,
                                                            fontFamily: activeFontFamily2
                                                        })
                                                    }
                                                >
                                                    {format(text2)}
                                                </Typography>
                                            </Rnd>
                                            <Rnd
                                                onDragStop={(e, d) => {
                                                    setCompanyLogoX(d.x);
                                                    setCompanyLogoY(d.y);
                                                    handlePositionChange(e, d);
                                                }}
                                                onResizeStop={(e, direction, ref, delta, position) => {
                                                    handleSizeChange(ref);
                                                }}
                                                className={classes.hoverbox}
                                                ref={c => {
                                                    logoBox = c;
                                                }}
                                                default={{
                                                    x: companyLogoX,
                                                    y: companyLogoY,
                                                    width: 220,
                                                    height: 60,
                                                }}
                                                // onDragStop = {(e,d) => {handlePositionChange(e,d)}}
                                                // onResizeStop={(e, direction, ref, delta, position) => {handleSizeChange(ref)}}
                                                //   className={classes.hoverbox}
                                                //   ref={c => { logoBox = c; }}
                                                //   default={{
                                                //     x: 0,
                                                //     y: 0,
                                                //     width: 220,
                                                //     height: 60,
                                                //   }}
                                            >
                                                <img height="60px" src={imageData.logoUrl}/>
                                            </Rnd>
                                            <Rnd
                                                onDragStop={(e, d) => {
                                                    setFooterX(d.x);
                                                    setFooterY(d.y);
                                                    handlePositionChange(e, d);
                                                }}
                                                onResizeStop={(e, direction, ref, delta, position) => {
                                                    handleSizeChange(ref);
                                                }}
                                                className={classes.hoverbox}
                                                ref={c => {
                                                    footerBox = c;
                                                }}
                                                default={{
                                                    x: footerX,
                                                    y: footerY,
                                                    width: 220,
                                                    height: 60,
                                                }}
                                                // onDragStop = {(e,d) => {handlePositionChange(e,d)}}
                                                // onResizeStop={(e, direction, ref, delta, position) => {handleSizeChange(ref)}}
                                                //   className={classes.hoverbox}
                                                //   ref={c => { footerBox = c; }}
                                                //   default={{
                                                //     x: 348,
                                                //     y: 322,
                                                //     width: 200,
                                                //     height: 60,
                                                //   }}
                                            >
                                                <Box
                                                    sx={{
                                                        fontSize: footerFontSize,
                                                        color: footerColor,
                                                        display: 'flex',
                                                        alignItems: 'end',
                                                        left: 0,
                                                        bottom: 0,
                                                        width: "100%",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Box
                                                        component="img"
                                                        height="24px"
                                                        src="https://iopophirepublicprod.s3.amazonaws.com/posting_images/Asset+8.png"
                                                        alt="PopHire"
                                                    />
                                                    <Typography sx={{marginLeft: 1, marginTop: 1}}>Powered by PopHire</Typography>
                                                </Box>
                                            </Rnd>
                                        </Box>
                                    </CardActionArea>
                                </Card>
                            }
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs>
                    <h3>Select background image</h3>
                    <Grid container direction="column">
                        <Grid item sx={{width: "100%"}}>
                            <Box>
                                <ImageGallery
                                    ref={i => imageGallery = i}
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    onClick={changeBackground}
                                    items={images}
                                />
                            </Box>
                        </Grid>
                        <Grid item ml={1} mt={1}>
                            <h3 style={{marginBottom: 0}}>Announcement Text</h3>
                        </Grid>
                        <Grid item margin={1}>
                            <FontPicker
                                pickerId="attention"
                                variants={["500italic"]}
                                apiKey="AIzaSyAbNzMjfj99oWbkn-z03hxRKX8VcVQJ3w8"
                                //activeFontFamily={activeFontFamily}
                                onChange={(nextFont) =>
                                    setActiveFontFamily(nextFont.family)
                                }
                            />
                            <IconButton
                                color="primary"
                                aria-label="increase font"
                                component="span"
                                onClick={increaseFontSize}
                                size="large">
                                <Box>
                                    <FormatSizeIcon/>
                                    <KeyboardArrowUpIcon/>
                                </Box>
                            </IconButton>
                            <IconButton
                                color="primary"
                                aria-label="decrease font"
                                component="span"
                                onClick={decreaseFontSize}
                                size="large">
                                <Box>
                                    <FormatSizeIcon/>
                                    <KeyboardArrowDownIcon/>
                                </Box>
                            </IconButton>
                            <IconButton
                                color="primary"
                                aria-label="select font color"
                                component="span"
                                onClick={pickFontColor}
                                size="large">
                                <FormatColorTextIcon/>
                            </IconButton>
                            {displayColorPicker ? <div style={popover}>
                                <div style={cover} onClick={handleColorClose}/>
                                <SketchPicker
                                    color={fontColor}
                                    onChangeComplete={handleColorChangeComplete}
                                />
                            </div> : null}
                        </Grid>
                        <Grid item margin={1}>
                                <textarea
                                    className={classes.attentionText}
                                    rows="4"
                                    onChange={handleChange}
                                    value={text1}/>
                        </Grid>
                        <Grid item ml={1} mt={1} mb={0}>
                            <h3 style={{marginBottom: 0}}>Subtitle Text</h3>
                        </Grid>
                        <Grid item margin={1}>
                            <FontPicker
                                pickerId="sub"
                                apiKey="AIzaSyAbNzMjfj99oWbkn-z03hxRKX8VcVQJ3w8"
                                //activeFontFamily={activeFontFamily2}
                                onChange={(nextFont) =>
                                    setActiveFontFamily2(nextFont.family)
                                }
                            />
                            <IconButton
                                color="primary"
                                aria-label="increase font"
                                component="span"
                                onClick={increaseFontSize2}
                                size="large"
                            >
                                <Box>
                                    <FormatSizeIcon/>
                                    <KeyboardArrowUpIcon/>
                                </Box>
                            </IconButton>
                            <IconButton
                                color="primary"
                                aria-label="decrease font"
                                component="span"
                                onClick={decreaseFontSize2}
                                size="large"
                            >
                                <Box>
                                    <FormatSizeIcon/>
                                    <KeyboardArrowDownIcon/>
                                </Box>
                            </IconButton>
                            <IconButton
                                color="primary"
                                aria-label="decrease font"
                                component="span"
                                onClick={pickFontColor2}
                                size="large">
                                <FormatColorTextIcon/>
                            </IconButton>
                            {displayColorPicker2 ? <div style={popover}>
                                <div style={cover} onClick={handleColorClose2}/>
                                <SketchPicker
                                    color={fontColor2}
                                    onChangeComplete={handleColorChangeComplete2}
                                />
                            </div> : null}
                        </Grid>
                        <Grid item margin={1}>
                            <textarea className={classes.attentionText} rows="4" value={text2} onChange={handleChangeSub}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default GenerateSocialMediaTemplate;
