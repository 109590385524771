import React from 'react';
import {CssBaseline} from "@mui/material";
import Box from "@mui/material/Box";
import SideBar from "./SideBar";
import AppBar from "./AppBar";
import Footer from "./Footer";
import {useTheme} from "@emotion/react";
import {useLocation} from "react-router-dom";
import {useAuthState} from "../auth/auth";

export const appBarHeight = 48;
export const footerHeight = 24;

const Navigation = (props) => {
    const theme = useTheme();
    const location = useLocation();
    const {authState} = useAuthState();
    const [drawerOpen, setDrawerOpen] = React.useState(true);
    const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);

    const drawerRender = !authState.loading && authState.isAuthenticated && !location.pathname.startsWith("/positions")

    const handleDrawerToggle = () => {
        setDrawerOpen(open => !open);
    };

    const handleMobileDrawerToggle = () => {
        setMobileDrawerOpen(open => !open);
    };

    const screenHeight = window.screen.height;
    const contentHeight = (screenHeight - footerHeight - appBarHeight - Number(theme.spacing(1).slice(0, -2))) * 100 / screenHeight;
    const contentHeightXs = (screenHeight - footerHeight - appBarHeight - Number(theme.spacing(15).slice(0, -2))) * 100 / screenHeight;

    return (
        <React.Fragment>
            <CssBaseline/>
            <AppBar drawerRender={drawerRender} drawerOpen={drawerOpen} onMobileDrawerToggle={handleMobileDrawerToggle}>
                <SideBar
                    drawerRender={drawerRender}
                    drawerOpen={drawerOpen}
                    onDrawerToggle={handleDrawerToggle}
                    mobileDrawerOpen={mobileDrawerOpen}
                    onMobileDrawerToggle={handleMobileDrawerToggle}
                />
                <Footer drawerRender={drawerRender} drawerOpen={drawerOpen}/>
                {/** -------------------------------- pages -------------------------------- */}
                {/** add height: 'inherit' to fit content and add pb: 2 for padding bottom*/}
                <Box sx={{mt: 2, px: 2, width: '100%', height: {
                        xs:`${contentHeightXs}vh`,
                        sm:`${contentHeight}vh`
                    }}}>
                    <Box sx={{height: 'inherit', pb: 3,}}>
                        {props.children}
                    </Box>
                </Box>
                {/** -------------------------------- /pages -------------------------------- */}
            </AppBar>
        </React.Fragment>
    );
}

export default Navigation;
