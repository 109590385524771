import React from "react";

export const referralStatus = {
    NEW: 'New',
    SUBMITTED: 'Not Started',
    IN_PROGRESS: 'In Progress',
    HIRE: 'Hired',
    PAID: 'Paid',
    COMPANY_PAID_POPHIRE_PENDING: 'Pending Disbursement',
    COMPANY_PAID_REFERRER_SETUP_INCOMPLETE: 'Pending Referrer Action',
    COMPANY_PAID_REFERRER_SETUP_INCOMPLETE_REFERRER: 'Company Paid, Complete Payment Setup to Receive Reward',
    ERROR: 'Error',

    NO_HIRE: 'Rejected',
};

export const referralStatusPicker = (state, noHire) => {
    if (noHire) {
        return referralStatus.NO_HIRE;
    }

    switch (state) {
        case 1:
            return referralStatus.NEW;
        case 2:
            return referralStatus.SUBMITTED;
        case 3:
            return referralStatus.IN_PROGRESS;
        case 4:
            return referralStatus.HIRE;
        case 5:
            return referralStatus.PAID;
        case 6:
            return referralStatus.PAID;
        default:
            return referralStatus.ERROR;
    }
};
export const referralHomeStatusPicker = (state, noHire, paymentSetupComplete) => {
    if (noHire) {
        return referralStatus.NO_HIRE;
    }

    switch (state) {
        case 1:
            return referralStatus.NEW;
        case 2:
            return referralStatus.SUBMITTED;
        case 3:
            return referralStatus.IN_PROGRESS;
        case 4:
            return referralStatus.HIRE;
        case 5:
            
            return referralStatus.PAID;
        case 6:
            if (!paymentSetupComplete)
                return referralStatus.COMPANY_PAID_REFERRER_SETUP_INCOMPLETE_REFERRER;
            return referralStatus.PAID;
        default:
            return referralStatus.ERROR;
    }
};

export const positionReferralStatusColorPicker = (status) => {
    switch (status) {
        case referralStatus.NEW:
        case referralStatus.SUBMITTED:
            return theme => ({color: theme.palette.common.black, background: theme.palette.common.black});
        case referralStatus.IN_PROGRESS:
            return theme => ({color: theme.palette.warning.dark, background: theme.palette.warning.dark});
        case referralStatus.HIRE:
            return theme => ({color: theme.palette.success.dark, background: theme.palette.success.dark});
        case referralStatus.PAID:
            return theme => ({color: theme.palette.info.dark, background: theme.palette.info.dark});
        case referralStatus.NO_HIRE:
        case referralStatus.ERROR:
            return theme => ({color: theme.palette.error.dark, background: theme.palette.error.dark});
        default:
            return (theme) => ({color: theme.palette.common.black, background: theme.palette.common.black});
    }
};

export const referrerReferralStatusColorPicker = (status) => {
    switch (status) {
        case referralStatus.NEW:
        case referralStatus.SUBMITTED:
            return theme => ({color: theme.palette.common.black, background: theme.palette.common.black});
        case referralStatus.IN_PROGRESS:
            return theme => ({color: theme.palette.warning.dark, background: theme.palette.warning.dark});
        case referralStatus.HIRE:
            return theme => ({color: theme.palette.success.dark, background: theme.palette.success.dark});
        case referralStatus.COMPANY_PAID_REFERRER_SETUP_INCOMPLETE_REFERRER:
        case referralStatus.PAID:
            return theme => ({color: theme.palette.info.dark, background: theme.palette.info.dark});
        case referralStatus.NO_HIRE:
        case referralStatus.ERROR:
            return theme => ({color: theme.palette.error.dark, background: theme.palette.error.dark});
        default:
            return (theme) => ({color: theme.palette.common.black, background: theme.palette.common.black});
    }
};
