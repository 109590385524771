import React, {useEffect, useState} from "react";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import axios from 'axios';

import {useAuthState} from "../auth/auth";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {DataGrid} from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import {blue, green, red} from "@mui/material/colors";
import DoneIcon from "@mui/icons-material/Done";
import NumberFormat from 'react-number-format';
import StateChip from "../shared/State/StateChip";
import CustomToolbar from "../shared/DataGrid/Toolbar/CustomToolbar";
import CustomLoadingOverlay from "../shared/DataGrid/Overlay/CustomLoadingOverlay";
import CustomNoRowsOverlay from '../shared/DataGrid/Overlay/CustomNoRowsOverlay';
import {referralHomeStatusPicker, referralStatus, referrerReferralStatusColorPicker} from "../components/Referral/ReferralStateHelper";
import GridViewIcon from '@mui/icons-material/GridView';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import {getModulePreferences, updateModulePreferences} from "../shared/preferences";
import {ButtonGroup, IconButton} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {format, parseJSON} from 'date-fns';

export default function UserReferralsListPage(props) {
    const theme = useTheme();
    const {authState} = useAuthState();
    const headers = authState.headers;
    const [paymentInfoSetup, setPaymentInfoSetup] = useState(false);
    const [referrals, setReferrals] = useState([]);
    const [displayMode, setDisplayMode] = useState("card"); 

    function getPaymentState() {
        axios.get('/api/account/payment/status', {headers: headers}).then(res => {
            return res.data;
        }).then(data => {
            if (data.customer_id !== "" && data.payouts_enabled) {
                setPaymentInfoSetup(true)
            }

        });
    }

    function getMyReferrals() {
        axios.get('/api/account/referrals', {headers: headers})
            .then(res => res.data)
            .then(data => {
                // transactions can have the same referral_id if the company has paid and pophire has transfered, we need to only show the latest
                //   status for a given referral id, where "latest" means newest success date
                let referrals = data.referrals;
                const referralMap = {};
                let foundDups = false;
                data.referrals.forEach(referral => {
                    if (referralMap[referral.referral_id]) {
                        foundDups = true;
                        // check if the successdate is greater than the stored version, if it is, replace

                        const firstDate = new Date(referralMap[referral.referral_id].transaction_created_at);
                        const secondDate = new Date(referral.transaction_created_at);
                        if (firstDate.getTime() < secondDate.getTime()) {
                            referralMap[referral.referral_id] = referral;
                        }
                    } else {
                        referralMap[referral.referral_id] = referral;
                    }
                });

                // now, convert back to array
                if (foundDups) {
                    referrals = Object.keys(referralMap).map((x) => referralMap[x]);
                }
                setReferrals(referrals);
            });
    }

    // get position id so that we can get pay amount TODO: sort out warm vs cold, default to warm for now
    useEffect(() => {
        if (!authState?.isAuthenticated) {
            // When user isn't authenticated, forget any user info
        } else {
            const prefs = getModulePreferences(authState.user.user_preferences, "referrals");
            // if we have a state stored, lets use it
            if (prefs?.position_display)
                setDisplayMode(prefs.position_display);
            getPaymentState();
            getMyReferrals();
        }
    }, [authState]); // Update if authState changes


    const setupPaymentInfo = () => {
        console.log("in setup payment info click")
        axios.post('/api/account/payment/setup', {}, {headers: headers}).then(res => {
            return res.data;
        }).then(data => {
            console.log(data)
            window.location = data.setup_payment_account_url
        });
    }

    function getStatus(status) {
        // compute status based on trx and account setup
        // if paymentInfoSetup and trx.status == 1, it means company has paid but pophire hasn't disbursed show "Pending Disbursement"
        // if paymentInfoSetup == false and trx.status == 1 it means account setup not complete, show "Finish setup"
        // if trx.status == 2 it means fully paid "Paid" check stripe for full status?
        if (status === "1" && paymentInfoSetup)
            return <Chip style={{backgroundColor: blue[300], color: "white"}} label="Pending Payment"/>
        else if (status === "1" && !paymentInfoSetup)
            return <Chip style={{backgroundColor: red[300], color: "white"}} label="Finish setup"/>
        else if (status === "2")
            return <Chip style={{backgroundColor: green[300], color: "white"}} avatar={<DoneIcon style={{color: "white"}}/>} label="Paid"/>
    }

    function getPaymentFormated(amt) {
        // convert from cents to dollars
        const payAmt = amt / 100;
        return <NumberFormat value={payAmt} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2}/>
    }

    function getReferralStatus(status, noHire, isPaymentInfoSetup) {

        const chipLabel = referralHomeStatusPicker(status, noHire, isPaymentInfoSetup);
        let variant = "outlined";
        let width = 110;
        if (chipLabel === referralStatus.PAID)
            variant = "filled";
        if (chipLabel === referralStatus.COMPANY_PAID_REFERRER_SETUP_INCOMPLETE_REFERRER)
            width = 400;
        return <StateChip width={width} variant={variant} label={chipLabel} colorPicker={referrerReferralStatusColorPicker}/>
    }

    const renderDataGridView = () => {
        return <Paper elevation={3} sx={{
            height: '85%',
        }}
        >
            {/* <Typography variant="h6">Your Referrals</Typography> */}
            <DataGrid
                components={{
                    Toolbar: CustomToolbar,
                    LoadingOverlay: CustomLoadingOverlay,
                    NoRowsOverlay: CustomNoRowsOverlay,
                }}
                componentsProps={{
                    toolbar: {
                        title: 'Your Referrals',
                        minimalView: true,
                    },
                }}
                getRowId={row => row.referral_id}
                rows={referrals}
                columns={columns}
            />
        </Paper>
    }

    const renderCardView = () => {
        return (
            <Box>
                <Grid container>
                    {
                        referrals.map((trx) => {
                            return (
                                <Grid xs={12} sm={6} lg={4} item key={trx.referral_id} sx={{display: "flex", justifyContent: "space-evenly"}}>
                                    <Paper sx={{maxWidth: 300, minWidth: 300, minHeight: 200, m: 2, p: 2}}>
                                        <Typography sx={{fontSize: 18, fontWeight: 600, mb: 1}}>{trx.candidate_name}</Typography>
                                        <Typography>
                                            Referred to: {trx.position_name}
                                        </Typography>
                                        <Box sx={{ml: 2, mr: 1}}>
                                            <Typography>
                                                at: {trx.company_name}
                                            </Typography>
                                            <Typography>
                                                on: {trx.submitted_at ? format(parseJSON(trx.submitted_at), 'MM/dd/yyyy h:mm a') : ""}
                                            </Typography>
                                            <Typography>
                                                for: {getPaymentFormated(trx.pay_amount_net_cents)}
                                            </Typography>
                                        </Box>
                                        <Box sx={{display: "flex", justifyContent: 'flex-end', alignItems: 'center', pr: 1}}>
                                            <Typography sx={{fontSize: 12, fontWeight: 700, mr: 2}} component="span">{"Status".toUpperCase()}</Typography>
                                            {getReferralStatus(trx.referral_status, trx.no_hire, paymentInfoSetup)}
                                        </Box>
                                    </Paper>
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </Box>
        );
    }

    const columns = [
        // { field: 'referral_id', headerName: 'Id', width: 150,  },
        {field: 'company_name', headerName: 'Company Name', minWidth: 200},
        {field: 'position_name', headerName: 'Position Name', minWidth: 200},
        {
            field: 'referral_status',
            headerName: 'Referral Status',
            minWidth: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                // build inputs that we need
                getReferralStatus(params.row.referral_status, params.row.no_hire, paymentInfoSetup)


            )
        },
        {field: 'candidate_name', headerName: 'Candidate Name', minWidth: 200},
        {
            field: 'pay_amount_net_cents',
            headerName: 'Reward Amount',
            minWidth: 120,
            flex: 1,
            renderCell: (params) => (
                getPaymentFormated(params.row.pay_amount_net_cents)
            ),
        },
        {
            field: 'submitted_at', headerName: 'Submitted at', minWidth: 180,
            valueGetter: (params) => params.value && format(parseJSON(params?.value), 'MM/dd/yyyy h:mm a'),
        },
        // { field: 'payment_status', headerName: 'Payment Status', width: 150, 
        // renderCell: (params) => (
        //     // build inputs that we need
        //     getStatus(params.row.payment_status)

        // ),},
    ];

    const renderData = () => {
        if (displayMode === "card") {
            return renderCardView();
        } else {
            return renderDataGridView();
        }
    }

    const switchToCard = () => {
        if (displayMode !== "card")
            setDisplayMode("card");
        // save preference
        updateModulePreferences("referrals", {position_display: "card"}, headers);
    }
    const switchToGrid = () => {
        if (displayMode !== "grid")
            setDisplayMode("grid");
        updateModulePreferences("referrals", {position_display: "grid"}, headers);
    }

    return (
        <Box sx={{mb: 4, height: '100%', color: 'text.primary', margin: "0 auto !important", overflowY: 'scroll'}}>
            <Typography sx={{fontWeight: 700, color: 'text.primary'}} component="h1" variant="h4" align="center">
                Welcome back, {authState.user?.first_name}!
            </Typography>
            <Box sx={{mb: 1}}>
                <ButtonGroup>
                    <IconButton sx={{color: displayMode === "card" ? theme.palette.success.main : "primary"}}
                                onClick={switchToCard}><GridViewIcon/></IconButton>
                    <IconButton sx={{color: displayMode === "grid" ? theme.palette.success.main : "primary"}}
                                onClick={switchToGrid}><ViewComfyOutlinedIcon/></IconButton>
                </ButtonGroup>
            </Box>
            {renderData()}
        </Box>
    );
}
