import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Typography from "@mui/material/Typography";
import {Divider, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import redirect from "../../../redirect";
import api from "../../../api";
import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useAuthState} from "../../../auth/auth";
import {positionStatus} from "./PositionsStatusHelper";
import GroupsIcon from '@mui/icons-material/Groups';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PositionActions = ({selectedPosition, companyId, onAction}) => {
    const history = useHistory();
    const {authState} = useAuthState();

    const handleActionClick = (action) => {
        action().finally(() => onAction());
    };

    return <List>
        <ListItem>
            <ListItemText sx={{ml:2,mr:2,mb:1,mt:1, textAlign:'center'}} primary={`${!!selectedPosition?.title ? selectedPosition?.title : 'Unnamed'}`}/>
        </ListItem>
        <Divider variant="middle" sx={{width: "100%", margin: 0}}/>
        <Typography variant="caption" sx={{paddingLeft: 1}}>Actions:</Typography>
        <ListItem disablePadding>
            
            <ListItemButton disabled={selectedPosition?.status === positionStatus.DRAFT} onClick={() => redirect.referrals.list(history, companyId, selectedPosition?.id)}>
            <ListItemIcon><GroupsIcon/></ListItemIcon>
                <ListItemText primary="View Position Referrals"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <CopyToClipboard text={`${window.location.protocol}//${window.location.host}/positions/${selectedPosition?.id}`}>
                <ListItemButton disabled={selectedPosition?.status === positionStatus.DRAFT} onClick={()=>{
                    toast.success('External Link copied to Clipboard', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored"
                        });
                }}>
                <ListItemIcon><ContentCopyIcon/></ListItemIcon>
                    <ListItemText primary="Copy Position External Link"/>
                </ListItemButton>
            </CopyToClipboard>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton onClick={() => redirect.positions.edit(history, companyId, selectedPosition?.id)}>
                <ListItemIcon><EditIcon/></ListItemIcon>
                <ListItemText primary="Edit Position"/>
            </ListItemButton>
        </ListItem>
        {/* <ListItem disablePadding>
            <ListItemButton onClick={() => redirect.positions.edit(history, companyId, selectedPosition?.id)}>
                <ListItemIcon><ContentCopyIcon/></ListItemIcon>
                <ListItemText primary="Copy Position"/>
            </ListItemButton>
        </ListItem> */}
        <ListItem disablePadding>
            <ListItemButton disabled={selectedPosition?.status === positionStatus.DRAFT}
                            onClick={() => handleActionClick(() => api.positions.close(companyId, selectedPosition?.id, authState.headers))}>
                <ListItemIcon><CheckCircleOutlineIcon/></ListItemIcon>
                <ListItemText primary="Close Position"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton disabled={selectedPosition?.status !== positionStatus.DRAFT}
                            onClick={() => handleActionClick(() => api.positions.del(companyId, selectedPosition?.id, authState.headers))}>
                <ListItemIcon><DeleteOutlineIcon/></ListItemIcon>
                <ListItemText primary="Delete Position"/>
            </ListItemButton>
        </ListItem>
        <ToastContainer />
    </List>;
};

export default PositionActions;
