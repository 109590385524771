import Paper from "@mui/material/Paper";
import React, {useState} from "react";
import Box from "@mui/material/Box";
import {IconButton} from "@mui/material";
import ReferralNotesAndActions from "./ReferralNotesAndActions";
import Grid from "@mui/material/Grid";
import PositionBreadcrumbs from "./PositionBreadcrumbs";
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import {useHistory} from "react-router-dom";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LinkIcon from '@mui/icons-material/Link';
import {useTheme} from "@mui/material/styles";
import ReferrerInfoDisplay from "../Referrer/ReferrerInfoDisplay";
import CandidateInfoDisplay from "../Candidate/CandidateInfoDisplay";

export const getLinkIcon = (url, blue, theme) => {
    if (url.toLowerCase().includes("linkedin")) {
        return <LinkedInIcon
            sx={{
                verticalAlign: 'bottom',
                color: blue ? theme.palette.linkedin.dark : "",
                mr: 1
            }}
        />
    }
    return <LinkIcon sx={{verticalAlign: 'bottom', mr: 1,}}/>
}

const SelectedReferralGrid = (props) => {

    const theme = useTheme();
    const history = useHistory();

    const {
        companyId, positionId, referral, transactions, onReferralBegin, onReferralNoHire, onReferralHire, onReferralPayout, reloadNotes,
    } = props;

    const [transactionsExpanded, setTransactionsExpanded] = useState(true);

    const transactionsExists = transactions?.length > 0;

    const getCrumbs = () => {
        return [
            {
                to: '/home',
                icon: <HomeIcon sx={{mr: 0.5}} fontSize="inherit"/>,
                name: 'Home',
            }, {
                to: `/company/${companyId}/positions`,
                icon: <GroupsIcon sx={{mr: 0.5}} fontSize="inherit"/>,
                name: 'Position Dashboard',
            }, {
                to: `/company/${companyId}/positions/${positionId}/referrals`,
                icon: <PeopleIcon sx={{mr: 0.5}} fontSize="inherit"/>,
                name: 'Referrals',
            }, {
                to: `/company/${companyId}/positions/${positionId}/referral/${referral.id}`,
                icon: <PersonIcon sx={{mr: 0.5}} fontSize="inherit"/>,
                name: `${referral.candidate_name}`,
                last: true
            },
        ];
    }

    const goBack = () => {
        history.goBack();
    }
    const getReferralInfo = () => {
        if (!referral.referrer_is_candidate) {
            return <ReferrerInfoDisplay
            aboutReferrerText={referral.about_referrer_text}
            referrerEmail={referral.referrer_email}
            referrerIsCandidate={referral.referrer_is_candidate}
            referrerName={referral.referrer_name}
            referrerPhone={referral.referrer_phone}
            referrerUrl={referral.referrer_url}
        />
        }
    }

    return (
        <Box sx={{height: 'inherit', overflowY: 'auto', pb: 2}}>
            <Box sx={{overflow: "hidden"}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{pb: 1}}
                >
                    <Grid item xs={11}><PositionBreadcrumbs crumbs={getCrumbs()}/></Grid>
                    <Grid item xs={1} sx={{textAlign: "right"}}><IconButton onClick={goBack} sx={{mr: 1}}><CloseIcon color="primary"/></IconButton></Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                    <CandidateInfoDisplay
                        companyId={companyId}
                        positionId={positionId}
                        referrerIsCandidate={referral.referrer_is_candidate}
                        isCandidateDuplicate={referral.is_candidate_duplicate}
                        candidateEmail={referral.candidate_email}
                        candidateLevelOfInterest={referral.candidate_level_of_interest}
                        isCandidateInterested={referral.is_candidate_interested}
                        candidateName={referral.candidate_name}
                        candidatePhone={referral.candidate_phone}
                        candidateResumeUrl={referral.candidate_resume_url}
                        candidateUrl={referral.candidate_url}
                        howDoYouKnowCandidate={referral.how_do_you_know_candidate}
                        aboutCandidateText={referral.about_candidate_text}
                        aboutCandidateUrl={referral.about_candidate_url}
                    />
                    {getReferralInfo()}
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Paper variant="outlined" sx={{height: "auto"}}>
                        {/* <Typography sx={{m:1}}>Actions And Notes</Typography> */}
                        <ReferralNotesAndActions
                            companyId={companyId}
                            positionId={positionId}
                            selectedReferral={referral}
                            transactions={transactions}
                            onReferralBegin={onReferralBegin}
                            onReferralHire={onReferralHire}
                            onReferralNoHire={onReferralNoHire}
                            onReferralPayout={onReferralPayout}
                            reloadNotes={reloadNotes}
                        />
                    </Paper>
                </Grid>
            </Grid>
            {/* <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>Referral Info</Typography>
                </AccordionSummary>
                <AccordionDetails> */}
            {/* <Paper sx={{height: '100%'}} elevation={0}> */}
            {/* <ReferralInfo selectedReferral={referral}/> */}
            {/* </Paper> */}
            {/* </AccordionDetails>
            </Accordion> */}
            {/* <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>Actions And Notes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ReferralNotesAndActions
                        companyId={companyId}
                        positionId={positionId}
                        selectedReferral={referral}

                        onReferralBegin={onReferralBegin}
                        onReferralHire={onReferralHire}
                        onReferralNoHire={onReferralNoHire}
                        onReferralPayout={onReferralPayout}
                        reloadNotes={reloadNotes}
                    />
                </AccordionDetails>
            </Accordion> */}
            {/* <Accordion
                disabled={!transactionsExists}
                expanded={transactionsExists && transactionsExpanded}
                onChange={() => setTransactionsExpanded(prevExpanded => !prevExpanded)}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>Payment Transactions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {
                            transactions?.map((transaction, index) => (
                                <Grid key={index} item xs={12} sm={6}>
                                    <TransactionCard paymentComplete={onReferralPayout} transaction={transaction}/>
                                </Grid>
                            ))
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion> */}
        </Box>
    );
};

export default SelectedReferralGrid;
