import React from "react";
import red from "@mui/material/colors/red";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import {withStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import {Box} from "@mui/material";
import {Avatar} from "@mui/material";

const styles = (theme) => ({
    icon: {
        // margin: theme.spacing.unit * 2
    }, iconHover: {
        margin: theme.spacing.unit * 2, "&:hover": {
            color: red[800]
        }
    }, input: {
        display: "none"
    }, secondaryButton: {
        color: "gray", margin: 10
    }, typography: {
        margin: theme.spacing.unit * 2, backgroundColor: "default"
    },

    searchRoot: {
        padding: "2px 4px", display: "flex", alignItems: "center", width: 400
    }, searchInput: {
        marginLeft: 8, flex: 1
    }, searchIconButton: {
        padding: 10
    }, searchDivider: {
        width: 1, height: 28, margin: 4
    }
});

class ImageUpload extends React.Component {

    state = {
        mainState: "initial", // initial, search, gallery, uploaded
        imageUploaded: 0, selectedFile: null,
    };

    handleUploadClick = event => {
        console.log("upload clicked");
        var file = event.target.files[0];
        const reader = new FileReader();
        var url = reader.readAsDataURL(file); // need to keep this line so tht the reader reads the file

        reader.onloadend = function (e) {
            this.setState({
                selectedFile: [reader.result]
            });
            const ret = {target: {value: e.target.result}};
            this.props.handleChange("logo_file", ret);
        }.bind(this);

        this.setState({
            mainState: "uploaded", selectedFile: event.target.files[0], imageUploaded: 1
        });
    };

    handleSearchClick = event => {
        this.setState({
            mainState: "search"
        });
    };

    handleGalleryClick = event => {
        this.setState({
            mainState: "gallery"
        });
    };

    renderInitialState() {
        const {classes, theme} = this.props;
        const {value} = this.state;

        return (
            <React.Fragment>

                <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={this.handleUploadClick}
                />
                <label htmlFor="contained-button-file">
                    <Button variant="contained" component="span" className={classes.button} sx={{width: 56, height: 56}}>
                        <AddPhotoAlternateIcon/>
                    </Button>
                </label>

            </React.Fragment>
        );
    }

    handleAvatarClick(value) {
        var filename = value.url.substring(value.url.lastIndexOf("/") + 1);
        console.log(filename);
        this.setState({
            mainState: "uploaded", imageUploaded: true, selectedFile: value.url, fileReader: undefined, filename: filename
        });
    }

    renderUploadedState() {
        return (
            <Avatar
                component={Button}
                onClick={this.imageResetHandler}
                variant="rounded"
                src={this.state.selectedFile?.toString()}
                sx={{width: 56, height: 56}}
            >
                {this.props.companyName?.charAt(0)}
            </Avatar>
        );
    }

    imageResetHandler = (event) => {
        this.setState({
            mainState: "initial", selectedFile: null, imageUploaded: 0
        });
    };

    componentDidMount() {
        if (this.props.selectedFile != null) {
            this.setState({
                mainState: "uploaded", selectedFile: this.props.selectedFile, imageUploaded: 1
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedFile != null && prevProps.selectedFile != this.props.selectedFile) {
            this.setState({
                mainState: "uploaded", selectedFile: this.props.selectedFile, imageUploaded: 1
            });
        }
    }

    render() {
        return (
            <Box>
                {
                    (this.state.mainState === "initial" && this.renderInitialState())
                    // || (this.state.mainState == "search" && this.renderSearchState())
                    // || (this.state.mainState == "gallery" && this.renderGalleryState())
                    || (this.state.mainState === "uploaded" && this.renderUploadedState())
                }
            </Box>
        );
    }
}

export default withStyles(styles, {withTheme: true})(ImageUpload);
