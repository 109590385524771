import React from "react";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import ElevationScroll from "../../shared/AppBar/ElevationScroll";
import {Typography, useScrollTrigger} from "@mui/material";
import {SaveStateColorPicker, saveStatePicker} from "../Formik/SaveStateHelper";
import {useTheme} from "@emotion/react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const AutoSaveAppBar = (props) => {
    const theme = useTheme();

    const {formik, scrollTarget, showCloudSave} = props;

    let saveState;

    let error = null;
    // const [showCloudMessage, setShowCloudMessage] = useState(true);

    if (Object.keys(formik.errors).length > 0) {
        saveState = saveStatePicker("error");
        error = Object.values(formik.errors)[0]
    } else if (formik.values.error != null) {
        saveState = saveStatePicker("error");
        error = formik.values.error
    } else {
        saveState = saveStatePicker(formik.status.state);
    }

    if (formik.status?.state === "not_submitted") {
        saveState = saveStatePicker("not_submitted");
    }

    // if (saveState.toLowerCase() == "saved" && !showCloudMessage){
    //     setShowCloudMessage(true);
    // }

    // useEffect(() => {
    //     if (showCloudMessage) {
    //       const timeout = setTimeout(
    //           () => 
    //             setShowCloudMessage(false), 10000);
    //       return () => {
    //         clearTimeout(timeout);
    //       };
    //     }
    //   }, [showCloudMessage]);

    const scrollTrigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: scrollTarget
    });

    return (
        
        <ElevationScroll scrollTrigger={scrollTrigger}>
            <AppBar position="sticky">
                    
                <Toolbar
                    variant="dense"
                    style={{padding: 0}}
                    sx={{placeContent: "flex-end", bgcolor: theme.palette.background.default, display:showCloudSave?'':'hidden'}}
                >
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            lg={3}
                            sx={{textAlign: "center"}}
                        >
                            <Typography variant="h4" component="div" sx={{color: "black", pb: 0.5}}>{props.title}</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={4}
                            sx={{textAlignLast: "center", alignSelf: "center", justifyContent: "right"}}
                        >
                            <Typography
                                sx={{
                                    pb: {xs: 0.5, lg: 0},
                                    display: error ? "inherit" : "none",
                                    paddingRight: theme.spacing(2),
                                    wordBreak: "break-word",
                                    textAlign: '-webkit-right',
                                }}
                                color="error"
                            >
                                {error}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={5}
                            sx={{display: 'flex', alignItems: 'center', justifyContent: "right"}}
                        >
                            <Box sx={{mr: 3}}>
                                <Box sx={{color: 'black'}}>{SaveStateColorPicker(saveState)}</Box>
                                {/* <StateChip label={saveState} colorPicker={saveStateColorPicker}/> */}
                            </Box>
                            {props.actions}
                        </Grid>
                    </Grid>
                </Toolbar>
                
                <Box
                    sx={{
                        placeContent: "flex-end",
                        bgcolor: theme.palette.background.default,
                        padding: {xs: 0.75, lg: 0.25}
                    }}
                >
                    {props.children}
                </Box>
            </AppBar>
        </ElevationScroll>
    );
};

export default AutoSaveAppBar;
