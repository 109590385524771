export const validateUrl = (urlString, hostname) => {
    if (urlString === null || urlString === "") {
        return {value: "", isError: false, error: "", href: ""};
    }

    let url;

    try {
        url = new URL(urlString);
    } catch (e) {
        return {value: urlString, isError: true, error: "invalid url", href: ""};
    }

    if (url.protocol !== "https:" && url.protocol !== "http:") {
        return {value: urlString, isError: true, error: "invalid protocol (use http: or https:)", href: ""};
    }

    if (hostname !== null && !url.hostname.toLowerCase().includes(hostname.toLowerCase())) {
        return {value: urlString, isError: true, error: "invalid url for " + hostname, href: ""};
    }

    return {value: urlString, isError: false, error: "", href: url.href};
}
