import PropTypes from 'prop-types'
import React, {useEffect} from 'react';
import {useTheme} from "@emotion/react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ReferralActions from "./Actions/ReferralActions";
import ReferralNotes from "./Notes/ReferralNotes";

const ReferralNotesAndActions = (props) => {

    const {
        companyId, positionId, selectedReferral, transactions,
        onReferralHire, onReferralPayout, onReferralBegin, onReferralNoHire, reloadNotes
    } = props;

    const selectedReferralId = selectedReferral?.id
    const theme = useTheme();

    //const scrollRef = React.createRef();

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         scrollRef?.current?.scrollIntoView({behavior: "smooth", block: 'end'});
    //     }, 400)

    //     return () => {
    //         clearTimeout(timer);
    //     };
    // }, [scrollRef]);

    return (
        <Grid
            container
            spacing={2}
            direction="column"
            //justifyContent="center"
            //alignItems="stretch"
        >
            <Grid
                item
                xs={12}
                sm={6}
                order={{xs: 1, sm: 2}}
                sx={{pb: 0,}}
                style={{paddingTop: theme.spacing(2)}}
            >
                <Box sx={{height: "100%"}}>
                    {/* <Paper sx={{height: "100%"}} variant="outlined"> */}
                        <ReferralActions
                            referral={selectedReferral}
                            transactions={transactions}
                            onReferralHire={onReferralHire}
                            onReferralPayout={onReferralPayout}
                            onReferralBegin={onReferralBegin}
                            onReferralNoHire={onReferralNoHire}
                        />
                    {/* </Paper> */}
                </Box>
                {/* <Box ref={scrollRef}/> */}
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
                order={{xs: 1, sm: 2}}
                sx={{pb: 0}}
                style={{paddingTop: theme.spacing(1)}}
            >
                <Box sx={{height: "100%"}}>
                    {/* <Paper variant="outlined"> */}
                        <ReferralNotes
                            companyId={companyId}
                            positionId={positionId}
                            referralId={selectedReferralId}
                            reloadNotes={reloadNotes}
                        />
                    {/* </Paper> */}
                </Box>
            </Grid>
        </Grid>
    );
};

export default ReferralNotesAndActions;

ReferralNotesAndActions.propTypes = {
    companyId: PropTypes.string.isRequired,
    onReferralBegin: PropTypes.func.isRequired,
    onReferralHire: PropTypes.func.isRequired,
    onReferralNoHire: PropTypes.func.isRequired,
    onReferralPayout: PropTypes.func.isRequired,
    positionId: PropTypes.string.isRequired,
    reloadNotes: PropTypes.object.isRequired,
    selectedReferral: PropTypes.object.isRequired
}
