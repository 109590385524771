import React, {useEffect, useState} from "react";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {useAuthState} from "../../auth/auth";
import CardInfo from "./CardInfo";
import {LoadingButton} from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {Box} from "@mui/material";
import AddNewCard from "./AddNewCard";

const SavedCardsList = (props) => {

    const [succeeded, setSucceeded] = useState(false);
    const [addCardPressed, setAddCardPressed] = useState(false);
    const [savedCards, setSavedCards] = useState([]);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [disabled, setDisabled] = useState(true);
    let {companyId} = useParams();

    const stripe = useStripe();
    const elements = useElements();

    const {authState} = useAuthState();
    const headers = authState?.headers;

    useEffect(() => {
        getSavedCards();
    }, []);

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);

        const payload = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: window.location.href // redirect user back to this page TODO: Handle why this is a bad idea
            },
            redirect: "if_required",
        });

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            const element = elements.getElement(PaymentElement);
            setAddCardPressed(false);
            //element.clear();
            getSavedCards();
        }
    };

    const handleCardRemove = (cardId) => {
        const newList = savedCards.filter((item) => item.id !== cardId);
        setSavedCards(newList);
    }
    const handleSetDefaultCard = (newDefaultCardId) => {
        console.log("in handle")
        // update cards default status
        var copiedCards = savedCards.slice();
        copiedCards.forEach(card => {
            card.default = card.id === newDefaultCardId
        });
        setSavedCards(copiedCards);
    }

    const getSavedCards = () => {
        axios.get(`/api/company/${companyId}/payment/methods`, {headers: headers})
            .then(res => {
                return res.data;
            })
            .then(data => {
                if (data) {
                    setSavedCards(data);
                }
            });
    };

    const initializeNewCardCreate = () => {
        setAddCardPressed(true)
    }

    return (
        <Box sx={{pt: 4, pl: 4, pr: 4, pb: 2, textAlign: "-webkit-center"}}>
            <Grid container spacing={2}>
                <Grid key="addNewCard" item xs={12} sm={6} lg={4}>
                    <AddNewCard onAddNewCard={initializeNewCardCreate}/>
                </Grid>
                {
                    savedCards.map((card, index) => (
                        <Grid key={index} item xs={12} sm={6} lg={4}>
                            <CardInfo
                                key={card.id}
                                card={card}
                                onSetDefault={handleSetDefaultCard}
                                onRemove={handleCardRemove}
                            />
                        </Grid>
                    ))
                }
            </Grid>
            {
                addCardPressed ?
                    <Paper sx={{mt: 2, p: 2, mr: 2}} variant="outlined">
                        <PaymentElement id="payment-element" onChange={handleChange}/>
                        <LoadingButton
                            sx={{mt: 2}}
                            disabled={processing || disabled || succeeded}
                            onClick={handleSubmit}
                            loading={processing}
                            loadingPosition="start"
                            startIcon={<SaveIcon/>}
                            variant="outlined"
                        >
                            Save Card
                        </LoadingButton>
                        {/* Show any error that happens when processing the payment */}
                        {
                            !!error && (
                                <div className="card-error" role="alert">
                                    {error}
                                </div>
                            )
                        }
                        {/* Show a success message upon completion */}
                        {
                            succeeded ?
                                <p className="result-message">
                                    Save succeeded
                                </p>
                                : null
                        }
                    </Paper>
                    : null
            }
        </Box>
    );
};

export default SavedCardsList;
