import React from 'react'
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, RadioGroup, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";

const ReferralNoHireDialog = (props) => {

    const {open, onClose, failedButtonLabel, onNoHire, failReasons} = props

    const [reasonRadioValue, setReasonRadioValue] = React.useState(null);
    const [reason, setReason] = React.useState("");

    const renderTextFieldRadio = () => <FormControlLabel
        value="other"
        control={<Radio/>}
        label={
            <TextField
                variant="standard"
                placeholder="Other"
                required
                onFocus={() => setReasonRadioValue("other")}
                value={reason}
                onChange={event => setReason(event.target.value)}
            />
        }
    />;

    return (
        <Dialog disableEscapeKeyDown open={open} onClose={onClose}>
            <DialogTitle>Select Reason</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={reasonRadioValue}
                            onChange={(event) => setReasonRadioValue(event.target.value)}
                        >
                            {failReasons.map(failReason => <FormControlLabel key={failReason} value={failReason} control={<Radio/>} label={failReason}/>)}
                            {renderTextFieldRadio()}
                        </RadioGroup>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions sx={{m: 2, mt: 0}}>
                <Button
                    disabled={reasonRadioValue === "other" && reason === ""}
                    onClick={() => onNoHire(reasonRadioValue === "other" ? reason : reasonRadioValue)}
                    variant="outlined"
                    color="primary"
                >
                    Reject and send notifications
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReferralNoHireDialog
