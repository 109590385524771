import React from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Avatar from '@mui/material/Avatar';

import {centsTo$} from '../../../../shared/money';

import makeStyles from '@mui/styles/makeStyles';
import HelpTooltip from "../../../../shared/Tooltip/HelpTooltip";
import { Box } from '@mui/system';

const useStyles = makeStyles((theme) => ({
    avatar: {
        color: 'white',
        backgroundColor: 'white',
    },
}));

const AwardDescription = ({awardCents, variant, sx, hint}) => {
    const classes = useStyles();

    const warmTooltipTitle=["- You know the candidate personally", "- You know that the candidate is interested", "- You can help to arrange a first meeting with the candidate"];
    const coldTooltipTitle=["- You are not in connection with the candidate", "- You think that the candidate may be interested in this position"];

    return (
        <Box
              sx={{
                bgcolor: 'rgba(251, 238, 236)',
              }}
            >
        <ListItem sx={sx}>
            <ListItemAvatar>
                <Avatar className={classes.avatar}>
                    {variant === 'cold' ? <AcUnitIcon/> : <FavoriteIcon style={{ color: 'red' }}/>}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={centsTo$(awardCents, "")}
                secondary={hint || `For a ${variant} intro that leads to a hire`}
            />
            <ListItemSecondaryAction>
                <HelpTooltip
                    tooltipTitle={variant === 'cold' ? coldTooltipTitle : warmTooltipTitle}
                    icon={<ErrorOutlineRoundedIcon/>}/>
            </ListItemSecondaryAction>
        </ListItem>
        </Box>
    )
}

export default AwardDescription;
