import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Typography from '@mui/material/Typography';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ConfirmImage from '../images/confirmcheckmark.png'
import {CircularProgress} from "@mui/material";

const CandidateConfirmInterestPage = (props) => {

    // use guid to look up position details (name, company)? Or, instead of that complexity, just pass position id in querystring
    // where do we store guid? on job submission table. Then we can look up position from guid...
    // get guid from path
    const [positionDetails, setPositionDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const {id} = useParams(); // this is a guid, need to map in routes

    useEffect(() => {
        axios.post(`/api/candidate/${id}/interested`, id)
            .then(resp => {
                const data = resp.data;

                if (data !== null || data !== {}) {
                    if (data.error) {
                        setError(resp);
                    } else {
                        setPositionDetails(data);
                    }
                } else {
                    setError({data: {error: {message: "load error"}}});
                }
            })
            .catch(resp => setError(resp))
            .finally(resp => setLoading(false))
    }, [id]);

    if (loading) {
        return (
            <Box sx={{display: 'flex', justifyContent: "space-around", height: '90vh', alignItems: 'center'}}>
                <CircularProgress size={80}/>
            </Box>
        );
    }

    return (
        <Box sx={{width: '100%', pt: 5, display: 'flex', justifyContent: 'center'}}>
            <Paper sx={{maxWidth: '800px'}} elevation={3}>
                <Box p={4} m={4} sx={{justifyContent: 'center'}}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Box component='img' sx={{width: '200px'}} src={ConfirmImage}/>
                    </Box>
                    <Typography sx={{pt: 5, pb: 3, textAlign: 'center', fontSize: 25, fontWeight: 600}}>Thank you</Typography>
                    {
                        error ? (
                            <Typography sx={{pb: 4, ml: 8, mr: 8, fontSize: 18, textAlign: 'center'}}>
                                {error?.data?.error?.message}
                            </Typography>
                        ) : (
                            <React.Fragment>
                                <Typography sx={{pb: 4, ml: 8, mr: 8, fontSize: 18, textAlign: 'center'}}>
                                    for confirming your interest in the {positionDetails.position_name} at {positionDetails.company_name}!
                                </Typography>
                                <Typography sx={{ml: 8, mr: 8, fontSize: 18, textAlign: 'center'}}>
                                    We'll be in touch after we've had a chance to review everything.
                                </Typography>
                            </React.Fragment>
                        )
                    }
                </Box>
            </Paper>
        </Box>
    )
};

export default CandidateConfirmInterestPage;
