import React from "react";

export const positionStatus = {
    ANY: 'ANY',
    DRAFT: "DRAFT",
    PUBLISHED: "PUBLISHED",
    CLOSED: "CLOSED",
    CLOSED_AND_PAID: "CLOSED_AND_PAID",
};

export const positionStatusColorPicker = (status) => {
    switch (status) {
        case positionStatus.ANY:
            return theme => ({color: theme.palette.common.black});
        case positionStatus.DRAFT:
            return theme => ({color: theme.palette.common.black,});
        case positionStatus.PUBLISHED:
        case 'ACTIVE':
            return theme => ({color: theme.palette.success.main});
        case positionStatus.CLOSED_AND_PAID:
            return theme => ({color: theme.palette.success.dark});
        case positionStatus.CLOSED:
            return theme => ({color: theme.palette.error.dark});
        default:
            return (theme) => ({color: theme.palette.common.black});
    }
};
export const positionStatusName = (status) => {
    switch (status) {
        case positionStatus.PUBLISHED:
            return "ACTIVE";
        default:
            return status;
    }
};
