import {ClickAwayListener, Divider, Fade, ListItemButton, ListItemText, Popper} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import {useHistory} from "react-router-dom";
import {useAuthState} from "../auth/auth";
import redirect from "../redirect";
import {useTheme} from "@emotion/react";


const UserMenu = (props) => {
    const theme = useTheme();
    const history = useHistory();
    const {authState} = useAuthState();

    const isAuthenticated = authState?.isAuthenticated;
    const userInitials = authState?.user?.first_name?.[0];

    const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null);
    const [userMenuOpen, setUserMenuOpen] = React.useState(false);

    const handleUserMenuClick = (event) => {
        setUserMenuAnchorEl(event.currentTarget);
        setUserMenuOpen((previousOpen) => !previousOpen);
    };

    const handleSignOut = () => {
        handleUserMenuClose();
        authState?.signOut();
    };

    const handleUserMenuClose = () => {
        setUserMenuOpen(false);
    };

    const handleMyCompanies = () => {
        handleUserMenuClose();
        redirect.company.list(history);
    };
    const handleMyReferrals = () => {
        handleUserMenuClose();
        history.push("/referrals")
    };

    const handleSignIn = () => {
        handleUserMenuClose();
        authState?.signIn();
        //history.push('/login');
    };

    const renderUserMenuContent = () => {
        const userName = `${authState?.user?.first_name} ${authState?.user?.second_name}`;

        return (
            <Paper>
                <Box sx={{display: "grid", placeItems: "center", marginTop: 1.5, padding: 2}}>
                    {
                        isAuthenticated && (
                            <React.Fragment>
                                <Avatar imgProps={{referrerPolicy:"no-referrer"}} src={authState?.idTokeClaims?.picture} sx={{width: 64, height: 64}}/>
                                {/* {userInitials?.split(' ').map(item => item[0]).join('')}</Avatar> */}
                                <Typography variant="subtitle2" sx={{marginTop: 1}}>{userName}</Typography>
                                <Typography variant="caption">{authState?.user?.email}</Typography>
                                <Button onClick={() => {
                                    handleUserMenuClose();
                                    history.push("/user/profile");
                                    }} variant="outlined" sx={{marginTop: 2, marginBottom: 2}}>Edit Account</Button>
                            </React.Fragment>
                        )
                    }
                    <Divider variant="middle" sx={{width: "100%"}}/>
                    <List sx={{width: "100%", textAlignLast: "center", padding: 0}}>
                        {
                            authState?.userCompanies?.length > 0 && (
                                <ListItem disablePadding>
                                    <ListItemButton onClick={handleMyCompanies}>
                                        <ListItemText primary="Companies"/>
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleMyReferrals}>
                                <ListItemText primary="Referrals"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider variant="middle" sx={{width: "100%"}}/>
                    {
                        isAuthenticated ?
                            <Button onClick={handleSignOut} variant="outlined" sx={{marginTop: 2}}>Sign Out</Button>
                        :
                            <Button onClick={handleSignIn} variant="outlined" sx={{marginTop: 2}}>Sign In</Button>
                    }
                </Box>
            </Paper>
        );
    };

    return (
        <ClickAwayListener onClickAway={handleUserMenuClose}>
            <div>
                <IconButton
                    sx={{padding: 0.5, margin: 0.25}}
                    aria-label="current user menu"
                    aria-controls="user-menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    size="large"
                    onClick={handleUserMenuClick}
                >
                    {/* <Avatar sx={{width: 32, height: 32}}>{userInitials?.split(' ').map(item => item[0]).join('')}</Avatar> */}
                    <Avatar imgProps={{referrerPolicy:"no-referrer"}} src={authState?.idTokeClaims?.picture} sx={{width: 40, height: 40}}/>
                </IconButton>
                <Popper open={userMenuOpen} anchorEl={userMenuAnchorEl} placement="bottom-end" transition style={{width: "100%", maxWidth: "355px", zIndex: theme.zIndex.appBar + 1}}>
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps} timeout={100}>
                            {renderUserMenuContent()}
                        </Fade>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

export default UserMenu;
