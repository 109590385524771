import React, {useEffect, useState} from 'react';
import StyledSkeleton from "../shared/Skeleton/StyledSkeleton";
import {useAuthState} from "../auth/auth";
import {useParams} from "react-router-dom";
import SelectedReferralGrid from "../components/Referral/Company/SelectedReferralGrid";
import api from "../api";

const CompanyReferralPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [referral, setReferral] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [reloadNotes, setReloadNotes] = useState({});

    const {companyId, positionId, referralId} = useParams();
    const {authState} = useAuthState();

    const headers = authState?.headers;
    

    const loadReferrals = () => {
        setLoading(true);

        api.referrals.showToCompany(companyId, positionId, referralId, headers)
            .then(response => {
                setReferral(response.data.referral);
                setTransactions(response.data.transactions)
            })
            .finally(() => setLoading(false))
    };

    useEffect(() => {
        if (!authState?.isAuthenticated) {
            return;
        }
        if (loading) {
            return;
        }

        loadReferrals();
    }, []);

    const handleReferralBegin = (referralId) => {
        setLoading(true);

        api.referrals.beginReferralHiring(companyId, positionId, referralId, headers)
            .then(response => {
                setReferral(response.data.referral)
                setTransactions(response.data.transactions)
            })
            .finally(() => {
                setLoading(false);
                setReloadNotes({});
            })
    };

    const handleReferralHire = (referralId) => {
        setLoading(true);

        api.referrals.hire(companyId, positionId, referralId, headers)
            .then(response => {
                setReferral(response.data.referral)
                setTransactions(response.data.transactions)
            })
            .finally(() => {
                setLoading(false);
                setReloadNotes({});
            })
    };
    const handleReferralPayout = (data) => { // this part is what we need to wire up
        setLoading(true);
        setReferral(data.referral)
        setTransactions(data.transactions)
        setLoading(false);
        setReloadNotes({});
    };

    const handleReferralNoHire = (referralId, reason) => {
        setLoading(true);

        api.referrals.noHire(companyId, positionId, referralId, reason, headers)
            .then(response => {
                setReferral(response.data.referral)
                setTransactions(response.data.transactions)
            })
            .finally(() => {
                setLoading(false);
                setReloadNotes({});
            })
    };

    const handleReferralTransactionPayout = (referralId, transactionId) => {
        setLoading(true);

        api.referrals.noHire(companyId, positionId, referralId, "no hire", headers)
            .then(response => {
                setReferral(response.data.referral)
                setTransactions(response.data.transactions)
            })
            .finally(() => {
                setLoading(false);
                setReloadNotes({});
            })
    };

    return (
        <React.Fragment>
            {
                referral != null
                    ? <SelectedReferralGrid
                        companyId={companyId}
                        positionId={positionId}
                        referral={referral}
                        transactions={transactions}

                        onReferralBegin={handleReferralBegin}
                        onReferralHire={handleReferralHire}
                        onReferralNoHire={handleReferralNoHire}
                        onReferralPayout={handleReferralPayout}
                        reloadNotes={reloadNotes}
                    />
                    : <StyledSkeleton/>
            }
            
        </React.Fragment>
    );
}

export default CompanyReferralPage;
