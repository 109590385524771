import { Chip, Typography } from "@mui/material";
import React from "react";
import StateChip from "../../../shared/State/StateChip";
import {positionStatusColorPicker, positionStatusName} from "./PositionsStatusHelper";

export const PositionStatusLabel = ({status}) => {

    if (!status) {
        return null;
    }

    return (
        // <Typography sx={positionStatusColorPicker(status)}>{positionStatusName(status)}</Typography>
        <StateChip label={positionStatusName(status)} colorPicker={positionStatusColorPicker}/>
        //<Chip label={positionStatusName(status)} color={positionStatusColorPicker(status)} variant="outlined"/>
    );

};

export default PositionStatusLabel;
