import makeStyles from "@mui/styles/makeStyles";
import React, {useMemo, useEffect, useState} from "react";
import {debounce} from "lodash";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CandidateFormUpdateBackground from "./CandidateFormUpdateBackground";
import Grid from "@mui/material/Grid";
import candidateIcon from "../../../../../../images/candidate_icon.png";
import {Box, Button, IconButton, Paper, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormikOutlinedTextField from "../../../../../../shared/Formik/FormikOutlinedTextField";
import UrlIcon from "../../../../../../shared/UrlIcon/UrlIcon";
import HelpTooltip from "../../../../../../shared/Tooltip/HelpTooltip";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import RecordLoomVideoButton from "../../../../../../shared/Loom/RecordLoomVideoButton";
import CollapseGrid from "../../../../../../shared/Collapse/CollapseGrid";
import FormikOutlinedControlledTextField from "../../../../../../shared/Formik/FormikOutlinedControlledTextField";
import MenuItem from "@mui/material/MenuItem";
import {DropzoneAreaBase} from "mui-file-dropzone";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContactForm from "../../../../../Contacts/ContactForm";
import {getCandidateInterestLevelDescription} from "../CandidateForm";
import StyledReactQuill from "../../../../../../shared/Quill/StyledReactQuill";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
        urlIcon: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            marginLeft: theme.spacing(1),
        },
        dropZone: {
            minHeight: "20px",
            paddingBottom: 16,
        },
        dropZoneText: {
            fontSize: 14
        }
    })
);

const referrerIsCandidateFields = [
    "candidate_name",
    "candidate_url",
    "about_candidate_text",
    "candidate_email",
    "candidate_phone",
    "candidate_texts_opt_in",
    "candidate_alt_contact_type",
    "candidate_alt_contact_value",
];

const SelfCandidateFormUpdate = ({formik, companyName}) => {
    const classes = useStyles();
    const [pipeLoaded, setPipeLoaded] = useState(false);
    const [pipeLoadCount, setPipeLoadCount] = useState(0);

    // const awardVariant = "warm";
    // const awardCents = position?.position_content_published[`${awardVariant}_award_cents`];

    const hasVideo = !!formik.values.about_candidate_url && formik.values.about_candidate_url !== '' && !formik.errors.about_candidate_url;

    const onInsertClicked = (shareLink) => {
        //formik.setStatus({state: "touched"});

        formik.setValues({
            ...formik.values,
            error: null,
            about_candidate_url: shareLink.embedUrl,
        });
    };

    const onChangeReferrerIsCandidateFlag = (event) => {
        let newCandidateFormValues;

        if (event.target.checked) {
            newCandidateFormValues = Object.fromEntries(referrerIsCandidateFields.map(field => [field, formik.values[field.replace("candidate", "referrer")]]));
            newCandidateFormValues.about_candidate_text = formik.values.about_referrer_text
        } else {
            newCandidateFormValues = Object.fromEntries(referrerIsCandidateFields.map(field => [field, formik.initialValues[field]]));
        }

        // formik.setStatus({state: "touched"});

        formik.setValues({
            ...formik.values,
            error: null,
            "referrer_is_candidate": event.target.checked,
            ...newCandidateFormValues,
        });
    };

    const removeVideo = () => {
        //formik.setStatus({state: "touched"});
        formik.setFieldValue("about_candidate_url", '');
       
    };

        const handleChange = (event, field) => {
            // formik.setStatus({state: "touched"});
            formik.setFieldValue(field, event.target.value,false);
            // formik.setValues({
            //     ...formik.values,
            //     error: null,
            //     [field]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
            //     [field.replace("candidate", "referrer")]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
            // });
        };
   

    const debounceOnChange = useMemo(() => debounce((event, field) => handleChange(event, field), 1500), []);

    const dropzoneFileObjects = [];
    if (formik.values.candidate_resume_url) {
        const parts = formik.values.candidate_resume_url.split('/');
        dropzoneFileObjects.push({file: {name: parts[parts.length - 1], type: ''}});
    }

    const getIcon = () => {
        return <CloudUploadIcon style={{color: 'rgba(105, 139, 90)'}}/>
    }

    const removeResume = () => {
        formik.setValues({
            ...formik.values,
            candidate_resume_files: [],
            candidate_resume_url: null,
            _destroy_candidate_resume: true
        }, false);
        // formik.setStatus({state: "touched"})
    }
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "//cdn.addpipe.com/2.0/pipe.js";
        script.async = true;
      
        document.body.appendChild(script);
        setPipeLoaded(true);
        // return () => {
        //   document.body.removeChild(script);
        //   setPipeLoaded(false);
        // }
      }, []);
    useEffect(() => {
        // const style = document.createElement('style');
      
        // style.src = "//cdn.addpipe.com/2.0/pipe.css";
        // style.async = true;
      
        // document.body.appendChild(style);
        const style = document.createElement("link");
        style.setAttribute("rel", "stylesheet");
        style.setAttribute("type", "text/css");
        style.setAttribute("href","//cdn.addpipe.com/2.0/pipe.css");
        document.head.appendChild(style);
      
        // return () => {
        //   document.body.removeChild(style);
        // }
      }, []);

      const checkAddPipeVideoAvailable = async (url) => {
        // tight loop and block the ui on this
        try {
            let res = await axios.get(url);
            console.log("checking url for ready video: " + url);
            formik.setFieldValue("about_candidate_url", url);
        } catch (err) {
            setTimeout(() => {
                console.log("didn't find video, checking again.");
                checkAddPipeVideoAvailable(url);
              }, 500);
        }
        // axios.get(url).catch((error)=>{
        //     setTimeout(() => {
        //         checkAddPipeVideoAvailable(url);
        //       }, 1000);
        // }).then((resp) => {
        //     formik.setFieldValue("about_candidate_url", url);
        // });
      }


      
      const pipeAccountHash = "e0e76c99bdd9b637d17a899a4aa512b7"
      const pipeParams1 = {size: {width:700,height:430}, qualityurl: "avq/360p.xml",accountHash:pipeAccountHash, eid:"IJnyGO", showMenu:"true", mrt:60,sis:0,asv:1,mv:0, dpv:0, ao:0, dup:0};
      useEffect(()=> {
        if (window.PipeSDK) {
            
            window.PipeSDK.insert('custom-id', pipeParams1, function(myRecorderObject){
                myRecorderObject.onVideoUploadSuccess = function(recorderId, filename,filetype,videoId,audioOnly,location){
                    var args = Array.prototype.slice.call(arguments);
                    console.log("onVideoUploadSuccess("+args.join(', ')+")");
                    let videoUrl = `https://${location}/${pipeAccountHash}/${filename}.mp4`;
                    checkAddPipeVideoAvailable(videoUrl);
                };
                myRecorderObject.onUploadDone = function(recorderId, streamName, streamDuration, audioCodec, videoCodec, fileType, audioOnly, location){
                    var args = Array.prototype.slice.call(arguments);
                    console.log("onUploadDone("+args.join(', ')+")");
                    let videoUrl = `https://${location}/${pipeAccountHash}/${streamName}.mp4`;
                    console.log(`Video url: ${videoUrl}`);
                    // with addpipe, the video won't be available immediately, let's use the poor man approach and poll for a few seconds then back off
                    checkAddPipeVideoAvailable(videoUrl);
                    
                };
             });
        }
        else {
            setPipeLoadCount(pipeLoadCount+1);    

        }
      }, [pipeLoaded, pipeLoadCount]);

    const handleAboutReferrerTextChange = (value) => {
        // formik.handleChange(event);
        formik.setFieldValue("about_candidate_text", value)
        // formik.setStatus({state: "touched"})
    }
    const handleAboutReferrerTextBlur = (previousRange, source, editor) => {
        // formik.handleChange(event);
        formik.setFieldValue("about_candidate_text", editor.getHTML())
        // formik.setStatus({state: "touched"})
    }

    return (
        <Paper elevation={0} variant="outlined" sx={{pb: 4}}>
            <CandidateFormUpdateBackground>
                <Grid container pl={4} pr={4} pb={1} spacing={2} alignContent="flex-start" alignItems="flex-start" mt={2}>
                    <Grid item xs={12} sm={12}>
                        <Grid container direction='column' alignItems='center'>
                            <Grid item xs={12}>
                                <Grid container direction="row" columnSpacing={2} justifyContent="center" alignItems="center">
                                    <Grid item>
                                        <img style={{height: '60px'}} src={candidateIcon} alt="Candidate"/>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            sx={{
                                                verticalAlign: 'middle',
                                                pb: 1,
                                                fontWeight: 600,
                                                color: 'text.primary'
                                            }}
                                            variant="h6"
                                        >
                                            Let's get to know you
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormikOutlinedTextField
                            fullWidth
                            formik={formik}
                            fieldName="candidate_name"
                            label="Name"
                            required
                            onChange={debounceOnChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormikOutlinedTextField
                            fullWidth
                            formik={formik}
                            fieldName="candidate_url"
                            label="Link to professional profile"
                            required
                            onChange={debounceOnChange}
                            InputProps={{
                                endAdornment: <UrlIcon className={classes.urlIcon} url={formik.values.candidate_url}/>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{mt: 1, textAlign: "center", display: "flex"}}>
                    <Typography>Tell us about yourself with a video!</Typography>
                        {/* <FormikOutlinedTextField
                            formik={formik}
                            fieldName="about_candidate_url"
                            label="Tell us about yourself with a video! Record a Loom below or put a link here: "
                            fullWidth
                            onChange={debounceOnChange}
                        /> */}
                        {/* <HelpTooltip
                            sx={{p: 1}}
                            tooltipTitle={["If you are looking for inspiration, take a look at the manager notes for this position"]}
                            icon={<ErrorOutlineRoundedIcon/>}
                        /> */}
                        {/*{*/}
                        {/*    <Link style={{paddingLeft: 14, paddingTop: 0}} href="https://www.loom.com/" target="_blank">*/}
                        {/*        Loom is a great free tool for this*/}
                        {/*    </Link>*/}
                        {/*}*/}
                    </Grid>
                    <Grid item sx={{display:hasVideo?"none":"block"}}>
                            <Box sx={{display: {xs:'none', sm:'block'}}}>
                                <RecordLoomVideoButton
                                    hasVideo={hasVideo}
                                    onInsertClicked={onInsertClicked}
                                    removeVideo={removeVideo}
                                />
                            </Box>
                            <Box sx={{display: {xs:'block', sm:'none'}}}>
                                <Box sx={{pl:2, display:hasVideo?"none":"block"}}>
                                    <div id="custom-id"></div>
                                </Box>
                            </Box>
                        {/* <Box sx={{pl:2}}>
                            <div id="custom-id"></div>
                        </Box> */}
                    </Grid>
                    {/* <Grid item xs={12} sm={12} sx={{mb: 1, textAlign: "center"}} style={{paddingTop: 8}}>
                        <RecordLoomVideoButton
                            hasVideo={hasVideo}
                            onInsertClicked={onInsertClicked}
                            removeVideo={removeVideo}
                        />
                    </Grid> */}
                    <CollapseGrid in={hasVideo}>
                        <Grid item xs={12} sm={12} style={{textAlign: "center"}}>
                            <div style={{position: "relative", paddingBottom: "56.25%", height: 0}}>
                                <iframe
                                    src={formik.values.about_candidate_url}
                                    frameBorder="0"
                                    allowFullScreen
                                    style={{"position": "absolute", "top": 0, "left": 0, "width": "100%", "height": "100%"}}
                                />
                            </div>
                            <Button variant="outlined" color="primary" onClick={removeVideo}
                                aria-label="remove video" startIcon={<DeleteIcon />}>
                                Remove Recording
                            </Button>
                        </Grid>
                    </CollapseGrid>
                    <Grid item xs={12} sm={12}>
                    <Typography>Or if you prefer, write about yourself here</Typography>
                                <StyledReactQuill
                                    //disabled={disabled}
                                    isError={Boolean(formik.errors.about_candidate_text)}
                                    errorMessage={formik.errors.about_candidate_text}
                                    defaultValue={formik.values.about_candidate_text}
                                    onBlur={handleAboutReferrerTextBlur}
                                    onChange={handleAboutReferrerTextChange}
                                />
                        {/* <FormikOutlinedTextField
                            formik={formik}
                            fieldName="about_candidate_text"
                            label=""
                            multiline
                            maxRows={4}
                            fullWidth
                            onChange={debounceOnChange}
                        /> */}
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                        {/*<PdfDropzone
                             fileObjects={[...formik.values.candidate_resume_files || [], ...dropzoneFileObjects]}
                             onAdd={(files) => {
                                 formik.setFieldValue("candidate_resume_files", files.map(file=>{
                                     console.log(Object.keys(file));
                                     return ({...file});
                                 }));
                                 formik.setStatus({state: "touched"})
                             }}
                        />*/}
                        <DropzoneAreaBase
                            dropzoneClass={classes.dropZone}  // for drop zone container style your height... here
                            dropzoneParagraphClass={classes.dropZoneText}
                            fileObjects={[...formik.values.candidate_resume_files || [], ...dropzoneFileObjects]}
                            acceptedFiles={['application/pdf']}
                            filesLimit={1}
                            //showFileNames={true}
                            //showPreviews={true}
                            showPreviewsInDropzone={false}
                            Icon={getIcon}
                            dropzoneText="Upload your resume (optional)"
                            onAdd={(files) => {
                                formik.setFieldValue("candidate_resume_files", files);
                                // formik.setStatus({state: "touched"})
                            }}
                            onDelete={() => {
                                formik.setValues({
                                    ...formik.values,
                                    candidate_resume_files: [],
                                    candidate_resume_url: null,
                                    _destroy_candidate_resume: true
                                }, false);
                                // formik.setStatus({state: "touched"})
                            }}
                        />
                        {
                            formik.values.candidate_resume_url && (
                                <Box component='span'>
                                    <a
                                        download
                                        target="_blank"
                                        rel="noopener noreferrer"

                                        href={formik.values.candidate_resume_url}
                                    >
                                        {formik.values.candidate_resume_url.split('/').pop()}
                                    </a>
                                    <IconButton onClick={removeResume}><DeleteOutlineOutlinedIcon/></IconButton>
                                </Box>
                            )
                        }
                    </Grid>

                    <ContactForm
                        formik={formik}
                        onChange={debounceOnChange}
                        prefix="candidate_"
                        title={`How can ${companyName} contact you?`}
                    />
                    {/*{*/}
                    {/*    <Grid item xs={12} sm={12}>*/}
                    {/*        {(!!awardCents) && <List><AwardDescription awardCents={awardCents} variant={awardVariant}/></List>}*/}
                    {/*    </Grid>*/}
                    {/*}*/}
                </Grid>
            </CandidateFormUpdateBackground>
        </Paper>
    );
};

export default SelfCandidateFormUpdate;
