import React,{useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useAuthState} from "./auth/auth";
import { v4 as uuidv4 } from 'uuid';



  const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(2),
            padding: theme.spacing(1),
        },
    },
}));

const Register = () => {
    const classes = useStyles();
    const history = useHistory();
    const { search } = useLocation();
    const {authState} = useAuthState();
    
    useEffect(() => {
        
        let query = new URLSearchParams(search);
        let shouldRegisterAsCompany = query.get("c")

        if (shouldRegisterAsCompany != null && shouldRegisterAsCompany.toLowerCase() === "y"){
            // store register company indicator in local storage and redirect to the sign up page
            var id = uuidv4();
            
            // check if we have a referral id in local storage, if we do
            
            let key = `shouldCreateCompany-${id}`;
            localStorage.setItem(key,"y")
            authState.signIn({"screen_hint":"signup"})
        }
    }, []);
    

    const registerAsCompany = () => {
        history.push('/login?r=y&userType=company')
    };
    const registerAsReferrer = () => {
        authState.signIn({"screen_hint":"signup"})
    };

    return (
        <div className={classes.root}>
            <Button variant="contained" onClick={registerAsCompany}>
                <VisibilityIcon />
                    Sign up to hire
            </Button>
            <Button variant="contained" onClick={registerAsReferrer}>
                <VisibilityIcon />
                    Sign up to Refer
            </Button>

        </div>
    );
};
export default Register;
