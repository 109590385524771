import React, {useEffect, useState} from "react";

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {useHistory, useParams} from 'react-router-dom';
import axios from 'axios';
import {useAuthState} from './auth/auth';
import Box from '@mui/material/Box';
import CardSelector from "./components/payments/CardSelector";
import NumberFormat from 'react-number-format';

export default function PayReferrer(props) {

    const {authState} = useAuthState();
    const headers = authState?.headers;
    let {companyId} = useParams();
    let {positionId} = useParams();
    let {referralId} = useParams();
    const [transactionId, setTransactionId] = useState(props.paymentDetails.transactionId);
    const [referrerName, setReffererName] = useState('');
    const [positionName, setPositionName] = useState('');
    const [paymentAmount, setPaymentAmount] = useState(props.paymentDetails.RewardAmount);
    const [pophireServiceFee, setPophireServiceFee] = useState(props.paymentDetails.PopHireFee);
    const [orderTotal, setOrderTotal] = useState(props.paymentDetails.TotalPaymentAmount);
    const [ccFee, setccFee] = useState(0);
    const [candidateName, setCandidateName] = useState('');
    const [selectedCard, setSelectedCard] = useState({});
    const [paymentDetails, setPaymentDetails] = useState(props.paymentDetails);


    // get position id so that we can get pay amount TODO: sort out warm vs cold, default to warm for now
    useEffect(() => {
        // if(!authState?.isAuthenticated) {
        //   // When user isn't authenticated, forget any user info
        // } else {
        //   // get all relevant payment details
        //   axios.get(`/api/company/${companyId}/positions/${positionId}/referrals/${referralId}/stagepayment`, {headers: headers}).then(res => {
        //     return res.data;
        //   })
        //   .then(data => {
        //     setPaymentDetails(data.staged_details);
        //     const rewardAmountDollars = (data.staged_details.PaymentAmount/100);
        //     setPaymentAmount(rewardAmountDollars);
        //     var popFee = (rewardAmountDollars / data.staged_details.PopHireFeePercent);
        //   setPophireServiceFee(popFee)
        //   var cFee = (rewardAmountDollars * 0.029) + 0.3
        //   // set to zero for now
        //   cFee = 0;
        //   setccFee(cFee)
        //   var total = rewardAmountDollars + popFee + cFee
        //   setOrderTotal(total);
        //   }).catch(err => {
        //     console.error(err);
        //   });

        getSavedCards();

        //}
    }, []); // Update if authState changes

    const history = useHistory();
    // const useStyles = makeStyles((theme) => ({
    //     root: {
    //         display: 'flex',
    //         flexWrap: 'wrap',
    //         '& > *': {
    //             //margin: theme.spacing(2),
    //             //padding: theme.spacing(1),
    //             //width: theme.spacing(16),
    //             //height: theme.spacing(16),
    //         },
    //     },

    // }));

    // const classes = useStyles();

    function getSavedCards() {
        axios.get('/api/company/' + companyId + '/payment/methods', {headers: headers}).then(res => {
            return res.data;
        }).then(data => {
            if (data) {
                var defaultCard = data.find(item => {
                    return item.default == true
                });
                if (typeof defaultCard !== "undefined") {
                    setSelectedCard(defaultCard)
                }
            }
        });
    }

    const onCardSelected = (card) => {
        setSelectedCard(card);
    }

    const makePayment = () => {
        var payload =
            {
                positionId: Number(positionId),
                referralId: Number(referralId),
                paymentMethodId: selectedCard.id
            }
        axios.put(`/api/company/${companyId}/positions/${positionId}/referrals/${referralId}/transaction/${transactionId}/create_payment`, JSON.stringify({"payment_method_id": selectedCard.id}), {headers: headers}).then(res => {
            return res.data;
        })
            .then(data => {
                props.paymentComplete(data);
            }).catch(err => {
            console.error(err);
        });
    }

    const getText = () => {
        if (paymentDetails.ReferrerIsCandidate)
            return getApplyText();
        return getReferrerText();
    }

    const getApplyText = () => {
        return <Grid item xs={12}>
        <Box maxWidth={500}>Reward&nbsp;
            <Box fontWeight='fontWeightMedium' component="span">{paymentDetails.ReferrerName}</Box>
            &nbsp;with the&nbsp;
            <Box fontWeight='fontWeightMedium' component="span">
                <NumberFormat value={paymentAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true}
                              decimalScale={2}/> bounty</Box>
            &nbsp;for applying to the {paymentDetails.PositionName} role!
        </Box>
        <hr/>
    </Grid>
    }
    const getReferrerText = () => {
        return <Grid item xs={12}>
        <Box maxWidth={500}>Reward&nbsp;
            <Box fontWeight='fontWeightMedium' component="span">{paymentDetails.ReferrerName}</Box>
            &nbsp;with the&nbsp;
            <Box fontWeight='fontWeightMedium' component="span">
                <NumberFormat value={paymentAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true}
                              decimalScale={2}/> bounty</Box>
            &nbsp;for referring {paymentDetails.CandidateName} to the {paymentDetails.PositionName} role!
        </Box>
        <hr/>
    </Grid>
    }

    return (
        <div>
            {/* <Paper elevation={3}> */}
            <Box margin={1}>
                <Typography variant="h5">
                    Complete Payment
                </Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={7} margin={1}>
                    <Grid container direction='column' spacing={1}>
                        <Grid item xs={12}> <Typography variant="h6">Details</Typography></Grid>
                        {getText()}
                        <Grid item xs={12}> <Typography variant="h6">Payment Method</Typography></Grid>
                        <Grid item xs={12}>
                            <CardSelector onCardSelected={onCardSelected}></CardSelector>
                            <hr/>
                        </Grid>
                        
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Box m={2} p={1} border={1} borderRadius="10px">
                        <Grid container direction="column" spacing={1}>
                            <Grid item><Box fontWeight='fontWeightMedium' component="span">Order Summary</Box>
                                <hr/>
                            </Grid>
                            <Grid container item justifyContent="space-between">
                                <Grid item>Referral award:</Grid>
                                <Grid item><NumberFormat value={paymentAmount} displayType={'text'} thousandSeparator={true} prefix={'$'}
                                                         fixedDecimalScale={true} decimalScale={2}/></Grid>
                            </Grid>
                            <Grid container item justifyContent="space-between">
                                <Grid item>Platform service:</Grid>
                                <Grid item><NumberFormat value={pophireServiceFee} displayType={'text'} thousandSeparator={true} prefix={'$'}
                                                         fixedDecimalScale={true} decimalScale={2}/></Grid>
                            </Grid>
                            {/* <Grid container item justify="space-between">
                  <Grid item>Processing fee:</Grid>
                  <Grid item><NumberFormat value={ccFee} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></Grid>
                </Grid> */}
                            <Grid item>
                                <hr/>
                            </Grid>
                            <Grid container item justifyContent="space-between">
                                <Grid item><Box fontWeight='fontWeightMedium' color="#8b0000" component="span">Order Total</Box></Grid>
                                <Grid item><Box fontWeight='fontWeightMedium' color="#8b0000" component="span"><NumberFormat value={orderTotal}
                                                                                                                             displayType={'text'}
                                                                                                                             thousandSeparator={true}
                                                                                                                             prefix={'$'}
                                                                                                                             fixedDecimalScale={true}
                                                                                                                             decimalScale={2}/></Box></Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={3} md={2}>
                                <Button m={1} variant="contained" color="primary" onClick={makePayment}>Make Payment</Button>
                                </Grid>
                                <Grid item xs={12} sm md mt={{xs:1,sm:1}}>
                                <Box ml={{sm:1,xs:0}}
                                                                                                                               fontWeight='fontWeightMedium'
                                                                                                                               color="#8b0000" component="span">Order
                            Total: <NumberFormat value={orderTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true}
                                                 decimalScale={2}/></Box>
                                </Grid>
                            </Grid>
                             


                        </Grid>
            </Grid>
            {/* </Paper> */}
        </div>
    );
}
