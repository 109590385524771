import axios from 'axios';
import redirect from "../redirect";

export const create = (companyId, history, headers) => {
    return axios.post(`/api/company/${companyId}/positions`, {}, {headers: headers})
        .then(resp => redirect.positions.edit(history, companyId, resp.data.position.id))
}

export const list = (companyId, spec, headers) => {
    return axios.get(`/api/company/${companyId}/positions`, {params: spec, headers: headers})
}

export const close = (companyId, positionId, headers) => {
    return axios.put(`/api/company/${companyId}/positions/${positionId}/close`, {}, {headers: headers})
}

export const del = (companyId, positionId, headers) => {
    return axios.delete(`/api/company/${companyId}/positions/${positionId}`, {headers: headers})
}

export const loadWithContent = (companyId, positionId, headers) => {
    return axios.get(`/api/company/${companyId}/positions/${positionId}`, {headers: headers})
}

export const update = (companyId, positionId, positionPayload, headers) => {
    return axios.put(`/api/company/${companyId}/positions/${positionId}`, positionPayload, {headers: headers})
}

export const publish = (companyId, positionId, history, headers) => {
    return axios.get(`/api/company/${companyId}/positions/${positionId}/publish`, {headers: headers})
        .then(response => redirect.positions.list(history, companyId, positionId))
}
