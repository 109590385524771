import React, {useEffect, useState} from 'react';
import {Redirect, useHistory, useParams} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PeopleIcon from '@mui/icons-material/People';

import api from "../api";
import {useAuthState} from "../auth/auth";
import {Box, Grid} from '@mui/material';

const useStyles = makeStyles((theme) => (
    {
        pos: {}
    }
));

const CompanyHomePage = () => {
    const classes = useStyles();
    const history = useHistory();
    const {companyId} = useParams();
    const {authState} = useAuthState();
    const headers = authState?.headers;
    const currUserCompany = authState?.currUserCompany;
    const [hour, setHour] = useState(new Date());
    const getGreeting = () => {
        const message = hour < 12 ? "Good Morning" : "Good Evening";
        return `${message}, ${authState.user?.first_name}`;
    }
    useEffect(() => {
        const date = new Date();
        setHour(date.getHours());
    }, [currUserCompany]);

    const [creatingPosition, setCreatingPosition] = React.useState(false);

    if (authState.loading || currUserCompany == null)
        return null;
    else if (authState.isAuthenticated === null) {
        return <Redirect to='/login'/>; //auth failed (wrong tokens) redirect to login page
    } else if (authState.isAuthenticated === true) {
        return (
            <Box sx={{ml: 4, mr: 4, mb: 4, overflowY: 'auto', height: '100%'}}>
                <Typography sx={{pb: 4, fontWeight: 700, color: 'text.primary'}} component="h1" variant="h4" align="center">
                    {getGreeting()}
                </Typography>
                <Grid container rowSpacing={3} columnSpacing={3} justifyContent="center" alignItems="center">
                <Grid item>
                        <Paper
                            sx={{
                                width: '290px',
                                p: 3, ':hover': {
                                    bgcolor: '#4F916B',
                                    color: 'white', cursor: 'pointer'
                                }
                            }}
                            elevation={3}
                            onClick={() => {
                                api.positions.create(companyId, history, headers)
                            }}
                        >
                            <PersonAddIcon sx={{justifyContent: 'left', borderRadius: '5px', bgcolor: '#FE8F66', color: 'white'}}/>
                            <Typography sx={{pt: 1, pb: 1}} variant="h6">
                                Post New Position
                            </Typography>

                            <Typography className={classes.pos} color="textSecondary">
                                Create position and set reward
                            </Typography>
                            <Typography className={classes.pos} color="textSecondary">
                                Record manager video
                            </Typography>

                        </Paper>
                    </Grid>
                    <Grid item>
                        <Paper sx={{
                            width: '290px',
                            p: 3, ':hover': {
                                bgcolor: '#4F916B',
                                color: 'white', cursor: 'pointer'
                            }
                        }} elevation={3} onClick={() => {
                            history.push(`/company/${companyId}/positions`)
                        }}>

                            <PeopleIcon sx={{justifyContent: 'left', borderRadius: '5px', bgcolor: '#0EAD61', color: 'white'}}/>
                            <Typography sx={{pt: 1, pb: 1}} variant="h6">
                                Position Dashboard
                            </Typography>

                            <Typography className={classes.pos} color="textSecondary">
                                Manage positions, review referrals, and select candidates
                            </Typography>

                            {/* <Typography className={classes.pos} color="textSecondary">
                        Review referrals and candidates
                        </Typography> */}

                        </Paper>
                    </Grid>
                    {currUserCompany.role === "Owner" &&
                        <Grid item>
                            <Paper sx={{
                                width: '290px',
                                p: 3, ':hover': {
                                    bgcolor: '#4F916B',
                                    color: 'white', cursor: 'pointer'
                                }
                            }} elevation={3} onClick={() => {
                                history.push(`/company/${companyId}/edit`)
                            }}>


                                <EditIcon sx={{justifyContent: 'left', borderRadius: '5px', bgcolor: '#FFCC40', color: 'white'}}/>
                                <Typography sx={{pt: 1, pb: 1}} variant="h6">
                                    Manage Company Profile
                                </Typography>

                                <Typography className={classes.pos} color="textSecondary">
                                    Update company info
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    Manage recruiting roles
                                </Typography>
                            </Paper>

                        </Grid>
                    }
                    {currUserCompany.role !== "Member" &&
                        <Grid item>
                            <Paper sx={{
                                width: '290px',
                                p: 3, ':hover': {
                                    bgcolor: '#4F916B',
                                    color: 'white', cursor: 'pointer'
                                }
                            }} elevation={3} onClick={() => {
                                history.push(`/company/${companyId}/payment`)
                            }}>

                                <CreditCardIcon sx={{justifyContent: 'left', borderRadius: '5px', bgcolor: '#783EFD', color: 'white'}}/>
                                <Typography sx={{pt: 1, pb: 1}} variant="h6">
                                    Manage Billing Info
                                </Typography>

                                <Typography className={classes.pos} color="textSecondary">
                                    A credit card is required to post positions with a referral reward
                                </Typography>
                            </Paper>
                        </Grid>
                    }
                    
                </Grid>
            </Box>
        );
    }
};

export default CompanyHomePage;
