import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import React, {useRef, useState} from 'react';
import {Box, Typography} from '@mui/material';

export const saveStateHelper = {
    LOADING: 'LOADING',
    SAVED: 'SAVED',
    CHANGED: 'CHANGED',
    SAVING: 'SAVING',
    NOT_SAVED: 'NOT SAVED',
    SUBMITTING: 'SUBMITTING',
    SUBMITTED: 'SUBMITTED',
    NOT_SUBMITTED: 'NOT SUBMITTED',
};

export const saveStatePicker = state => {
    if (state === null || state === undefined) {
        return saveStateHelper.LOADING;
    }

    switch (state.toLowerCase()) {
        case 'touched':
            return saveStateHelper.CHANGED;
        case 'updated':
        case 'new':
            return saveStateHelper.SAVED;
        case 'updating':
            return saveStateHelper.SAVING;
        case 'submitting':
            return saveStateHelper.SUBMITTING;
        case 'submitted':
            return saveStateHelper.SUBMITTED;
        case 'error':
            return saveStateHelper.NOT_SAVED;
        case 'not_submitted':
            return saveStateHelper.NOT_SUBMITTED;
        default:
            return null;
    }
};

export const SaveStateColorPicker = state => {

    const [showCloudMessage, setShowCloudMessage] = useState(false);
    // useEffect(() => {
    //     if (showCloudMessage) {
    //       const timeout = setTimeout(
    //           () => 
    //             setShowCloudMessage(false), 10000);
    //       return () => {
    //         clearTimeout(timeout);
    //       };
    //     }
    //   }, [showCloudMessage]);

    const timeoutRef = useRef(null);

    const startTimer = () => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => setShowCloudMessage(false), 3000);
    }

    switch (state) {
        case saveStateHelper.LOADING:
            return <Typography><AutorenewIcon sx={{verticalAlign: "bottom"}}/> Loading...</Typography>
        // return theme => ({color: theme.palette.common.black, background: theme.palette.common.black});
        case saveStateHelper.CHANGED:
        case saveStateHelper.SAVING:
        case saveStateHelper.SUBMITTING:
            if (!showCloudMessage) {
                setShowCloudMessage(true);
            }
            return <Typography><AutorenewIcon sx={{verticalAlign: "bottom"}}/> Saving...</Typography>
        //return theme => ({color: theme.palette.warning.dark, background: theme.palette.warning.dark});
        case saveStateHelper.SAVED:
        case saveStateHelper.SUBMITTED:
            startTimer();
            return (
                <Typography>
                    <CloudDoneOutlinedIcon sx={{verticalAlign: "bottom"}}/>
                    <Box component="span" sx={{display: !showCloudMessage ? "none" : null}}> Progress Saved</Box>
                </Typography>
            );
        // return theme => ({color: theme.palette.success.dark, background: theme.palette.success.dark});
        case saveStateHelper.NOT_SAVED:
        case saveStateHelper.NOT_SUBMITTED:
            return <Typography><RunningWithErrorsIcon sx={{verticalAlign: "bottom"}}/> Not saved</Typography>
        //return theme => ({color: theme.palette.error.dark, background: theme.palette.error.dark});
        default:
            return <Typography><RunningWithErrorsIcon sx={{verticalAlign: "bottom"}}/> Not saved</Typography>
    }
};
