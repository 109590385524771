import React from 'react';
import "yup-phone";

import useWindowSize from 'react-use/lib/useWindowSize'
import makeStyles from '@mui/styles/makeStyles';
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Confetti from "react-confetti";
import { Link } from "react-router-dom";
import { useAuthState } from "../../../../../auth/auth";
import Box from "@mui/material/Box";
import CandidateMaleImage from '../../../../../images/orange_person.png'
import GreenHead from '../../../../../images/candidate_girl.png'
import GreenMoney from '../../../../../images/greenmoney.png'
import Money from '../../../../../images/money.png'
import { Paper } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        height: "75vh",
        width: "95%",
        maxWidth: 800,


        justifySelf: "center",
        justifyContent: "center",
        alignSelf: "center",

        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),

        marginLeft: 'auto',
        marginRight: 'auto',

        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),

        [theme.breakpoints.up('xs')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    backgroundimages: {
        position: 'absolute',
        zIndex: -1
    }
}));

const ReferralSubmitted = (props) => {
    const { content, confirmationId } = props;

    const classes = useStyles();

    const { authState } = useAuthState();

    const isAuthenticated = authState?.isAuthenticated;

    const { width, height } = useWindowSize()

    const authButton = () =>
        <Box sx={{ pt:5, display: 'flex', justifyContent:'center' }}>
            <Tooltip
                placement="right"
                title={
                    <Typography variant="subtitle2">
                        You will need an account to collect your reward if accepted.
                    </Typography>
                }
            >
                <Button component={Link} to={`/CreateAccount?confId=${confirmationId}`} sx={{
                    bgcolor: "success.main", color: "#ffffff", ':hover': {
                        bgcolor: '#4F916B',
                        color: 'white',
                    }, pl: 4, pr: 4, pt: 1, pb: 1, mb: 5
                }}>Create Account</Button>
            </Tooltip>
        </Box>
    const moreJobsButton = () => <Box sx={{ pt:5, display: 'flex', justifyContent:'center' }}><Button component={Link} to="/"  sx={{
        bgcolor: "success.main", color: "#ffffff", ':hover': {
            bgcolor: '#4F916B',
            color: 'white',
        }, pl: 4, pr: 4, pt: 1, pb: 1, mb: 5
    }}>Home</Button></Box>

    return (
        <Box sx={{ position: 'relative', display: 'flex' }}>

            <Box component='img' sx={{
                top: '0px', left: {
                    lg: '125px',
                    md: '40px',
                    sm: 0
                },
                width: {
                    md: '80px',
                    sm: '40px',
                    xs: '30px'
                }
            }} className={classes.backgroundimages} src={GreenMoney} />

            <Box component='img' sx={{
                top: '680px', left: {
                    lg: '140px',
                    sm: 0
                },
                width: {
                    md: '80px',
                    sm: '40px',
                    xs: '30px'
                }
            }} className={classes.backgroundimages} src={CandidateMaleImage} />


            <Box component='img' sx={{
                top: '-2px', right: {
                    lg: '10%',
                    md: '7%',
                    xs: '0%'
                },
                width: {
                    md: '50px',
                    sm: '50px',
                    xs: '30px'
                }
            }} className={classes.backgroundimages} src={Money} />


            <Box component='img' sx={{
                top: '600px', right: {
                    lg: '10%',
                    md: '7%',
                    xs: '0%'
                },
                width: {
                    md: '100px',
                    sm: '50px',
                    xs: '30px'
                }
            }} className={classes.backgroundimages} src={GreenHead} />
            <Confetti
                width={width}
                height={height}
                recycle={false}
                numberOfPieces={50}
            />
            <Paper className={classes.root}>
                {content}
                {isAuthenticated ? moreJobsButton() : authButton()}
            </Paper>
        </Box>
    )
}

export default ReferralSubmitted;
