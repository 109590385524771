import React, {useEffect, useState} from 'react';
import ReferralForm from "../Referral/User/ReferralForm";
import api from "../../api";
import {useAuthState} from "../../auth/auth";
import StyledSkeleton from "../../shared/Skeleton/StyledSkeleton";
import {useParams} from "react-router-dom";

const PositionPreviewPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [position, setPosition] = useState(null);
    const [loadingError, setLoadingError] = useState(null);

    const {companyId, positionId} = useParams();
    const {authState} = useAuthState();
    const headers = authState?.headers;
    const isAuthenticated = authState?.isAuthenticated;

    const loadPosition = () => {
        setLoading(true);

        api.positions.loadWithContent(companyId, positionId, headers)
            .then(resp => setPosition(resp.data.position))
            .catch(err => setLoadingError(err))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }
        if (loading) {
            return;
        }

        loadPosition();
    }, [isAuthenticated]);

    return (
        <React.Fragment>
            {
                !!position
                    ? <ReferralForm position={position} preventSubmit defaultToPositionDetails={true}/>
                    : <StyledSkeleton/>
            }
        </React.Fragment>
    );
}

export default PositionPreviewPage;
