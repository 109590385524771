import React from 'react';
import {Box, Grid, Paper, Typography} from '@mui/material';
import ReferrerForm from "../Referrer/ReferrerForm";
import SelfCandidateForm from "../Candidate/SelfCandidateForm";

const ReviewSelfReferralSubmission = (props) => {
    const formik = props.formik;
    return (
        <Box>
            <Paper sx={{maxWidth: 800}}>
                <Grid container direction="column">
                    <Grid item>
                        <Box mt={2} sx={{display: 'flex', justifyContent: 'center'}}>
                            <Typography sx={{verticalAlign: 'middle', pb: 1, pt: 2, fontWeight: 500, color: 'text.primary'}} component="h1" variant="h6">
                                About you
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <SelfCandidateForm formik={formik} position={props.position} reviewMode={true}/>
                    </Grid>
                </Grid>

            </Paper>
        </Box>
    );
}

export default ReviewSelfReferralSubmission
