import {format, parseJSON} from "date-fns";
import Typography from "@mui/material/Typography";
import React from 'react';

const parseDate = (date) => date ? ` ${format(parseJSON(date), 'MM/dd/yyyy')}` : "";

const getUserFullName = (user) => user ? `${user?.first_name} ${user?.second_name}` : "";

export const userWithDate = (user, createdAt) => {
    return (
        <React.Fragment>
            <Typography variant="caption">{getUserFullName(user)}</Typography>
            <Typography variant="caption">{parseDate(createdAt)}</Typography>
        </React.Fragment>
    );
}
export const userNameWithDate = (name, createdAt) => {
    return (
        <React.Fragment>
            <Typography variant="caption">{name}</Typography>
            <Typography variant="caption">{parseDate(createdAt)}</Typography>
        </React.Fragment>
    );
}

export const userFullName = (user) => {
    return <Typography variant="caption">{getUserFullName(user)}</Typography>;
}
