import React from 'react';
import "yup-phone";
import makeStyles from '@mui/styles/makeStyles';
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, CardMedia } from '@mui/material';
import SubmitSuccessImage from "../../../../../images/submitsuccess.png";

const ReferrerSubmittedContent = (props) => {
    const {referrerName, candidateName, companyName, positionName,} = props;

    return (
        <React.Fragment>
            <Box sx={{display:'flex', justifyContent:'center'}}>
            <Box
            component="img"
            src={SubmitSuccessImage}
            sx={{pt:8, pb:4, width:'200px'}}
            
            >

            </Box>
            </Box>
            <Box sx={{pl:4,pr:4, textAlign:'center'}}>
            <Typography sx={{pb:4}} variant="h5">Thank you, {referrerName}, for submitting {candidateName} for the {positionName} position at {companyName}!</Typography>
            <Typography variant="h6" sx={{pb:4, color:"success.main"}}>Referral confirmation number has been sent to your email</Typography>
                <Typography sx={{pb:4, fontSize:18}}>
                    As an FYI, We've also sent an email to {candidateName}.
                </Typography>
                <Typography sx={{fontSize:18, fontWeight:500, color:'success.main'}} component='span'>Pro tip: </Typography><Typography sx={{fontSize:18, color:"#9e9e9e"}} component='span' variant="caption">for best results, if you haven't followed up with {candidateName} about this, now is a good time to do so.
                </Typography>
            </Box>
        </React.Fragment>
    )
}

export default ReferrerSubmittedContent;
