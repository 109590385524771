import React, {useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import VideocamIcon from "@mui/icons-material/Videocam";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {isSupported, RecordingType, setup} from "@loomhq/record-sdk";

const MAX_ATTEMPTS = 10;

const RecordLoomVideoButton = (props) => {

    const {hasVideo, onInsertClicked, removeVideo} = props;

    const recordLoomButtonId = "recordLoomVideoButton";

    const [loomLoaded, setLoomLoaded] = useState({loaded: false, loading: false, attempts: 0});

    useEffect(() => {
        if (loomLoaded.loaded || loomLoaded.loading || loomLoaded.attempts >= MAX_ATTEMPTS) {
            return;
        }

        const button = document.querySelector(`#${recordLoomButtonId}`);

        if (button === null) {
            return;
        }

        setLoomLoaded({loaded: false, loading: true});

        isSupported().then(isSupported => {
            if ((!isSupported.supported || isSupported.error !== undefined) && isSupported.error !== "third-party-cookies-disabled") {
                console.log(isSupported);
                //setLoomLoaded({loaded: false, loading: false, attempts: MAX_ATTEMPTS});
                setTimeout(() => setLoomLoaded({loaded: false, loading: false, attempts: loomLoaded.attempts + 1}), 2000);
                return;
            }
            let hostname = window.location.hostname.toLowerCase();
            const isProd = hostname.startsWith("app") || hostname.startsWith("prod");
            let loomKeySandbox = "f643881c-eca1-434e-b6f0-ab48612f75ee";
            let loomKeySandboxOriginal = "6c8e2a2f-dff9-4ee4-99bc-2699d199f5c1";
            let loomKeyProd = "a18cfc48-4356-4853-89c9-725ae7e2f6a0";
            let loomKeyProdOriginal = "77170c0c-bb9b-4a3a-9436-31e238e6909";

            let loomKey = isProd? loomKeyProd:loomKeySandbox;
            setup({apiKey: loomKey,config:{defaultRecordingType:RecordingType.CameraOnly}}).then(setup => { // use prod key if in prod, else use sandbox key
                if (!setup.status().success) {
                    setTimeout(() => setLoomLoaded({loaded: false, loading: false, attempts: loomLoaded.attempts + 1}), 2000);
                    return;
                }

                setup.configureButton({
                    element: button,
                    hooks: {
                        onInsertClicked: onInsertClicked,
                        onStart: () => {
                        },
                        onCancel: () => {
                        },
                        onComplete: () => {
                        },
                    }
                });

                setLoomLoaded({loaded: true, loading: false});
            });
        });

    }, [loomLoaded.loaded, loomLoaded.loading, loomLoaded.attempts, onInsertClicked]);


    const renderDeleteLoomVideoButton = () => {
        if (!hasVideo) {
            return null;
        }

        return (
            <IconButton
                style={{marginLeft: 12}}
                color="primary"
                aria-label="remove video"
                component="span"
                onClick={removeVideo}
                size="large">
                <DeleteIcon/>
            </IconButton>
        );
    };

    let buttonText = 'Loom uninitialized';
    if (loomLoaded.loaded) {
        buttonText = "Record LOOM Message";
    } else if (loomLoaded.loading) {
        buttonText = 'Loom loading';
    } else if (loomLoaded.attempts >= MAX_ATTEMPTS) {
        buttonText = 'Loom loading failed';
    }

    return (
        <React.Fragment>
            <Button
                id={recordLoomButtonId}
                variant="outlined"
                sx={{color:"#565BDD"}}
                //color="primary"
                startIcon={<VideocamIcon/>}
            >
                {buttonText}
            </Button>
            {renderDeleteLoomVideoButton()}
        </React.Fragment>
    );
};

export default RecordLoomVideoButton;
