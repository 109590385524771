import PropTypes from 'prop-types'
import React from "react";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const renderIsCandidateInterested = (isCandidateInterested) => {
    if (isCandidateInterested === undefined || isCandidateInterested === null) {
        return (
            <React.Fragment>
                <QuestionMarkIcon color="warning" sx={{height: 24, width: 24}}/>
                <Typography variant="subtitle2" sx={{ml: 1}}>No response from the candidate</Typography>
            </React.Fragment>
        );
    } else if (isCandidateInterested === true) {
        return (
            <React.Fragment>
                <CheckCircleOutlineIcon color="success" sx={{height: 24, width: 24}}/>
                <Typography variant="subtitle2" sx={{ml: 1}}>Candidate confirmed interest</Typography>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <RemoveCircleOutlineIcon color="error" sx={{height: 24, width: 24}}/>
                <Typography variant="subtitle2" sx={{ml: 1}}>Candidate denied interest</Typography>
            </React.Fragment>
        );
    }
};

const CandidateInterestConfirmationTypographyIcon = ({isCandidateInterested}) => {
    return (
        <Box
            sx={{
                p: 2, pl: 3.5, pb: 0,
                alignItems: "center",
                display: "flex",
                placeContent: "center",
            }}
        >
            {renderIsCandidateInterested(isCandidateInterested)}
        </Box>
    );
};

export default CandidateInterestConfirmationTypographyIcon;

CandidateInterestConfirmationTypographyIcon.propTypes = {
  isCandidateInterested: PropTypes.bool
}
