import React from 'react';
import CandidateFormPreview from "./Preview/CandiateFormPreview";
import SelfCandidateFormPreview from './Preview/SelfCandiateFormPreview';
import CandidateFormUpdate from "./Update/CandidateFormUpdate";
import SelfCandidateFormUpdate from './Update/SelfCandidateFormUpdate';

export const getCandidateInterestLevelDescription = (interest) => {
    switch (interest) {
        case 1:
            return "Unknown candidate interest level";
        case 2:
            return "Low interest";
        case 3:
            return "Partially interested";
        case 4:
            return "Passively looking / Open";
        case 5:
            return "Actively looking / Very interested";
        default:
            return "Unknown candidate interest level";
    }
}

const SelfCandidateForm = ({formik, position, reviewMode}) => {

    const companyName = position?.company?.name;

    if (reviewMode) {
        return (
            <SelfCandidateFormPreview formik={formik} companyName={companyName}/>
        );
    } else {
        return (
            <SelfCandidateFormUpdate formik={formik} companyName={companyName}/>
        );
    }
}

export default SelfCandidateForm;
