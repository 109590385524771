import * as React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import {Box} from '@mui/material';

const PositionBreadcrumbs = (props) => {
    return (
        <Box role="presentation" sx={{ml: 1}}>
            <Breadcrumbs aria-label="breadcrumb">
                {props.crumbs.map((value, index) => {
                    if (!value.last) {
                        return (
                            <Link
                                component={RouterLink}
                                key={index}
                                underline="hover"
                                sx={{display: 'flex', alignItems: 'center', color: "#1F235B"}}
                                color="inherit"
                                to={value.to}
                            >
                                {value.icon}
                                {value.name}
                            </Link>
                        );
                    } else {
                        return (
                            <Typography sx={{display: "flex", alignItems: "center"}} key={index}>{value.icon} {value.name}</Typography>
                        );
                    }
                })}
            </Breadcrumbs>
        </Box>
    );
};

export default PositionBreadcrumbs;
