import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import CompanyHomePage from './pages/CompanyHomePage';
import './App.css';
import CompanyEditPage from './pages/CompanyEditPage';
import PositionPreviewPage from './components/Position/PositionPreview';
import GenerateSocialMediaTemplate from './GenerateSocialMediaTemplate';
import Register from './Register';
import EditCompanyPaymentInfoPage from './pages/EditCompanyPaymentInfoPage';
import RegisterPage from './RegisterPage';
import UserReferralsListPage from './pages/UserReferralsListPage';
import GettingStartedPage from './pages/GettingStartedPage';
import PayReferrer from "./PayReferrer";
import Admin from "./components/Admin/Admin";
import CreateOrEditPosition from "./pages/CreateOrEditPositionPage";
import CandidateConfirmInterestPage from './pages/CandidateConfirmInterestPage';
import CreateAccount from './pages/CreateAccount';
import ReferralSubmittedShow from "./components/Referral/User/Form/Submit/ReferralSubmittedShow";
import UserReferralPage from "./pages/UserReferralPage";
import PositionReferralsPage from "./pages/CompanyReferralListPage";
import Loom from './Loom';
import PositionsListPage from "./pages/PositionsListPage";
import CompaniesListPage from "./pages/CompaniesListPage";
import {Auth, AuthRoute, ClearUserCompany, CurrUserCompanySetter} from "./auth/auth";
import LoginCallback from "./auth/LoginCallback";
import LogoutCallback from "./auth/LogoutCallback";
import Navigation from "./Navigation/Navigation";
import CompanyReferralPage from "./pages/CompanyReferralPage";
import UserAccountPage from "./pages/UserAccountPage";
import CreateNewCompanyUser from './pages/CreateNewCompanyUser';
import UserPositionPage from './pages/UserPositionPage';
import UserPaymentInfoPage from './pages/UserPaymentInfoPage';
import CandidateReferralPage from './pages/CandidateReferralPage';
import ReferralSubmissionCandidateViewPage from './pages/ReferralSubmissionCandidateViewPage';

const AppWithRouterAccess = () => {
    return (
        <Auth>
            <ClearUserCompany/>
            <Navigation>
                <Route exact path='/' component={Home}/>
                <Route exact path='/home' component={Home}/>
                <Route exact path='/login' render={(props) => <Login {...props}/>}/>

                <Route exact path='/loom' component={Loom}/>

                <AuthRoute exact path='/admin' component={Admin}/>

                <AuthRoute exact path='/referrals' component={UserReferralsListPage}/>
                <AuthRoute exact path='/payment_info' component={UserPaymentInfoPage}/>

                <AuthRoute exact path='/company' component={CompaniesListPage}/>
                <AuthRoute exact path='/company_create' component={CompanyEditPage}/>

                <Route path='/company/:companyId/' component={CurrUserCompanySetter}/>
                <AuthRoute exact path='/company/:companyId' component={CompanyHomePage}/>
                <AuthRoute exact path='/company/:companyId/getting_started' component={GettingStartedPage}/>
                <AuthRoute exact path='/company/:companyId/edit' component={CompanyEditPage}/>
                <AuthRoute exact path='/company/:companyId/payment' component={EditCompanyPaymentInfoPage}/>

                <AuthRoute exact path='/company/:companyId/positions' component={PositionsListPage}/>
                <AuthRoute exact path='/company/:companyId/positions/:positionId' component={CreateOrEditPosition}/>
                <AuthRoute exact path='/company/:companyId/positions/:positionId/preview' component={PositionPreviewPage}/>

                <AuthRoute exact path='/company/:companyId/positions/:positionId/referrals' component={PositionReferralsPage}/>
                <AuthRoute exact path='/company/:companyId/positions/:positionId/referrals/:referralId' component={CompanyReferralPage}/>

                <AuthRoute exact path='/company/:companyId/GenerateSocialMediaTemplate/:positionId' component={GenerateSocialMediaTemplate}/>
                <AuthRoute path='/approve_payout/:jobId/:refId' component={PayReferrer}/>

                <Route exact path='/positions/:positionId' component={UserPositionPage}/>
                <Route exact path='/positions/:positionId/refer' component={UserReferralPage}/>
                <Route exact path='/positions/:positionId/apply' component={CandidateReferralPage}/>
                <Route exact path='/positions/:positionId/referrals/selfcandidate/:referralId' component={CandidateReferralPage}/>
                <Route exact path='/positions/:positionId/referrals/:referralId' component={UserReferralPage}/>
                <Route exact path='/positions/:positionId/referrals/:referralId/candidateview' component={ReferralSubmissionCandidateViewPage}/>
                <Route exact path='/positions/:positionId/referrals/:referralId/submitted' component={ReferralSubmittedShow}/>
                
                <AuthRoute path='/company/:companyId/positions/:positionId/PayReferrer/:referrerId' component={PayReferrer}/>
                <AuthRoute path='/user/profile' component={UserAccountPage}/>

                <Route path='/candidate_interested/:id' component={CandidateConfirmInterestPage}/>
                <Route exact path='/CreateAccount' component={CreateAccount}/>
                <Route exact path='/confirmcompanyuser' component={CreateNewCompanyUser}/>
                <Route path='/referrer/return'><Redirect to="/referrals"/></Route>
                <Route path='/Register' component={Register}/>
                <Route path="/registerpage" component={RegisterPage}/>

                <Route path='/login/callback' component={LoginCallback}/>
                <Route path='/logout/callback' component={LogoutCallback}/>
            </Navigation>
        </Auth>
    );
};
export default AppWithRouterAccess;
