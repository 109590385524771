import React from 'react';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import WarningIcon from "@mui/icons-material/Warning";
import Typography from "@mui/material/Typography";
import CollapseGrid from "../../shared/Collapse/CollapseGrid";
import FormHelperText from "@mui/material/FormHelperText";
import {Box} from "@mui/material";
import NumberFormat from 'react-number-format';

const PositionReferralSpecificForm = (props) => {
    const {formik, disabled} = props;

    const onChange = (event) => {
        formik.handleChange(event);
        formik.setStatus({state: "touched"})
    }

    const showWarmReferralAward = true;//formik.values.referral_type !== null;
    const showColdReferralAward = formik.values.referral_type === 2;
    formik.values.referral_type = 1;

    const referralSpecificsDisabled = formik.values.published_at !== null;

    return (
        <Grid container spacing={3} alignContent="flex-start" alignItems="center">
            <Grid item xs={12} sm={12}>
                <Typography variant="h6">Referral Award Details</Typography>
                <Box sx={{backgroundColor: "rgb(255, 250, 230);", alignItems: "start", display: 'flex', mb: 1, mt: 2}}>
                    <WarningIcon sx={{mt: 1, mr: 1, ml: 1, color: "rgb(255, 153, 31);", verticalAlign: ''}}/>
                    <Typography
                        sx={{mt: 1, mb: 1, mr: 1, fontSize: 14}}
                        component="span"
                    >
                        Please note: Once a position has been published,
                        the reward amount cannot be decreased.
                    </Typography>
                </Box>
            </Grid>
            {/* <Grid item xs={2}> */}
            {/* TODO: add cold and warm distinction and options */}
            {/* Referral Type <Tooltip title={<Box sx={{fontSize:14}}><b>Warm Referral</b> - Referrer personally knows the candidate and is willing to provide extensive detail and arrange introductions as necessary<br/><br/> 
                                <b>Cold Referral</b> - Referrer isn't personally connected with the candidate but is aware of their accomplishments through others.
                                                            </Box>}>
                                                    
                                                            <HelpIcon fontSize='small'/>
                                                
                                                </Tooltip>
                <TypographyWithTooltipIcon
                    title="Referral Type"
                    titleVariant="subtitle1"
                    tooltipTitle={["Warm Referral - Referrer personally knows the candidate and is willing to provide extensive detail and arrange introductions as necessary", "Cold Referral - Referrer isn't personally connected with the candidate but is awware of their accomplishments through others."]}
                    icon={<HelpIcon/>}
                    color={theme.palette.primary.main}
                /> */}
            {/* </Grid> */}
            <Grid item xs={10} sx={{pb: 1, display: 'none'}}>
                <Box sx={{height: 50}}>
                    <FormControl
                        fullWidth
                        required
                        disabled={disabled || referralSpecificsDisabled}
                        error={formik.touched.referral_type && Boolean(formik.errors.referral_type)}
                    >
                        <InputLabel htmlFor="referral-type">Referral Type</InputLabel>
                        <Select
                            label={"Referral Type *"}
                            value={formik.values.referral_type === null ? 1 : formik.values.referral_type}
                            onChange={event => {
                                const value = event.target.value;

                                formik.setFieldValue("referral_type", value);
                                formik.setStatus({state: "touched"});
                            }}
                            inputProps={{id: 'referral_type', name: 'referral_type',}}
                        >
                            <MenuItem value={1}>Warm Only</MenuItem>
                            <MenuItem value={2}>Warm & Cold</MenuItem>
                        </Select>
                        {
                            formik.touched.referral_type && Boolean(formik.errors.referral_type)
                                ? <FormHelperText>{formik.touched.referral_type && formik.errors.referral_type}</FormHelperText>
                                : null
                        }
                    </FormControl>
                </Box>
            </Grid>
            <CollapseGrid in={showWarmReferralAward}>
                <Grid item xs={12} md={2} style={{placeSelf: "center"}}>
                    <Typography variant="subtitle2">Referral Award Amount</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <input type="hidden" name="referral_type" id='referral_type' value={formik.values.referral_type}/>
                    <Box sx={{height: 50}}>
                        <NumberFormat
                            customInput={TextField}
                            onValueChange={(values) => {
                                formik.setFieldValue("warm_award", values.value);
                                formik.setStatus({state: "touched"});
                            }}
                            value={formik.values.warm_award || ""}
                            // you can define additional custom props that are all forwarded to the customInput e. g.
                            variant="outlined"
                            thousandSeparator
                            isNumericString
                            id="warm_award"
                            name="warm_award"
                            autoComplete="warm_award"
                            label="Award Amount"
                            fullWidth
                            placeholder="Not Specified"
                            prefix="$"
                            disabled={disabled}
                            error={formik.touched.warm_award && Boolean(formik.errors.warm_award)}
                            helperText={formik.touched.warm_award && formik.errors.warm_award}
                        />
                        {/* <TextField
                            id="warm_award"
                            name="warm_award"
                            autoComplete="warm_award"
                            label="Warm Award"
                            fullWidth
                            placeholder="Not Specified"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled={disabled}
                            value={formik.values.warm_award || ""}
                            onChange={onChange}
                            error={formik.touched.warm_award && Boolean(formik.errors.warm_award)}
                            helperText={formik.touched.warm_award && formik.errors.warm_award}
                        /> */}
                    </Box>
                </Grid>
            </CollapseGrid>
            <CollapseGrid in={showColdReferralAward}>
                <Grid item xs={2} style={{placeSelf: "center"}}>
                    <Typography variant="subtitle1">Cold referral award</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Box sx={{height: 50}}>
                        <TextField
                            id="cold_award"
                            name="cold_award"
                            autoComplete="cold_award"
                            label="Cold Award"
                            fullWidth
                            placeholder="Not Specified"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled={disabled}
                            value={formik.values.cold_award || ""}
                            onChange={onChange}
                            error={formik.touched.cold_award && Boolean(formik.errors.cold_award)}
                            helperText={formik.touched.cold_award && formik.errors.cold_award}
                        />
                    </Box>
                </Grid>
            </CollapseGrid>
            {/* <Grid item xs={2}>
                <TypographyWithTooltipIcon
                    title="Payout Model"
                    titleVariant="subtitle1"
                    tooltipTitle={["How reference reward will be payed to the referrer"]}
                    icon={<HelpIcon/>}
                    color={theme.palette.primary.main}
                />
            </Grid> */}
            <Grid item xs={10} sx={{display: "none"}}>
                <Box sx={{height: 50}}>
                    <FormControl
                        fullWidth
                        required
                        disabled={disabled || referralSpecificsDisabled}
                        error={formik.touched.payout_description && Boolean(formik.errors.payout_description)}
                    >
                        <InputLabel id="payout-description">Payout Model</InputLabel>
                        <Select
                            id="payout-description"
                            label="Payout Model *"
                            value={formik.values.payout_description === null ? "" : formik.values.payout_description}
                            onChange={event => {
                                const value = event.target.value;

                                formik.setFieldValue("payout_description", value);
                                formik.setStatus({state: "touched"});
                            }}
                            inputProps={{
                                id: 'payout-description',
                                name: 'payout-description',
                            }}
                        >
                            <MenuItem value={0}>100% upon hire</MenuItem>
                            <MenuItem value={1}>50% now / 50% 90 days after start</MenuItem>
                        </Select>
                        {formik.touched.payout_description && Boolean(formik.errors.payout_description) ?
                            <FormHelperText>{formik.touched.payout_description && formik.errors.payout_description}</FormHelperText> : null}
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
    )
}

export default PositionReferralSpecificForm;
