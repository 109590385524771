const oktaAuthConfig = {
    // Note: If your app is configured to use the Implicit Flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to add `pkce: false`
    issuer: 'https://login.pophire.io/oauth2/default',
    clientId: '0oa7tfq89jZiFIamM5d6',
    redirectUri: window.location.origin + '/login/callback',
    postLogoutRedirectUri: window.location.origin + '/logout/callback',
    scopes: ['openid', 'email', 'profile', 'address', 'phone']
};
const successCallback = (response) => {
    var s = "";
}

const failCallback = (response) => {
    var s = "";
}

const oktaSignInConfig = {
    baseUrl: 'https://login.pophire.io',
    clientId: '0oa7tfq89jZiFIamM5d6',
    redirectUri: window.location.origin + '/login/callback',
    postLogoutRedirectUri: window.location.origin + '/logout/callback',
    authParams: {
        // If your app is configured to use the Implicit Flow
        // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
        // you will need to uncomment the below line
        // pkce: false
        scopes: ['openid', 'email', 'profile', 'address', 'phone']
    },
    idps: [
        {type: 'google', id: '0oa7v2egxQ0XH0TQQ5d6'},
        {type: 'linkedin', id: '0oa7vz1mtNVXdeKnA5d6'}
    ],
    idpDisplay: "SECONDARY",
    features: {registration: true},
    registration: {
        // click: function() {
        //     window.location.href = '/login?r=y';
        //   },
        // preSubmit: function (postData, successCallback, failCallback) {
            
        //     // var qs = queryString.parse(window.location.search)
        //     // var userType = qs.userType;
        //     const urlParams = new URLSearchParams(window.location.search);
        //     const userType = urlParams.get('userType');
        //     if (userType === "referrer") {
        //         postData.userType = "referrer";
        //     } else if (userType === "company") {
        //         postData.userType = "company";
        //     }
        //     successCallback(postData);
        // },
        // postSubmit: function (response, onSuccess, onFailure) {
        //     // In this example postSubmit callback is used to log the server response to the browser console before completing registration flow
        //     console.log(response);
        //     // call onSuccess to finish registration flow
        //     onSuccess(response);
        //   }
    }
};

export {oktaAuthConfig, oktaSignInConfig};
