import {useState} from "react";
import _ from 'lodash';

export const getCurrentPage = (spec) => spec?.page?.offset / spec?.page?.limit;
export const getSort = (spec) => spec.sort;
export const getFilter = (spec) => spec.filter;

export const useSpec = ({defaultSort = [], defaultFilter = []}) => {

    const [spec, setSpec] = useState({
        page: {
            limit: 100,
            offset: 0,
        },
        filter: defaultFilter,
        sort: defaultSort,
    });

    const handlePageChange = (newPageNumber) => {
        setSpec(prevSpec => (
            {
                ...prevSpec,
                page: {
                    offset: (+newPageNumber) >= 0 ? (+newPageNumber) * prevSpec?.page?.limit : 0,
                    limit: prevSpec?.page?.limit,
                }
            }
        ));
    };

    const handlePageSizeChange = (newPageSize) => {
        setSpec(prevSpec => (
            {
                ...prevSpec,
                page: {
                    offset: prevSpec?.page?.offset,
                    limit: newPageSize
                }
            }
        ));
    };

    const handleFilterChange = (newFilter, columns) => {
        let filter = [];
        let extra;

        if (!!newFilter) {
            filter = newFilter.items
                .map(filterItem => {
                        if (filterItem.extra) {
                            extra = filterItem.extra;
                        }

                        return filterItem;
                    }
                )
                .filter(filterItem => filterItem.value)
                .map(filterItem => {
                    const column = _.find(columns, column => column.field === filterItem.columnField);

                    if (column === undefined) {
                        return filterItem;
                    }

                    return ({
                        columnField: !!column.actual_field ? column.actual_field : filterItem.columnField,
                        operatorValue: !!filterItem.specOperatorValue ? filterItem.specOperatorValue : filterItem.operatorValue,
                        value: filterItem.value,
                        joinOperator: newFilter.linkOperator,
                        type: !!filterItem.type ? filterItem.type : (column?.type)
                    });
                });
        }

        extra?.forEach(extraFilter => filter.push(extraFilter));

        setSpec(prevSpec => {
            if (_.isEqual(prevSpec.filter, filter)) {
                return prevSpec;
            }

            return {
                ...prevSpec,
                filter: filter
            };
        });
    };

    const handleSortChange = (newSort, columns) => {
        setSpec(prevSpec => (
            {
                ...prevSpec,
                sort: newSort.map(sortItem => {
                        const column = _.find(columns, column => column.field === sortItem.field);

                        return {
                            field: !!column.actual_field ? column.actual_field : sortItem.field,
                            sort: sortItem.sort,
                        };
                    }
                ),
            }
        ));
    };

    return {
        spec,
        onPageChange: handlePageChange,
        onPageSizeChange: handlePageSizeChange,
        onFilterChange: handleFilterChange,
        onSortChange: handleSortChange,
    }
};
