import axios from 'axios';
import {useAuthState} from "../auth/auth";
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";

axios.interceptors.request.use(request => {
    console.log('Starting Request', JSON.stringify(request, null, 2))
    // intercept all requests to embed the auth header if not provided, also retry if a 403 (set retry=true to prevent infinite loop, memento refresh call)
    return request
  })

//   axios.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//       const config = error?.config;
  
//       if (error?.response?.status === 403 && !config?.sent) {
//         config.sent = true;
//         // const {saveAuthState} = useAuthState();
//         // saveAuthState()    
//         const authContext = Auth0Provider();
//         const result = await authContext.getAccessTokenSilently()
  
//         if (result?.accessToken) {
//           config.headers = {
//             ...config.headers,
//             authorization: `Bearer ${result?.accessToken}`,
//           };
//         }
  
//         return axios(config);
//       }
//       return Promise.reject(error);
//     }
//   );

export const list = (headers) => {
    return axios.get('/api/company', {headers: headers});
}

export const update = (companyId, payload, headers) => {
    return axios.put(`/api/company/${companyId}`, payload, {headers: headers})
}
export const create = (payload, headers) => {
    return axios.post(`/api/company`, payload, {headers: headers})
}

export const load = (companyId, headers) => {
    return axios.get(`/api/company/${companyId}`, {headers: headers})
}

export const users = (companyId, headers) => {
    return axios.get(`/api/company/${companyId}/users`, {headers: headers})
}

export const addUser = (newCompanyUser, companyId, headers) => {
    return axios.post(`/api/company/${companyId}/users`, [newCompanyUser], {headers: headers})
}
export const updateUser = (companyUser, companyId, headers) => {
    return axios.put(`/api/company/${companyId}/users`, companyUser, {headers: headers})
}
export const inviteUser = (newCompanyUser, companyId, headers) => {
    return axios.post(`/api/company/${companyId}/users/invite`, newCompanyUser, {headers: headers})
}
export const associateEmailToUser = (companyUserConfId, headers) => {
    return axios.post(`/api/users/finalizeUserAssociation?companyUserConfId=${companyUserConfId}`, {}, {headers: headers})
}

export const deleteUser = (companyId, userCompanyId, headers) => {
    return axios.delete(`/api/company/${companyId}/users/${userCompanyId}`, {headers: headers})
}
