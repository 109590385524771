import PropTypes from 'prop-types'
import React from "react";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import ChevronTripleUp from "../../../../shared/icons/ChevronTripleUp.svg";
import ChevronDoubleUp from "../../../../shared/icons/ChevronDoubleUp.svg";
import ChevronUp from "../../../../shared/icons/ChevronUp.svg";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import {getCandidateInterestLevelDescription} from "../../User/Form/Candidate/CandidateForm";
import RemoveIcon from '@mui/icons-material/Remove';

export const renderCandidateInterestLevelIcon = (interest) => {
    switch (interest) {
        case 1:
            return <QuestionMarkIcon color="warning" sx={{height: 24, width: 24}}/>;
        case 2:
            return <RemoveIcon color="error" sx={{height: 24, width: 24}}/>;
        case 3:
            return <Box component="img" sx={{height: 20, width: 20}} src={ChevronUp}/>
        case 4:
            return <Box component="img" sx={{height: 20, width: 20}} src={ChevronDoubleUp}/>
        case 5:
            return <Box component="img" sx={{height: 20, width: 20}} src={ChevronTripleUp}/>;
        default:
            return <QuestionMarkIcon color="warning" sx={{height: 20, width: 20}}/>;
    }
}

const CandidateInterestLevelTypographyIcon = ({candidateLevelOfInterest}) => {
    return (
        <Box
            sx={{
                p: 2, pl: 3.5, pb: 0,
                alignItems: "center",
                display: "flex",
                placeContent: "center",
            }}
        >
            {renderCandidateInterestLevelIcon(candidateLevelOfInterest)}
            <Typography variant="subtitle2" sx={{ml: 1}}>{getCandidateInterestLevelDescription(candidateLevelOfInterest)}</Typography>
        </Box>
    );
};

export default CandidateInterestLevelTypographyIcon;

CandidateInterestLevelTypographyIcon.propTypes = {
    candidateLevelOfInterest: PropTypes.number
}
