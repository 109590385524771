import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import GuyInFrame2 from '../../../../../images/guyinframe2.png'
import Gears from '../../../../../images/gears.png'
import Present from '../../../../../images/present.png'
import Frames from '../../../../../images/frames.png'
import Money from '../../../../../images/money.png'
import NegativeMarginImagesCandidate from './NegativeMarginImagesCandidate';

const useStyles = makeStyles((theme) => ({
    
    backgroundimages: {
        //width: '100px',
       position: 'relative',
        //zIndex: -1,
    },
}));

const NegativeMarginImages = (props) => {
    const classes = useStyles();

        return <React.Fragment>
            <NegativeMarginImagesCandidate/>
            
                <Box
                    component='img'
                    sx={{
                        display: {
                            xs:'none',
                            sm:'none',
                            md:'initial'
                        },
                        top: '800px', left: {
                            // lg: '140px',
                            // md: '110px',
                            sm: '-610px'
                        },
                        width: {
                            sm: '220px',
                            //sm: '100px'
                        }, zIndex:'-1'
                        
                    }}
                    className={classes.backgroundimages}
                    src={GuyInFrame2}
                />
                <Box
                    component='img'
                    sx={{
                        display: {
                            xs:'none',
                            sm:'none',
                            md:'initial'
                        },
                        position: 'absolute', zIndex: -1, top: '682px', left: {
                            // lg: '220px',
                            // md: '80px',
                            sm: '978px'
                        },
                        width: {
                            md: '50px',
                            sm: '50px'
                        }
                    }} src={Present}/>
                <Box
                    component='img'
                    sx={{
                        display: {
                            xs:'none',
                            sm:'none',
                            md:'initial'
                        },
                        top: '718px', left: {
                            //lg: '150px',
                            sm: '732px'
                        },
                        width: {
                            sm: '150px',
                            // sm: '80px'
                        }
                    }}
                    className={classes.backgroundimages}
                    src={Gears}
                />
                <Box
                    component='img'
                    sx={{
                        display: {
                            xs:'none',
                            sm:'none',
                            md:'initial'
                        },
                        top: '655px', right: {
                            // lg: '100px',
                            // md: '70px',
                            sm: '-600px'
                        },
                        width: {
                            sm: '242px',
                            //sm: '100px'
                        }
                    }}
                    className={classes.backgroundimages}
                    src={Frames}
                />
                <Box
                    component='img'
                    sx={{
                        display: {
                            xs:'none',
                            sm:'none',
                            md:'initial'
                        },
                        zIndex:-1,
                        top: '675px', right: {
                            // lg: '212px',
                            // md: '20px',
                            xs: '424px'
                        },
                        width: {
                            // md: '50px',
                            xs: '50px'
                        }
                    }}
                    className={classes.backgroundimages}
                    src={Money}
                />
        </React.Fragment>
}

export default NegativeMarginImages;