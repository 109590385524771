import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import CandidateMaleImage from '../../../../../images/orange_person.png'
import CandidateGray from '../../../../../images/candidate_gray.png'
import CandidateGirl from '../../../../../images/candidate_girl.png'
import RedGirl from '../../../../../images/candidate_girl_red.png'
import GreenHead from '../../../../../images/green_head.png'
import WhatCheck from '../../../../../images/whatcheck.png'

const useStyles = makeStyles((theme) => ({
    backgroundimages: {
        //width: '100px',
       position: 'relative',
        //zIndex: -1,
    },
 
}));

const NegativeMarginImagesCandidate = (props) => {
    const classes = useStyles();

        return <React.Fragment>
            {/* First three floating images on the left */}
            <Box
                    component='img'
                    sx={{
                        display: {
                            xs:'none',
                            sm:'none',
                            md:'initial'
                        },
                        top: '250px', left: {
                            sm: '-100px',
                            // md: '40%',
                            // sm: 0
                        },
                        width: {
                            sm: '100px',
                            //sm: '50px'
                        }
                    }}
                    className={classes.backgroundimages}
                    src={CandidateMaleImage}
                />
                <Box className={classes.backgroundimages} component='img'
                     sx=
                         {{
                            display: {
                                xs:'none',
                                sm:'none',
                                md:'initial'
                            },
                             top: '400px', left: {
                                 sm: '-150px',
                                //  md: '80px',
                                //  sm: 0
                             },
                             width: {
                                 sm: '50px',
                                 //sm: '50px'
                             }
                         }} src={CandidateGray}/>
                <Box
                    component='img'
                    sx={{
                        display: {
                            xs:'none',
                            sm:'none',
                            md:'initial'
                        },
                        top: '530px', left: {
                            sm: '-260px',
                            //sm: 0
                        },
                        width: {
                            sm: '100px',
                            //sm: '50px'
                        }
                    }}
                    className={classes.backgroundimages}
                    src={CandidateGirl}
                />
                <Box
                    component='img'
                    sx={{
                        display: {
                            xs:'none',
                            sm:'none',
                            md:'initial'
                        },
                        top: '200px', right: {
                            sm: '-560px',
                            // md: '50px',
                            // sm: 0
                        },
                        width: {
                            sm: '50px',
                            //sm: '50px'
                        }
                    }}
                    className={classes.backgroundimages}
                    src={GreenHead}
                />
                <Box
                    component='img'
                    sx={{
                        display: {
                            xs:'none',
                            sm:'none',
                            md:'initial'
                        },
                        top: '400px', right: {
                            sm: '-510px',
                            // md: '70px',
                            // sm: 0
                        },
                        width: {
                            sm: '100px',
                            //sm: '50px'
                        }
                    }}
                    className={classes.backgroundimages}
                    src={RedGirl}
                />
                <Box
                    component='img'
                    sx={{
                        display: {
                            xs:'none',
                            sm:'none',
                            md:'initial'
                        },
                        top: '600px', right: {
                            sm: '-450px',
                            // md: '20px',
                            // sm: 0
                        },
                        width: {
                            sm: '50px',
                            //sm: '50px'
                        }
                    }}
                    className={classes.backgroundimages}
                    src={WhatCheck}
                />
        </React.Fragment>
}

export default NegativeMarginImagesCandidate;