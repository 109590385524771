import React from 'react';

import Avatar from "@mui/material/Avatar";
import CardHeader from '@mui/material/CardHeader';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => (
    {
        companyHeader: {
            padding: 0,
        },
        avatar: {
            width: theme.spacing(8),
            height: theme.spacing(8),
            marginRight: theme.spacing(1),
        }
    }
))

const CompanyHeader = ({company, positionTitle}) => {
    const classes = useStyles()
    return (
        <CardHeader
            className={classes.companyHeader}
            avatar={<Avatar className={classes.avatar} src={company.logo_url} alt="logo"/>}
            title={positionTitle}
            subheader= {company.name}
            titleTypographyProps={{variant: 'h5'}}
        />
    )
}

export default CompanyHeader;
