import api from "../../../../api";
import {useQuery} from "react-query";
import {CancelToken} from "axios";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAuthState} from "../../../../auth/auth";
import CustomAutocomplete from "../../../../shared/Autocomplete/CustomAutocomplete";
import {getGridSingleSelectOperators} from "@mui/x-data-grid";
import {usePrevious} from "../../../../shared/React/usePrevious";
import _ from "lodash";

const fetchAssignedToPositionReferralsUsersByName = async (companyId, positionId, nameLike, cancelToken, headers) => {
    if (nameLike.length < 3) {
        return [];
    }
    const promise = api.referrals.assignedToPositionReferralsUsersByName(companyId, positionId, nameLike, cancelToken, headers);
    const {data} = await promise;
    return !!data ? data.users : [];
};

const ReferralAssignedToInputValue = (props) => {
    const {item, applyValue} = props;

    const [nameLike, setNameLike] = useState('');

    const currValue = item?.selected;
    const [selectedUser, setSelectedUser] = useState(!!currValue ? currValue : null);

    const {companyId, positionId} = useParams();

    const {authState} = useAuthState();
    const headers = authState?.headers;

    const {isLoading, data} = useQuery(['users', nameLike], () => {
        const source = CancelToken.source();
        const promise = fetchAssignedToPositionReferralsUsersByName(companyId, positionId, nameLike, source.token, headers);
        promise.cancel = () => source.cancel("Query cancelled");
        return promise;
    });


    const handleFilterChange = (selectedCompanyUser) => {
        setSelectedUser(selectedCompanyUser);
        applyValue({...item, specOperatorValue: item.operatorValue, value: selectedCompanyUser?.id?.toString(), selected: selectedCompanyUser});
    };

    useEffect(() => applyValue({...item, specOperatorValue: item.operatorValue, value: selectedUser?.id?.toString(),}), [item.operatorValue])

    return (
        <CustomAutocomplete
            id="referral-assigned-to-user-filter"
            label="Company Member"
            noOptionsText="Type user name..."
            isLoading={isLoading}
            options={data}
            selectedOption={selectedUser}
            onChangeSelectedOption={handleFilterChange}
            onInputChange={setNameLike}
            getOptionLabel={(option) => `${option?.first_name} ${option?.second_name}`.trim() || ""}
            inputVariant="standard"
        />
    );
};

export const getAssignedToReferralsFilterOperators = () => {
    return [
        ...getGridSingleSelectOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: ReferralAssignedToInputValue,
            }),
        ),
        {
            label: 'assigned',
            value: 'assigned',
            InputComponent: ({item, applyValue}) => {
                const prevItem = usePrevious(item);

                useEffect(() => {
                    if (_.isEqual(item, prevItem)) {
                        return
                    }

                    applyValue({...item, specOperatorValue: 'not', value: 'null'});
                }, [item]);

                return null;
            },
        },
        {
            label: 'unassigned',
            value: 'unassigned',
            InputComponent: ({item, applyValue}) => {
                const prevItem = usePrevious(item);

                useEffect(() => {
                    if (_.isEqual(item, prevItem)) {
                        return
                    }

                    applyValue({...item, specOperatorValue: 'is', value: 'null'});
                }, [item]);

                return null;
            },
        },
    ];
};
