import React, {useEffect, useState} from "react";
import {Link as RouterLink, useParams} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import axios from 'axios';
import Link from '@mui/material/Link';
import {useAuthState} from "../auth/auth";
import {Stack} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            //margin: theme.spacing(2),
            //padding: theme.spacing(1),
            //width: theme.spacing(16),
            //height: theme.spacing(16),
        },
    },

}));

const GettingStartedPage = (props) => {

    // const percentage = 66;

    const classes = useStyles();
    let {companyId} = useParams();
    const {authState} = useAuthState();
    const headers = authState?.headers;
    const [status, setStatus] = useState({});
    const [percentComplete, setPercentComplete] = useState(0);

    useEffect(() => {
        axios.get(`/api/company/${companyId}/getting_started`, {headers: headers})
            .then(res => {
                setStatus(res.data.status);
                const stat = res.data.status;

                let count = 0;
                let completeCount = 0;

                for (const property in stat) {
                    count++;
                    if (stat[property] === true)
                        completeCount++;
                    // console.log(`${property}: ${stat[property]}`);
                }

                const pComplete = (completeCount / count) * 100;

                setPercentComplete(pComplete);
            });
    }, []);

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <Paper elevation={3}>
                        <Box p={1}>
                            <Typography variant="h6">Welcome to PopHire, let's get started.
                            </Typography></Box>
                        <Grid container spacing={2} marginTop={2} justifyContent="space-around">
                            <Grid item xs={3} marginLeft={2} minWidth="250px">
                                <Stack direction="row" alignItems="center" marginBottom={1} gap={1}>
                                    {status.profile_complete ? <CheckBoxIcon color="success"/> : <CheckBoxOutlineBlankIcon/>}
                                    <Link
                                        component={RouterLink}
                                        to="edit"
                                        underline="hover"
                                    >
                                        Finalize Company Profile
                                    </Link>
                                </Stack>
                                <Stack direction="row" alignItems="center" marginBottom={1} gap={1}>
                                    {status.billing_complete ? <CheckBoxIcon color="success"/> : <CheckBoxOutlineBlankIcon/>}
                                    <Link
                                        component={RouterLink}
                                        to="payment"
                                        underline="hover"
                                    >
                                        Add billing info
                                    </Link>
                                </Stack>
                                <Stack direction="row" alignItems="center" marginBottom={1} gap={1}>
                                    {status.first_position_created ? <CheckBoxIcon color="success"/> : <CheckBoxOutlineBlankIcon/>}
                                    <Link
                                        component={RouterLink}
                                        to="positions"
                                        underline="hover"
                                    >
                                        Create first position
                                    </Link>
                                </Stack>
                                <Stack direction="row" alignItems="center" marginBottom={1} gap={1}>
                                    {status.first_position_published ? <CheckBoxIcon color="success"/> : <CheckBoxOutlineBlankIcon/>}
                                    <Link
                                        component={RouterLink}
                                        to="positions"
                                        underline="hover"
                                    >
                                        Publish first position
                                    </Link>
                                </Stack>
                            </Grid>
                            <Grid item xs={5} minWidth={175} marginBottom={2} style={{alignItems: "center"}}>
                                <div style={{height: 150, width: 150}}>
                                    <CircularProgressbar value={percentComplete} text={`${percentComplete}%`}/>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default GettingStartedPage;
