import React from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ClassIcon from '@mui/icons-material/Class';
import FacebookIcon from '@mui/icons-material/Facebook';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import Tooltip from '@mui/material/Tooltip';

const FACEBOOK_REGEXP = /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:\w*#!\/)?(?:pages\/)?(?:[?\w-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w-]*)?/

const DefaultIcon = ({className}) => {
  return (
    <Tooltip className={className} title="Unknown website">
      <ErrorOutlineRoundedIcon />
    </Tooltip>
  )
}

const UrlIcon = ({url, className}) => {
  if (!url) {
    return <span />
  }
  try {
    let Component = DefaultIcon;
    if (url.match(FACEBOOK_REGEXP)) {
      Component = FacebookIcon;
    }
    const parsed = new URL(url);
    if (parsed.hostname.includes("glassdoor")) {
      Component = ClassIcon;
    }
    if (parsed.hostname.match(/linkedin.com$/)) {
      Component = LinkedInIcon;
    }

    return (
      <Component className={className} />
    )
  } catch(err) {
    return <span />
  }
}

export default UrlIcon;
