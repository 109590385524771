import 'react-hot-loader'
import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createRoot} from 'react-dom/client';
import {setConfig} from 'react-hot-loader'

//https://stackoverflow.com/questions/54770535/react-hot-loader-react-dom-patch-is-not-detected/54816859#54816859
setConfig({
    showReactDomPatchNotification: false
})

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    // have to comment this out for now because of stupid quill creating double toolbars
    // <React.StrictMode>
        <App/>
    // </React.StrictMode>
);

//https://github.com/cdharris/react-app-rewire-hot-loader todo real hot reload without eject
if (module.hot) {
    module.hot.accept();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
