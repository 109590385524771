import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import Collapse from "@mui/material/Collapse";
import React from "react";

const GridItemXs12 = React.forwardRef((props, ref) => <Grid item xs={12} {...props} ref={ref}/>);

const useCollapseStyles = makeStyles((theme) => (
        {
            hidden: {
                padding: "0 !important",
            }
        }
    )
);

const CollapseGrid = (props) => {
    const classes = useCollapseStyles();

    const collapseHeight = !props.in ? 0 : '100%'
    const display = !props.in ? 'none' : 'flex'

    return (
        <Collapse
            sx={{height: collapseHeight, pt: 0.75, pb: 1.5}}
            component={GridItemXs12}
            classes={{
                hidden: classes.hidden,
            }}
            {...props}
        >
            {/* This spacing has to match with the one in the container
            outside the collapse */}
            <Grid sx={{height: collapseHeight, display: display}} container spacing={2}>
                {props.children}
            </Grid>
        </Collapse>
    );
};

export default CollapseGrid;
