import React from "react"
import {GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton} from "@mui/x-data-grid";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => (
    {
        dataGridToolbarContainer: {
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    }
));

const CustomToolbar = (props) => {
    const classes = useStyles();

    const {toolbarActions, title, minimalView} = props

    return (
        <GridToolbarContainer className={classes.dataGridToolbarContainer}>
            <Box sx={{display: 'flex'}}>
                <Typography variant="h4" sx={{pr: 2}}>{title}</Typography>
                {toolbarActions}
            </Box>
            {!minimalView && <div>
                
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton/>
                {/*<GridToolbarDensitySelector/>*/}
                <GridToolbarExport/>
                
            </div>
            }
        </GridToolbarContainer>
    );
};

export default CustomToolbar;
