import React from 'react';
import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import CollapseText from './../../../../shared/Collapse/CollapseText';
import {Box, Link} from '@mui/material';
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";

const CompanyDetails = ({company}) => {
    const summary = company ? company.summary : "";
    const name = company ? company.name : "";

    const hasUrl = (url) => {
        return url != null && url.trim() !== "";
    }

    return (
        <Card variant="outlined">
            <CardContent sx={{py: 3, px: 5}} style={{paddingBottom: 16}}>
                <Typography sx={{fontWeight: 400, color: 'text.primary'}} component="h1" variant="h6">About {name}</Typography>
                <Box>
                    {hasUrl(company.linked_in_url) &&
                        <Link href={company.linked_in_url} target="_blank" rel="noopener noreferrer"><LinkedInIcon sx={{color: "#2867B2"}}/></Link>}
                    {hasUrl(company.facebook_url) &&
                        <Link href={company.facebook_url} target="_blank" rel="noopener noreferrer"><FacebookIcon sx={{color: "#1777F2"}}/></Link>}
                    {hasUrl(company.glassdoor_url) &&
                        <Link href={company.glassdoor_url} target="_blank" rel="noopener noreferrer"><MeetingRoomIcon sx={{color: "#61A84B"}}/></Link>}
                </Box>
                <CollapseText text={summary} length={300}/>
            </CardContent>
        </Card>
    )
}

export default CompanyDetails;
