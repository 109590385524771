import PropTypes from 'prop-types'
import Button from "@mui/material/Button";
import {ClickAwayListener} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import ReferralNoHireDialog from "./ReferralNoHireDialog";
import {useTheme} from "@mui/material/styles";

const ReferralStepActions = ({active, error, successButtonLabel, successAction, failButtonLabel, failAction, failReasons}) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    if (error || !active) {
        return null;
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            {
                successButtonLabel && (
                    <Button
                        variant="contained"
                        color="linkedin"
                        onClick={successAction}
                        sx={{ mt: 1, mr: 1, width: 130}}
                    >
                        {successButtonLabel}
                    </Button>
                )
            }
            {
                failButtonLabel && (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box sx={{display: 'contents'}}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClickOpen}
                                sx={{width: 130, mt: 1}}
                            >
                                {failButtonLabel}
                            </Button>
                            <ReferralNoHireDialog
                                open={open}
                                onClose={handleClose}
                                failedButtonLabel={failButtonLabel}
                                failReasons={failReasons}
                                onNoHire={(reason) => {
                                    handleClose();
                                    failAction(reason);
                                }}
                            />
                        </Box>
                    </ClickAwayListener>
                )
            }
        </Box>
    );
};

export default ReferralStepActions;

ReferralStepActions.propTypes = {
    active: PropTypes.bool,
    error: PropTypes.bool,
    failAction: PropTypes.func,
    failReasons: PropTypes.array,
    failButtonLabel: PropTypes.string,
    successAction: PropTypes.func,
    successButtonLabel: PropTypes.string
}
