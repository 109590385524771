import React from "react";
import {Box} from "@mui/material";
import NegativeMarginImages from "../NegativeMarginImages";

const CandidateFormPreviewBackground = (props) => {
    return (
        <Box sx={{marginTop: {xs: 0, sm: 0, md: "-370px"}}}>
            <NegativeMarginImages/>
            {props.children}
        </Box>
    );
};

export default CandidateFormPreviewBackground;
