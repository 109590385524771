import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import {useTheme} from "@emotion/react";

const StyledSkeleton = (props) => {
    const theme = useTheme();

    return (
        <Box sx={{height: "inherit"}}>
            <Skeleton
                sx={{
                    margin: 'auto',
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(3),
                    padding: theme.spacing(2),
                    [theme.breakpoints.up('xs')]: {
                        marginBottom: theme.spacing(3),
                        padding: theme.spacing(3),
                    },
                }}
                variant="rectangular"
            />
        </Box>
    );
};

export default StyledSkeleton;
