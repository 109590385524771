import React, {Component} from 'react';
import 'date-fns';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import GeneralCompanyInfoForm from "../components/Company/GeneralCompanyInfoForm";
import ContactCompanyInfoForm from "../components/Company/ContactCompanyInfoForm";
import CompanyCrewInfoTable from "../components/Company/CompanyCrewInfoTable";
import _ from 'lodash';
import {Redirect, withRouter} from "react-router-dom";
import {withAuthState} from "../auth/auth";
import api from "../api";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HelpIcon from '@mui/icons-material/Help';
import {IconButton, Tooltip} from '@mui/material';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';

const styles = (theme) => (
    {
        root: {
            width: "95%",
            maxWidth: 1000,

            display: 'grid',

            marginLeft: 'auto',
            marginRight: 'auto',

            [theme.breakpoints.up('xs')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },

        },
        grid: {
            padding: theme.spacing(2),

        },
        paper: {
            padding: theme.spacing(3),
            maxWidth: 800,
            borderRadius: '20px'
        },
        // tableContainer: {
        //     width: '100%',
        //     marginTop: theme.spacing(2),
        //     padding: 0,
        // },
    }
);

class CompanyEditPage extends Component {
    state = {
        company: {
            id: null,
            name: null,
            user: {email: null},
            logo_url: null,
            logo_file: null,
            summary: null,
            linked_in_url: null,
            facebook_url: null,
            glassdoor_url: null,
            email: this.props.authState?.user?.email,
            phone: null,
            address_line1: null,
            address_line2: null,
            country: null,
            city: null,
            state: null,
            zip: null,
        },
        company_users: [],
        requiredFields: ["name", "summary", "email", "address_line1", "country", "city", "zip"],
        saving: false,
        loading: true,
        loaded: false,
        loadingCompany: false,
        loadingUsers: false,
        loadingError: null,
    }

    handleChanges = (scope, prop, event) => {
        this.setState(prevState => ({...prevState, [scope]: {...prevState[scope], [prop]: event.target.value}}));
    };

    loadCompany = () => {
        if (this.state.loadingCompany || this.state.loadingCompanyError) {
            return;
        }

        this.setState(prevState => ({...prevState, loadingCompany: true}));

        const companyId = this.props.match.params.companyId;
        const headers = this.props.authState.headers;

        api.company.load(companyId, headers)
            .then(resp => {
                const filteredCompanyResponse = _.omitBy(resp.data.company, data => _.isNil(data) || data === "");

                this.setState(prevState => ({
                    ...prevState,
                    company: {...prevState.company, ...filteredCompanyResponse},
                    loadedCompany: true
                }));

            })
            .catch((err) => {
                this.setState(prevState => ({...prevState, loadingCompanyError: err}));
            })
            .finally(() => this.setState(prevState => ({...prevState, loadingCompany: false})));
    }

    loadCompanyUsers = () => {
        if (this.state.loadingUsers || this.state.loadingUsersError) {
            return;
        }

        this.setState(prevState => ({...prevState, loadingUsers: true}));

        const companyId = this.props.match.params.companyId;
        const headers = this.props.authState.headers;

        api.company.users(companyId, headers)
            .then(resp => {
                this.setState(prevState => ({
                    ...prevState,
                    company_users: resp.data.company_users.map(companyUser => ({...companyUser, id: companyUser.id,})),
                    loadedUsers: true
                }));
            })
            .catch((err) => this.setState(prevState => ({...prevState, loadingUsersError: err})))
            .finally(() => {
                this.setState(prevState => ({...prevState, loadingUsers: false}));
                this.setState(prevState => ({...prevState, loading: false}));
            });
    }

    updateCompany = (payload) => {
        if (this.state.saving) {
            return;
        }

        this.setState(prevState => ({...prevState, saving: true}));

        const companyId = this.props.match.params.companyId;
        const headers = this.props.authState.headers;
        // if create then make a new company and redirect to getting started if successful

        if (this.state.isCreate) {
            //console.log("about to call create company with these headers: " + JSON.stringify(headers));
            api.company.create(payload, headers)
                .then((resp) => {
                    const companyId = resp.data.company.id;
                    const newUserCompanies = resp.data.user_companies;

                    const currUserCompany = newUserCompanies?.find(userCompany => userCompany.company_id.toString() === companyId);
                    this.props.saveAuthState({userCompanies: newUserCompanies, currUserCompany: currUserCompany});
                    //this.props.authState.saveAuthState({currUserCompany: currUserCompany});

                    if (this.props.authState.isAuthenticated && currUserCompany === undefined) {
                        this.props.history.push('/company');
                    } else {
                        this.props.history.push(`/company/${companyId}/getting_started`);
                    }
                });
        } else {
            // if edit, just update and show toast if success
            api.company.update(companyId, payload, headers)
                .then(() => {
                    toast.success('Company info saved', {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored"
                    });
                })
                .catch((error) => {
                    const message = error?.response?.data?.error?.message;

                    toast.error(`Failed to save company info: ${message}`, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored"
                    });
                    }
                )
                .finally(() => this.setState(prevState => ({...prevState, saving: false})));
        }
    }

    checkRequiredFields = () => {
        const companyUpdate = {};

        this.state.requiredFields
            .filter(requiredField => this.state.company[requiredField] === null)
            .forEach(requiredField => companyUpdate[requiredField] = "");

        this.setState(prevState => ({...prevState, company: {...prevState.company, ...companyUpdate}}));

        return this.state.requiredFields
            .filter(requiredField => this.state.company[requiredField] === null
                || this.state.company[requiredField] === ""
                || this.state.company[requiredField] === "<p><br></p>");
    };

    componentDidMount() {
        this._isMounted = true;

        const companyId = this.props.match.params.companyId;

        if (!companyId) {
            this.setState(prevState => ({
                ...prevState,
                loading: false,
                loadedUsers: true,
                loadedCompany: true,
                isCreate: true,

            }));

            return
        }

        if (!this.state.loadedCompany && !this.state.loadingCompanyError) {
            this.loadCompany();
        }

        if (!this.state.loadedUsers && !this.state.loadingUsersError) {
            this.loadCompanyUsers();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this._isMounted && !this.state.loadedUsers && !this.state.loadingUsersError) {
            this.loadCompanyUsers();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    buildCompanyPayload = () => this.state.company;

    trySaveCompanyInfo() {
        const check = this.checkRequiredFields();
        if (check?.length !== 0) {
            return;
        }

        this.updateCompany(this.buildCompanyPayload());
    }

    render() {
        const {classes} = this.props;

        const columns = [
            {
                field: 'actions', headerName: 'Edit', width: 80, headerAlign: 'center', align: 'center', renderCell: (params) => {
                    return (
                        <React.Fragment>
                            {/* <IconButton size="small">
                            <DangerousOutlinedIcon fontSize="small" />
                        </IconButton> */}
                            <IconButton size="small">
                                <BuildCircleOutlinedIcon fontSize="small"/>
                            </IconButton>
                        </React.Fragment>
                    )
                },
            },
            {field: 'email', headerName: 'Email', width: 220},
            {field: 'name', headerName: 'Name', width: 170},
            {
                field: 'role', headerName: 'Role', width: 130, headerAlign: 'right', align: 'center',
                renderHeader: (params) => {
                    return (
                        <React.Fragment>
                            <Tooltip title={<Box sx={{fontSize: 14}}>Member:<br/>Create and Publish positions.<br/>Review submitted referrals<br/><br/>
                                Admin:<br/>Everything in Member + <br/>
                                Authorize payment to referrers<br/><br/>
                                Owner:<br/>
                                Everything in Admin + <br/>
                                Company profile management including adding team members and updating billing and company details
                            </Box>}>
                                <IconButton size='small'>
                                    <HelpIcon fontSize='small'/>
                                </IconButton>
                            </Tooltip>
                            Roles
                            {/* <HelpTooltip
                icon={<HelpIcon/>}
                tooltipTitle={["Member:<br> Create and Publish positions", "May attach link to a video"]}
                />
                Roles */}
                        </React.Fragment>
                    )


                },
            },
            {field: 'status', headerName: 'Status', width: 120, headerAlign: 'center', align: 'center'},
        ];

        //todo move redirect to AppWithRouterAccess
        if (!this.props.authState?.isAuthenticated) {
            return (<Redirect to='/login'/>);
        }

        return (
            <Box sx={{height: '100%', overflowY: 'scroll'}}>
                <Grid container direction="column" justifyContent="flex-start" alignItems="center" rowSpacing={3}>
                    <Grid item>
                        <Typography sx={{pb: 1, fontWeight: 700, color: 'text.primary'}} component="h1" variant="h4" align="left">
                            {this.state.isCreate ? "Create Company" : "Company Profile"}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Paper className={classes.paper}>
                            <Typography sx={{pt: 1, pb: 2}} variant="h6">About</Typography>
                            <GeneralCompanyInfoForm
                                companyName={this.state.company.name}
                                logoUrlString={this.state.company.logo_url}
                                logoFile=""
                                adminEmail={this.state.company.user.email}
                                summary={this.state.company.summary}
                                linkedInUrlString={this.state.company.linked_in_url}
                                facebookUrlString={this.state.company.facebook_url}
                                glassdoorUrlString={this.state.company.glassdoor_url}
                                handleChange={(prop, event) => this.handleChanges("company", prop, event)}
                                isCreate
                                isPending={this.state.loading}
                                isSaving={this.state.saving}
                            />
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Paper className={classes.paper}>
                            <Typography variant="h6" sx={{pt: 1, pb: 2}}>Contact Info</Typography>
                            <ContactCompanyInfoForm
                                contactEmail={this.state.company.email}
                                contactPhone={this.state.company.phone}
                                addressLine1={this.state.company.address_line1}
                                addressLine2={this.state.company.address_line2}
                                country={this.state.company.country}
                                city={this.state.company.city}
                                state={this.state.company.state}
                                zip={this.state.company.zip}
                                handleChange={(prop, event) => this.handleChanges("company", prop, event)}
                                isPending={this.state.loading}
                            />
                        </Paper>
                        <Box sx={{pt: 4}}>
                            <Box sx={{maxWidth: '680px', ml: 2, mb: 2, alignContent: 'center', color: "black"}}>
                                <Typography>
                                    By clicking {this.state.isCreate ? "'Create Company'" : "'Save'"} you certify that
                                    you are a lawful representative of {this.state.company.name} and that you have
                                    the appropriate authority to post and hire
                                    positions on behalf of the company.
                                </Typography>
                            </Box>
                            <Grid container justifyContent="flex-end" spacing={2}>
                                <Grid item>
                                    <Button sx={{color: "text.primary", bgcolor: "#ffffff", pl: 4, pr: 4, pt: 1, pb: 1}}
                                            onClick={this.loadCompany}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button disabled={this.state.saving}
                                            onClick={() => this.trySaveCompanyInfo()}
                                            sx={{bgcolor: "success.main", color: "#ffffff", pl: 4, pr: 4, pt: 1, pb: 1}}
                                    >
                                        {this.state.isCreate ? "Create Company" : "Save"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{pt: 4}} display={this.state.company_users.length > 0 ? "block" : "none"}>
                            <Paper className={classes.paper} sx={{mb: 3}}>
                                <CompanyCrewInfoTable
                                    {...this.props}
                                    rows={this.state.company_users}
                                    columns={columns}
                                    isPending={this.state.loading}
                                    companyName={this.state.company.name}
                                    loadCompanyUsers={this.loadCompanyUsers}
                                    sx={{flexGrow: 1}}
                                />
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
                <ToastContainer/>
            </Box>
        );
    }
}

export default withStyles(styles)(withAuthState(withRouter(CompanyEditPage)));

