import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useHistory} from 'react-router-dom';
import {Divider, ListItemButton} from "@mui/material";
import Box from "@mui/material/Box";
import {footerHeight} from "./Navigation";
import {useAuthState} from "../auth/auth";
import MuiDrawer from "@mui/material/Drawer";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import MainLogo from "./MainLogo";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export const drawerWidth = 240;
export const miniDrawerWidth = (theme) => `calc(${theme.spacing(7)} + 1px)`;

const openedMixin = (theme, width) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});


const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: miniDrawerWidth(theme),
});

const hideMixin = (theme) => ({
    width: 0,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
});

export const StyledPermanentDrawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open' && prop !== 'render'})(
    ({theme, open, render}) => {
        return {
            flexShrink: 0,
            whiteSpace: 'nowrap',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
            ...{
                [theme.breakpoints.down('md')]: {
                    ...{
                        ...hideMixin(theme),
                        '& .MuiDrawer-paper': hideMixin(theme),
                    }
                }
            },
            ...(!render && {
                ...hideMixin(theme),
                '& .MuiDrawer-paper': hideMixin(theme),
            }),
        };
    },
);

export const StyledTemporaryDrawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'render'})(
    ({theme, render}) => {
        return {
            flexShrink: 0,
            whiteSpace: 'nowrap',
            display: 'block',
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
            ...{
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                }
            },
            ...(!render && {
                display: 'none',
            }),
        };
    },
);

const StyledDrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const sidebarRoutesCompany = [
    {
        onClick: (history, companyId) => history.push(`/company/${companyId}/getting_started`),
        name: 'Getting Started',
        icon: (theme) => <AutoAwesomeIcon style={{color: theme.palette.drawer.text}}/>,
        showForRole: (role) => role === "Owner",
    },
    {
        onClick: (history, companyId) => history.push(`/company/${companyId}`),
        name: 'Home',
        icon: (theme) => <BusinessIcon style={{color: theme.palette.drawer.text}}/>,
        showForRole: (role) => role === "Owner" || role === "Admin",
    },
    {
        onClick: (history, companyId) => history.push(`/company/${companyId}/positions`),
        name: 'Positions',
        icon: (theme) => <PeopleIcon style={{color: theme.palette.drawer.text}}/>,
        showForRole: (role) => role === "Owner" || role === "Admin" || role === "Member",
    },
    // {
    //     onClick: (history, companyId) => history.push(`/company/${companyId}/payment`),
    //     name: 'Billing Info',
    //     icon: (theme) => <PaymentsIcon style={{color: theme.palette.drawer.text}}/>,
    //     maxRoleRequired: 2
    // }
];
const sidebarRoutesRef = [
    {
        onClick: (history) => history.push(`/user/profile`),
        name: 'Profile',
        icon: (theme) => <ManageAccountsIcon style={{color: theme.palette.drawer.text}}/>,
    },
    {
        onClick: (history) => history.push(`/company`),
        name: 'Companies',
        icon: (theme) => <BusinessIcon style={{color: theme.palette.drawer.text}}/>,
    },
    {
        onClick: (history) => history.push(`/referrals`),
        name: 'Referrals',
        icon: (theme) => <AutoAwesomeIcon style={{color: theme.palette.drawer.text}}/>,
    },

    // {
    //     onClick: (history, companyId) => history.push(`/company/${companyId}/payment`),
    //     name: 'Billing Info',
    //     icon: (theme) => <PaymentsIcon style={{color: theme.palette.drawer.text}}/>,
    //     maxRoleRequired: 2
    // }
];

const SideBar = ({drawerRender, drawerOpen, onDrawerToggle, mobileDrawerOpen, onMobileDrawerToggle}) => {
    const history = useHistory();
    const {authState} = useAuthState();
    const theme = useTheme();

    const currUserCompany = authState?.currUserCompany;

    let drawer;

    if (currUserCompany) {
        drawer = sidebarRoutesCompany;
    } else {
        drawer = sidebarRoutesRef
    }

    const renderDrawerContent = () => (
        <React.Fragment>
            <MainLogo/>
            <Divider style={{background: theme.palette.drawer.text}}/>

            <List sx={{pt: 0, pb: 0, color: theme.palette.common.white}}>
                {
                    drawer.map((route) => {
                        if ((route.showForRole && currUserCompany != null && route.showForRole(currUserCompany.role)) || !route.showForRole) {
                            return <ListItemButton
                                key={route.name}
                                onClick={() => {
                                    route.onClick(history, currUserCompany?.company_id);
                                    onMobileDrawerToggle();
                                }}
                            >
                                <ListItemIcon>{route.icon(theme)}</ListItemIcon>
                                <ListItemText primary={route.name}/>
                            </ListItemButton>
                        }
                    })}
            </List>
            <Divider style={{background: theme.palette.drawer.text}}/>
            <Box sx={{height: "100%"}}/>
            <Divider style={{background: theme.palette.drawer.text}}/>
            <StyledDrawerHeader style={{minHeight: footerHeight - 1, maxHeight: footerHeight - 1,}} sx={{display: {xs: 'none', md: 'inherit'}}}>
                <IconButton size="small" onClick={onDrawerToggle} sx={{p: 0, mr: 1}}>
                    {drawerOpen ? <ChevronLeftIcon sx={{fontSize: 20}} style={{color: theme.palette.drawer.text}}/> :
                        <ChevronRightIcon sx={{fontSize: 20}} style={{color: theme.palette.drawer.text}}/>}
                </IconButton>
            </StyledDrawerHeader>
        </React.Fragment>
    );

    if (authState.loading) {
        return null;
    }
        // else if (authState.isAuthenticated === null) {
        //     return <Redirect to='/login'/>; //auth failed (wrong tokens) redirect to login page
    //} 
    else if (authState.isAuthenticated === true) {
        return (
            <React.Fragment>
                <StyledTemporaryDrawer
                    variant="temporary"
                    open={mobileDrawerOpen}
                    render={drawerRender ? 1 : 0}
                    onClose={onMobileDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    PaperProps={{sx: {bgcolor: theme.palette.primary.main, color: theme.palette.drawer.text}}}
                >
                    {renderDrawerContent()}
                </StyledTemporaryDrawer>
                <StyledPermanentDrawer
                    variant="permanent"
                    open={drawerOpen}
                    render={drawerRender ? 1 : 0}
                    PaperProps={{sx: {bgcolor: theme.palette.primary.main, color: theme.palette.drawer.text}}}
                >
                    {renderDrawerContent()}
                </StyledPermanentDrawer>
            </React.Fragment>
        );
    }
}

export default SideBar;
