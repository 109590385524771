import React from 'react';
import { setup, isSupported } from "@loomhq/record-sdk";
import { oembed } from "@loomhq/loom-embed";
import { useEffect, useState } from "react";
let hostname = window.location.hostname.toLowerCase();
const isProd = hostname.startsWith("app") || hostname.startsWith("prod");
let loomKeySandbox = "f643881c-eca1-434e-b6f0-ab48612f75ee";
let loomKeySandboxOriginal = "6c8e2a2f-dff9-4ee4-99bc-2699d199f5c1";
let loomKeyProd = "a18cfc48-4356-4853-89c9-725ae7e2f6a0";
let loomKeyProdOriginal = "77170c0c-bb9b-4a3a-9436-31e238e6909";

let loomKey = isProd? loomKeyProd:loomKeySandbox;
const BUTTON_ID = "loom-sdk-button";

export default function Loom() {
  const [videoHTML, setVideoHTML] = useState("");

  useEffect(() => {
    async function setupLoom() {
      const { supported, error } = await isSupported();

      if (!supported) {
        console.warn(`Error setting up Loom: ${error}`);
        //return;
      }

      const button = document.getElementById(BUTTON_ID);

      if (!button) {
        return;
      }

      const { configureButton } = await setup({
        apiKey: loomKey
      });

      const sdkButton = configureButton({ element: button });

      sdkButton.on("insert-click", async video => {
        const { html } = await oembed(video.sharedUrl, { width: 400 });
        setVideoHTML(html);
      });
    }

    setupLoom();
  }, []);

  return (
    <>
      <button id={BUTTON_ID}>Record</button>
      <div dangerouslySetInnerHTML={{ __html: videoHTML }}></div>
    </>
  );
}