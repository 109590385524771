import {Box} from '@mui/system';
import React from 'react';
import CompanyDetails from './Form/CompanyDetails';
import ReferralAwardDetails from './Form/ReferralAwardDetails';
import ReferralMainCard from './Form/ReferralMainCard';
import BeginReferralButtons from "./BeginReferralButtons";

const PositionDescriptionDetails = ({position, showGettingStartedButtons = false, showAwardDetails = true}) => {
    
    const getButtons = () => {
        if (showGettingStartedButtons) {
            return <BeginReferralButtons/>
        }
    }
    const getRewardDetails = () => {
        if (showAwardDetails) {
            return <Box sx={{mt: 2}}>
                        <ReferralAwardDetails position={position}/>
                    </Box>
        }
    }

    return (
        <React.Fragment>
            <ReferralMainCard position={position}/>
            {getRewardDetails()}
            <Box sx={{mt: 2}}>
                <CompanyDetails company={position.company}/>
            </Box>
            {
                getButtons()
            }
            
        </React.Fragment>
    );
}

export default PositionDescriptionDetails;
