import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Typography from '@mui/material/Typography';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ConfirmImage from '../images/confirmcheckmark.png'
import {Button, Card, CardContent, CircularProgress} from "@mui/material";
import PositionDescriptionDetails from "../components/Referral/User/PositionDescriptionDetails";

const ReferralSubmissionCandidateViewPage = (props) => {

    // use guid to look up position details (name, company)? Or, instead of that complexity, just pass position id in querystring
    // where do we store guid? on job submission table. Then we can look up position from guid...
    // get guid from path
    const [referralDetails, setReferralDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const {positionId,referralId} = useParams(); // this is a guid, need to map in routes
    

    useEffect(() => {
        axios.get(`/api/positions/${positionId}/referrals/${referralId}`)
            .then(resp => {
                const data = resp.data;

                if (data !== null || data !== {}) {
                    if (data.error) {
                        setError(resp);
                    } else {
                        setReferralDetails(data.referral);
                    }
                } else {
                    setError({data: {error: {message: "load error"}}});
                }
            })
            .catch(resp => setError(resp))
            .finally(resp => setLoading(false))
    }, [referralId]);

    if (loading) {
        return (
            <Box sx={{display: 'flex', justifyContent: "space-around", height: '90vh', alignItems: 'center'}}>
                <CircularProgress size={80}/>
            </Box>
        );
    }

    return (
            <Box sx={{height: '100%', pb: 2, overflowY: 'scroll', margin: 'auto', position: 'relative'}}>
                <Box sx={{height: '100%', pb: 2, maxWidth: 800, margin: 'auto',}}>
                <Card variant="outlined" sx={{mt: 2}}>
            <CardContent sx={{pl: 5, pr: 5, py: 4}} style={{paddingBottom: 16}}>
            <Typography sx={{color:"black"}}>Hi, {referralDetails.candidate_name}! 
                    You've been submitted for the {referralDetails.position.title} role at {referralDetails.position.company.name} by {referralDetails.referrer_name}.<br/><br/>
                    Check it out below and if it interests you, let us know by clicking the 'I'm interested' button</Typography>
                    <Box sx={{ display: 'flex',justifyContent: 'center' }}>
                    <Button variant="outlined" href={`/candidate_interested/${referralDetails.candidate_confirmation_id}`} sx={{
                                            bgcolor: "success.main",
                                            color: "#ffffff",
                                            ':hover': {
                                                bgcolor: '#4F916B',
                                                color: 'white',
                                            },
                                            pl: 4, pr: 4, pt: 1, pb: 1, mb: 2, mt:3
                                        }}>I'm Interested</Button>
                    </Box>
                </CardContent>
                </Card>
                    <PositionDescriptionDetails position={referralDetails.position} showAwardDetails={false}/>
                </Box>
            </Box>
        );
};

export default ReferralSubmissionCandidateViewPage;
