import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useAuthState} from '../auth/auth';
import ReferralsGrid, {referralColumns} from "../components/Referral/Company/ReferralsGrid";
import api from "../api";
import {getCurrentPage, getFilter, getSort, useSpec} from "../shared/Specification/Specification";

export const CompanyReferralListPage = () => {

    const {companyId, positionId} = useParams();
    const [position, setPosition] = useState({});
    const [referrals, setReferrals] = useState(Array);

    const history = useHistory();
    const historyFilter = history.location?.state?.filter;
    const defaultFilter = !!historyFilter ? historyFilter : [];

    const {
        spec,
        onPageChange,
        onPageSizeChange,
        onFilterChange,
        onSortChange,
    } = useSpec({defaultSort: [{field: 'submitted_at', sort: 'desc'}], defaultFilter: defaultFilter});

    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    const {authState} = useAuthState();
    const headers = authState?.headers;

    const loadReferrals = () => {
        setLoading(true);

        api.referrals.list(companyId, positionId, spec, headers)
            .then(response => {
                const {data} = response;
                setTotalCount(data.total_count);

                setReferrals(data.referrals.map(referral => ({...referral, id: referral.id,})));
                setPosition(data.position)
            })
            .finally(() => setLoading(false))
    };

    useEffect(() => {
        if (!authState?.isAuthenticated) {
            return;
        }
        if (loading) {
            return;
        }

        loadReferrals();
    }, [positionId, spec]);

    return (
        <ReferralsGrid
            companyId={companyId}
            positionId={positionId}
            position={position}
            referrals={referrals}

            page={getCurrentPage(spec)}
            pageSize={spec?.page?.limit}
            rowCount={totalCount}

            onPageChange={(page) => onPageChange(page)}
            onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
            onSortChange={(sort) => onSortChange(sort, referralColumns)}
            onFilterChange={(filter) => onFilterChange(filter, referralColumns)}

            sort={getSort(spec)}
            filter={getFilter(spec)}

            loading={loading}
        />
    );
};

export default CompanyReferralListPage;
