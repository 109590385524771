import React from 'react';
import "yup-phone";
import {useHistory, useLocation} from "react-router-dom";
import ReferralSubmitted from "./ReferralSubmitted";
import ReferrerSubmittedContent from "./ReferrerSubmittedContent";
import ReferrerIsCandidateSubmittedContent from "./ReferrerIsCandidateSubmittedContent";
import _ from 'lodash';

const ReferralSubmittedShow = (props) => {
    const location = useLocation();
    const history = useHistory();

    if (_.isNil(location.state)) {
        history.push("/")
        return null;
    }

    const {referrerName, candidateName, referrerIsCandidate, companyName, positionName, confirmationId} = location.state;

    if (_.isNil(candidateName)
        // || _.isNil(referrerName)
        || _.isNil(referrerIsCandidate)
        || _.isNil(companyName)
        || _.isNil(positionName)) {
        history.push("/")
        return null;
    } else if (referrerIsCandidate) {
        return <ReferralSubmitted
            confirmationId = {confirmationId}
            content={
                <ReferrerIsCandidateSubmittedContent
                    candidateName={candidateName}
                    companyName={companyName}
                    positionName={positionName}
                />
            }
        />
    } else {
        return <ReferralSubmitted
        confirmationId = {confirmationId}    
        content={
                <ReferrerSubmittedContent
                    referrerName={referrerName}
                    candidateName={candidateName}
                    companyName={companyName}
                    positionName={positionName}
                />
            }
        />
    }
}

export default ReferralSubmittedShow;
