import React, { useState, useEffect } from "react";
import {
  useLocation,
  useParams
} from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import {useAuthState} from '../../auth/auth';
import Box from '@mui/material/Box';
import CardInfo from "./CardInfo";
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCcVisa, faCcAmex, faCcMastercard } from "@fortawesome/free-brands-svg-icons";
import { useTheme } from '@mui/material/styles';

export default function CardSelector(props) {

    const {onCardSelected} = props;
    const theme = useTheme();

    const {authState} = useAuthState();
    const headers = authState?.headers;
    let { companyId } = useParams();
    const [savedCards, setSavedCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState([]);
    const [showCards, setShowCards] = React.useState(false)


    useEffect(() => {
        if (!authState?.isAuthenticated) {
            // When user isn't authenticated, forget any user info
        } else {
            // let's see if we've already saved a credit card
            getSavedCards();

        }
    }, []); // Update if authState changes

    function getSavedCards() {
        axios.get('/api/company/'+companyId+'/payment/methods', {headers:headers}).then(res => {
            return res.data;
        }).then(data => {
            if (data) {
                var defaultCard = data.find(item => {
                    return item.default == true
                });
                setSavedCards(data);
                if (typeof defaultCard !== "undefined"){
                    setSelectedCard(defaultCard)
                }
            }
        });
    }

    const handleCardClicked = (card) => {
        console.log(card);
        setSelectedCard(card);
        onCardSelected(card);
        setShowCards(false);
      }
    const handleChangeCardClick = (card) => {
        console.log(card);
        setShowCards(!showCards);
      }

      const getLogo = () =>{
          if (selectedCard == null || selectedCard.cardType == null)
          return ""

        if (selectedCard.cardType.toLowerCase() === "visa")
          return <FontAwesomeIcon icon={faCcVisa} color={theme.palette.primary.main} />
        if (selectedCard.cardType.toLowerCase() === "amex")
          return <FontAwesomeIcon icon={faCcAmex} />
        if (selectedCard.cardType.toLowerCase() === "mastercard")
          return <FontAwesomeIcon icon={faCcMastercard} />
      }
// show current default card as a single clean line, include a "change" link button to swap cards,
// this will toggle the card selection map below
    return (
        <div>
            <Grid container direction='column' spacing={1}>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={10}>
                        <Box mr={1} component="span">{getLogo()}</Box>{selectedCard.cardType} ending in {selectedCard.last4}
                    </Grid>
                    <Grid item xs={2}>
                        <Box m={1} variant="contained"><Link component="button" onClick={(e) => handleChangeCardClick(e)}>Change</Link>
                        </Box>
                    </Grid>
                </Grid>
                {showCards ?
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={12}>
                            <hr />
                        </Grid>
                        <Grid item xs={12}>
                            <Box>Select a card below</Box>
                        </Grid>
                        <Grid item xs={12}>
                            {savedCards.map(card => (
                                <CardInfo key={card.id} card={card} onClick={() => handleCardClicked(card)}></CardInfo>
                            ))}
                        </Grid>
                    </Grid> : ""}
            </Grid>

        </div>
    );
}



