import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import NumberFormat from "react-number-format";
import Skeleton from '@mui/material/Skeleton';
import {validateEmail} from "../../DataValidation/EmailValidation";
import {validatePhone} from "../../DataValidation/PhoneValidation";

const useStyles = makeStyles((theme) => ({}));

const FormattedPhoneInput = props => {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            //getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            format="(###) ###-####"
            // allowEmptyFormatting
        />
    );
};

const ContactCompanyInfoForm = (props) => {
    const classes = useStyles();

    const {
        contactEmail, contactPhone, addressLine1, addressLine2, country, city, state, zip,
        isPending,
        handleChange
    } = props;

    const isContactEmailValid = validateEmail(contactEmail);
    const isContactPhoneValid = validatePhone(contactPhone);
    const isAddressLine1Valid = addressLine1 === null || addressLine1 !== "";
    const isAddressLine2Valid = addressLine2 === null || addressLine2 === "" || addressLine2 !== "";
    const isCountryValid = country === null || country !== "";
    const isStateValid = state === null || state === "" || state !== "";
    const isCityValid = city === null || city !== "";
    const isZipValid = zip === null || zip !== "";

    if (isPending) {
        return <Skeleton variant="rectangular"/>;
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="contactEmail"
                    name="contactEmail"
                    label="Email"
                    type="email"
                    autoComplete="email"
                    fullWidth
                    value={contactEmail === null ? "" : contactEmail}
                    error={!isContactEmailValid}
                    helperText={isContactEmailValid ? "" : "email invalid"}
                    onChange={(event) => handleChange('email', event)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <NumberFormat 
                    // {...props}
                    value={contactPhone === null ? "" : contactPhone}
                    id="contactPhone"
                    name="contactPhone"
                    isNumericString={true}
                    customInput={TextField}
                    format="(###) ###-####"
                    label="Phone"
                    type="tel"
                    autoComplete="tel"
                    fullWidth
                    error={!isContactPhoneValid}
                    helperText={isContactPhoneValid ? "" : "phone invalid"}
                    onValueChange={(event) => handleChange('phone', 
                    {"target":{"value":event.value}})}
                >

                </NumberFormat>
                {/* <TextField
                    id="contactPhone"
                    name="contactPhone"
                    label="Phone"
                    type="tel"
                    autoComplete="tel"
                    fullWidth
                    value={contactPhone === null ? "" : contactPhone}
                    error={!isContactPhoneValid}
                    helperText={isContactPhoneValid ? "" : "phone invalid"}
                    onChange={(event) => handleChange('phone', event)}
                    InputProps={{
                        inputComponent: FormattedPhoneInput,
                    }}
                /> */}
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    id="contactAddress1"
                    name="contactAddress1"
                    label="Address line 1"
                    type="address-line1"
                    autoComplete="address-line1"
                    required
                    fullWidth
                    value={addressLine1 === null ? "" : addressLine1}
                    error={!isAddressLine1Valid}
                    helperText={isAddressLine1Valid ? "" : "required"}
                    onChange={(event) => handleChange('address_line1', event)}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    id="contactAddress2"
                    name="contactAddress2"
                    label="Address line 2"
                    type="address-line2"
                    autoComplete="address-line2"
                    fullWidth
                    value={addressLine2 === null ? "" : addressLine2}
                    onChange={(event) => handleChange('address_line2', event)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="contactAddressCountry"
                    name="contactAddressCountry"
                    label="Country"
                    type="country"
                    autoComplete="country"
                    required
                    fullWidth
                    value={country === null ? "" : country}
                    error={!isCountryValid}
                    helperText={isCountryValid ? "" : "required"}
                    onChange={(event) => handleChange('country', event)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="contactAddressCity"
                    name="contactAddressCity"
                    label="City"
                    type="address-level2"
                    autoComplete="address-level2"
                    required
                    fullWidth
                    value={city === null ? "" : city}
                    error={!isCityValid}
                    helperText={isCityValid ? "" : "required"}
                    onChange={(event) => handleChange('city', event)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="contactAddressState"
                    name="contactAddressState"
                    label="State/Province"
                    type="address-level1"
                    autoComplete="address-level1"
                    fullWidth
                    value={state === null ? "" : state}
                    onChange={(event) => handleChange('state', event)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="contactAddressZipcode"
                    name="contactAddressZipcode"
                    label="Zip/Postal Code"
                    type="postal-code"
                    autoComplete="postal-code"
                    required
                    fullWidth
                    value={zip === null ? "" : zip}
                    error={!isZipValid}
                    helperText={isZipValid ? "" : "required"}
                    onChange={(event) => handleChange('zip', event)}
                />
            </Grid>
        </Grid>);
}

export default ContactCompanyInfoForm
