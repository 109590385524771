import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import FormikOutlinedTextField from "../../shared/Formik/FormikOutlinedTextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import {Box, TextField, Typography} from '@mui/material';
import InputAdornment from "@mui/material/InputAdornment";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => (
    {
        field: {
            marginRight: theme.spacing(3),
        },
        otherLabel: {
            marginRight: theme.spacing(2),
        },
    }
));

const ContactForm = ({formik, onChange, title, prefix, reviewMode}) => {

    const classes = useStyles();

    const readOnly = reviewMode ? reviewMode : false;

    const readonlyView = (labelTitle, value) => {
        return (
            <Box>
                <Typography
                    sx={{fontSize: 14, fontWeight: 500, color: 'text.primary'}}
                    component='span'
                >
                    {labelTitle}:
                </Typography>
                <Typography
                    sx={{fontSize: 12}} component='span'
                >
                    {value}
                </Typography>
            </Box>
        );
    }

    const makeLink = (url) => {
        return <a href={url}> {url}</a>
    }
    const getFormikValue = (key) => {
        return formik.values[`${prefix}${key}`]
    }

    const pageview = () => {
        if (readOnly) {
            return <Grid container direction='column' rowSpacing={1}>
                <Grid item>
                    <Box sx={{display: 'flex', justifyContent: 'left'}}>{title}</Box>
                </Grid>
                <Grid item>
                    {readonlyView('Email', getFormikValue('email'))}
                </Grid>
                <Grid item>
                    {readonlyView('Phone', getFormikValue('phone'))}
                </Grid>
                <Grid item>
                    {readonlyView("Receiving texts is ok", getFormikValue('texts_opt_in') === true ? "Yes" : "No")}
                </Grid>
                <Grid item>
                    {(getFormikValue('alt_contact_type') !== null) ?
                        readonlyView("Alternative Contact Info", `${getFormikValue('alt_contact_type')}: ${getFormikValue('alt_contact_value')}`) : ""}
                </Grid>

            </Grid>
        } else {

            return (
                <React.Fragment>
                    <Grid item xs={12} sm={12}>
                        <Box mt={2} sx={{display: 'flex', justifyContent: 'center'}}>
                            <Typography
                                sx={{verticalAlign: 'middle', pb: 1, fontWeight: 400, color: 'text.primary'}}
                                variant="h6">{title}</Typography></Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormikOutlinedTextField
                            fullWidth
                            required
                            formik={formik}
                            className={classes.field}
                            fieldName={`${prefix}email`}
                            {...prefix === "referrer_" ? ({autoComplete: "email"}) : ({})}
                            label="Email"
                            onChange={(event) => {
                                formik.setStatus({state: "touched"});
                                formik.setFieldValue(`${prefix}email`, event.target.value);
                            }}
                            //onBlur = {onBlur}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NumberFormat
                            value={formik.values[`${prefix}phone`] === null ? "" : formik.values[`${prefix}phone`]}
                            id={`${prefix}phone`}
                            name={`${prefix}phone`}
                            customInput={TextField}
                            isNumericString
                            label="Phone"
                            type="tel"
                            {...prefix === "referrer_" ? ({autoComplete: "tel"}) : ({})}
                            fullWidth
                            size="small"
                            error={Boolean(formik.errors[`${prefix}phone`])}
                            helperText={formik.errors[`${prefix}phone`]}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+</InputAdornment>,
                            }}
                            onValueChange={(event) => {
                                formik.setStatus({state: "touched"});
                                formik.setFieldValue(`${prefix}phone`, event.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{alignSelf: "center"}} style={{paddingTop: 12}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values[`${prefix}texts_opt_in`]}
                                    onChange={event => onChange(event, `${prefix}texts_opt_in`)}
                                    name={`${prefix}texts_opt_in`}
                                />
                            }
                            label="Texts are ok"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl
                            size="small"
                            variant="outlined"
                            fullWidth
                        >
                            <InputLabel id="contact-form-other-label">Other</InputLabel>
                            <Select
                                value={formik.values[`${prefix}alt_contact_type`] === null ? "" : formik.values[`${prefix}alt_contact_type`]}
                                name={`${prefix}alt_contact_type`}
                                onChange={event => onChange(event, `${prefix}alt_contact_type`)}
                                label="Other"
                            >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value="WhatsApp">WhatsApp</MenuItem>
                                <MenuItem value="WeChat">WeChat</MenuItem>
                                <MenuItem value="Telegram">Telegram</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <FormikOutlinedTextField
                            fullWidth
                            formik={formik}
                            className={classes.field}
                            fieldName={`${prefix}alt_contact_value`}
                            label="Alternative Contact"
                            onChange={onChange}
                        />
                    </Grid>
                </React.Fragment>
            );
        }
    }


    return (
        pageview()
    )
}

export default ContactForm
