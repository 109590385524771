import React from "react";
import {Box} from "@mui/material";
import NegativeMarginImagesCandidate from "../NegativeMarginImagesCandidate";

const CandidateFormUpdateBackground = (props) => {
    return (
        <Box sx={{marginTop: {xs: 0, sm: 0, md: "-100px"}}}>
            <NegativeMarginImagesCandidate/>
            {props.children}
        </Box>
    );
};

export default CandidateFormUpdateBackground;
