import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => (
        {
            darkDisabledText: {
                "& .MuiInputBase-root.Mui-disabled": {
                    color: "black"
                }
            }
        }
    )
);

const CustomAutocomplete = React.memo((props) => {
    const classes = useStyles();

    const {
        isLoading,
        id,
        disabled,
        label,
        options, selectedOption, onChangeSelectedOption, getOptionLabel, renderOption, noOptionsText,
        onInputChange,
        inputVariant,
    } = props;

    return (
        <Autocomplete
            id={id + "-custom-autocomplete"}
            className={classes.darkDisabledText}
            disabled={disabled}
            fullWidth
            noOptionsText={noOptionsText}
            getOptionLabel={getOptionLabel}
            options={!!options ? options : []}
            autoComplete
            includeInputInList
            loading={isLoading}
            value={selectedOption}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, newValue) => {
                onChangeSelectedOption(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                onInputChange(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant={!!inputVariant ? inputVariant : "outlined"}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            renderOption={renderOption}
        />
    );
});

export default CustomAutocomplete;
