import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import {DataGrid} from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';

import axios from 'axios';
import { useAuthState } from "../../auth/auth";
import { Button, Modal } from "@mui/material";
import NumberFormat from "react-number-format";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(2),
            padding: theme.spacing(1),
        },
    },
    paper: {

        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

}));

function Admin() {

    const history = useHistory();
    const {authState} = useAuthState();
    const headers = authState?.headers;
    const [referrals, setReferrals] = useState([]);

    const canAccessAdmin = (perms) => {
        if (!perms && perms.length() < 1)
            return false;
        var canDoAdmin = perms.includes("manage:admin");
        return canDoAdmin;
    }

    const loadPaymentGrid = () => {
        axios.get('/api/admin/transactions', {headers: headers}).then(res => {
            setReferrals(res.data);
        });
    }

    useEffect(() => {
        if (authState?.isAuthenticated && canAccessAdmin(authState.user?.permissions)) {
             // let's see if we've already saved a credit card
           loadPaymentGrid();  
        } else {
           return;
        }
    }, [headers]); // Update if authState changes

    const columns = [
        {field: 'id', headerName: 'Id', width: 150},
        {field: 'company_id', headerName: 'Company Id', width: 150},
        {field: 'company_name', headerName: 'Company Name', width: 200},
        {field: 'position_id', headerName: 'Position Id', width: 150},
        {field: 'position_name', headerName: 'Position Name', width: 200},
        {field: 'referrer_id', headerName: 'Referrer Id', width: 150},
        {field: 'referrer_name', headerName: 'Referrer Name', width: 200},
        {field: 'referrer_payment_enabled', headerName: 'Referrer Payment Enabled', width: 200},
        {field: 'referrer_payment_status', headerName: 'Referrer Payment Status', width: 200},
        {
            field: 'col5', headerName: 'Pay', width: 150,
            renderCell: (params) => {
                // build inputs that we need
                var item = params.row;
                return <Button onClick={(e) => openPaymentDialog(e, item)}>
                    Authorize Payment
                </Button>
            },
        },
    ];

    const [open, setOpen] = React.useState(false);
    const [selectedReferrer, setSelectedReferrer] = React.useState(null);
    const handleClose = () => {
        setOpen(false);
    };

    const openPaymentDialog = (event, item) => {
        setSelectedReferrer(item);
        setOpen(true);
    };

    const disburse = () => {
        axios.post('/api/admin/disburse',{"referral_id":selectedReferrer.referral_id}, {headers: headers}).then(res => {
            loadPaymentGrid();
            setOpen(false);
            
            toast.success('Payment Complete', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored"
                });
        })
    }

    const classes = useStyles();

    return (
        <div className={classes.root}>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper elevation={3}>
                        <Box p={1}>
                            <Typography variant="h6">All Referrals to manage
                            </Typography></Box>
                        <div style={{height: 300, width: '100%'}}>
                            <DataGrid rows={referrals} columns={columns}/>
                        </div>
                    </Paper>
                </Grid>
            </Grid>

            <Modal
                    open={open}
                    className={classes.modal}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description">
                    <div className={classes.paper}>
                        <Grid container direction="column">
                            <Grid item>
                                Company: {selectedReferrer?.company_name} (Id: {selectedReferrer?.company_id})
                            </Grid>
                            <Grid item>
                                Position: {selectedReferrer?.position_name} (Id: {selectedReferrer?.position_id})
                            </Grid>
                            <Grid item>
                                Referral Id: {selectedReferrer?.referral_id}
                            </Grid>
                            <Grid item>
                                Referrer Name: {selectedReferrer?.referrer_name} (Email: {selectedReferrer?.referrer_email} User Id: {selectedReferrer?.referrer_id})
                            </Grid>
                            <Grid item>
                                Candidate Name: {selectedReferrer?.candidate_name}
                            </Grid>
                            <Grid item>
                                Referral Status: {selectedReferrer?.referral_status}
                            </Grid>
                            <Grid item>
                                Date Candidate Submitted: {selectedReferrer?.referral_submission_date}
                            </Grid>
                            <Grid item>
                                Date Company Paid: {selectedReferrer?.company_payment_date}
                            </Grid>
                            <Grid item>
                                Company Transaction Id: {selectedReferrer?.stripe_payment_id_company}
                            </Grid>
                            <Grid item>
                                Dollar Amount to send: <NumberFormat value={selectedReferrer?.reward_amount_cents/100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true}
                                                 decimalScale={2}/>
                            </Grid>
                            <Grid item>
                                <Box><Button>Cancel</Button><Button onClick={disburse}>Disburse</Button></Box>
                            </Grid>
                        </Grid>
                        <Typography sx={{fontSize:14}}>Confirm disbursement details to {selectedReferrer?.referrer_name}</Typography>
                        
                        {/* <PayReferrer
                            paymentComplete={handlePaymentCompete}
                            paymentDetails={formatTransactionDetails(transactions[0])}
                        /> */}
                    </div>
                </Modal>
                <ToastContainer />
        </div>
    );
}

export default Admin;
