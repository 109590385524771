import React from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import Box from "@mui/material/Box";
import Button from '@mui/material/Button';

import {truncate} from 'lodash';

const CollapseText = ({text, length, className, sx}) => {
    const [showMore, setShowMore] = React.useState(false);
    const shortText = truncate(text, {length: length || 300, separator: /,? +/});
    const removeExtraNewLines = (text) => {
        return text.replaceAll("<p><br></p>", "");
        
    }
    const content = showMore ? removeExtraNewLines(text) : removeExtraNewLines(shortText);
    const seeText = showMore ? "see less" : "see more";
    return (
        <Box className={className} sx={sx}>
            <Box>
            <div dangerouslySetInnerHTML={{__html: content}}></div>
                {/* {content} */}
            </Box>
            {text === shortText ? null :
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        size="small"
                        sx={{color:"success.main"}}
                        onClick={() => setShowMore(!showMore)}
                        endIcon={showMore ? <ArrowUpwardIcon/> : <ArrowDownwardIcon/>}
                    >
                        {seeText}
                    </Button>
                </Box>
            }
        </Box>
    )
}

export default CollapseText;
