import PropTypes from 'prop-types'
import React from 'react';
import Grid from '@mui/material/Grid';
import {Box} from '@mui/system';
import Typography from "@mui/material/Typography";
import CallIcon from "@mui/icons-material/Call";
import NumberFormat from "react-number-format";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import {IconButton, Link, Tooltip} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useTheme} from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ArticleIcon from "@mui/icons-material/Article";
import AboutCandidateDisplay from "../User/Form/Candidate/AboutCandidateDisplay";
import {getLinkIcon} from "../Company/SelectedReferralGrid";
import ChevronUp from "../../../shared/icons/ChevronUp.svg";
import ChevronDoubleUp from "../../../shared/icons/ChevronDoubleUp.svg";
import ChevronTripleUp from "../../../shared/icons/ChevronTripleUp.svg";
import {useHistory} from "react-router-dom";
import ReferrerIsCandidateTypographyIcon from "./Icons/ReferrerIsCandidateTypographyIcon";
import CandidateInterestConfirmationTypographyIcon from "./Icons/CandidateInterestConfirmationTypographyIcon";
import CandidateInterestLevelTypographyIcon from "./Icons/CandidateInterestLevelTypographyIcon";
import CandidateResumeDialog from "./Resume/CandidateResumeDialog";

const renderCandidateLevelOfInterestIcon = (candidateLevelOfInterest) => {
    if (candidateLevelOfInterest === 4) {
        return <Box component="img" sx={{height: 24, width: 24}} src={ChevronTripleUp}/>
    } else if (candidateLevelOfInterest === 3) {
        return <Box component="img" sx={{height: 24, width: 24}} src={ChevronDoubleUp}/>
    } else if (candidateLevelOfInterest === 2) {
        return <Box component="img" sx={{height: 24, width: 24}} src={ChevronUp}/>
    } else if (candidateLevelOfInterest === 1) {
        return <Box component="img" sx={{height: 24, width: 24}} src={ChevronUp}/>
    } else {
        return <Box component="img" sx={{height: 24, width: 24}} src={ChevronUp}/>
    }
}


const CandidateInfoDisplay = (props) => {
    const {
        companyId, positionId,
        referrerIsCandidate, isCandidateDuplicate, isCandidateInterested,
        candidateName, candidatePhone, candidateEmail, candidateUrl, candidateResumeUrl, candidateLevelOfInterest,
        howDoYouKnowCandidate,
        aboutCandidateText, aboutCandidateUrl,
    } = props;

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const theme = useTheme();
    const history = useHistory();

    return (
        <Paper variant="outlined" sx={{backgroundColor: "", pb: 2}}>
            <Box sx={{display: 'flex', alignItems: 'center', p: 2, pb: 0,}}>
                <AccountCircleIcon sx={{height: 48, width: 48, ml: 0, color: theme.palette.linkedin.light, verticalAlign: 'bottom'}} fontSize="large"/>
                {/*<Box sx={{display: "flex", justifyContent: "space-between"}}></Box>*/}
                <Typography
                    component="span"
                    variant="h5"
                    sx={{fontWeight: 600, ml: 1, flexGrow: 1}}
                >
                    {candidateName.toUpperCase()}
                </Typography>
                
                <Tooltip
                    title={
                        <Box sx={{fontSize: 14}}>
                            This candidate was submitted by more than one person<br/>
                            Click to see all duplicates
                        </Box>
                    }
                >
                    <IconButton
                        sx={{
                            color: theme.palette.warning.main,
                            p: 0.2,
                            display: isCandidateDuplicate ? "flex" : "none"
                        }}
                        onClick={() => history.push({
                            pathname: `/company/${companyId}/positions/${positionId}/referrals`,
                            state: {
                                filter: [
                                    {
                                        columnField: "candidate_email",
                                        value: candidateEmail,
                                        operatorValue: "equals",
                                        type: "string",
                                        joinOperator: "and",
                                    },
                                ]
                            }
                        })}
                    >
                        <ReportGmailerrorredIcon sx={{fontSize: 36,}}/>
                    </IconButton>
                </Tooltip>
            </Box>
            <Box
                sx={{
                    whiteSpace: "nowrap",
                    color: 'white',
                    bgcolor: theme.palette.linkedin.dark,
                    mt: 0,
                    p: 1,
                    fontSize: 14,
                    border: 'none',
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    ml: 2.5,
                    position: 'relative',
                    top: "20px",
                    mr:10
                }}
            >
                {getLinkIcon(candidateUrl, false, theme)}
                <Link
                    sx={{
                        fontWeight: 600,
                        color: "white",
                    }}
                    //underline="none"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={candidateUrl}
                >
                    {candidateUrl}
                </Link>
            </Box>
            <Box sx={{bgcolor: theme.palette.linkedin.light, p: 2, pt: 5, border: "none", textAlign: "center"}}>
                <Grid
                    container
                    // justifyContent="space-around"
                    sx={{fontSize: 13}}
                    rowSpacing={1}
                >
                    {
                        candidatePhone && (
                            <Grid item xs={12} md={4}>
                                <Box sx={{display: "flex", placeContent: "center", textAlign: "center"}}>
                                    <CallIcon color="primary" sx={{mr: 1, verticalAlign: 'text-top'}} fontSize="small"/>
                                    <NumberFormat
                                        format="(###) ### ####"
                                        displayType="text"
                                        value={candidatePhone}/>
                                </Box>
                            </Grid>
                        )
                    }
                    <Grid item xs={12} md={4}>
                        <Box sx={{display: "flex", placeContent: "center", textAlign: "center"}}>
                            <AlternateEmailIcon
                                color="primary"
                                sx={{mr: 1, verticalAlign: 'text-top'}}
                                fontSize="small"
                            />
                            <Link href={`mailto:${candidateEmail}`}>{candidateEmail}</Link>
                        </Box>
                    </Grid>
                    {
                        candidateResumeUrl && (
                            <Grid item xs={12} md={4}>
                                <Box sx={{display: "flex", placeContent: "center", textAlign: "center"}}>
                                    <ArticleIcon
                                        color="primary"
                                        sx={{mr: 1, verticalAlign: 'text-top'}}
                                        fontSize="small"
                                    />
                                    <Link onClick={handleClickOpen}>
                                        View Resume
                                    </Link>
                                    <CandidateResumeDialog open={open} handleClose={handleClose} candidateResumeUrl={candidateResumeUrl}/>
                                </Box>
                            </Grid>
                        )
                    }
                    {/*<Grid item>*/}
                    {/*    <ChatIcon sx={{mr: 1, verticalAlign: 'text-top'}} fontSize="small"/>{candidate_alt_contact_type}:{candidate_alt_contact_value}*/}
                    {/*</Grid>*/}
                </Grid>
            </Box>
            <Box sx={{px: 2, py: 0, border: "none", textAlign: "center"}}>
                <Grid
                    container
                    justifyContent="space-around"
                    sx={{
                        ml: 1,
                        fontSize: 13,
                        alignItems: "center"
                    }}
                    rowSpacing={1}
                >
                    
                    {/* <Grid item xs={12} lg={6} xl={4}>
                        <CandidateInterestConfirmationTypographyIcon isCandidateInterested={isCandidateInterested}/>
                    </Grid>
                    <Grid item xs={12} lg={6} xl={4}>
                        <CandidateInterestLevelTypographyIcon candidateLevelOfInterest={candidateLevelOfInterest}/>
                    </Grid> */}
                </Grid>
            </Box>
            <AboutCandidateDisplay
                aboutCandidateUrl={aboutCandidateUrl}
                aboutCandidate={aboutCandidateText}
                isCandidateInterested={isCandidateInterested}
                candidateLevelOfInterest={candidateLevelOfInterest}
                referrerIsCandidate ={referrerIsCandidate}
            />
            
            {
                !referrerIsCandidate && howDoYouKnowCandidate && (
                    <Box sx={{px: 2, pb: 1}}>
                        <Typography sx={{fontSize: 18, fontWeight: 600, pb: 1}}>How Referrer Knows Candidate</Typography>
                        {howDoYouKnowCandidate}
                    </Box>
                )
            }
        </Paper>
    );
}

export default CandidateInfoDisplay

CandidateInfoDisplay.propTypes = {
    companyId: PropTypes.string,
    positionId: PropTypes.string,
    aboutCandidateText: PropTypes.string,
    aboutCandidateUrl: PropTypes.string,
    candidateEmail: PropTypes.string,
    candidateLevelOfInterest: PropTypes.number,
    candidateName: PropTypes.string,
    candidatePhone: PropTypes.string,
    candidateResumeUrl: PropTypes.string,
    candidateUrl: PropTypes.string,
    howDoYouKnowCandidate: PropTypes.string,
    referrerIsCandidate: PropTypes.bool,
    isCandidateDuplicate: PropTypes.bool,
}
