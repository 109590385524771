import React, {useEffect, useState} from 'react';

import Typography from '@mui/material/Typography';
import {useFormik} from "formik";
import * as yup from "yup";
import "yup-phone";
import {useHistory} from "react-router-dom";
import CandidateForm from "./Form/Candidate/CandidateForm";
import ReviewReferralSubmission from "./Form/Submit/ReviewReferralSubmission";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {StepButton} from "@mui/material";
import {useTheme} from "@emotion/react";
import AutoSave from "../../../shared/Formik/AutoSave";
import ReferralAppBar from "./Form/ReferralAppBar";
import api from "../../../api";
import {useAuthState} from "../../../auth/auth";
import redirect from "../../../redirect";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import PositionDescriptionDetails from './PositionDescriptionDetails';
import ReferrerForm from "./Form/Referrer/ReferrerForm";

const toInt = v => v ? +v : null;

const phoneRegExp = /^\d{10}$/;

const validationSchema = yup.object({
    referrer_name: yup.string().required('Referrer Name is required').nullable(),
    referrer_url: yup.string().required('Referrer Link to Profile is required').url("Referrer Professional Profile must be a valid url").nullable(),
    about_referrer_text: yup.string().max(1000, "Max 1000 symbols").nullable(),
    referrer_email: yup.string().nullable().email("Referrer Email must be valid").required('Referrer Email is required'),
    referrer_phone: yup.number().nullable(),
    referrer_texts_opt_in: yup.boolean().nullable(),
    referrer_alt_contact_type: yup.string().nullable(),
    referrer_alt_contact_value: yup.string().nullable().test("Alt Contact", "Alternative Contact is required when type set", function (value) {
        return (!this.parent.referrer_alt_contact_type) || !!value;
    }),
    candidate_name: yup.string().nullable().required('Candidate Name is required'),
    candidate_url: yup.string().url("Candidate Professional Profile must be a valid url").nullable().required('Referrer Link to Profile is required'),
    candidate_resume_url: yup.string().nullable(),
    candidate_resume_files: yup.array().nullable(),
    how_do_you_know_candidate: yup.string().nullable(),
    about_candidate_text: yup.string().nullable(),
    about_candidate_url: yup.string().url("Candidate Video Link must be a valid url").nullable(),
    candidate_email: yup.string().nullable().email("Candidate Email must be valid").required('Candidate Email is required')
        .test("ReferrerIsCandidateFlagNotSet",
            "Referrer Email is equal to Candidate Email, but Referrer Is Candidate flag not set", function (value) {
                return !(this.parent.referrer_email !== null
                    && value !== null && value !== undefined
                    && this.parent.referrer_email.toLowerCase() === value.toLowerCase()
                    && this.parent.referrer_is_candidate === false);
            })
        .test("ReferrerIsCandidateFlagSet",
            "Referrer Email is not equal to Candidate Email, but Referrer Is Candidate flag is true", function (value) {
                return !(this.parent.referrer_email !== null
                    && value !== null && value !== undefined
                    && this.parent.referrer_email.toLowerCase() !== value.toLowerCase()
                    && this.parent.referrer_is_candidate === true);
            }),
    candidate_phone: yup.number().nullable(),
    candidate_texts_opt_in: yup.boolean().nullable(),
    candidate_alt_contact_type: yup.string().nullable(),
    candidate_alt_contact_value: yup.string().nullable(),
});

export const referralInitialValues = {
    referrer_name: '',
    referrer_url: '',
    about_referrer_text: '',
    referrer_email: '',
    referrer_phone: '',
    referrer_texts_opt_in: true,
    referrer_alt_contact_type: '',
    referrer_alt_contact_value: '',
    referrer_is_candidate: false,
    candidate_name: '',
    candidate_url: '',
    candidate_resume_files: [],
    how_do_you_know_candidate: '',
    candidate_level_of_interest: '',
    about_candidate_text: '',
    about_candidate_url: '',
    candidate_is_aware: false,
    candidate_email: '',
    candidate_phone: '',
    candidate_texts_opt_in: true,
    candidate_alt_contact_type: '',
    candidate_alt_contact_value: '',
    error: null,
};

const useStyles = makeStyles(() => ({
    root: {
        //   "& .MuiStepIcon-active": { color: "red" },
        "& .Mui-completed": {color: "green"},
        "& .Mui-error": {color: "#d32f2f"},
        //   "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" }
    }
}));

const steps = ['Position Details', 'About the candidate', 'Tell us about yourself', 'Review and Submit'];

const ReferralForm = ({referral, position, preventSubmit, defaultToPositionDetails = false}) => {

    const [activeStep, setActiveStep] = React.useState(defaultToPositionDetails ? 0 : 1);
    const [skipped, setSkipped] = React.useState(new Set());
    const [completed, setCompleted] = React.useState({0: true}); // default to the first fill out page

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        //handleNext();
    };

    const isStepOptional = (step) => {
        return false;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const isStepFailed = (step) => {
        if (Object.keys(formik.errors).length === 0)
            return false;

        return doesStepHaveErrors(step)
    };

    // create a method, on handle next pass step and return has errors, DoesStepHaveErrors(step)

    const doesStepHaveErrors = (step) => {
        let z;

        if (step === 0) {
            return false;
        } else if (step === 1) {
            z = Object.keys(formik.errors)
                .filter(k => k.indexOf('candidate_') === 0)
                .reduce((newData, k) => {
                    newData[k] = formik.errors[k];
                    return newData;
                }, {});
            return Object.keys(z).length !== 0;

        } else if (step === 2) {
            z = Object.keys(formik.errors)
                .filter(k => k.indexOf('referrer_') === 0)
                .reduce((newData, k) => {
                    newData[k] = formik.errors[k];
                    return newData;
                }, {});
            return Object.keys(z).length !== 0
        }
    }

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        //markStepErroredOrComplete(activeStep);
        if (!doesStepHaveErrors(activeStep) && activeStep !== steps.length - 1) // don't show the last step as complete
            handleComplete();

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleStep = (step) => () => {
        // determine if step has any errors on it, if so, mark step as error (fields need to be touched and have errors)
        // if no errors, mark step complete

        // lastly, 
        if (!doesStepHaveErrors(activeStep) && activeStep !== steps.length - 1)
            handleComplete();

        setActiveStep(step);
    };

    const formik = useFormik({
        initialValues: referral || referralInitialValues,
        validateOnChange: true,
        initialStatus: {state: "new"},
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            if (preventSubmit) {
                return;
            }

            // actions.setStatus({state: "updating"});

            // const newReferral = {
            //     ...values,
            //     candidate_level_of_interest: toInt(values.candidate_level_of_interest)
            // };

            // return api.referrals.update(referral.position_id, referral.id, newReferral, headers)
            //     .then(resp => {
            //         actions.setValues({...resp.data.referral, error: null}, false);
            //         actions.setStatus({state: "updated"});
            //     })
            //     .catch(err => {
            //         actions.setValues({...values, error: err.response.data.error.message}, false)
            //         actions.setStatus({state: "error"})
            //     })
        },
    });

    useEffect(
        () => {
            if (!referral) {
                return;
            }

            formik.setValues(referral, false)
                .then(() => formik.setStatus({state: "new"}))
        },
        [referral]
    );

    const history = useHistory();
    const {authState} = useAuthState();
    const headers = authState?.headers;

    const onSubmit = () => {
        if (formik.status.state === "submitting" || formik.status.state === "saving") {
            return;
        }

        formik.setStatus({state: "submitting"});
        const newReferral = {
                ...formik.values,
                candidate_level_of_interest: toInt(formik.values.candidate_level_of_interest)
            };

        api.referrals.submit(referral.position_id, referral.id, newReferral, headers)
            .then(resp => {
                formik.setStatus({state: "submitted"});

                const referral = resp.data.referral;

                redirect.referrals.submitSuccess(history, referral?.position_id, referral?.id, {
                    referrerName: referral?.referrer_name,
                    candidateName: referral?.candidate_name,
                    referrerIsCandidate: referral?.referrer_is_candidate,
                    companyName: referral?.position.company.name,
                    positionName: referral?.position.title,
                    confirmationId: referral?.referrer_confirmation_id
                });
            })
            .catch(err => {
                formik.setValues({...formik.values, error: err.response.data.error.message}, false)
                formik.setStatus({state: "not_submitted"})
            })
    };

    const theme = useTheme();

    const [scrollTarget, setScrollTarget] = useState(undefined)

    function renderStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <React.Fragment>
                        <PositionDescriptionDetails position={position}/>
                        {/*<ReferralMainCard position={position}/>*/}
                        {/*<Box sx={{mt: theme.spacing(2)}}>*/}
                        {/*    <ReferralAwardDetails position={position}/>*/}
                        {/*</Box>*/}
                        {/*<Box sx={{mt: theme.spacing(2)}}>*/}
                        {/*    <CompanyDetails company={position.company}/>*/}
                        {/*</Box>*/}
                    </React.Fragment>
                );
            case 1:
                return (
                    <CandidateForm formik={formik} position={position}/>
                );
            case 2:
                return (
                    <ReferrerForm formik={formik}/>
                );
            case 3:
                return (
                    <ReviewReferralSubmission formik={formik} position={position}/>
                );
            default:
                return <div>Not Found</div>;
        }
    }

    const classes = useStyles();

    const getNextStepButtonLabel = (activeStep) => {
        if (activeStep === 0) {
            return (<React.Fragment>Get Started</React.Fragment>);
        } else if (activeStep === steps.length - 1) {
            return (<React.Fragment>Submit</React.Fragment>);
        } else {
            return (<React.Fragment>Next</React.Fragment>);
        }
    };

    return (
        <Box
            sx={{
                height: 'inherit',
                pb: 2,
                overflowY: 'scroll',
                margin: 'auto',
                position: 'relative'
            }}
            ref={node => {
                if (node) {
                    //setScrollTarget(node);
                }
            }}
        >
            {/* <ReferralAppBar
                formik={formik}
                onSubmit={onSubmit}
                preventSubmit={preventSubmit}
                scrollTarget={scrollTarget}
            > */}
                <Box sx={{overflowX: "auto", overflowY: "hidden"}}>
                    <Stepper sx={{py: 2, mx: 1}} className={classes.root} nonLinear activeStep={activeStep}>
                        {
                            steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};

                                if (isStepOptional(index)) {
                                    labelProps.optional = (
                                        <Typography variant="caption">Optional</Typography>
                                    );
                                }

                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }

                                if (isStepFailed(index)) {
                                    labelProps.optional = (
                                        <Typography variant="caption" color="error">
                                            Items need attention
                                        </Typography>
                                    );

                                    labelProps.error = true;
                                }

                                return (
                                    <Step key={label} completed={completed[index]} {...stepProps}>
                                        <StepButton color="inherit" onClick={handleStep(index)}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </StepButton>
                                    </Step>
                                );
                            })
                        }
                    </Stepper>
                </Box>
            {/* </ReferralAppBar> */}
            <Box
                sx={{
                    height: 'inherit',
                    pb: 2,
                    maxWidth: 800,
                    margin: 'auto',
                }}
            >
                <Typography
                    align="center"
                    sx={{
                        pt: 1, pb: 2,
                        alignContent: "center",
                        fontWeight: 700,
                        color: 'text.primary'
                    }}
                    variant="h4"
                >
                    {steps[activeStep]}
                </Typography>
                {renderStepContent(activeStep)}
                {
                    activeStep === steps.length ? (
                        <Paper elevation={3}>
                            <Box sx={{p: theme.spacing(2), pt: 0}}>
                                <React.Fragment>
                                    <Typography sx={{mt: 2, mb: 1}}>
                                        All steps completed - you&apos;re finished
                                    </Typography>
                                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                        <Box sx={{flex: '1 1 auto'}}/>
                                        <Button onClick={handleReset}>Reset</Button>
                                    </Box>
                                </React.Fragment>
                            </Box>
                        </Paper>
                    ) : (
                        <React.Fragment>
                            <Grid container mt={2} justifyContent="center" columnSpacing={2}>
                                {
                                    activeStep !== 0 && (
                                        <Grid item xs={6}>
                                            <Button fullWidth
                                                    sx={{
                                                        bgcolor: "#ffffff",
                                                        color: "text.primary",
                                                        borderColor: "GrayText.primary",
                                                        borderStyle: 'solid',
                                                        pl: 4, pr: 4, pt: 1, pb: 1, mb: 5,
                                                    }}
                                                    disabled={activeStep === 0}
                                                    variant="outlined"
                                                    onClick={handleBack}
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                    )
                                }
                                <Grid item xs={6}>
                                    {
                                        isStepOptional(activeStep) && (
                                            <Button color="inherit" onClick={handleSkip}>
                                                Skip
                                            </Button>
                                        )
                                    }
                                    <Button
                                        onClick={activeStep === steps.length - 1 ? onSubmit : handleNext}
                                        fullWidth
                                        sx={{
                                            bgcolor: "success.main",
                                            color: "#ffffff",
                                            ':hover': {
                                                bgcolor: '#4F916B',
                                                color: 'white',
                                            },
                                            pl: 4, pr: 4, pt: 1, pb: 1, mb: 5
                                        }}
                                    >
                                        {getNextStepButtonLabel(activeStep)}
                                    </Button>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )
                }
                <AutoSave formik={formik}/>
            </Box>
        </Box>
    );
};

export default ReferralForm;
