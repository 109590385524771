import Toolbar from "@mui/material/Toolbar";
import React from "react";
import {appBarHeight} from "./Navigation";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {drawerWidth, miniDrawerWidth} from "./SideBar";
import {useAuthState} from "../auth/auth";
import UserMenu from "./UserMenu";
import UserActions from "./UserActions";
import {useTheme} from "@emotion/react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import MainLogo from "./MainLogo";
import {useLocation} from 'react-router-dom';

export const StyledAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open, render, height}) => {
    return {
        height: height,
        marginLeft: `${miniDrawerWidth(theme)}`,
        width: `calc(100% - ${miniDrawerWidth(theme)})`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        ...{
            [theme.breakpoints.down('md')]: {
                height: height,
                marginLeft: 0,
                width: "100%",
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                })
            }
        },
        ...(!render && {
            marginLeft: 0,
            width: '100%',
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }
});


const AppBar = ({drawerRender, drawerOpen, onMobileDrawerToggle, ...other}) => {
    const theme = useTheme();

    return (
        <StyledAppBar position="fixed" open={drawerOpen} render={drawerRender ? 1 : 0} height={appBarHeight} elevation={0} square>
            <Toolbar variant="dense" style={{paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1)}}>
                {
                    drawerRender && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={onMobileDrawerToggle}
                            sx={{display: {md: 'none'}, pl: 2}}
                        >
                            <MenuIcon/>
                        </IconButton>
                    )
                }
                {drawerRender ? null : <MainLogo/>}
                <UserActions/>
                <UserMenu/>
            </Toolbar>
            {other.children}
        </StyledAppBar>
    );
}

export default AppBar;
