import React from 'react';
import Grid from '@mui/material/Grid';
import {Box} from '@mui/system';
import Typography from "@mui/material/Typography";
import CandidateInterestConfirmationTypographyIcon from '../../../Candidate/Icons/CandidateInterestConfirmationTypographyIcon';
import CandidateInterestLevelTypographyIcon from '../../../Candidate/Icons/CandidateInterestLevelTypographyIcon';
import ReferrerIsCandidateTypographyIcon from '../../../Candidate/Icons/ReferrerIsCandidateTypographyIcon';

const AboutCandidateDisplay = (props) => {
    const {aboutCandidate, aboutCandidateUrl, isCandidateInterested, candidateLevelOfInterest, referrerIsCandidate} = props;

    const renderAboutCandidate = aboutCandidate => {
        if (!aboutCandidate) {
            return null;
        }

        return (
            <Grid item xs={12}>
                <Box sx={{mb:1}}>
                {aboutCandidate}
                {/* <TextField
                    id="about-candidate-display"
                    //label="About Candidate"
                    //variant="outlined"
                    fullWidth
                    value={aboutCandidate}
                    InputProps={{
                        readOnly: true,
                    }}
                /> */}
                </Box>
            </Grid>
        );
    };

    const renderAboutCandidateUrl = (aboutCandidateUrl) => {
        if (!aboutCandidateUrl) {
            return null;
        }

        return (
            <Grid item xs={12} sm={12} style={{display: 'block', textAlign: "center"}}>
                <Box sx={{position: 'relative', minHeight: '300px'}}>
                    <iframe
                        src={aboutCandidateUrl}
                        frameBorder="0"
                        allowFullScreen
                        style={{minHeight: "300px", width: "100%"}}
                    />
                </Box>
            </Grid>
        );
    };

    let aboutCandidateDisplay = "block";

    if (!Boolean(aboutCandidate) && !Boolean(aboutCandidateUrl)) {
        aboutCandidateDisplay = "none"
    }

    return (
        <Box sx={{display: aboutCandidateDisplay}}>
            <Typography sx={{fontSize: 18, fontWeight: 600, pl: 2, pt: 2}}>About Candidate</Typography>
            <Grid container sx={{mb:1}}>
            {
                        referrerIsCandidate && (
                            <Grid item>
                                <ReferrerIsCandidateTypographyIcon/>
                            </Grid>
                        )
                    }
                <Grid item><CandidateInterestConfirmationTypographyIcon isCandidateInterested={isCandidateInterested}/></Grid>
                <Grid item><CandidateInterestLevelTypographyIcon candidateLevelOfInterest={candidateLevelOfInterest}/></Grid>
            </Grid>
            <Box sx={{p: 2, pt: 1}}>
                <Grid container spacing={1}>
                    {renderAboutCandidate(aboutCandidate)}
                    {renderAboutCandidateUrl(aboutCandidateUrl)}
                </Grid>
            </Box>
        </Box>
    );
}

export default AboutCandidateDisplay
