import React, {useEffect, useState} from "react";
import PositionsGrid, {positionColumns} from '../components/Position/List/PositionsGrid';
import api from "../api";

import {useHistory, useParams} from 'react-router-dom'
import {useAuthState} from "../auth/auth";
import {getCurrentPage, getSort, useSpec} from "../shared/Specification/Specification";
import makeStyles from '@mui/styles/makeStyles';
import { Box, Button, ButtonGroup, Grid, IconButton, Paper, Popover, Typography } from "@mui/material";
import { getModulePreferences, updateModulePreferences } from "../shared/preferences";
import GridViewIcon from '@mui/icons-material/GridView';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import {useTheme} from "@mui/material/styles";
import PositionStatusLabel from "../components/Position/List/PositionStatusLabel";
import NumberFormat from "react-number-format";
import { format, getDate, parseJSON } from "date-fns";
import PositionActions from "../components/Position/List/PositionActions";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-iconSeparator': {
            color: '#737574',
          },
          '& .position-header': {
            backgroundColor: '#DEEBFF'},
    },
});

export const PositionsListPage = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const [positions, setPositions] = useState([]);
    const [creatingPosition, setCreatingPosition] = useState(false);

    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [displayMode, setDisplayMode] = useState("card");

    const {
        spec,
        onPageChange,
        onPageSizeChange,
        onFilterChange,
        onSortChange,
    } = useSpec({defaultSort: [{field: 'id', sort: 'desc'}]});

    const history = useHistory();
    const {companyId} = useParams();
    const {authState} = useAuthState();
    const headers = authState?.headers;

    const loadPositionList = () => {
        api.positions.list(companyId, spec, headers)
            .then(response => {
                const data = response.data

                setTotalCount(data.total_count);
                setPositions(data.positions);
            })
            .finally(() => setLoading(false))
    };

    useEffect(() => {
        if (!authState?.isAuthenticated) {
            return;
        }
        if (loading) {
            return;
        }

        setLoading(true);
        const prefs = getModulePreferences(authState.user.user_preferences, "positions");
            // if we have a state stored, lets use it
            if (prefs?.position_display)
                setDisplayMode(prefs.position_display);
        loadPositionList();
    }, [spec]);

    const createPosition = () => {
        if (creatingPosition) {
            return;
        }

        setCreatingPosition(true);

        api.positions.create(companyId, history, headers)
            .finally(() => setCreatingPosition(false));
    }

    const handleAction = () => {
        loadPositionList();
    };

    function getFormattedCurrency(amt) {
        // Create our number formatter.
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        return formatter.format(amt); /* $2,500.00 */
    }

    const getDate = (dateString) => {
        let date = "";
        if (dateString)
            date= format(parseJSON(dateString), 'MM/dd/yyyy h:mm a');
        return date;
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedPosition, setSelectedPosition] = React.useState(null);

    const handleClick = (event, selectedPosition) => {
        setSelectedPosition(selectedPosition)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderCardView = () => {
        return (
            <Box>
                <Button sx={{
                                bgcolor: "success.main", color: "#ffffff", ':hover': {
                                    bgcolor: '#4F916B',
                                    color: 'white'
                                }
                            }} onClick={createPosition}>Create New Position</Button>
                <Grid container>
                    {
                        positions.map((position) => {
                            return (
                                <Grid xs={12} sm={8} md={7} lg={6} xl={4} item key={position.id} sx={{display: "flex", justifyContent: "space-evenly"}}>
                                    <Paper sx={{maxWidth: 400, minWidth: 400, minHeight: 200, m: 2, p: 2}} onClick={(e) =>handleClick(e,position)}>
                                        <Grid container direction="column" rowSpacing={2}>
                                            <Grid item>
                                                <Grid container direction="row" justifyContent="space-between">
                                                    <Grid item> <Typography sx={{fontSize: 18, fontWeight: 600, mb: 1}}>{position.title}</Typography></Grid>
                                                    <Grid item><PositionStatusLabel status={position.status}/></Grid>
                                                </Grid>
                                               </Grid>
                                            <Grid item>
                                                <Grid container direction="row" justifyContent="space-around">
                                                    <Grid item><Typography sx={{fontSize: 18,  mb: 1}}>New Referrals: {position.submitted_referrals_count - position.assigned_referrals_count}</Typography></Grid>
                                                    <Grid item><Typography sx={{fontSize: 18, mb: 1}}>Total Referrals: {position.submitted_referrals_count}</Typography></Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" justifyContent="space-around">
                                                    <Grid item>
                                                        <Typography sx={{fontSize: 18, fontWeight: 600, mb: 1}}>
                                                        Reward Amount:&nbsp; 
                                                        <NumberFormat value={position.reward_amount_cents / 100} displayType={'text'}thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={0}/>
                                                        </Typography>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Box
                                                component='img'
                                                src={position.featured_image_url}
                                                sx={{
                                                    pt: 1, pb: 1,
                                                    height: '200px',
                                                    alignSelf: 'center',
                                                    alignContent: 'center',
                                                    alignItems: 'center',
                                                    justifyContent: "center"
                                                }}
                                            />
                                            </Grid>
                                            <Grid item>
                                                <Grid container justifyContent="space-between" alignItems="center">
                                                    <Grid item>
                                                        <Box>
                                                        <Typography sx={{fontWeight: 600}}>Actions <IconButton aria-label="actions">
                                                                <MenuRoundedIcon/>
                                                            </IconButton>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>Published: {getDate(position.published_at)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            
                                        </Grid>
                                        
                                        
                                        
                                        
                                        
                                        
                                        {/* <Typography>
                                            Referred to: {trx.position_name}
                                        </Typography>
                                        <Box sx={{ml: 2, mr: 1}}>
                                            <Typography>
                                                at: {trx.company_name}
                                            </Typography>
                                            <Typography>
                                                on: {trx.submitted_at ? format(parseJSON(trx.submitted_at), 'MM/dd/yyyy h:mm a') : ""}
                                            </Typography>
                                            <Typography>
                                                for: {getPaymentFormated(trx.pay_amount_net_cents)}
                                            </Typography>
                                        </Box>
                                        <Box sx={{display: "flex", justifyContent: 'flex-end', alignItems: 'center', pr: 1}}>
                                            <Typography sx={{fontSize: 12, fontWeight: 700, mr: 2}} component="span">{"Status".toUpperCase()}</Typography>
                                            {getReferralStatus(trx.referral_status, trx.no_hire, paymentInfoSetup)}
                                        </Box> */}
                                    </Paper>
                                </Grid>
                            );
                        })
                    }
                </Grid>
                <Popover
                id={selectedPosition?.id}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
            >
                <PositionActions selectedPosition={selectedPosition} companyId={companyId} onAction={() => {
                    handleClose();
                    handleAction();
                }}/>
            </Popover>
            </Box>
        );
    }

    const renderDataGridView = () => {
        return <PositionsGrid
                    positions={positions}

                    page={getCurrentPage(spec)}
                    pageSize={spec?.page?.limit}
                    rowCount={totalCount}

                    sort={getSort(spec)}

                    onPageChange={(page) => onPageChange(page)}
                    onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
                    onSortChange={(sort) => onSortChange(sort, positionColumns)}
                    onFilterChange={(filter) => onFilterChange(filter, positionColumns)}
                    className={classes.root}
                    loading={loading}

                    onCreatePosition={createPosition}
                    onAction={handleAction}
                />
    }

    const renderData = () => {
        if (displayMode === "card") {
            return renderCardView();
        } else {
            return renderDataGridView();
        }
    }

    const switchToCard = () => {
        if (displayMode !== "card")
            setDisplayMode("card");
        // save preference
        updateModulePreferences("positions", {position_display: "card"}, headers);
    }
    const switchToGrid = () => {
        if (displayMode !== "grid")
            setDisplayMode("grid");
        updateModulePreferences("positions", {position_display: "grid"}, headers);
    }

    return (
        <Box sx={{mb: 4, height: '100%', color: 'text.primary', margin: "0 auto !important", overflowY: 'scroll'}}>
            <Typography sx={{fontWeight: 700, color: 'text.primary'}} component="h1" variant="h4" align="center">
                Positions
            </Typography>
            <Box sx={{mb: 1}}>
                <ButtonGroup>
                    <IconButton sx={{color: displayMode === "card" ? theme.palette.success.main : "primary"}}
                                onClick={switchToCard}><GridViewIcon/></IconButton>
                    <IconButton sx={{color: displayMode === "grid" ? theme.palette.success.main : "primary"}}
                                onClick={switchToGrid}><ViewComfyOutlinedIcon/></IconButton>
                </ButtonGroup>
            </Box>
            {renderData()}
        </Box>
        
    );
};

export default PositionsListPage;
