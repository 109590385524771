import React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import CustomToolbar from "../../../shared/DataGrid/Toolbar/CustomToolbar";
import CustomLoadingOverlay from "../../../shared/DataGrid/Overlay/CustomLoadingOverlay";

import {format, parseJSON} from 'date-fns';
import StateChip from "../../../shared/State/StateChip";
import {positionReferralStatusColorPicker, referralStatusPicker} from "../ReferralStateHelper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {getAssignedToReferralsFilterOperators} from "./Filter/ReferralAssignedToUserFIlter";
import {getIsCandidateInterestedFilterOperators} from "./Filter/ReferralIsCandidateInterestedFilter";
import {getStatusFilterOperators} from "./Filter/ReferralStatusFilter";
import redirect from "../../../redirect";
import {Link as RouterLink, useHistory} from "react-router-dom";
import PositionBreadcrumbs from './PositionBreadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import {IconButton, Link, Tooltip} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-iconSeparator': {
            color: '#737574',
        },
        '& .position-header': {
            backgroundColor: '#DEEBFF'
        },
        '& .candidate-name-cell': {
            justifyContent: "space-between",
        },
    },
    score: {
        border: `1px solid`,
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: 26,
        borderRadius: 2
    },
    value: {
        position: "absolute",
        lineHeight: "24px",
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    bar: {
        height: "100%",
        "&.low": {
            backgroundColor:  "#088208a3" //"#b32518"
        },
        "&.medium": {
            backgroundColor: "#088208a3" //"#f5c242"
        },
        "&.high": {
            backgroundColor: "#088208a3"
        }
    }
});

const ProgressBar = React.memo(function ProgressBar(props) {
    const {value} = props;
    const valueInPercent = value * 100;
    const classes = useStyles();

    return (
        <div className={classes.score}>
            <div className={classes.value}>
                {`${valueInPercent.toLocaleString()} %`}
            </div>
            <div
                className={
                    clsx(classes.bar, {
                        low: valueInPercent < 30,
                        medium: valueInPercent >= 30 && valueInPercent <= 70,
                        high: valueInPercent > 70
                    })}
                style={{maxWidth: `${valueInPercent}%`}}
            />
        </div>
    );
});

export const referralColumns = [
    {
        field: 'status',
        headerName: 'Status',
        type: 'singleSelect',
        headerClassName: 'position-header',
        sortable: true,
        headerAlign: 'left',
        sortComparator: (v1, v2) => v1.status < v2.status,
        align: 'left',
        width: 160,
        renderHeader: (params) => {
            return (
                <Box component="span" sx={{alignItems: 'center'}} className='position-header'>
                    <Typography sx={{fontWeight: 500, fontSize: 14}} component="span">Status&nbsp;</Typography>
                    <Tooltip
                        title={
                            <Box sx={{fontSize: 14}}>
                                Not Started:<br/>
                                Referral was received but no recruiting team member has been assigned to review<br/><br/>
                                In Progress:<br/>
                                Team has reviewed and is engaged in further discussion with the candidate.<br/><br/>
                                Rejected:<br/>
                                Team has decided to not proceed with the candidate or candidate is not interested.<br/><br/>
                                Hired:<br/>
                                Candidate has accepted the position.<br/><br/>
                                Paid:<br/>
                                Payment to referrer was authorized and successfully processed.
                            </Box>
                        }
                    >
                        <HelpIcon sx={{color: "#4B72C2", verticalAlign: "middle"}} fontSize='small'/>
                    </Tooltip>

                    {/* <HelpTooltip
                icon={<HelpIcon/>}
                tooltipTitle={["Member:<br> Create and Publish positions", "May attach link to a video"]}
                />
                Roles */}
                </Box>
            )
        },
        renderCell: (params) => {
            return (
                <StateChip label={referralStatusPicker(params.row.status, params.row.no_hire)} colorPicker={positionReferralStatusColorPicker}/>
            )
        },

        filterOperators: getStatusFilterOperators(),
    },
    {
        field: 'candidate_name',
        headerName: 'Candidate Name',
        type: 'string',
        headerClassName: 'position-header',
        // cellClassName: 'candidate-name-cell',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
            return (
                <React.Fragment>
                    <Tooltip
                        title={
                            <Box sx={{fontSize: 14}}>
                                This candidate was submitted by more than one person<br/>
                                Click to see all duplicates
                            </Box>
                        }
                    >
                        <IconButton
                            sx={{
                                color: "#ffa726",
                                mr: 1,
                                p: 0.2,
                                display: params.row.is_candidate_duplicate ? "flex" : "none"
                            }}
                            onClick={() => {
                                params.api.setFilterModel({
                                    items: [{
                                        columnField: "candidate_email",
                                        value: params.row.candidate_email,
                                        operatorValue: "equals",
                                    }],
                                    joinOperator: "and"
                                });
                            }}
                        >
                            <ReportGmailerrorredIcon/>
                        </IconButton>
                    </Tooltip>
                    <Link component={RouterLink}
                          underline="always"
                          to={`${window.location.pathname}/${params.row.id}`}
                    >
                        {params.row.candidate_name}
                    </Link>
                </React.Fragment>
            );
        },
    },
    {
        field: 'candidate_email',
        actual_field: 'candidate_email',
        headerName: 'Candidate Email',
        hide: true,
        type: 'string',
        headerClassName: 'position-header',
        minWidth: 150,
        flex: 1,
    },
    {
        field: 'referrer_name',
        headerName: 'Referrer Name',
        type: 'string',
        headerClassName: 'position-header',
        minWidth: 150,
        flex: 1,
    },
    {
        field: 'submitted_at',
        headerName: 'Submission Date',
        type: 'date',
        headerClassName: 'position-header',
        minWidth: 110,
        flex: 1,
        valueGetter: (params) => params.value && format(parseJSON(params?.value), 'MM/dd/yyyy h:mm a'),
    },
    {
        field: 'score',
        headerName: 'Referral Strength',
        type: 'number',
        sortable: true,
        headerClassName: 'position-header',
        width: 160,
        renderCell: (params) => {
            return <ProgressBar value={Number((params.row.score / 38).toPrecision(2))}/>
        }
    },

    {
        field: 'is_candidate_interested',
        headerName: 'Candidate Interested',
        type: 'boolean',
        headerClassName: 'position-header',
        nullable: true,
        sortable: true,
        width: 170,
        flex: 1,
        renderCell: (params) => {
            if (params.value === true) {
                return <CheckIcon/>;
            } else if (params.value === false) {
                return <CloseIcon/>;
            } else {
                return <Typography/>;
            }
        },
        filterOperators: getIsCandidateInterestedFilterOperators(),
    },
    {
        field: 'assigned_to',
        actual_field: 'assigned_to_id',
        headerName: 'Assigned To',
        type: 'singleSelect',
        headerClassName: 'position-header',
        minWidth: 150,
        flex: 1,
        nullable: true,
        sortable: true,
        valueGetter: (params) => params.value && `${params?.value?.first_name?.trim()} ${params?.value?.second_name?.trim()}`,
        filterOperators: getAssignedToReferralsFilterOperators(),
    },
];

const ReferralsGrid = (props) => {
    const classes = useStyles();

    const {
        companyId, positionId,
        position,
        referrals, rowCount, pageSize, page, loading,
        filter, sort,
        onPageSizeChange, onPageChange, onSortChange, onFilterChange
    } = props;

    const history = useHistory();

    const handleRowClick = (params, event) => {
        const referralId = params.id;
        redirect.referrals.showSelecteReferral(history, companyId, positionId, referralId);
    };

    const getUrlToCandidate = (params) => {
        const referralId = params.id;
        return `/company/${companyId}/positions/${positionId}/referrals/${referrals}/${referralId}`;
    };

    const getCrumbs = () => {
        const items = [
            {
                key: 1,
                to: '/home',
                icon: <HomeIcon sx={{mr: 0.5}} fontSize="inherit"/>,
                name: 'Home',
            },
            {
                key: 2,
                to: `/company/${companyId}/positions`,
                icon: <GroupsIcon sx={{mr: 0.5}} fontSize="inherit"/>,
                name: 'Position Dashboard',
            },
            {
                key: 3,
                to: `/company/${companyId}/positions/${positionId}/referrals`,
                icon: <PeopleIcon sx={{mr: 0.5}} fontSize="inherit"/>,
                name: 'Referrals',
                last: true
            },
        ]
        return items;
    }

    return (
        <Box
            sx={{
                height: 'inherit',
                pb: 2,
            }}
        >
            <Grid sx={{height: '100%'}} container spacing={0} alignContent="flex-start" alignItems="center">
                <Grid sx={{height: '100%'}} item xs={12} sm={12} style={{paddingBottom: 0}}>
                    <Box sx={{height: '100%'}}>
                        <Box sx={{pb: 2, pl: 0.5, pt: 1}}>
                            <PositionBreadcrumbs crumbs={getCrumbs()}/>
                        </Box>
                        <Paper sx={{height: '93.5%'}}>
                            <DataGrid
                                className={classes.root}

                                rows={referrals}

                                columns={referralColumns}
                                // columnVisibilityModel={{
                                //     candidate_email: false,
                                // }}

                                disableColumnMenu

                                // onRowClick={(params, event) => handleRowClick(params, event)}
                                disableClickEventBubbling={true}

                                page={page}
                                pageSize={pageSize}
                                rowCount={rowCount}

                                loading={loading}

                                rowsPerPageOptions={[10, 50, 100]}

                                checkboxSelection={false}
                                hideFooterSelectedRowCount
                                components={{
                                    Toolbar: CustomToolbar,
                                    LoadingOverlay: CustomLoadingOverlay,
                                }}
                                componentsProps={{
                                    toolbar: {
                                        title: loading ? '' : `Referrals for ${position?.title}`,
                                    },
                                }}

                                filterMode="server"
                                sortingMode="server"
                                paginationMode="server"

                                initialState={{
                                    filter: {
                                        filterModel: {
                                            items: filter,
                                        },
                                    },
                                    sorting: {
                                        sortModel: sort,
                                    }
                                }}

                                onPageChange={(page) => onPageChange(page)}
                                onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
                                onSortModelChange={(sort) => onSortChange(sort)}
                                onFilterModelChange={(filter) => onFilterChange(filter)}
                            />
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ReferralsGrid;
