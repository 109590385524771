import React, {useMemo, useEffect, useState} from 'react';
import {Box, Button, Grid, InputAdornment, TextField, Typography} from "@mui/material"
import CollapseGrid from "../../shared/Collapse/CollapseGrid"
import RecordLoomVideoButton from "../../shared/Loom/RecordLoomVideoButton"
import StyledReactQuill from "../../shared/Quill/StyledReactQuill"
import InfoIcon from '@mui/icons-material/Info';
import LinkIcon from '@mui/icons-material/Link';
import {debounce} from 'lodash';
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

const ManagerNotes = (props) => {

    const {formik, disabled} = props;
    const [pipeLoaded, setPipeLoaded] = useState(false);
    const [pipeLoadCount, setPipeLoadCount] = useState(0);

    const hasVideo = !!formik.values.manager_note_url && formik.values.manager_note_url !== '' && !formik.errors.manager_note_url;

    const handleManagerNoteSelectionChange = (previousRange, source, editor) => {
        // if (val === formik.value)
        const newContent = editor.getHTML();
        const existingContent = formik.values.manager_note;

        if (newContent !== existingContent) {
            debounceHandleManagerNoteChange(newContent);
        }
    };

    const writeManageNoteToFormik = (val) => {
        formik.setStatus({state: "touched"});
        formik.setFieldValue("manager_note", val);
    };

    const handleManagerNoteChange = (val) => {
        debounceHandleManagerNoteChange(val);
    };

    const handleChange = (event) => {
        formik.handleChange(event);
        formik.setStatus({state: "touched"})
    }

    const debounceHandleChange = useMemo(() => debounce((event) => handleChange(event), 3000), []);

    const onInsertClicked = (shareLink) => {
        formik.setStatus({state: "touched"});
        formik.setFieldValue("manager_note_url", shareLink.embedUrl);
        
    };

    const removeVideo = () => {
        formik.setStatus({state: "touched"});
        formik.setFieldValue("manager_note_url", '');
        // formik.setValues({
        //     ...formik.values, error: null, manager_note_url: '',
        // });
    };
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "//cdn.addpipe.com/2.0/pipe.js";
        script.async = true;
      
        document.body.appendChild(script);
        setPipeLoaded(true);
        // return () => {
        //   document.body.removeChild(script);
        //   setPipeLoaded(false);
        // }
      }, []);
    useEffect(() => {
        // const style = document.createElement('style');
      
        // style.src = "//cdn.addpipe.com/2.0/pipe.css";
        // style.async = true;
      
        // document.body.appendChild(style);
        const style = document.createElement("link");
        style.setAttribute("rel", "stylesheet");
        style.setAttribute("type", "text/css");
        style.setAttribute("href","//cdn.addpipe.com/2.0/pipe.css");
        document.head.appendChild(style);
      
        // return () => {
        //   document.body.removeChild(style);
        // }
      }, []);

      const checkAddPipeVideoAvailable = async (url) => {
        // tight loop and block the ui on this
        try {
            let res = await axios.get(url);
            console.log("checking url for ready video: " + url);
            formik.setFieldValue("manager_note_url", url);
        } catch (err) {
            setTimeout(() => {
                console.log("didn't find video, checking again.");
                checkAddPipeVideoAvailable(url);
              }, 500);
        }
        // axios.get(url).catch((error)=>{
        //     setTimeout(() => {
        //         checkAddPipeVideoAvailable(url);
        //       }, 1000);
        // }).then((resp) => {
        //     formik.setFieldValue("about_candidate_url", url);
        // });
      }


      
      const pipeAccountHash = "e0e76c99bdd9b637d17a899a4aa512b7"
      const pipeParams1 = {size: {width:700,height:430}, qualityurl: "avq/360p.xml",accountHash:pipeAccountHash, eid:"IJnyGO", showMenu:"true", mrt:60,sis:0,asv:1,mv:0, dpv:0, ao:0, dup:0};
      useEffect(()=> {
        if (window.PipeSDK) {
            
            window.PipeSDK.insert('custom-id', pipeParams1, function(myRecorderObject){
                myRecorderObject.onVideoUploadSuccess = function(recorderId, filename,filetype,videoId,audioOnly,location){
                    var args = Array.prototype.slice.call(arguments);
                    console.log("onVideoUploadSuccess("+args.join(', ')+")");
                    let videoUrl = `https://${location}/${pipeAccountHash}/${filename}.mp4`;
                    checkAddPipeVideoAvailable(videoUrl);
                };
                myRecorderObject.onUploadDone = function(recorderId, streamName, streamDuration, audioCodec, videoCodec, fileType, audioOnly, location){
                    var args = Array.prototype.slice.call(arguments);
                    console.log("onUploadDone("+args.join(', ')+")");
                    let videoUrl = `https://${location}/${pipeAccountHash}/${streamName}.mp4`;
                    console.log(`Video url: ${videoUrl}`);
                    // with addpipe, the video won't be available immediately, let's use the poor man approach and poll for a few seconds then back off
                    checkAddPipeVideoAvailable(videoUrl);
                    
                };
             });
        }
        else {
            setPipeLoadCount(pipeLoadCount+1);    

        }
      }, [pipeLoaded, pipeLoadCount]);

    const debounceHandleManagerNoteChange = useMemo(() => debounce((value) => writeManageNoteToFormik(value), 1500), []);

    return (
        <Grid container rowSpacing={2}>
            <Grid item>
                <Typography variant="h6">Manager Notes</Typography>
            </Grid>

            <Grid item xs={12} sm={12} style={{paddingBottom: 0}}>
                {/* <Typography sx={{ mb: 1 }} variant="subtitle2">Manager Notes</Typography> */}
                <Box sx={{backgroundColor: "#eff5ff", alignItems: "start", display: 'flex'}}>
                    <InfoIcon sx={{mt: 1, mr: 1, ml: 1, color: "#4B72C2", verticalAlign: ''}}/>
                    <Typography sx={{mt: 1, mb: 1, mr: 1, fontSize: 14}} component="span">Use this section to personalize the job posting. Tell the candidate
                        about the team, the culture, you as a manager, expectations, or anything else that would provide more color about the environment,
                        culture, style of work.</Typography>
                </Box>
            </Grid><Grid item xs={12} sm={12}>
            <StyledReactQuill
                disabled={disabled}
                isError={Boolean(formik.errors.manager_note)}
                errorMessage={formik.errors.manager_note}
                defaultValue={formik.values.manager_note}
                onChange={handleManagerNoteChange}
                onSelectionChange={handleManagerNoteSelectionChange}/>
        </Grid><Grid item xs={12} sm={12}>
            <Typography variant="subtitle2">Manager Video Link</Typography>
            <Box sx={{backgroundColor: "#eff5ff", alignItems: "start", display: 'flex', mb: 1, mt: 1}}>
                <InfoIcon sx={{mt: 1, mr: 1, ml: 1, color: "#4B72C2", verticalAlign: ''}}/>
                <Typography sx={{mt: 1, mb: 1, mr: 1, fontSize: 14}} component="span">
                    In addition, or instead of writing about what makes your company and
                    team unique, tell the candidate in a video. You can record one by clicking record below.
                </Typography>
            </Box>
            <Box sx={{display: {xs:'none', sm:'block'}}}>
            <RecordLoomVideoButton
                hasVideo={hasVideo}
                onInsertClicked={onInsertClicked}
                removeVideo={removeVideo}
            />
            </Box>
            <Box sx={{display: {xs:'block', sm:'none'}}}>
                        <Box sx={{pl:2, display:hasVideo?"none":"block"}}>
                            <div id="custom-id"></div>
                        </Box>
                        </Box>
        </Grid>
            <CollapseGrid in={hasVideo}>
                <Grid item xs={12} sm={12} style={{textAlign: "center"}}>
                    <div style={{position: "relative", paddingBottom: "56.25%", height: 0}}>
                        <iframe
                            src={formik.values.manager_note_url}
                            frameBorder="0"
                            allowFullScreen
                            style={{"position": "absolute", "top": 0, "left": 0, "width": "100%", "height": "100%"}}
                        />
                    </div>
                    <Button variant="outlined" color="primary" onClick={removeVideo}
                                aria-label="remove video" startIcon={<DeleteIcon />}>
                                Remove Recording
                            </Button>
                </Grid>
            </CollapseGrid>
            {/* <Grid item xs={12}>
                <TextField
                    id="manager_note_url"
                    name="manager_note_url"
                    autoComplete="manager_note_url"
                    type="url"
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><LinkIcon/></InputAdornment>,
                    }}
                    disabled={disabled}
                    defaultValue={formik.values.manager_note_url}
                    onChange={debounceHandleChange}
                    error={Boolean(formik.errors.manager_note_url)}
                    helperText={formik.errors.manager_note_url}/>
            </Grid> */}
        </Grid>
    )
}

export default ManagerNotes;
