import {ListItemButton} from "@mui/material";
import {appBarHeight} from "./Navigation";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import * as React from "react";
import redirect from "../redirect";
import {useTheme} from "@emotion/react";
import {drawerWidth} from "./SideBar";
import {useHistory} from "react-router-dom";
import Box from "@mui/material/Box";

const MainLogo = (props) => {
    const theme = useTheme();
    const history = useHistory();

    const handleGoHome = () => {
        redirect.users.home(history)
    }

    return (
        <Box sx={{width: drawerWidth}}>
            <ListItemButton
                onClick={handleGoHome}
                disableRipple
                style={{width: drawerWidth,}}
                sx={{
                    pl: 1.5,
                    width: drawerWidth,
                    minHeight: appBarHeight - 1,
                    maxHeight: appBarHeight - 1,
                    "&.MuiButtonBase-root:hover": {bgcolor: "transparent"}
                }}>
                <ListItemIcon>
                    <img height="24px" src="https://iopophirepublicprod.s3.amazonaws.com/posting_images/Asset+8.png" alt={null}/>
                </ListItemIcon>
                <ListItemText primary={<Typography variant="h5" sx={{pl: 0.5, mr: 0}} style={{fontSize: '1.5rem', color: theme.palette.common.white}}>PopHire</Typography>}/>
            </ListItemButton>
        </Box>
    );
};

export default MainLogo
