import React from 'react';

import CompanyCard from './CompanyCard';

import Grid from '@mui/material/Grid';
import CreateCompanyCard from "./CreateCompanyCard";

const CompaniesList = ({companies}) => {
    return (
        <Grid sx={{p: 4, justifyContent: "center"}} container spacing={4}>
            {
                companies.map(company => (
                    <Grid item xs={12} md={6} xl={4} key={company.company_id} style={{maxWidth: 400}}>
                        <CompanyCard company={company}/>
                    </Grid>
                ))
            }
            <Grid item xs={12} md={6} xl={4} key="createCompany" style={{maxWidth: 400}}>
                <CreateCompanyCard/>
            </Grid>
        </Grid>
    )
}

export default CompaniesList;
