import {Avatar, ClickAwayListener, Divider, Fade, ListItemAvatar, ListItemButton, ListItemText, Popper, Tooltip} from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Typography from "@mui/material/Typography";
import React from "react";
import {useHistory} from "react-router-dom";
import {useAuthState} from "../auth/auth";
import redirect from "../redirect";
import {useTheme} from "@emotion/react";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const UserActions = () => {
    const history = useHistory();
    const theme = useTheme();

    const {authState} = useAuthState();

    const currUserCompany = authState?.currUserCompany;
    const drawerRender = !!authState?.currUserCompany;

    const [userCompaniesAnchorEl, setUserCompaniesAnchorEl] = React.useState(null);
    const [userCompaniesOpen, setUserCompaniesOpen] = React.useState(false);

    const [userMoreAnchorEl, setUserMoreAnchorEl] = React.useState(null);
    const [userMoreOpen, setUserMoreOpen] = React.useState(false);

    const handleUserCompaniesClick = (event) => {
        setUserCompaniesAnchorEl(event.currentTarget);
        setUserCompaniesOpen((previousOpen) => !previousOpen);
    };
    const handleUserMoreClick = (event) => {
        setUserMoreAnchorEl(event.currentTarget);
        setUserMoreOpen((previousOpen) => !previousOpen);
    };

    const handleUserCompaniesClose = () => {
        setUserCompaniesOpen(false);
    };

    const handleUserMoreClose = () => {
        setUserMoreOpen(false);
    };

    const handleMyCompanies = () => {
        handleUserCompaniesClose();
        handleUserMoreClose();
        redirect.company.list(history);
    }

    const handleCompanySelect = (companyId) => {
        handleUserCompaniesClose();
        handleUserMoreClose();
        redirect.company.company(history, companyId);
    }

    const renderUserCompaniesPopper = () => {
        return (
            <Popper
                open={userCompaniesOpen}
                anchorEl={userCompaniesAnchorEl}
                placement="bottom-start"
                transition
                style={{zIndex: theme.zIndex.appBar + 1}}
            >
                {({TransitionProps}) => (<Fade {...TransitionProps} timeout={100}>
                    <Paper>
                        <Box sx={{display: "grid", placeItems: "center", marginTop: -4,}}>
                            <Typography sx={{p: 1}}>My Companies</Typography>
                            <List sx={{width: "100%", padding: 0}}>
                                {/* <ListItem key="My Companies" disablePadding>
                                        <ListItemButton sx={{pl: 1}} onClick={handleMyCompanies}>
                                            <ListItemText primary="My Companies"/>
                                        </ListItemButton>
                                    </ListItem> */}
                                <Divider variant="middle" sx={{width: "100%", margin: 0}}/>
                                {
                                    authState?.userCompanies?.map((userCompany) => {
                                        const userCompanyName = userCompany?.name;
                                        const userCompanyRole = userCompany?.role;

                                        return (
                                            <ListItem key={userCompanyName} disablePadding>
                                                <ListItemButton sx={{pl: 1}} onClick={() => handleCompanySelect(userCompany?.company_id)}>
                                                    <ListItemAvatar>
                                                        <Avatar variant="rounded" src={userCompany?.logo_url}>{userCompanyName?.charAt(0)}</Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={`${userCompanyName} :: ${userCompanyRole}`}/>
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })
                                }
                                <Divider variant="middle" sx={{width: "100%", margin: 0}}/>
                                <ListItem key="My Companies" disablePadding>
                                    <ListItemButton sx={{pl: 1}} onClick={handleMyCompanies}>
                                        <ListItemText primary="All Companies"/>
                                    </ListItemButton>
                                </ListItem>

                            </List>
                        </Box>
                    </Paper>
                </Fade>)}
            </Popper>
        );
    };

    const renderUserMorePopper = () => {
        return (
            <Popper open={userMoreOpen} anchorEl={userMoreAnchorEl} placement="bottom-end" transition
                    style={{zIndex: theme.zIndex.appBar + 1}}>
                {
                    ({TransitionProps}) => (
                        <Fade {...TransitionProps} timeout={100}>
                            <Paper>
                                <Box sx={{display: "grid", placeItems: "center", marginTop: -4,}}>
                                    <List sx={{width: "100%", padding: 0}}>
                                        <Typography variant="caption" sx={{paddingLeft: 1}}>Current company</Typography>
                                        <ListItem sx={{pl: 1}} key="Current Company" disablePadding>
                                            <ListItemText primary={currUserCompany?.name}/>
                                        </ListItem>
                                        <Divider variant="middle" sx={{width: "100%", margin: 0}}/>
                                        <ListItem key="My Companies" disablePadding>
                                            <ListItemButton sx={{pl: 1}} onClick={handleMyCompanies}>
                                                <ListItemText primary="Switch Company"/>
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Paper>
                        </Fade>
                    )
                }
            </Popper>
        );
    };

    const currCompanyName = currUserCompany?.name;

    return (
        <Box sx={{flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
            {
                drawerRender && (
                    <ClickAwayListener onClickAway={handleUserCompaniesClose}>
                        <div>
                            <Button
                                sx={{textTransform: "none", '& .MuiButton-endIcon': {marginLeft: 0}, display: {xs: 'none', sm: 'flex'}}}
                                color="inherit"
                                underline="hover"
                                variant="body2"
                                onClick={handleUserCompaniesClick}
                                startIcon={<ArrowDropDownIcon/>}
                                disableRipple
                            >
                                <Avatar
                                    variant="rounded"
                                    sx={{mr: 2}}
                                    src={currUserCompany?.logo_url}
                                >
                                    {currCompanyName?.charAt(0)}
                                </Avatar>
                                {currCompanyName}
                                &nbsp;::&nbsp;
                                {currUserCompany?.role} &nbsp;
                                <Tooltip
                                    title={
                                        <Box sx={{fontSize: 14}}>
                                            Member:<br/>
                                            Create and Publish positions.
                                            <br/>Review submitted referrals
                                            <br/><br/>
                                            Admin:<br/>
                                            Everything in Member + <br/>
                                            Authorize payment to referrers<br/><br/>
                                            Owner:<br/>
                                            Everything in Admin + <br/>
                                            Company profile management including adding team members and updating billing and company details
                                        </Box>
                                    }>
                                    <HelpOutlineIcon sx={{color: "#ffffff"}} fontSize='small'/>
                                </Tooltip>

                            </Button>
                            {renderUserCompaniesPopper()}
                        </div>
                    </ClickAwayListener>
                )
            }
            {
                drawerRender && (
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}, justifyContent: 'flex-end'}}>
                        <ClickAwayListener onClickAway={handleUserMoreClose}>
                            <div>
                                <IconButton
                                    title="User Companies"
                                    onClick={handleUserMoreClick}
                                    sx={{color: theme.palette.common.white}}
                                >
                                    <MoreVertIcon/>
                                </IconButton>
                                {renderUserMorePopper()}
                            </div>
                        </ClickAwayListener>
                    </Box>
                )
            }
        </Box>
    );
};

export default UserActions;
