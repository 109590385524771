import React, {useEffect, useState} from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {useAuthState} from "../auth/auth";
import api from "../api";
import {Button, Card, CardActionArea, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Modal} from '@mui/material';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {Box} from "@mui/material";
import UserProfile from '../components/user/UserProfile';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "95%",
        maxWidth: 800,

        display: 'grid',

        marginLeft: 'auto',
        marginRight: 'auto',

        [theme.breakpoints.up('xs')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },

    },
    grid: {
        padding: theme.spacing(2),

    },
    paper: {
        padding: theme.spacing(3),
        maxWidth: 900,
        borderRadius: '20px'
    },

}));

const Home = () => {
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [homePath, setHomePath] = React.useState(false);
    const classes = useStyles();
    const history = useHistory();
    const {authState} = useAuthState();
    const headers = authState.headers;

    const createCompany = () => {
        history.push(`/company_create`);
    }

    const goToProfile = () => {
        history.push(`/user/profile`);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleSaveProfileCompete = (data) => {
        setOpen(false);
        goToHome();
        // onReferralPayout(data);
    };

    const goToHome = () =>{
        if (homePath)
            history.push("/" + homePath);
    }


    useEffect(() => {

        if (Object.keys(headers).length === 0) return;

        let referrerConfirmationId = "";
        let referrerConfirmationIdKey = "";
        // check if we have a referral id in local storage, if we do
        let search = `refConfId-`;
        let values = Object.keys(localStorage)
            .filter((key) => key.startsWith(search))

        values.forEach(i => {
            {
                const val = localStorage[i];
                console.log(`key: ${i}, val: ${val}`);
                const item = JSON.parse(val);
                //    1 -  associate it with this user
                //    2 -  remove from local storage
                //    3 -  redirect to referrer home page
                referrerConfirmationId = item.referrerConfirmationId;
                referrerConfirmationIdKey = i;
                // api.referrals.associateReferralToUser(item.referrerConfirmationId,headers).then((res) => {
                //     if (res.status == 200){
                //         // remove from local storage, work here is done
                //         localStorage.removeItem(i);
                //         // redirect to referrer home screen
                //         history.push(`/referrals`);
                //         return;
                //     }
                // }).catch(error =>{
                //     console.log(error);
                //     })
            }
        });

        // check if we have a referral id in local storage, if we do
        let companyConfirmationId = "";
        let companyConfirmationIdKey = "";

        let searchCompany = `companyUserConfId-`;
        let valuesCompany = Object.keys(localStorage)
            .filter((key) => key.startsWith(searchCompany))

        valuesCompany.forEach(i => {
            {
                const val = localStorage[i];
                console.log(`key: ${i}, val: ${val}`);
                const item = JSON.parse(val);
                //    1 -  associate it with this user
                //    2 -  remove from local storage
                //    3 -  redirect to referrer home page
                companyConfirmationId = item.companyUserConfirmationId;
                companyConfirmationIdKey = i
                //     api.company.associateEmailToUser(item.companyUserConfirmationId,headers).then((res) => {
                //         if (res.status == 200){
                //             // remove from local storage, work here is done
                //             localStorage.removeItem(i);
                //             // redirect to referrer home screen
                //             history.push(`/CompanyHome`);
                //             return;
                //         }
                //     }).catch(error =>{
                //         console.log(error);
                //         })
            }
        });

        // check if user has completed referral registration

        // const paymentAccountId = authState?.user?.payment_account_id;
        // if (paymentAccountId != null && paymentAccountId != '') {
        //     // user has completed their profile to receive payment, take them to referral home
        //     history.push(`/referrals`);
        // }


        // api.company.list(headers)
        //     .then(response => {
        //         var comps = response.data.user_companies;
        //         if (comps !== null && comps.length > 0){
        //             history.push(`/company/${comps[0].company_id}/getting_started`)
        //         }
        //     })
        //     .finally(() => setLoading(false));

        // now check if we are supposed to create the company by checking local storage for for y
        // check if we have a referral id in local storage, if we do
        let shouldCreateCompany = false;
        let shouldCreateCompanyKey = "";
        let searchShouldCreateCompany = `shouldCreateCompany-`;
        let createCompanyValues = Object.keys(localStorage)
            .filter((key) => key.startsWith(searchShouldCreateCompany))

        createCompanyValues.forEach(i => {
            {
                // var val = localStorage[i];
                // console.log(`key: ${i}, val: ${val}`);
                // localStorage.removeItem(i);
                // addCompany();
                shouldCreateCompany = true;
                shouldCreateCompanyKey = i;
            }
        });

        // check if user is associated with a referral submission, if so, default home to referral home
        //  api.referrals.getAllReferredPositionsByCurrentUser(headers).then(resp => {
        //     var referrals = resp.referrals;
        //     if (referrals != null && referrals.length > 0)
        // {
        //     history.push(`/referrals`);
        // }
        // });
        const homeDetails = {
            "referral_confirmation_id": referrerConfirmationId,
            "referral_confirmation_id_local_storage_id": referrerConfirmationIdKey,
            "company_confirmation_id": companyConfirmationId,
            "company_confirmation_id_local_storage_id": companyConfirmationIdKey,
            "should_create_company": shouldCreateCompany,
            "should_create_company_local_storage_id": shouldCreateCompanyKey
        };
        api.users.determineHome(homeDetails, headers).then(resp => {

            
            if (resp != null && resp.data != null && resp.data !== {}) {
                const payload = resp.data;
                //remove the temporary key from local storage
                if (payload.local_storage_id) {
                    localStorage.removeItem(payload.local_storage_id);
                }
                // if first and last name is not yet set, show modal and force, then redirect
                //if (true)
                if (!authState.user?.first_name || !authState.user?.second_name) 
                    {
                        if (payload.home)
                            setHomePath(payload.home); // we'll redirect after saving the dialog
                        setOpen(true);
                         
                    }    
                // history.push("/user/profile")
                // if the server gives us a location, go to it
                else if (payload.home) {
                    history.push("/" + payload.home);
                }
            }
            // if we can't determine where to go, stay on this page
            setLoading(false);
        });

        // if we get here, it means this user is logged in, didn't just finish registration, and isn't associated with any companies, redirecto to referrals
        //history.push(`/referrals`);

    }, [headers])

    if (authState.loading) {
        return (
            <Box sx={{display: 'flex', justifyContent: "space-around", height: '90vh', alignItems: 'center'}}>
                <CircularProgress size={80}/>
            </Box>
        );
    } else if (authState.isAuthenticated === null) {
        //return null;
        return <Redirect to='/login'/>; //auth failed (wrong tokens) redirect to login page
    } else if (loading) {
        return (
            <Box sx={{display: 'flex', justifyContent: "space-around", height: '90vh', alignItems: 'center'}}>
                <CircularProgress size={80}/>
            </Box>
        );
    } else if (authState.isAuthenticated === true) {
        return (
            <Box sx={{display: 'flex', pt: 4}}>
                <Grid container direction="row" justifyContent="center" rowSpacing={1} alignItems="stretch">
                    <Grid item xs={12} sm={6} md={4} sx={{minWidth: 300}} margin={1}>
                        <Card sx={{minWidth: 300}} elevation={3}>
                            <CardActionArea sx={{minHeight: 180}} onClick={createCompany}>
                                <CardContent>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <AddBusinessIcon sx={{mb: 1, mr: 1}}/>
                                        <Typography gutterBottom variant="h5">
                                            Setup Company
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        Create a company to start recruiting
                                        candidates. You can post an unlimited number of positions.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{minWidth: 300}} margin={1}>
                        <Card sx={{minWidth: 300,}} elevation={3}>
                            <CardActionArea sx={{minHeight: 180}} onClick={goToProfile}>
                                <CardContent>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <ManageAccountsIcon sx={{mb: 1, mr: 1}}/>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Setup profile
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        Finish setting up your profile to
                                        receive your reward for referring candidates that are accepted.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
                <Dialog
                    sx={{ '& .MuiDialog-paper': { width: '85%', maxHeight: 435 } }}
                    // maxWidth="xs"
                    //TransitionProps={{ onEntering: handleEntering }}
                    open={open}
                    >
                    <DialogTitle>Complete your profile by telling us your name</DialogTitle>
                    <DialogContent dividers>
                        <UserProfile
                                saveProfileComplete={handleSaveProfileCompete} completeRegistration={true}
                            />
                    </DialogContent>
                    {/* <DialogActions>
                        <Button onClick={handleOk}>Ok</Button>
                    </DialogActions> */}
                </Dialog>
                {/* <Modal
                    open={open}
                    //className={classes.modal}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description">
                    <div className={classes.paper}>
                        
                    </div>
                </Modal> */}
            </Box>
        );
    } else {
        return <Redirect to='/login'/>; //auth failed (wrong tokens) redirect to login page
    }
};
export default Home;
