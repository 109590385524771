import React, {useEffect, useState} from 'react';
import {Redirect, useHistory, useLocation} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import {useAuthState} from "../auth/auth";
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(2),
            padding: theme.spacing(1),
        },
    },
}));

const CreateNewCompanyUser = () => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const {authState} = useAuthState();
    const isAuthenticated = authState.isAuthenticated;
    const headers = authState.headers;
    const location = useLocation();
   
    

   
    useEffect(() => {
        const queryString = require('query-string');
        var qs = queryString.parse(location.search);
        var userConfId = qs.confId;
        // store company user id in local storage and redirect to the sign up page
        var id = uuidv4();
        
        let key = `companyUserConfId-${id}`;
        localStorage.setItem(key,JSON.stringify({"companyUserConfirmationId": userConfId}))
        authState.signIn({"screen_hint":"signup"})
        return null;
        
            
    },[]);
    return null;
    
    // if (authState.isLoading)
    //     return <div>Loading...</div>

    // if (authState.isAuthenticated === null) {
    //     return <React.Fragment>Unauthorized user home page</React.Fragment>
    // } else if (authState.isAuthenticated === true) {
    //     return <div className={classes.root}>
    //         Loading...
    //     </div>
    // } else {
    //     return <Redirect to='/login'/>; //auth failed (wrong tokens) redirect to login page
    // }
};
export default CreateNewCompanyUser;
