import Chip from "@mui/material/Chip";
import React from "react";
import {useTheme} from "@mui/material/styles";

const StateChip = (props) => {
    const {label, colorPicker} = props

    const theme = useTheme();

    return (
        <Chip
            sx={{
                width: props.width? props.width:110,
                color: props.colorPicker(props.label)(theme).color,
                fontWeight: 500,
                borderColor: props.colorPicker(props.label)(theme).color
                //backgroundColor: alpha(props.colorPicker(props.label)(theme).background, 0.2),
            }}
            variant= {props.variant?props.variant:"outlined"}
            //size="small"
            label={label}
        />
    );
};

export default StateChip;
