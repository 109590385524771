import React, {useEffect, useRef, useState} from 'react';
import PositionDetailsForm from './PositionDetailsForm';
import Paper from "@mui/material/Paper";
import * as yup from "yup";
import {useFormik} from "formik";
import {positionContentBackToFront} from "../../pages/CreateOrEditPositionPage";
import {useHistory, useParams} from "react-router-dom";
import PositionReferralSpecificsForm from "./PositionReferralSpecificsForm";
import api from "../../api";
import redirect from "../../redirect";
import {useAuthState} from "../../auth/auth";
import Box from "@mui/material/Box";
import {useTheme} from "@emotion/react";
import PositionAppBar from "./PositionAppBar";
import GenerateSocialMediaTemplate from '../../GenerateSocialMediaTemplate';
import ManagerNotes from './ManagerNotes';
import _ from 'lodash';

// const isEqualQuestions = (v1, v2) => {
//     return false;
// }

const centsFrontToBack = (value) => {
    if (value === "" || value === null || value === undefined) {
        return null
    }

    return value * 100;
}

const dateFrontToBack = (value) => {
    return value;
}

const stringFrontToBack = (value) => {
    if (value === "") {
        return null;
    }

    return value;
}

const positionContentFrontToBack = (values) => {
    const position_content = {};

    position_content.id = values.id;

    position_content.title = stringFrontToBack(values.title);
    position_content.description = stringFrontToBack(values.description);
    position_content.description_short_plain = stringFrontToBack(values.description_short_plain);

    position_content.salary_cents_from = centsFrontToBack(values.salary_from);
    position_content.salary_cents_to = centsFrontToBack(values.salary_to);

    position_content.manager_note = stringFrontToBack(values.manager_note);
    position_content.manager_note_url = stringFrontToBack(values.manager_note_url);

    // position_content.open_at = dateFrontToBack(values.open_at);
    position_content.close_at = dateFrontToBack(values.close_at);

    position_content.cold_award_cents = centsFrontToBack(values.cold_award);
    position_content.warm_award_cents = centsFrontToBack(values.warm_award);

    position_content.referral_type = values.referral_type;
    position_content.payout_description = values.payout_description;

    position_content.featured_image_file = values.featured_image_file;

    position_content.featured_image_template_data = JSON.stringify(values.featured_image_template_data);

    // position_content.questions = values.questions.map(question => mapValues(question, value => stringFrontToBack(value)));

    return position_content;
}

const validationSchema = yup.object({
    id: yup.string().required(),

    published_at: yup.date().nullable(),

    title: yup.string().required('Title is required'),
    description: yup.string().required('Description is required').test("Description quill non-empty", `Description is required`, function (value) {
        return value !== "<p><br></p>";
    }),
    salary_from: yup.number().typeError('Must be a number').nullable().positive('Must be a positive number'),
    salary_to: yup.number().typeError('Must be a number').nullable().positive('Must be a positive number').test("Salary To >= Salary From", `Must be >= Salary From`, function (value) {
        if (this.parent.salary_from === undefined) {
            return true;
        }

        if (value === undefined || value === null) {
            return true;
        }

        return value >= this.parent.salary_from
    }),
    //manager_note: yup.string().max(1000, "Max 1000 characters"),
    manager_note_url: yup.string().nullable().url('Must be a valid url'),
    close_at: yup.date().nullable().min(new Date(new Date().setHours(0, 0, 0, 0)), "Values in the past are not allowed for Close Date"),

    referral_type: yup.number().nullable().required("Referral Type is required"),
    payout_description: yup.number().nullable().required("Payout model is required"),
    cold_award_persisted: yup.number().nullable(),
    cold_award: yup.number()
        .typeError('Must be a number')
        .nullable()
        .positive('Must be a positive number')
        .test("Cold Award", `Cold award is required`, function (value) {
            if (this.parent.referral_type === 2) {
                return value !== null && value !== undefined;
            }

            return true;
        })
        .test("Published Cold Award", `Cold Award can only be increased after position publish`, function (value) {
            if (this.parent.published_at !== null && this.parent.cold_award_persisted > value) {
                return false;
            }

            return true;
        }),
    warm_award_persisted: yup.number().nullable(),
    warm_award: yup.number()
        .typeError('Must be a number')
        .nullable()
        .positive('Must be a positive number')
        .test("Warm Award", `Warm Award is required`, function (value) {
            if (this.parent.referral_type !== null) {
                return value !== null && value !== undefined;
            }

            return true;
        })
        .test("Published Warm Award", `Warm Award can only be increased after position publish`, function (value) {
            if (this.parent.published_at !== null && this.parent.warm_award_persisted > value) {
                return false;
            }

            return true;
        }),
});

const PositionForm = ({form}) => {
    const theme = useTheme();
    const {authState} = useAuthState();
    const headers = authState?.headers;
    const {companyId, positionId} = useParams();

    const [renderImageData, setRenderImageData] = useState({
        companyName: authState.currUserCompany.name,
        logoUrl: authState.currUserCompany.logo_url,
        title: form.title,
        warm: form.warm_award,
        featured_image_template_data: form.featured_image_template_data,
    });

    // const [positionDescription, setPositionDescription] = useState("");
    // const [managerNotes, setManagerNotes] = useState("");

    const buildPositionPayload = (values) => ({
        id: +positionId,
        position_content_draft: {
            position_id: +positionId,
            ...(positionContentFrontToBack(values)),
        },
    });

    const formik = useFormik({
        initialValues: form,
        initialStatus: {state: "new", rendering: true},
        validateOnChange: true,
        // validateOnBlur: true,
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            actions.setStatus({state: "updating"});
            // need to save our featured image as well before saving this, not ideal timing but should be fine for now
            //var feature_url = await saveFeatureImage();
            const positionPayload = buildPositionPayload(values);

            return api.positions.update(companyId, positionId, positionPayload, headers)
                .then(resp => {
                    actions.setValues(positionContentBackToFront(resp.data.position), false);
                    actions.setStatus({state: "updated"});
                });
        },
    });

    // const handleAddQuestion = () => {
    //     // this.handleChanges({
    //     //     questions: [
    //     //         ...this.state.form.questions,
    //     //         {question: '', answer_type: '', position_id: +this.props.match.params.id}
    //     //     ]
    //     // });
    // }
    //
    // const handleEditQuestion = ({id, field, props, number}) => {
    //     // const {questions} = this.state.form;
    //     // let value = props.value;
    //     // if (value !== '' && number) {
    //     //     value = +value;
    //     // }
    //     // this.handleChanges({
    //     //     questions: [
    //     //         ...questions.slice(0, id),
    //     //         {...(questions[id]), [field]: value},
    //     //         ...questions.slice(id + 1),
    //     //     ]
    //     // })
    // }
    //
    // const handleDeleteQuestion = ({id}) => {
    //     // const {questions} = this.state.form;
    //     // this.handleChanges({
    //     //     questions: [
    //     //         ...questions.slice(0, id),
    //     //         ...questions.slice(id + 1),
    //     //     ]
    //     // });
    // }

    const history = useHistory()

    const handlePreview = () => {
        redirect.positions.preview(history, companyId, positionId)
    }

    // const renderFeatureImage = () => {
    //     setShouldRenderFeatureImage(true);
    // }

    const handleSaveDraft = () => {
        formik.submitForm();
    }

    const handlePublish = () => {
        formik.submitForm()
            .then(r => {
                if (Object.keys(formik.errors).length === 0) {
                    api.positions.publish(companyId, positionId, history, headers)
                        .catch(error => {
                            formik.setErrors({
                                ...formik.errors,
                                message: error.error.message,
                            });
                        })
                }
            });
    }

    // const handleDescriptionUpdate = (newDescription) => {
    //     setPositionDescription(newDescription);
    // }

    // const handleManagerNotesUpdate = (newManagerNote) => {
    //     setManagerNotes(newManagerNote);
    // }

    useEffect(() => {
        if (formik.status.state === "touched") {

            const values = formik.values;

            const newImageData = {
                companyName: authState.currUserCompany.name,
                logoUrl: authState.currUserCompany.logo_url,
                title: values.title,
                warm: values.warm_award,
                featured_image_template_data: values.featured_image_template_data,
            }

            setRenderImageData(prevImageData => {
                if (_.isEqual(prevImageData, newImageData)) {
                    //if image is not changed then submit form
                    return prevImageData;
                } else {
                    //if image changed trigger image change and submit after rendered
                    return newImageData;
                }
            });

            if (!formik.status.rendering) {
                formik.submitForm();
            }
        }

    }, [formik.status]);

    const scrollTargetRef = useRef(undefined);

    return (
        <Box
            sx={{
                height: 'inherit',
                pb: 2,
                overflowY: 'scroll',
            }}
            ref={scrollTargetRef}
        >
            <PositionAppBar
                formik={formik}
                publishButtonLabel={form.published_at ? "Republish" : "publish"}
                title={form.published_at ? "Edit Position" : "Create Position"}
                onPublish={handlePublish}
                onPreview={handlePreview}
                onSaveDraft={handleSaveDraft}
                scrollTarget={scrollTargetRef?.current}
            />
            <Box sx={{
                px: 2,
                margin: 'auto',
                maxWidth: 900,
            }}>
                <Paper elevation={3} sx={{borderRadius: 4, mt: 3}}>
                    <Box sx={{
                        p: 2,
                        pt: 0,
                    }}>
                        <Box>
                            <PositionDetailsForm formik={formik}/>
                        </Box>
                    </Box>
                </Paper>
                <Paper elevation={3} sx={{mt: 4, borderRadius: 4,}}>
                    <Box sx={{p: theme.spacing(2), pt: 0, borderRadius: 4,}}>
                        <ManagerNotes formik={formik}/>
                    </Box>
                </Paper>
                <Paper elevation={3} sx={{mt: 5, borderRadius: 4,}}>
                    <Box sx={{p: theme.spacing(2), pt: 0,}}>
                        <PositionReferralSpecificsForm formik={formik}/>
                    </Box>
                </Paper>
                <Paper sx={{mt: 2, borderRadius: 4,}} elevation={3}>
                    <Box sx={{p: theme.spacing(2), pt: 0,}}>
                        <GenerateSocialMediaTemplate
                            imageData={renderImageData}
                            onImageChange={(file, templateData) => {
                                formik.setFieldValue("featured_image_file", file, false);
                                formik.setFieldValue("featured_image_template_data", templateData, false);

                                formik.setStatus({state: "touched", rendering: true});
                            }}
                            onImageRendered={() => formik.setStatus({state: "touched", rendering: false})}
                        />
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
}

export default PositionForm;
