import React, {Fragment} from 'react';
import PopupState from "material-ui-popup-state";

const StatefulPopover = (props) => {
    return (
        <PopupState variant="popover" popupId="stateful-popup">
            {
                popupState => (
                    <Fragment>
                        {props.content(popupState)}
                    </Fragment>
                )
            }
        </PopupState>
    );
};

export default StatefulPopover;
