import React, {useEffect, useState} from 'react';

import api from "../api";
import StyledSkeleton from "../shared/Skeleton/StyledSkeleton";
import {useAuthState} from "../auth/auth";
import {useParams} from "react-router-dom";
import PositionDescriptionDetails from '../components/Referral/User/PositionDescriptionDetails';
import {Box} from '@mui/material';

const UserPositionPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(null);
    const [position, setPosition] = useState(null);

    const {positionId} = useParams();
    const {authState} = useAuthState();

    const headers = authState?.headers;

    const loadPosition = () => {
        if (loading) {
            return;
        }
        // if (authLoading) {
        //     return;
        // }

        setLoading(true);

        api.referrals.showPositionToUser(positionId, headers)
            .then(({data: {position}}) => setPosition(position))
            .catch(err => setLoadingError(err))
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (loading) {
            return;
        }
        // if (authLoading) {
        //     return;
        // }

        loadPosition();
    }, []);

    if (!position) {
        return <StyledSkeleton/>;
    }

    return (
        <Box sx={{height: '100%', pb: 2, overflowY: 'scroll', margin: 'auto', position: 'relative'}}>
            <Box sx={{height: '100%', pb: 2, maxWidth: 800, margin: 'auto',}}>
                <PositionDescriptionDetails position={position} showGettingStartedButtons={true}/>
            </Box>
        </Box>
    );
}

export default UserPositionPage;
