import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import CustomDialogTitle from "../../shared/Dialog/CustomDialogTitle";
import CustomAutocomplete from "../../shared/Autocomplete/CustomAutocomplete";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import {roles} from "./CompanyCrewInfoTable";
import {CancelToken} from "axios";
import {useQuery} from "react-query";
import api from "../../api";
import {useAuthState} from "../../auth/auth";
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { validateEmail } from '../../DataValidation/EmailValidation';

const useStyles = makeStyles((theme) => (
        {
            inviteUserDialogActions: {
                paddingRight: theme.spacing(3),
                paddingBottom: theme.spacing(3),
            }
        }
    )
);

const fetchUsers = async (emailStartsWith, source, headers) => {
    if (emailStartsWith.length < 3) {
        return [];
    }
    const promise = api.users.list(emailStartsWith, source.token, headers);
    const {data} = await promise;
    return data === undefined ? [] : data.users;
};

const AddCompanyCrewMemberDialog = (props) => {
    const classes = useStyles();

    const {openDialog, handleCloseDialog, inviteCompanyUser} = props;

    const [emailStartsWith, setEmailStartsWith] = React.useState('');
    const [newUserEmail, setNewUserEmail] = React.useState('');
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [selectedRole, setSelectedRole] = React.useState('');

    const {authState} = useAuthState();
    const headers = authState?.headers;

    const {isLoading, data} = useQuery(['users', emailStartsWith], () => {
        const source = CancelToken.source();
        const promise = fetchUsers(emailStartsWith, source, headers);
        promise.cancel = () => source.cancel("Query cancelled");
        return promise;
    });


    const [isEmailValid, setIsEmailValid] = useState(validateEmail(newUserEmail));

    const handleEmailChange = (event) => {
        setIsEmailValid(validateEmail(event.target.value));
        setNewUserEmail(event.target.value);
    }

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    }
    const renderRoleOptions = () => {
        return (
            roles.map(role => (
                <MenuItem key={role.id} value={role.id}>{role.value}</MenuItem>
            ))
        );
    }

    return (
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            scroll="body"
            fullWidth
            maxWidth="sm"
            aria-labelledby="invite-crew-member-dialog"
        >
            <CustomDialogTitle
                id="invite-crew-member-dialog"
                onClose={handleCloseDialog}
            >
                Invite Team Member
            </CustomDialogTitle>
            <DialogContent>
                <Grid sx={{mt:1}} container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="user"
                            label="New User Email"
                            onChange={(event) => handleEmailChange(event)}
                            options={data}
                            fullWidth
                            value={newUserEmail}
                            error={!isEmailValid}
                            helperText={isEmailValid ? "" : "email invalid"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel id="user-role-label">New User Role</InputLabel>
                            <Select
                                id="user-role"
                                labelId="user-role-label"
                                label="New User Role"
                                value={selectedRole}
                                onChange={handleRoleChange}
                                
                            >
                                {renderRoleOptions()}
                                
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.inviteUserDialogActions}>
                <Button
                    disabled={isEmailValid === false || selectedRole === ''}
                    onClick={() => {
                        inviteCompanyUser(newUserEmail, selectedRole);
                        handleCloseDialog();
                    }}
                    variant="contained"
                    color="primary">Invite
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddCompanyCrewMemberDialog
