import React from 'react';
import {Box, Grid, Paper, Typography} from '@mui/material';
import ReferrerForm from "../Referrer/ReferrerForm";
import CandidateForm from "../Candidate/CandidateForm";

const ReviewReferralSubmission = (props) => {
    const formik = props.formik;
    return (
        <Box>
            <Paper sx={{maxWidth: 800}}>
                <Grid container direction="column">
                    <Grid item>
                        <Box mt={2} sx={{display: 'flex', justifyContent: 'center'}}>
                            <Typography sx={{verticalAlign: 'middle', pb: 1, pt: 2, fontWeight: 500, color: 'text.primary'}} component="h1" variant="h6">
                                About the Candidate
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <CandidateForm formik={formik} position={props.position} reviewMode={true}/>
                    </Grid>
                    <Grid item>
                        <Box mt={2} sx={{display: 'flex', justifyContent: 'center'}}>
                            <Typography sx={{verticalAlign: 'middle', pb: 2, pt: 2, fontWeight: 500, color: 'text.primary'}} component="h1" variant="h6">
                                About you, the referrer
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sx={{mb: 2}}>
                        <ReferrerForm formik={formik} reviewMode={true}/>
                    </Grid>

                </Grid>

            </Paper>
        </Box>
    );
}

export default ReviewReferralSubmission
