import React from "react";
import {Box} from "@mui/material";
import OuroborosSvg from "../../../../shared/icons/Ouroboros.svg";
import Typography from "@mui/material/Typography";

const ReferrerIsCandidateTypographyIcon = (props) => {
    return (
        <Box
            sx={{
                p: 2, pl: 3.5, pb: 0,
                alignItems: "center",
                display: "flex",
                placeContent: "center",
            }}
        >
            <Box component="img" sx={{height: 20, width: 20}} src={OuroborosSvg}/>
            <Typography variant="subtitle2" sx={{ml: 1}}>Candidate applied directly</Typography>
        </Box>
    );
};

export default ReferrerIsCandidateTypographyIcon;
