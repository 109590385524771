import PropTypes from 'prop-types'
import {Step, StepContent, StepLabel} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {userFullName} from "../../../../shared/User/User";
import Box from "@mui/material/Box";
import {useAuthState} from "../../../../auth/auth";
import makeStyles from "@mui/styles/makeStyles";
import {useTheme} from "@mui/material/styles";

const getOptionalLabel = (referral, transactions, assigned, active, completed, error, successLabel) => {

    if (completed) {
        return successLabel(referral, transactions);
    }

    if (active) {
        if (error) {
            return <>Rejected by: {userFullName(referral.no_hire_by, referral.no_hire_at)}</>;
        } else if (assigned) {
            return <>Assigned to: {userFullName(referral.assigned_to, referral.assigned_at)}</>;
        } else {
            return ``;
        }
    }

    return null;
};

const useStyles = makeStyles((theme) => (
        {
            styledStepper: {
                '& .Mui-root': {
                    color: theme.palette.linkedin.light,
                },
                '& .Mui-completed': {
                    color: theme.palette.linkedin.dark,
                },
                '& .Mui-error': {
                    color: `${theme.palette.error.dark} !important`,
                },
                '& .Mui-active': {
                    color: theme.palette.linkedin.main,
                },
            },
        }
    )
);

const ReferralStep = (props) => {

    const {
        referral,
        transactions,
        assigned,
        active,
        completed,
        error,
        last,
        label,
        successOptionalLabel,
        description,
        dynamicDescription,
        referralStepActions,
        ...other
    } = props;

    const {authState} = useAuthState();
    const classes = useStyles();
    const theme = useTheme();

    if (!referral) {
        return null;
    }

    const renderOptionalLabel = () => {
        return (
            <Typography
                variant="caption"
                color={error ? "error" : "inherit"}>
                {getOptionalLabel(referral, transactions, assigned, active, completed, error, successOptionalLabel)}
            </Typography>
        );
    };

    const renderDescription = () => {
        if (error) {
            return <Typography color="error">{referral.no_hire_reason}</Typography>;
        } else if (description) {
            return <Typography sx={{pt: 1, pb: 1}}>{description}</Typography>;
        } else {
            return dynamicDescription(referral, transactions, authState?.currUserCompany)
        }
    };

    let stepConnectorColor = "inherit";

    if (error) {
        stepConnectorColor = theme.palette.error.dark;
    } else if (completed) {
        stepConnectorColor = theme.palette.linkedin.dark;
    } else if (active) {
        stepConnectorColor = theme.palette.linkedin.main
    } else {
        stepConnectorColor = "#bdbdbd";
    }

    return (
        <Step {...other}>
            <StepLabel
                className={classes.styledStepper}
                sx={{alignItems: 'start'}}
                //StepIconComponent={ReferralStepIcon}
                //StepIconProps={{referralState: referral.status}}
                optional={renderOptionalLabel(successOptionalLabel, error)}
                error={error}
            >
                {label}
            </StepLabel>
            <StepContent sx={{borderColor: stepConnectorColor}}>
                {renderDescription()}
                {referralStepActions(active, error, authState?.currUserCompany)}
            </StepContent>
            <Box
                sx={{
                    display: last ? "none" : "block",
                    height: active ? 0 : "20px",
                    borderLeft: active ? "inherit" : `1px solid ${stepConnectorColor}`,
                    marginLeft: '12px',
                    paddingLeft: '20px',
                    paddingRight: '8px',
                    mt: 0,
                    mb: active ? 0.5 : 0,
                }}
            />
        </Step>
    );
};

export default ReferralStep;

ReferralStep.propTypes = {
    referral: PropTypes.object,
    transactions: PropTypes.array,
    assigned: PropTypes.bool,
    active: PropTypes.bool,
    completed: PropTypes.bool,
    error: PropTypes.bool,
    last: PropTypes.bool,
    label: PropTypes.string,
    successOptionalLabel: PropTypes.func,
    description: PropTypes.string,
    dynamicDescription: PropTypes.func,
    referralStepActions: PropTypes.func,
}
