import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import {Link as RouterLink} from "react-router-dom";
import Link from '@mui/material/Link'
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const CompanyCard = ({company}) => {
    const url = `/company/${company.company_id}`;
    const editUrl = `/company/${company.company_id}/edit`;

    return (
        <Card>
            <CardHeader
                sx={{minHeight: 150}}
                title={
                    <Link
                        component={RouterLink}
                        to={url}
                        underline="hover"
                        sx={{wordBreak: "break-word"}}
                    >
                        <Typography variant="h6">
                            {company.name}
                        </Typography>
                    </Link>
                }
                subheader={company.role}
                avatar={
                    <Link component={RouterLink} to={url} underline="none">
                        <Avatar
                            variant="rounded"
                            sx={{width: 64, height: 64}}
                            src={company.logo_url}
                        >
                            {company?.name?.charAt(0)}
                        </Avatar>
                    </Link>
                }
                action={
                    <Button
                        sx={{'display': company.actions.includes("edit") ? "block" : "none", textAlign: "center"}}
                        size="small"
                        component={RouterLink}
                        to={editUrl}
                    >
                        Edit
                    </Button>
                }
            />
        </Card>
    )
};

export default CompanyCard;
