import FormControl from "@mui/material/FormControl";
import {InputLabel, NativeSelect} from "@mui/material";
import {getGridBooleanOperators} from "@mui/x-data-grid";
import React from "react";

const IsCandidateInterestedInputValue = (props) => {
    const {item, applyValue} = props;

    const handleFilterChange = (event) => {
        applyValue({...item, value: event.target.value});
    };

    return (
        <FormControl fullWidth>
            <InputLabel variant="standard" shrink>Values</InputLabel>
            <NativeSelect onChange={handleFilterChange} defaultValue={item.value}>
                <option value="">any</option>
                <option value={true}>interested</option>
                <option value={false}>not interested</option>
                <option value={"null"}>no reply</option>
            </NativeSelect>
        </FormControl>
    );
};

export const getIsCandidateInterestedFilterOperators = () => {
    return getGridBooleanOperators().map(
        (operator) => ({
            ...operator,
            InputComponent: IsCandidateInterestedInputValue,
        }),
    );
};
