import * as React from 'react';
import {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack5';
import makeStyles from "@mui/styles/makeStyles";
import {Box, Link} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {appBarHeight} from "../../../../Navigation/Navigation";
import DownloadIcon from '@mui/icons-material/Download';
import {useWindowWidth} from '@wojtekmaj/react-hooks';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const useStyles = makeStyles((theme) => ({
        document: {
            textAlign: "-webkit-center",
            paddingBottom: 24,
            paddingTop: 48,
        },
        page: {
            paddingBottom: 24,
        },
    }
));

const CandidateResumeDialog = ({open, handleClose, candidateResumeUrl}) => {
    const [numPages, setNumPages] = useState(null);

    const classes = useStyles();
    const width = useWindowWidth();

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };

    return (
        <div>
            <Dialog
                keepMounted
                fullScreen
                open={open}
                onClose={handleClose}
                // TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                        backgroundColor: 'rgba(0,0,0,0.6)',
                        boxShadow: 'none',
                    },
                }}
            >
                <AppBar
                    position="fixed"
                    elevation={0}
                    sx={{
                        background: 'linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))',
                        boxShadow: 'none',
                    }}
                >
                    <Toolbar style={{minHeight: appBarHeight, paddingLeft: 0, paddingRight: 0}}>
                        <IconButton
                            onClick={handleClose}
                            color="inherit"
                            sx={{
                                ml: 2,
                                ':hover': {
                                    bgcolor: 'rgba(255, 255, 255, 0.4)',
                                },
                            }}
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Box sx={{flexGrow: 1,}}/>
                        <IconButton
                            component={Link}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                            href={candidateResumeUrl}
                            color="inherit"
                            sx={{
                                mr: 2,
                                ':hover': {
                                    bgcolor: 'rgba(255, 255, 255, 0.4)',
                                    // color: 'white',
                                },
                            }}
                        >
                            <DownloadIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Document
                    className={classes.document}
                    loading={null}
                    file={candidateResumeUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {
                        Array.from(new Array(numPages), (el, index) => {
                            return (
                                <Page
                                    className={classes.page}
                                    renderAnnotationLayer={false}
                                    renderTextLayer={false} //text layer font size differs from pdf font size
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    width={Math.min(width * 0.9, 800)} // width: 90vw; max-width: 2400px
                                />
                            );
                        })
                    }
                </Document>
            </Dialog>
        </div>
    );
};

export default CandidateResumeDialog;
