import React, {Fragment} from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Grid, Modal, Stepper} from "@mui/material";
import ReferralStep from "./ReferralStep";
import {userFullName, userNameWithDate, userWithDate} from "../../../../shared/User/User";
import ReferralStepActions from "./ReferralStepActions";
import PayReferrer from '../../../../PayReferrer';
import makeStyles from '@mui/styles/makeStyles';
import {format, parseJSON} from "date-fns";
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(2),
            padding: theme.spacing(1),
        },
    },
    paper: {

        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

}));

const beginByLabel = (referral) => {
    const name = userFullName(referral.begin_by, referral.begin_at);

    return <React.Fragment>Completed by: {name}</React.Fragment>;
}

const hireByLabel = (referral) => {
    const name = userWithDate(referral.hire_by, referral.hire_at);

    return <React.Fragment>Completed by: {name}</React.Fragment>;
}
const payoutByLabel = (referral, transactions) => {
    // just get the first trx
    if (!transactions || !transactions[0])
        return "";
    const trx = transactions[0];
    const name = userNameWithDate(trx.initiator_name, trx.created_at);
    //return trx.initiator_name;
    return (
        <React.Fragment>
            Marked hired by {userFullName(referral.hire_by)}
            on {format(parseJSON(referral.hire_at), 'MM/dd/yyyy')}
        </React.Fragment>
    );
    // return <Grid container spacing={2}>
    //                     {
    //                         transactions?.map((transaction, index) => (
    //                             <Grid key={index} item xs={12} sm={6}>
    //                                 {/* <TransactionCard paymentComplete={onReferralPayout} transaction={transaction}/> */}
    //                                 <TransactionCard transaction={transaction}/>
    //                             </Grid>
    //                         ))
    //                     }
    //                 </Grid>
}
const paidLabel = (referral, transactions) => {
    if (!transactions || !transactions[0]) {
        return "";
    }

    const trx = transactions[0];
    const name = userNameWithDate(trx.initiator_name, trx.created_at);
    //return trx.initiator_name;
    return <Box>
        <React.Fragment>Completed by: {name}</React.Fragment>
        <Grid container direction='column'>
            <Grid item>Transaction Details</Grid>
            <Grid item sx={{ml: 3}}>Candidate Name: {trx.candidate_name}</Grid>
            <Grid item sx={{ml: 3}}>Referrer Name: {trx.referrer_name}</Grid>
            <Grid item sx={{ml: 3}}>
                Total amount paid including service fee:
                <NumberFormat
                    value={trx.pay_amount_total_cents / 100}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    fixedDecimalScale={true}
                    decimalScale={2}/>
            </Grid>
        </Grid>
    </Box>

}

const getMakePaymentDescription = (referral, transaction, loggedInUser) => {
    if (loggedInUser?.role === "Owner" || loggedInUser?.role === "Admin") { // only admin+ can do payment
        return "Click 'Continue' to open the referrer payment checkout screen";
    }

    return "To complete the process, ask an admin to send payment to the referrer";
}
const steps = [
    {
        label: 'Initial Assessment',
        optionalLabel: beginByLabel,
        description: `Based on the provided information, are you interested in moving this candidate to the next stage?`,
        actions: (referralActions, extraReasons) => (
            (active, error) => (
                <ReferralStepActions
                    active={active}
                    error={error}
                    successButtonLabel='Continue'
                    successAction={referralActions.onReferralBegin}
                    failButtonLabel='Reject'
                    failReasons={[...extraReasons, "Not the right fit", "Insufficient candidate info", "Insufficient referrer info"]}
                    failAction={referralActions.onReferralNoHire}
                />
            )
        )
        ,
    },
    {
        label: 'In Progress',
        optionalLabel: hireByLabel,
        description: 'Was candidate successfully hired?',
        actions: (referralActions, extraReasons) => (
            (active, error) => (
                <ReferralStepActions
                    active={active}
                    error={error}
                    successButtonLabel='Yes'
                    successAction={referralActions.onReferralHire}
                    failButtonLabel='No'
                    failReasons={[...extraReasons, "Candidate did not pass interview", "Candidate did not accept offer", "Could not reach candidate or referrer"]}
                    failAction={referralActions.onReferralNoHire}
                />
            )
        ),
    },
    {
        label: 'Candidate Hired',
        //description: 'Ask company administrator to approve payment.', // if logged in user is an admin, show the approve payment button
        dynamicDescription: getMakePaymentDescription,
        optionalLabel: payoutByLabel,
        actions: (referralActions, extraReasons) => (
            (active, error, loggedInUser) => {
                if (loggedInUser?.role === "Owner" || loggedInUser?.role === "Admin") {
                    return (
                        <ReferralStepActions
                            active={active}
                            error={error}

                            successButtonLabel='Continue'
                            // successAction={referralActions.onReferralPayout}
                            successAction={referralActions.openPaymentDialog}
                            failButtonLabel='Reject'
                            failReasons={[...extraReasons,]}
                            failAction={referralActions.onReferralNoHire}
                        />
                    );
                } else {
                    return (
                        <ReferralStepActions
                            active={active}
                            error={error}

                            // successButtonLabel='Send Payment'
                            // successAction={referralActions.onReferralPayout}
                            // failButtonLabel='Cancel'
                            // failReasons={["Hire error", "Assessment not passed"]}
                            // failAction={referralActions.onReferralNoHire}
                        />
                    );
                }
            }
        ),
    },
    {
        label: 'Referrer Payment',
        description: 'Referrer has been paid',
        optionalLabel: paidLabel,
        actions: (referralActions) => (
            (active, error) => (
                <ReferralStepActions
                    active={active}
                    error={error}
                    // successButtonLabel='Send Payment'
                    //successAction={referralActions.onReferralPayout}
                    // failButtonLabel='Decline Payment'
                    //failReasons={["Hire error", "Assessment not passed"]}
                    //failAction={referralActions.onReferralNoHire}
                />
            )
        ),
    },
];

const stateToSteps = {
    1: 0,
    2: 0,
    3: 1,
    4: 2,
    5: 2,
    6: 4
};

const ReferralActions = (props) => {
    const {
        referral, transactions,
        onReferralBegin, onReferralHire, onReferralPayout, onReferralNoHire
    } = props;


    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    if (!referral) {
        return null;
    }

    const referralState = referral.status
    const assigned = !!referral.assigned_to
    const noHire = referral.no_hire;
    const activeStepIndex = stateToSteps[referralState];

    const referralActions = {
        onReferralBegin: () => onReferralBegin(referral.id),
        onReferralHire: () => onReferralHire(referral.id),
        onReferralPayout: () => onReferralPayout(referral.id),
        onReferralNoHire: (reason) => onReferralNoHire(referral.id, reason),
        openPaymentDialog: () => openPaymentDialog(referral.id)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openPaymentDialog = (event, item) => {
        setOpen(true);
    };
    const handlePaymentCompete = (data) => {
        setOpen(false);
        onReferralPayout(data);
    };

    const formatTransactionDetails = (trx, referral) => {
        if (!trx)
            return {};
        var rewardInDollars = trx.pay_amount_net_cents / 100;
        return {
            ReferrerName: trx.referrer_name,
            CandidateName: trx.candidate_name,
            PositionName: trx.position_name,
            TotalPaymentAmount: trx.pay_amount_total_cents / 100,
            PopHireFee: (trx.pay_amount_fee_cents / 100),
            RewardAmount: rewardInDollars,
            transactionId: trx.id,
            ReferrerIsCandidate: referral.referrer_is_candidate
        }
    };

    return (
        <Fragment>
            <Box flex sx={{p: 2, display: "flex", justifyContent: "space-between"}}>
                <Typography variant="h6">Referral Status</Typography>
                {/* <Button size="small" variant="contained" onClick={() => {
                }}>assign</Button> */}
            </Box>
            <Box sx={{maxWidth: '100%', mr: '10%', px: 0, pl: "10%"}}>
                <Stepper
                    activeStep={activeStepIndex}
                    connector={null}
                    orientation="vertical"
                    // sx={{pl: '20%'}}
                >
                    {
                        steps.map((step, index) => {
                            const active = activeStepIndex === index;
                            const completed = index < activeStepIndex;
                            const error = active && noHire;
                            const last = index === steps.length - 1;

                            const extraReasons = referral.is_candidate_duplicate ? ["Candidate was submitted by another person"] : [];

                            return (
                                <ReferralStep
                                    key={index}
                                    referral={referral}
                                    transactions={transactions}
                                    assigned={assigned}
                                    active={active}
                                    completed={completed}
                                    error={error}
                                    last={last}
                                    label={step.label}
                                    successOptionalLabel={step.optionalLabel}
                                    description={step.description}
                                    dynamicDescription={step.dynamicDescription}
                                    referralStepActions={step.actions(referralActions, extraReasons)}
                                />
                            );
                        })
                    }
                </Stepper>
            </Box>
            {transactions != null && transactions[0] != null &&
                <Modal
                    open={open}
                    className={classes.modal}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description">
                    <div className={classes.paper}>
                        <PayReferrer
                            paymentComplete={handlePaymentCompete}
                            paymentDetails={formatTransactionDetails(transactions[0], referral)}
                        />
                    </div>
                </Modal>
            }
        </Fragment>
    );
};

export default ReferralActions;
