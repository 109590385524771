export const list = (history, companyId, positionId) => {
    return history.push(`/company/${companyId}/positions/${positionId}/referrals`)
}
export const showSelecteReferral = (history, companyId, positionId, referrals) => {
    return history.push(`/company/${companyId}/positions/${positionId}/referrals/${referrals}`)
}
export const submitSuccess = (history, positionId, referralId, state) => {
    return history.push({pathname: `/positions/${positionId}/referrals/${referralId}/submitted`, state: state});
}
export const edit = (history, positionId, referralId) => {
    return history.push(`/positions/${positionId}/referrals/${referralId}`)
}
export const editselfreferral = (history, positionId, referralId) => {
    return history.push(`/positions/${positionId}/referrals/selfcandidate/${referralId}`)
}
