import {useHistory} from "react-router-dom";
import {Button, Grid} from "@mui/material";
import React from "react";


const BeginReferralButtons = (props) => {
    const history = useHistory();

    const createReferral = () => {
        history.push(window.location.pathname + "/referrals");
    }
    const createSelfReferral = () => {
        history.push(window.location.pathname + "/referrals/selfcandidate");
    }

    return <Grid container columnSpacing={3}>
        <Grid item xs={6}>
            <Button
                href={window.location.pathname + "/apply"}
                // onClick={createSelfReferral}
                fullWidth
                sx={{
                    bgcolor: "success.main",
                    color: "#ffffff",
                    ':hover': {
                        bgcolor: '#4F916B', color: 'white'
                    },
                    pl: 4, pr: 4, pt: 1, mt: 2, pb: 1, mb: 2
                }}
            >
                I am the candidate
            </Button>
        </Grid>
        <Grid item xs={6}>
            <Button
                href={window.location.pathname + "/refer"}
                //onClick={createReferral}
                fullWidth
                sx={{
                    bgcolor: "success.main",
                    color: "#ffffff",
                    ':hover': {
                        bgcolor: '#4F916B', color: 'white'
                    },
                    pl: 4, pr: 4, pt: 1, mt: 2, pb: 1, mb: 2
                }}
            >
                Refer someone
            </Button>
        </Grid>
    </Grid>;
};

export default BeginReferralButtons;
