import React from 'react';
import TextField from '@mui/material/TextField';

const FormikOutlinedControlledTextField = (props) => {
    const {formik, fieldName, onChange, ...other} = props;
    return (
        <TextField
            name={fieldName}
            id={fieldName}
            value={formik.values[fieldName] === null ? "" : formik.values[fieldName]}
            onChange={event => onChange(event, fieldName)}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors[fieldName])}
            helperText={formik.errors[fieldName]}
            variant="outlined"
            size="small"
            {...other}
        />
    )
}

export default FormikOutlinedControlledTextField;
