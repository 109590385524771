import React from "react";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from "@mui/material/Button";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tooltip from "@mui/material/Tooltip";
import StatefulPopover from "../../shared/StatefulPopover/StatefulPopover";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from '@mui/icons-material/Delete';
import AutoSaveAppBar from "../../shared/AppBar/AutoSaveAppBar";
import Box from "@mui/material/Box";
import {useTheme} from "@emotion/react";

const PositionAppBar = (props) => {
    const {formik, publishButtonLabel, title, onPublish, onPreview} = props;

    const theme = useTheme();

    const appBarActions = () => (
        <Box>
            <Button
                sx={{mr: 1, bgcolor: "success.main", color: "#ffffff", ':hover': {bgcolor: '#4F916B', color: 'white'}}}
                aria-label="position-publish"
                color="primary" variant="contained"
                onClick={onPublish}
                size="small"
            >
                {publishButtonLabel}
            </Button>
            <Tooltip title="Preview">
                <IconButton
                    aria-label="position-preview"
                    size="small"
                    onClick={onPreview}
                >
                    <VisibilityIcon/>
                </IconButton>
            </Tooltip>
            <StatefulPopover
                content={
                    (popupState) =>
                        <React.Fragment>
                            <Tooltip title="More Actions">
                                <IconButton
                                    {...bindTrigger(popupState)}
                                    sx={{marginRight: theme.spacing(2),}}
                                    aria-label="position-more-actions"
                                    size="small"
                                >
                                    <MoreHorizIcon/>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                style={{marginTop: 10}}
                                {...bindMenu(popupState)}
                                elevation={1}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <MenuItem onClick={popupState.close}>
                                    <ListItemIcon style={{minWidth: 30}}>
                                        <DeleteIcon fontSize="small"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Delete Draft"/>
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                }
            />
        </Box>
    );

    return (
        <AutoSaveAppBar showCloudSave={true} formik={formik} title={title} actions={appBarActions()} {...props}/>
    );
};

export default PositionAppBar;
