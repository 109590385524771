import React from "react"
import {LinearProgress} from "@mui/material";
import Box from "@mui/material/Box";

const CustomLoadingOverlay = () => (
    <Box sx={{position: 'absolute', top: 55, width: '100%'}}>
        <LinearProgress/>
    </Box>
);

export default CustomLoadingOverlay;
