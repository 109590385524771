import axios from 'axios';
import redirect from "../redirect";

export const list = (companyId, positionId, spec, headers) => {
    return axios.get(`/api/company/${companyId}/positions/${positionId}/referrals`, {params: spec, headers: headers});
}
export const showToCompany = (companyId, positionId, referralId, headers) => {
    return axios.get(`/api/company/${companyId}/positions/${positionId}/referrals/${referralId}`, {headers: headers});
}
export const loadNotes = (companyId, positionId, referralId, headers) => {
    return axios.get(`/api/company/${companyId}/positions/${positionId}/referrals/${referralId}/notes`, {headers: headers})
}
export const addNote = (companyId, positionId, referralId, content, headers) => {
    return axios.post(`/api/company/${companyId}/positions/${positionId}/referrals/${referralId}/notes`, {content}, {headers: headers});
}
export const beginReferralHiring = (companyId, positionId, referralId, headers) => {
    return axios.put(`/api/company/${companyId}/positions/${positionId}/referrals/${referralId}/begin`, {}, {headers: headers});
}
export const noHire = (companyId, positionId, referralId, reason, headers) => {
    return axios.put(`/api/company/${companyId}/positions/${positionId}/referrals/${referralId}/no_hire`, {}, {params: {no_hire_reason: reason}, headers: headers});
}
export const makePayment = (companyId, positionId, referralId, transactionId, headers) => {
    return axios.put(`/api/company/${companyId}/positions/${positionId}/referrals/${referralId}/transactions/${transactionId}/create_payment`, {}, {headers: headers});
}
export const confirmPayment = (companyId, positionId, referralId, transactionId, headers) => {
    return axios.put(`/api/company/${companyId}/positions/${positionId}/referrals/${referralId}/transactions/${transactionId}/confirm_payment`, {}, {headers: headers});
}
export const hire = (companyId, positionId, referralId, headers) => {
    return axios.put(`/api/company/${companyId}/positions/${positionId}/referrals/${referralId}/hire`, {}, {headers: headers});
}
export const getOrCreate = (positionId, headers, history) => {
    return axios.get(`/api/positions/${positionId}/referrals`, {headers: headers})
        //.then(resp => redirect.referrals.edit(history, positionId, resp.data.referral.id))

}
export const getOrCreateSelfReferral = (positionId, headers, history) => {
    return axios.get(`/api/positions/${positionId}/referrals`, {headers: headers});
}
export const showPositionToUser = (positionId, headers) => {
    return axios.get(`/api/positions/${positionId}`, {headers: headers})
}
export const showToUser = (positionId, referralId, headers) => {
    return axios.get(`/api/positions/${positionId}/referrals/${referralId}`, {headers: headers})
}
export const submit = (positionId, referralId, newReferral, headers) => {
    return axios.post(`/api/positions/${positionId}/referrals/submit`, newReferral, {headers: headers});
}
export const update = (positionId, referralId, updateReferral, headers) => {
    return axios.put(`/api/positions/${positionId}/referrals/${referralId}`, updateReferral, {headers: headers})
}
export const assignedToPositionReferralsUsersByName = (companyId, positionId, nameLike, cancelToken, headers) => {
    return axios.get(`/api/company/${companyId}/positions/${positionId}/referrals/assigned_to_users`, {
        headers: headers,
        params: {assigned_to_name_like: nameLike},
        cancelToken: cancelToken,
    });
}
export const getAllReferredPositionsByCurrentUser = (headers) => {
    return axios.get(`/api/referrer/referredPositions`, {
        headers: headers,
    }).then(resp => resp.data);
}
export const associateReferralToUser = (referralConfirmationId, headers) => {
    return axios.post(`/api/referrer/${referralConfirmationId}/claim`,{}, {headers: headers});
}
