import React, {useMemo} from 'react';
import Grid from '@mui/material/Grid';

import FormikOutlinedTextField from "../../../../../shared/Formik/FormikOutlinedTextField";
import UrlIcon from '../../../../../shared/UrlIcon/UrlIcon';

import makeStyles from '@mui/styles/makeStyles';
import ContactForm from "../../../../Contacts/ContactForm";
import {Box, Paper, Typography} from '@mui/material';
import {debounce} from "lodash";
import StyledReactQuill from '../../../../../shared/Quill/StyledReactQuill';

const useStyles = makeStyles((theme) => ({
        urlIcon: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            marginLeft: theme.spacing(1),
        },
    }
));

// const descriptionPlaceholder = "Anything you want to tell us about yourself?";

const ReferrerForm = ({formik, reviewMode}) => {
    const classes = useStyles();

    const readOnly = !!reviewMode;

    let handleChange;

    if (formik.values.referrer_is_candidate === true) {
        handleChange = (event, field) => {
            formik.setStatus({state: "touched"})

            formik.setValues({
                ...formik.values,
                error: null,
                [field]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
                [field.replace("referrer", "candidate")]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
            }, true)
        };
    } else {
        handleChange = (event) => {
            formik.handleChange(event);
            formik.setStatus({state: "touched"});
        }
    }

    const debounceHandleChange = useMemo(() => debounce((event, field) => handleChange(event, field), 100), []);

    const readonlyView = (labelTitle, value, asHtml = false) => {
        return (
            <Box>
                <Typography
                    sx={{fontSize: 14, fontWeight: 500, color: 'text.primary'}}
                    component='span'
                >
                    {labelTitle}:
                </Typography>
                <Typography
                    sx={{fontSize: 12}}
                    component='span'
                >
                    {asHtml ? <div dangerouslySetInnerHTML={{__html: value}}/> : value}
                </Typography>
            </Box>
        );
    }

    const makeLink = (url) => {
        return <a href={url}> {url}</a>
    }

    // const debounceHandleAboutReferrerTextChange = useMemo(() => debounce((value) => handleAboutReferrerTextChange(value), 1500), []);

    const handleAboutReferrerTextChange = (value) => {
        // formik.handleChange(event);
        formik.setFieldValue("about_referrer_text", value)
        formik.setStatus({state: "touched"})
    }
    const handleAboutReferrerTextBlur = (previousRange, source, editor) => {
        // formik.handleChange(event);
        formik.setFieldValue("about_referrer_text", editor.getHTML())
        formik.setStatus({state: "touched"})
    }

    if (readOnly) {
        return (
            <Box>
                <Grid container direction='column' rowSpacing={1}>
                    <Grid item ml={6}>
                        {readonlyView('Referrer Name', formik.values.referrer_name)}
                    </Grid>
                    <Grid item ml={6}>
                        {readonlyView('Link to your professional profile', makeLink(formik.values.referrer_url))}
                    </Grid>
                    <Grid item ml={6}>

                        {readonlyView('About Yourself', formik.values.about_referrer_text, true)}
                    </Grid>
                    <Grid item ml={6}>
                        <ContactForm
                            formik={formik}
                            reviewMode={true}
                            prefix="referrer_"
                            title={
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        pb: 1,
                                        pt: 1,
                                        fontWeight: 400,
                                        color: 'text.primary'
                                    }}
                                >
                                    How can you be contacted for payment processing and follow up?
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    } else {
        return (
            <Grid container direction='row'>
                <Grid item xs>
                    <Paper elevation={0} variant="outlined" sx={{pb: 4, pt: 2, px: 2}}>
                        <Grid container pl={2} pr={2} pt={2} pb={1} spacing={2} alignContent="flex-start" alignItems="flex-start">
                            <Grid item xs={12} sm={12}>
                                <FormikOutlinedTextField
                                    formik={formik}
                                    fieldName="referrer_name"
                                    label="Referrer name"
                                    autoComplete="name"
                                    required
                                    onChange={debounceHandleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormikOutlinedTextField
                                    formik={formik}
                                    id="referrer_url"
                                    name="referrer_url"
                                    fieldName="referrer_url"
                                    label="Link to your professional profile"
                                    placeholder="https://pophire.io"
                                    required
                                    onChange={debounceHandleChange}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <UrlIcon className={classes.urlIcon} url={formik.values.referrer_url}/>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography>Tell us about yourself</Typography>
                                <StyledReactQuill
                                    //disabled={disabled}
                                    isError={Boolean(formik.errors.about_referrer_text)}
                                    errorMessage={formik.errors.about_referrer_text}
                                    defaultValue={formik.values.about_referrer_text}
                                    onBlur={handleAboutReferrerTextBlur}
                                    onChange={handleAboutReferrerTextChange}
                                />
                                {/* <FormikOutlinedTextField
                                        formik={formik}
                                        fieldName="about_referrer_text"
                                        autoComplete="description"
                                        label="Tell us about yourself"
                                        multiline
                                        fullWidth
                                        placeholder={descriptionPlaceholder}
                                        onChange={debounceHandleChange}
                                        rows={2}
                                    /> */}
                            </Grid>
                            <ContactForm
                                formik={formik}
                                prefix="referrer_"
                                onChange={debounceHandleChange}
                                title={
                                    <div>
                                        <Typography
                                            sx={{pb: 1, fontWeight: 400, color: 'text.primary'}}
                                            variant="h5"
                                        >
                                            How can you be contacted for payment processing and follow up?
                                        </Typography>
                                    </div>
                                }
                            />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default ReferrerForm;
