import { Button, IconButton, Link, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import axios from "axios";
import React, {useEffect, useState} from "react";
import { useAuthState } from "../auth/auth";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ConfirmDialog from "../shared/ConfirmDialog";

const useStyles = makeStyles((theme) => ({}));

const UserPaymentInfoPage = (props) => {

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };

    const classes = useStyles();
    const {authState} = useAuthState();
    const headers = authState?.headers;
    const [bankInfo, setBankInfo] = useState([]);

    useEffect(() => {
        if (!authState?.isAuthenticated) {
            return
        }

        axios.get(`/api/account/payment/bankinfo`, {headers: headers})
            .then(res => {
                return res.data;
            })
            .then(data => {
                setBankInfo(data.bank_info);
            })
            .catch(err => console.error(err));
    }, [headers]); // Update if authState changes

    const setupPaymentInfo = () => {
        console.log("in setup payment info click")
        axios.post('/api/account/payment/setup',{}, { headers: headers }).then(res => {
            return res.data;
          }).then(data => {
            console.log(data)
            window.location = data.setup_payment_account_url
          });
    }
    const removeBankAccount = () => {
       setOpen(true);
    }
    const deleteBank = () => {
        console.log("in remove bank account click");
        //var c = confirm("Are you sure?");
        axios.delete('/api/account/payment/bankinfo', { headers: headers }).then(res => {
            return res.data;
          }).then(data => {
            console.log(data)
            setBankInfo(data.bank_info);
          });
    }

    const renderBankInfo = (info) => {
        return <Box key={info.last4}>
            <Box>
                Bank Name: {info?.bank_name}
                </Box>
            <Box>Last 4 digits of account: {info?.last4}</Box>
            <Box>Routing Number: {info?.routing_number}</Box>
            {bankInfo.length>1?<Box><Button onClick={removeBankAccount}> <DeleteForeverOutlinedIcon/> Remove Bank Acount</Button></Box>:""}
        </Box>
    }

    return (
        <Box sx={{px:0, mb: 4, height: '100%', maxWidth: 900, color: 'text.primary', margin: "0 auto !important"}}>
            <Typography sx={{pb: 4, fontWeight: 700, color: 'text.primary'}} component="h1" variant="h4" align="center">
                Payment
            </Typography>
            <Paper sx={{p: 1, borderRadius: '20px'}} elevation={1}>
                {
                    bankInfo?.map(info => {
                        return renderBankInfo(info);
                    })
                   
                }
                {/* <Button disabled={!!bankInfo} sx={{mt:2, mb:2}} m={1} variant="contained" onClick={() => setupPaymentInfo()}>Setup Payment Info</Button> */}
                <Button sx={{mt:2, mb:2, ml: 2}} m={1} variant="contained" onClick={() => setupPaymentInfo()}>Setup Payment Info</Button>
                
                <Box sx={{pl: 1, pb: 2, pt: 1}}>
                    <Typography component="span" sx={{fontSize: 13, fontWeight: 500}}>Note:&nbsp;</Typography>
                    {/* <Typography component="span" sx={{display:!!bankInfo?"inline":"none", fontSize: 13}}>Only one bank account can be present. To add a different one, first remove the existing account above. </Typography> */}
                    <Typography
                        component="span"
                        sx={{fontSize: 13}}
                    >
                        Bank information is stored security in stripe and is only used to send your
                        reward payment for successfully hired referrals.
                    </Typography>
                </Box>
            </Paper>
            <ConfirmDialog
                title="Delete Bank Account Information?"
                open={open}
                setOpen={setOpen}
                onConfirm={deleteBank}>
                Are you sure you want to delete this bank account information? This cannot be undone.
            </ConfirmDialog>
        </Box>
    );
}

export default UserPaymentInfoPage;
