import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import React, {Fragment} from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => (
        {
            customTooltip: {
                marginLeft: theme.spacing(1),
                marginBottom: theme.spacing(0.3),
            },
            customWidth: {
                maxWidth: 350,
            },
        }
    )
);


const HelpTooltip = (props) => {
    const {icon, tooltipTitle, color, ...other} = props;
    const classes = useStyles();

    return (
            <Tooltip
                {...other}
                classes={{tooltip: classes.customWidth}}
                placement="right"
                title={
                    <Fragment>
                        {tooltipTitle.map((tooltipTitleString, i) => <Typography key={i} variant="subtitle2">{tooltipTitleString}</Typography>)}
                    </Fragment>
                }>
                <IconButton
                    className={classes.customTooltip}
                    style={{color: color}}
                    size="small"
                >
                    {icon}
                </IconButton>
            </Tooltip>
    )
}

export default HelpTooltip;
