import {hot} from 'react-hot-loader/root'
import React from 'react';
import AppWithRouterAccess from './AppWithRouterAccess';
import {QueryClient, QueryClientProvider} from "react-query";
import {createTheme, responsiveFontSizes, StyledEngineProvider, ThemeProvider,} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {BrowserRouter as Router} from "react-router-dom";

const queryClient = new QueryClient()

let themeLight = createTheme({
    palette: {
        background: {
            default: "#EDEDED",
        },
        drawer: {
            text: "#EDEDED"
        },
        primary: {
            main: '#1F235B'
        },
        linkedin: {
            dark: '#0965c2',
            main: '#90caf9',
            light: "#e1edfa",
        },
        text: {
            primary: "#000000"
        },
        success: {
            main: "#6CBF6C"
        },
    }
});

themeLight = responsiveFontSizes(themeLight);

const App = () => (
    <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themeLight}>
                <CssBaseline/>
                <Router>
                    <AppWithRouterAccess/>
                </Router>
            </ThemeProvider>
        </StyledEngineProvider>
        {/*<ReactQueryDevtools initialIsOpen={false}/>*/}
    </QueryClientProvider>
);
export default hot(App);
