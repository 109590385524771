import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from '@mui/material/Radio';
import {FormikDatePicker} from "../../shared/DatePicker/FormikDatePicker";
import 'react-quill/dist/quill.snow.css';
import StyledReactQuill from "../../shared/Quill/StyledReactQuill";
import {debounce} from "lodash";

const useStyles = makeStyles((theme) => ({
    dataGrid: {
        height: '40vh', padding: theme.spacing(2)
    }, gridToolbar: {
        placeContent: 'space-between', marginBottom: theme.spacing(1)
    }, radioDatePicker: {
        display: 'inline-flex', alignItems: 'center', paddingTop: '1px', height: 70,
    }, radioText: {
        paddingRight: theme.spacing(2),
    }, salaryInputRoot: {
        paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1), height: 80
    }, datePickerRoot: {
        paddingTop: theme.spacing(2.5), paddingBottom: theme.spacing(2), height: 100
    }, helpIcon: {
        marginLeft: theme.spacing(1), marginBottom: theme.spacing(0.3),
    }, customWidth: {
        maxWidth: 350,
    },
}));

const PositionDetailsForm = ({formik, disabled}) => {
    const classes = useStyles();

    // const hasVideo = !!formik.values.manager_note_url && formik.values.manager_note_url !== '' && !formik.errors.manager_note_url;
    // const [values, setValues] = React.useState({...formik.values});

    const [closeAtRadioValue, setCloseAtRadioValue] = React.useState(formik.values.close_at === null ? "closeManually" : "closeOnDate");

    const handleCloseAtRadioChange = (event) => {
        const value = event.target.value;

        if (value === "closeManually") {
            formik.setStatus({state: "touched"});
            formik.setFieldValue("close_at", null);
        }

        setCloseAtRadioValue(value);
    };

    const writeDescriptionToFormik = (val, fragment) => {
        formik.setStatus({state: "touched"});
        formik.setFieldValue("description", val);
        formik.setFieldValue("description_short_plain", fragment);
    };

    const debounceHandleDescriptionChange = useMemo(() => debounce((value, fragment) => writeDescriptionToFormik(value, fragment), 1500), []);
    const debounceHandleDescriptionSelectionChange = useMemo(() => debounce((value, fragment) => writeDescriptionToFormik(value, fragment), 1500), []);

    const handleDescriptionChange = (val, delta, source, editor) => {
        const plainText = editor.getText();
        const fragment = getFragment(plainText);
        debounceHandleDescriptionChange(val, fragment);
    };
    const getFragment = (plainText) => {
        const fragment = plainText.slice(0,197) + "...";
        return fragment;
    };

    const handleSelectionChange = (previousRange, source, editor) => {
        // if (val === formik.value)
        const newContent = editor.getHTML();
        const plainText = getFragment(editor.getText());
        const existingContent = formik.values.description;

        if (newContent !== existingContent) {
            debounceHandleDescriptionSelectionChange(newContent, plainText);
        }
    };

    const handleChange = (event) => {
        formik.handleChange(event);
        formik.setStatus({state: "touched"})
    }

    const debounceHandleChange = useMemo(() => debounce((event) => handleChange(event), 1500), []);

    return (
        <Grid container spacing={2} alignContent="flex-start" alignItems="center">
            <Grid item xs={12} sm={12} sx={{mt: 2}}>
                <Typography sx={{mb: 3}} variant="h6">Position Details</Typography>
                <TextField
                    id="title"
                    name="title"
                    autoComplete="title"
                    label="Job Title"
                    fullWidth
                    required
                    disabled={disabled}
                    defaultValue={formik.values.title}
                    onBlur={formik.handleBlur}
                    onChange={debounceHandleChange}
                    error={Boolean(formik.errors.title)}
                    helperText={formik.errors.title}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography sx={{pb: 1}} variant="subtitle2">Job Description</Typography>
                <StyledReactQuill
                    disabled={disabled}
                    isError={Boolean(formik.errors.description)}
                    errorMessage={formik.errors.description}
                    defaultValue={formik.values.description}
                    onChange={handleDescriptionChange}
                    onSelectionChange={handleSelectionChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2">Salary Range</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <div className={classes.salaryInputRoot}>
                    <TextField
                        id="salary_from"
                        name="salary_from"
                        autoComplete="salary_from"
                        label="From"
                        fullWidth
                        placeholder="Not Specified"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        disabled={disabled}
                        defaultValue={formik.values.salary_from || ""}
                        onChange={debounceHandleChange}
                        error={Boolean(formik.errors.salary_from)}
                        helperText={formik.errors.salary_from}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={4}>
                <div className={classes.salaryInputRoot}>
                    <TextField
                        id="salary_to"
                        name="salary_to"
                        autoComplete="salary_to"
                        label="To"
                        fullWidth
                        placeholder="Not Specified"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        disabled={disabled}
                        defaultValue={formik.values.salary_to || ""}
                        onChange={debounceHandleChange}
                        error={Boolean(formik.errors.salary_to)}
                        helperText={formik.errors.salary_to}
                    />
                </div>
            </Grid>

            <Grid item xs={12} sm={12}>
                <FormControl variant="standard" error={false}>
                    <Typography variant="subtitle2" sx={{pb: 2}}>When should the position be closed?</Typography>
                    {/* <FormLabel component="legend" sx={{pb: 2}}></FormLabel> */}
                    <RadioGroup
                        id="position-close-date-radio-group"
                        aria-label="position-close-date-radio-group"
                        name="position-close-date-radio-group"
                        value={closeAtRadioValue}
                        onChange={handleCloseAtRadioChange}
                        sx={{pb: 2}}
                    >
                        <FormControlLabel
                            value="closeManually"
                            control={<Radio/>}
                            label="Close Manually"
                            disabled={disabled}
                            //sx={{pb: 2}}
                        />
                        <FormControlLabel
                            value="closeOnDate"
                            control={<Radio/>}
                            disabled={disabled}
                            label={
                                <div className={classes.radioDatePicker}>
                                    <Typography variant="subtitle1" className={classes.radioText}>On</Typography>
                                    <FormikDatePicker
                                        id="close_at"
                                        label="Close Date"
                                        formik={formik}
                                        disabled={closeAtRadioValue === 'closeManually' || disabled}
                                        disablePast
                                    />
                                </div>
                            }
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
}

PositionDetailsForm.propTypes = {
    formik: PropTypes.object,
}

export default PositionDetailsForm;
