import axios from 'axios';
import axiosRetry from 'axios-retry';

export const meRetry = (headers) => {
    const retryAxios = axios.create();
    axiosRetry(retryAxios, {
        retries: 1,
        retryDelay: axiosRetry.exponentialDelay,
        retryCondition: (error) => !(error.response.code.toString() === '404' || error.response.code.toString() === '500')
    });
    return retryAxios.get('/api/users/me', {headers: headers})
}

export const me = (headers) => {
    return axios.get('/api/users/me', {headers: headers});
}
export const updateMe = (profile, headers) => {
    return axios.post('/api/users/me',profile, {headers: headers});
}
export const determineHome = (homeDetails, headers) => {
    return axios.post('/api/users/home',homeDetails, {headers: headers});
}

export const list = (emailStartsWith, cancelToken, headers) => {
    return axios.get("/api/users", {
        headers: headers,
        params: {email_starts_with: emailStartsWith},
        cancelToken: cancelToken,
    });
}
