import React, {useEffect} from "react";
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import {useParams} from 'react-router-dom';
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import SavedCardsList from "../components/payments/SavedCardsList";
import axios from "axios";
import {useAuthState} from "../auth/auth";
import {Box} from "@mui/material";
import {Paper} from "@mui/material";

const useStyles = makeStyles((theme) => ({}));

const EditCompanyPaymentInfoPage = () => {
    // Make sure to call loadStripe outside of a component’s render to avoid
    // recreating the Stripe object on every render.
    // loadStripe is initialized with your real test publishable API key.
    const classes = useStyles();
    const {authState} = useAuthState();
    const {companyId} = useParams();
    const headers = authState?.headers;

    const [clientSecret, setClientSecret] = React.useState(null);
    const [stripe, setStripe] = React.useState(null);

    useEffect(() => {
        if (!authState?.isAuthenticated) {
            return
        }

        axios.post(`/api/company/${companyId}/payment/methods/setup`, {}, {headers: headers})
            .then(res => {
                return res.data;
            })
            .then(data => {
                setClientSecret(data.clientSecret);
                loadStripe(data.publicKey)
                    .then(stripe => setStripe(stripe))
            })
            .catch(err => console.error(err));
    }, [headers]); // Update if authState changes

    const loaded = !!(stripe && clientSecret);

    return (
        <Box sx={{mb: 4, height: '100%', overflowY:'scroll', maxWidth: 1000, color: 'text.primary', margin: "0 auto !important"}}>
            <Typography sx={{pb: 4, fontWeight: 700, color: 'text.primary'}} component="h1" variant="h4" align="center">
                Billing Info
            </Typography>
            <Paper sx={{p: 1,mb:4, borderRadius: '20px'}}>
                {
                    loaded && (
                        <Elements stripe={stripe} options={{clientSecret: clientSecret}}>
                            <SavedCardsList/>
                        </Elements>
                    )
                }
                <Box sx={{pl: 1, pb: 2, pt: 1}}>
                    <Typography component="span" sx={{fontSize: 13, fontWeight: 500}}>Note:&nbsp;</Typography>
                    <Typography
                        component="span"
                        sx={{fontSize: 13}}
                    >
                        Credit Cards on file will only be used to
                        process payment for successfully hired referrals.
                    </Typography>
                </Box>
            </Paper>
        </Box>

    );
};

export default EditCompanyPaymentInfoPage;
