import React, {createContext, useContext, useEffect, useState} from 'react'
import {Route, useHistory, useLocation, useParams} from "react-router-dom";
import Login from "../pages/Login";
import {oktaAuthConfig} from "./config";
import {OktaAuth} from '@okta/okta-auth-js';
import api from "../api";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import {usePrevious} from "react-use";
import _ from "lodash";
import axios from 'axios';

const AuthContext = createContext(null);

const oktaAuth = new OktaAuth(oktaAuthConfig);

export const Auth = (props) => {

    // use the right client ID and domain depending on environment
    const npeDomain = "dev-kv2ni6w1.us.auth0.com"
    const npeClientId = "CpBJn7aw1lYgH9lnNvx2S6jaOqheIoXT"
    const prodDomain = "login.pophire.io"
    const prodClientId = "gCkVs0chOrEg2sDez60shi8zfqNYGnQN"
    let hostname = window.location.hostname.toLowerCase();
    const isProd = hostname.startsWith("app") || hostname.startsWith("prod");
    let domain = isProd ? prodDomain : npeDomain;
    let clientId = isProd ? prodClientId : npeClientId;

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            audience="https://pophire.io/api" //"https://dev-kv2ni6w1.us.auth0.com/api/v2/" "https://pophire.io/api"
            redirectUri={window.location.origin}
            scope="openid profile email offline_access"
            useRefreshTokens={true}
            cacheLocation= 'localstorage'
        >
            <AuthContextProvider>
                {props.children}
            </AuthContextProvider>
        </Auth0Provider>
    );
};

const useAuth0AuthState = () => {
    const [authState, setAuthState] = useState(null);

    const authContext = useAuth0();
    const location = useLocation();

    //const oktaAuthState = oktaContext..authState;
    //const oktaAuth = oktaContext.oktaAuth;

    const isAuthenticated = authContext?.isAuthenticated;

    // const idToken = authContext.g?.idToken?.idToken;
    // const nonce = oktaAuthState?.idToken?.claims?.nonce;

    if (isAuthenticated === true) {
        //set original uri for okta login redirect;
        // if (location.pathname !== "/login") {
        //     oktaAuth.setOriginalUri(location.pathname)  //used by okta to redirect after login
        // }
    }

    var accessToken = null;

    useEffect(() => {
        (async () => {
            if (authContext === null || authContext.isLoading) {
                return;
            }
            // not all pages require login so lets bow out if user isn't logged in
            if (authContext.isAuthenticated === false) {
                setAuthState({
                    loading: false,
                    headers: {},
                    signIn: (opts) => authContext.loginWithRedirect(opts)
                });
                return;
            }
            try {
                let claimsPromise = authContext.getIdTokenClaims();
                let accessTokenPromise = authContext.getAccessTokenSilently();
                let values = await Promise.all([claimsPromise, accessTokenPromise]);
                let claims = values[0];
                let accessToken = values[1];
                // let refreshToken
                setAuthState({
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        // x: 'y'
                    },
                    idTokeClaims: claims,
                    loading: false,

                    signOut: () => authContext.logout({returnTo: window.location.origin}),
                    signIn: (opts) => authContext.loginWithRedirect(opts),
                    refreshTokens: async () => {
                        let accessToken = await authContext.getAccessTokenSilently();
                        //headers.Authorization = `Bearer ${accessToken}`
                    }
                });
            } catch (e) {
                console.log("Error getting IdTokeClaims");
                console.log(e);
                setAuthState({loading: false});
            }
        })();
    }, [authContext]);

    return authState;
};

export const AuthContextProvider = (props) => {
    const authContext = useAuth0();
    const [authState, setAuthState] = useState({
        isAuthenticated: null,
        headers: {},
        signOut: () => {
        },
        signIn: (opts) => authContext.loginWithRedirect(opts),

        user: null,
        userCompanies: [],
        currUserCompany: null,

        error: null,
    });
    const [loading, setLoading] = useState(true);

    const saveAuthState = (newAuthState) => {
        setAuthState((prevAuthState) => ({
            ...prevAuthState, ...newAuthState
        }));
    };

    const auth0AuthState = useAuth0AuthState();
    const prevHeaders = usePrevious(auth0AuthState?.headers);
    axios.interceptors.response.use(
        (response) => response,
        async (error) => {
          const config = error?.config;
      
          if (error?.response?.status === 403 && !config?.sent) {
            config.sent = true;
            // const {saveAuthState} = useAuthState();
            // saveAuthState()    
            //const authContext = Auth0Provider();
            const result = await authContext.getAccessTokenSilently()
      
            if (result) {
                saveAuthState({headers:{Authorization: `Bearer ${result}`}});
              config.headers = {
                ...config.headers,
                Authorization: `Bearer ${result}`,
              };
            }
      
            return axios(config);
          }
          return Promise.reject(error);
        }
      );

    useEffect(() => {
        (async () => {
            const headers = auth0AuthState?.headers;
            
            if (!auth0AuthState || _.isEqual(prevHeaders, headers)) {
                return;
            }

            setLoading(true);

            await api.users.meRetry(headers)
                .then((response) => {
                    if (response.data.user === null) {
                        saveAuthState({
                            isAuthenticated: null,
                            headers: headers,
                            signOut: () => auth0AuthState.signOut({returnTo: window.location.origin}),
                            signIn: (opts) => auth0AuthState.signIn(opts),
                            refreshTokens: async () => {
                                let accessToken = await authContext.getAccessTokenSilently();
        
                            },
                            user: null,
                            userCompanies: null,

                            error: null,
                        });
                    } else {
                        let u = {...response.data.user};
                        saveAuthState({
                            isAuthenticated: true,
                            headers: headers,
                            signOut: auth0AuthState.signOut,
                            signIn: auth0AuthState.signIn,
                            idTokeClaims: auth0AuthState.idTokeClaims,
                            refreshTokens: async () => {
                                let accessToken = await authContext.getAccessTokenSilently();
        
                            },
                            user: u,
                            userCompanies: response.data.user_companies,

                            error: null,
                        });
                    }
                })
                .catch(response => {
                    const message = response?.response?.data?.error?.message;

                    saveAuthState({
                        isAuthenticated: false,
                        headers: {},
                        signOut: () => {
                        },
                        signIn: () => {
                        },

                        user: null,
                        userCompanies: [],

                        error: `Auth failed; Cause: ${message}`
                    });
                })
                .finally(() => setLoading(false));
        })();
    }, [auth0AuthState, prevHeaders]);

    return (
        <AuthContext.Provider value={{authState: {...authState, loading}, saveAuthState}}>
            {props.children}
        </AuthContext.Provider>
    );
};

export const useAuthState = () => {
    return useContext(AuthContext);
};

export const withAuthState = WrappedComponent => (props) => {
    const {authState, saveAuthState} = useAuthState();
    
    return (
        <WrappedComponent
            {...props}
            authState={authState}
            saveAuthState={saveAuthState}
        />
    );
};

export const AuthRoute = ({component: Component, ...rest}) => {
    const {authState} = useAuthState();

    return (
        <Route
            {...rest}
            render={(props) => {
                if (authState.isAuthenticated === true) {
                    return <Component {...props} />;
                } else if (authState.loading) {
                    return (
                        <Box sx={{display: 'flex', justifyContent: "space-around", height: '90vh', alignItems: 'center'}}>
                            <CircularProgress size={80}/>
                        </Box>
                    );
                } else {
                    return <Login/>;
                }
            }}
        />
    );
};

export const CurrUserCompanySetter = (props) => {
    const {authState, saveAuthState} = useAuthState();
    const location = useLocation();
    const {companyId} = useParams();
    const history = useHistory();

    const userCompanies = authState?.userCompanies;
    const isAuthenticated = authState?.isAuthenticated;

    useEffect(() => {
        const currUserCompany = userCompanies?.find(userCompany => userCompany.company_id.toString() === companyId);

        saveAuthState({currUserCompany: currUserCompany})

        if (isAuthenticated && currUserCompany === undefined) {
            history.push('/company');
        }
    }, [companyId, location, userCompanies]);

    return null;
}

export const ClearUserCompany = (props) => {
    const {saveAuthState} = useAuthState();
    const location = useLocation();

    const pathname = location.pathname;

    useEffect(() => {
        saveAuthState({currUserCompany: null})
    }, [pathname]);

    return null;
}


