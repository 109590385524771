import React, {useEffect} from 'react';
import {debounce} from "lodash";

const AutoSave = ({formik, debounceMs = 1000}) => {
    const debouncedSubmit = React.useMemo(
        () => debounce(() => formik.submitForm(), debounceMs),
        [debounceMs, formik],
    );

    useEffect(
        () => {
            if (formik.status === undefined || formik.status.state === "touched") {
                debouncedSubmit()
            }
        },
        [formik.status]
    );

    return (<React.Fragment/>);
};

export default AutoSave;
