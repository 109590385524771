import React from 'react';
import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import {centsTo$} from '../../../../shared/money';

import CompanyHeader from './CompanyHeader';
import CollapseText from '../../../../shared/Collapse/CollapseText';
import EmbeddedMedia from '../../../../shared/EmbeddedMedia/EmbeddedMedia';
import Grid from "@mui/material/Grid";

const ReferralMainCard = ({position}) => {
    const {position_content_published} = position;
    const {manager_note, manager_note_url} = position_content_published;
    const salaryFrom = centsTo$(position_content_published.salary_cents_from, "from");
    const salaryTo = centsTo$(position_content_published.salary_cents_to, "to");
    const hasNotes = !!(manager_note || manager_note_url);

    return (
        <Card variant="outlined" sx={{mt: 2}}>
            <CardContent sx={{pl: 5, pr: 5, py: 4}} style={{paddingBottom: 16}}>
                <CompanyHeader company={position.company} positionTitle={position.title}/>
                <Grid container sx={{pb: 2, pt: 3}} spacing={2} alignContent="flex-start" alignItems="flex-start">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {salaryFrom === "" && salaryTo === "" ? "" : `Salary ${salaryFrom} ${salaryTo}`}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography sx={{fontWeight: 400, color: 'text.primary'}} component="h1" variant="h6">About the Job</Typography>
                <CollapseText
                    text={position_content_published.description}
                    length={300}
                />
                {
                    hasNotes && (
                        <React.Fragment>
                            <Typography
                                sx={{fontWeight: 400, color: 'text.primary'}}
                                component="h1"
                                variant="h6"
                            >
                                Notes from Manager
                            </Typography>
                            {manager_note_url && <EmbeddedMedia src={manager_note_url}/>}
                            {manager_note && <CollapseText text={manager_note} length={300}/>}
                        </React.Fragment>
                    )
                }
            </CardContent>
        </Card>
    )
}

export default ReferralMainCard;
