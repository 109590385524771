import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import {DataGrid, GRID_DEFAULT_LOCALE_TEXT, GridToolbarContainer} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Skeleton from '@mui/material/Skeleton';
import Button from "@mui/material/Button";
import {useMutation} from "react-query";
import AddCompanyCrewMemberDialog from "./AddCompanyCrewMemberDialog";
import UpdateCompanyCrewMemberDialog from "./UpdateCompanyCrewMemberDialog";
import {useParams} from "react-router-dom";
import api from "../../api";
import {useAuthState} from "../../auth/auth";

const useStyles = makeStyles((theme) => (
        {
            dataGrid: {
                height: '40vh',
                padding: theme.spacing(2),
            },
            gridToolbar: {
                placeContent: 'space-between',
                marginBottom: theme.spacing(1)
            },
        }
    )
);

export const roles = [{id: 1, value: "Owner", displayName: "Super Admin"}, {id: 2, value: "Admin", displayName:"Admin"}, {id: 3, value: "Member", displayName:"Member"}];

const CompanyCrewInfoTable = React.memo((props) => {
    const classes = useStyles();
    const {authState} = useAuthState();
    const headers = authState?.headers;
    
    const {
        rows, columns,
        isPending,
        loadCompanyUsers,
        companyName,
        ...other
    } = props;

    const handleOpenInviteCompanyUserDialog = () => {
        setOpenInviteCompanyUser(true);
    };

    const [openInviteCompanyUser, setOpenInviteCompanyUser] = React.useState(false);
    const [openEditCompanyUser, setOpenEditCompanyUser] = React.useState(false);
    const [selectedUserToEdit, setSelectedUserToEdit] = React.useState(null);

    const handleCloseInviteCompanyUserDialog = () => {
        setOpenInviteCompanyUser(false);
    };

    const handleCloseEditCompanyUserDialog = () => {
        setOpenEditCompanyUser(false);
    };

    const {companyId} = useParams();

    const inviteCompanyUser = (newUserEmail, newUserRole) =>{
        api.company.inviteUser({"email":newUserEmail, "role":newUserRole}, companyId, headers).then(data => {
            loadCompanyUsers();
        }).catch(err => {
          console.error(err);
        });
        // onError: (error, variables, context) => {
        //     console.log(error)
        // },
        // onSuccess: (data, variables, context) => {
        //     loadCompanyUsers();
        // }
    }
    const updateCompanyUser = (userId, newUserRole) =>{
        api.company.updateUser({"id":userId, "role":newUserRole}, companyId, headers).then(data => {
            loadCompanyUsers();
        }).catch(err => {
          console.error(err);
        });
        // onError: (error, variables, context) => {
        //     console.log(error)
        // },
        // onSuccess: (data, variables, context) => {
        //     loadCompanyUsers();
        // }
    }

    const deleteCompanyUser = (userCompanyId) => api.company.deleteUser(companyId, userCompanyId, headers).then(data => {
        loadCompanyUsers();
    }).catch(err => {
      console.error(err);
    });


    const CustomToolbar = () => (
        <GridToolbarContainer className={classes.gridToolbar}>
            <Typography variant="h6" gutterBottom>{companyName} Recruiting Team</Typography>
            <Button size="small" sx={{bgcolor:"success.main", color:"#ffffff"}} onClick={handleOpenInviteCompanyUserDialog}>Add Team Members</Button>
            <AddCompanyCrewMemberDialog
                {...other}
                openDialog={openInviteCompanyUser}
                handleCloseDialog={handleCloseInviteCompanyUserDialog}
                inviteCompanyUser={inviteCompanyUser}
            />
            <UpdateCompanyCrewMemberDialog
                {...other}
                openDialog={openEditCompanyUser}
                handleCloseDialog={handleCloseEditCompanyUserDialog}
                updateCompanyUser={updateCompanyUser}
                deleteCompanyUser={deleteCompanyUser}
                selectedUser={selectedUserToEdit}
            />
        </GridToolbarContainer>
    );


    if (isPending) {
        return <Skeleton variant="rectangular" className={classes.dataGrid}/>;
    }

    return (
        <DataGrid
            className={classes.dataGrid}
            rows={rows}
            columns={columns}
            hideFooter
            headerHeight={56}
            rowHeight={52}
            columnBuffer={10}
            sortingOrder={['asc', 'desc', null]}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
            density="compact"
            disableColumnMenu = {true}
            onCellClick={(value) => {
                setSelectedUserToEdit(value.row);
                setOpenEditCompanyUser(true);
            }}
            components={{
                Toolbar: CustomToolbar,
            }}
        />
    );
});

export default CompanyCrewInfoTable
