import React from "react";
import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AddIcon from "@mui/icons-material/Add";
import CardActionArea from "@mui/material/CardActionArea";

const AddNewCard = ({onAddNewCard}) => {
    return (
        <Card sx={{width: 260, height: 170}}>
            <CardActionArea onClick={onAddNewCard}>
                <CardContent sx={{bgcolor: '#e1edfa', display: "grid", justifyItems: "center"}}>
                    <AddIcon color="primary" sx={{height: 40, width: 40}}/>
                    <Typography color="primary" gutterBottom variant="h6">
                        Add New Card
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default AddNewCard;
