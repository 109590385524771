import PropTypes from 'prop-types'
import React from 'react';
import Grid from '@mui/material/Grid';
import {Box} from '@mui/system';
import Typography from "@mui/material/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink} from "@fortawesome/free-solid-svg-icons";
import CallIcon from "@mui/icons-material/Call";
import NumberFormat from "react-number-format";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import {Link} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useTheme} from "@mui/material/styles";
import {getLinkIcon} from "../Company/SelectedReferralGrid";

const ReferrerInfoDisplay = (props) => {
    const {referrerIsCandidate, referrerName, referrerPhone, referrerEmail, referrerUrl, aboutReferrerText} = props;

    const theme = useTheme();

    return (
        <Paper variant="outlined" sx={{mt: 2, display: referrerIsCandidate ? "none" : "block"}}>
            <Typography
                sx={{
                    fontSize: 18,
                    fontWeight: 600,
                    pl: 2,
                    pt: 3,
                }}
            >
                About Referrer
            </Typography>
            {/* #fbeeec */}
            <Box>
                <Box sx={{color: "black", fontWeight: 500, p: 2}}>
                    <FontAwesomeIcon icon={faLink} color="#90caf9"/>
                    &nbsp;&nbsp;
                    {referrerName.toUpperCase()}
                </Box>
                <Box sx={{bgcolor: theme.palette.linkedin.light}}>
                    <Grid
                        container
                        // justifyContent="space-around"
                        sx={{fontSize: 13, py: 1}}
                        columnSpacing={1}
                        rowSpacing={1}
                    >
                        {
                            referrerPhone && (
                                <Grid item xs={12} md={4}>
                                    <Box sx={{display: "flex", placeContent: "center", textAlign: "center"}}>
                                        <CallIcon
                                            color="primary"
                                            sx={{mr: 1, verticalAlign: 'text-top'}}
                                            fontSize="small"
                                        />
                                        <NumberFormat
                                            format="(###) ### ####"
                                            displayType="text"
                                            value={referrerPhone}
                                        />
                                    </Box>
                                </Grid>
                            )
                        }
                        <Grid item xs={12} md={4}>
                            <Box sx={{display: "flex", placeContent: "center", textAlign: "center"}}>
                                <AlternateEmailIcon
                                    color="primary"
                                    sx={{mr: 1, verticalAlign: 'text-top'}}
                                    fontSize="small"
                                />
                                <Link href={`mailto:${referrerEmail}`}>{referrerEmail}</Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{display: "flex", placeContent: "center", textAlign: "center", textOverflow:"ellipsis"}}>
                                {getLinkIcon(referrerUrl, true, theme)}
                                <Link download
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={referrerUrl}
                                >
                                    {referrerUrl}
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{p: 2, display: aboutReferrerText ? "block" : "none"}}>
                <Box sx={{pt: 1}} dangerouslySetInnerHTML={{__html: aboutReferrerText}}></Box>
            </Box>
        </Paper>
    );
}

export default ReferrerInfoDisplay

ReferrerInfoDisplay.propTypes = {
    aboutReferrerText: PropTypes.string,
    referrerEmail: PropTypes.string,
    referrerIsCandidate: PropTypes.bool,
    referrerName: PropTypes.string,
    referrerPhone: PropTypes.string,
    referrerUrl: PropTypes.string
}
