import React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from "@mui/material/Snackbar";
import PositionForm from "../components/Position/PositionForm";
import {withRouter} from "react-router-dom";
import api from "../api";
import {withAuthState} from "../auth/auth";
import {withTheme} from "@emotion/react";
import StyledSkeleton from "../shared/Skeleton/StyledSkeleton";

const centsBackToFront = (value) => {
    if (value === null) {
        return null
    }

    return value / 100;
}

const dateBackToFront = (value) => {
    if (value === null) {
        return null
    }

    return new Date(value);
}

const stringBackToFront = (value) => {
    if (value === null) {
        return "";
    }

    return value;
}

export const positionContentBackToFront = (position) => {
    let position_content_draft = position.position_content_draft
    const position_content_published = position.position_content_published
    const form = {};
    if (position_content_draft == null)
        position_content_draft = position_content_published; // this has to be a terrible idea
    form.id = position_content_draft.id;

    form.published_at = dateBackToFront(position.published_at);

    form.title = stringBackToFront(position_content_draft.title);
    form.description = stringBackToFront(position_content_draft.description);
    form.description_short_plain = stringBackToFront(position_content_draft.description_short_plain);

    form.salary_from = centsBackToFront(position_content_draft.salary_cents_from);
    form.salary_to = centsBackToFront(position_content_draft.salary_cents_to);

    form.manager_note = stringBackToFront(position_content_draft.manager_note);
    form.manager_note_url = stringBackToFront(position_content_draft.manager_note_url);

    form.open_at = dateBackToFront(position_content_draft.open_at);
    form.close_at = dateBackToFront(position_content_draft.close_at);

    form.cold_award = centsBackToFront(position_content_draft.cold_award_cents);
    form.cold_award_persisted = position_content_published ? centsBackToFront(position_content_published.cold_award_cents) : 0;
    form.warm_award = centsBackToFront(position_content_draft.warm_award_cents);
    form.warm_award_persisted = position_content_published ? centsBackToFront(position_content_published.warm_award_cents) : 0;

    form.referral_type = position_content_draft.referral_type;
    form.payout_description = position_content_draft.payout_description;

    const featuredImageTemplateData = position_content_draft.featured_image_template_data;
    form.featured_image_template_data = featuredImageTemplateData ? JSON.parse(featuredImageTemplateData) : "";

    form.featured_image_file = position_content_draft.featured_image_file

    // form.questions = position_content.questions.map(question => mapValues(question, value => stringBackToFront(value)));

    return form;
}

class CreateOrEditPositionPage extends React.Component {
    constructor(props) {
        super(props);
        // this.debouncedPersistChanges = debounce(::this.persistChanges, 500 /* ms */);
        this.state = {
            showSnackbar: true,
            form: {
                id: "",
                position_content_id: "",
                title: "",
                description: "",
                description_short_plain: "",
                salary_from: null,
                salary_to: null,
                manager_note: "",
                manager_note_url: "",
                open_at: null,
                close_at: null,
                cold_award: "",
                warm_award: "",
                payout_description: 0,
                questions: [],
                is_published: false,
                featured_image_url: "",
                featured_image_template_data: "",
                referral_type: 1
            },
            loaded: false,
            loading: false,
            loadingError: null,
        }
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.state.loaded) {
            return;
        }

        this.loadPosition();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this._isMounted && !this.state.loaded) {
            this.loadPosition();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    // reLoadPosition = () => {
    //     this.setState((prevState) => ({...prevState, loaded: false,}));
    // }

    loadPosition = () => {
        if (this.state.loading || this.state.loaded || this.state.loadingError) {
            return;
        }

        const positionId = this.props.match.params.positionId;
        let companyId = this.props.match.params.companyId;

        this.setState((prevState) => ({...prevState, loading: true}));

        const headers = this.props.authState.headers;

        api.positions.loadWithContent(companyId, positionId, headers)
            .then(resp => {
                const form = positionContentBackToFront(resp.data.position);

                this.setState((prevState) => (
                    {
                        ...prevState,
                        form: {...prevState.form, ...form},
                        loaded: true,
                    }
                ));
            })
            .catch(err => {
                this.setState((prevState) => ({...prevState, loadingError: err}));
                console.error(err)
            })
            .then(() => this.setState((prevState) => ({...prevState, loading: false})));
    }

    renderAlert() {
        if (this.state.loadingError) {

            let alert;

            if (this.state.loadingError?.response?.data?.error) {
                const {context, message} = this.state.loadingError.response.data.error;
                alert = <Alert severity="error" variant="filled">{`${context}: ${message}`}</Alert>
            } else {
                alert = <Alert severity="error" variant="filled">Failed to load Position</Alert>
            }

            return (
                <Snackbar open={this.state.loadingError != null} anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
                    {alert}
                </Snackbar>
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.loaded
                        ? <PositionForm form={this.state.form}/>
                        : <StyledSkeleton/>
                }
                {this.renderAlert()}
            </React.Fragment>
        );
    }
}

export default withAuthState(withRouter(withTheme(CreateOrEditPositionPage)));
