import React from 'react';
import "yup-phone";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const ReferrerIsCandidateSubmittedContent = (props) => {
    const {candidateName, companyName, positionName,} = props;

    return (
        <React.Fragment>
            <CardHeader
                title={<Typography variant="h4">Thank you {candidateName}, for your interest in the {positionName} position at {companyName}!</Typography>}
                subheader={<Typography variant="h6" sx={{mt:2}} color="textSecondary">Submission confirmation number has been sent to your email</Typography>}
            >
            </CardHeader>
            <CardContent>
                <Typography variant="subtitle1">
                    You will receive a notification when your application has been processed.
                </Typography>
            </CardContent>
        </React.Fragment>
    )
}

export default ReferrerIsCandidateSubmittedContent;
