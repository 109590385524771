import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCcAmex, faCcMastercard, faCcVisa} from "@fortawesome/free-brands-svg-icons";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {useTheme} from '@mui/material/styles';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {useAuthState} from "../../auth/auth";
import Chip from '@mui/material/Chip';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {CardActions} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

const CardInfo = (props) => {
    const theme = useTheme();
    let {companyId} = useParams();
    const {authState} = useAuthState();
    const headers = authState?.headers;
    const deletePayment = (paymentId) => {
        axios.delete(`/api/company/${companyId}/payment/methods/${paymentId}`, {headers: headers})
            .then(res => res.data)
            .then(data => props.onRemove(paymentId))
            .catch(err => console.log(err));
    }

    const getLogo = () => {
        if (props.card.cardType.toLowerCase() === "visa")
            return <FontAwesomeIcon icon={faCcVisa} color={theme.palette.primary.main}/>
        if (props.card.cardType.toLowerCase() === "amex")
            return <FontAwesomeIcon icon={faCcAmex}/>
        if (props.card.cardType.toLowerCase() === "mastercard")
            return <FontAwesomeIcon icon={faCcMastercard}/>
    }
    const getDefaultCardComponent = () => {
        // I know, it's bad
        if (props.onSetDefault == null && !props.card.default)
            return ""
        else {
            return !props.card.default
                ? <Button component="button" sx={{color: "#0965c2"}} onClick={() => makeDefault(props.card.id)}>Make default</Button>
                : <Chip sx={{mr: 2}} style={{backgroundColor: "#0965c2", color: "white"}} label={<Typography>Default</Typography>}/>
        }
    }

    function selectCard() {
        if (props != null && props.onClick != null)
            props.onClick(props.card.id);
    }

    const makeDefault = (paymentId) => {
        axios.post(`/api/company/${companyId}/payment/methods/${paymentId}/set_default`, {}, {headers: headers}).then(res => {
            return res.data;
        })
            .then(data => {
                props.onSetDefault(paymentId)
            }).catch(err => {
            console.log(err);
        });
    }

    return (
        <Card sx={{width: 260, marginBottom:'10px', height: 170, ':hover': {
            bgcolor: '#4F916B', cursor: 'pointer'
        }}} onClick={() => {selectCard();}}>
            <CardContent sx={{bgcolor: '#e1edfa', display: "grid"}}>
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", pr: 1}}>
                    <Typography variant="h4">
                        <Box m={1}>{getLogo()}</Box>
                    </Typography>
                    <Typography m={1} variant="contained">**** **** **** {props.card.last4}</Typography>
                </Box>
                <Typography sx={{textAlign: "right", pr: 2}} variant="contained">{props.card.expiresAt}</Typography>
            </CardContent>
            <CardActions sx={{justifyContent: "right", p: 1}}>
                {getDefaultCardComponent()}
                {props.onSetDefault == null ? "" : <IconButton onClick={() => deletePayment(props.card.id)}><DeleteOutlineIcon/></IconButton>}
            </CardActions>
        </Card>
    );
};
;

export default CardInfo;
