import PropTypes from 'prop-types'
import React from "react";
import ReactQuill from "react-quill";
import makeStyles from "@mui/styles/makeStyles";
import FormHelperText from "@mui/material/FormHelperText";

const useStyles = makeStyles((theme) => (
        {
            customErrorBorder: {
                '& .ql-container': {
                    border: 'none',
                },
                '& .ql-editor': {
                    borderTop: '1px solid red !important',
                    border: '1px solid red',
                    padding: '12px',
                    borderBottomRightRadius: '4px',
                    borderBottomLeftRadius: '4px',
                },
                '& .ql-toolbar': {
                    borderTopRightRadius: '4px',
                    borderTopLeftRadius: '4px',
                },
            },
            customBorder: {
                '& .ql-container': {
                    border: 'none',
                },
                '& .ql-editor': {
                    padding: '12px',
                    border: '1px solid #ccc',
                    borderBottomRightRadius: '4px',
                    borderBottomLeftRadius: '4px',
                },
                '& .ql-toolbar': {
                    borderTopRightRadius: '4px',
                    borderTopLeftRadius: '4px',
                },
                '& .ql-editor:hover': {
                    padding: '12.25px',
                    border: '0.5px solid #1F235B !important',
                },
                '& .ql-editor:focus': {
                    padding: '11px',
                    border: '2px solid #1F235B !important',
                },
            }
        }
    )
);

const defaultModules = {
    toolbar: [
        [{'header': [1, 2, false]}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
    ],
};

const defaultFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
];

const StyledReactQuill = (props) => {
    const classes = useStyles();

    const {disabled, isError, errorMessage, defaultValue, theme = "snow", onChange, onBlur, onSelectionChange, placeholder, modules = defaultModules, formats = defaultFormats, ...other} = props;

    return (
        <React.Fragment>
            <ReactQuill
                {...other}
                readOnly={disabled}
                className={isError ? classes.customErrorBorder : classes.customBorder}
                theme={theme}
                defaultValue={defaultValue}
                onChange={onChange}
                onChangeSelection={onSelectionChange}
                onBlur={onBlur}
                modules={modules}
                formats={formats}
                placeholder={placeholder}
            />
            <FormHelperText sx={{mx: 2, display: isError ? "block" : "none"}} error={isError}>{errorMessage}</FormHelperText>
        </React.Fragment>
    );
};

export default StyledReactQuill;

StyledReactQuill.propTypes = {
    formats: PropTypes.array,
    isError: PropTypes.bool,
    disabled: PropTypes.bool,
    modules: PropTypes.object,
    onChange: PropTypes.func,
    theme: PropTypes.string,
    errorMessage: PropTypes.string,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
}
