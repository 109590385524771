import FormControl from "@mui/material/FormControl";
import {InputLabel, NativeSelect} from "@mui/material";
import {getGridSingleSelectOperators} from "@mui/x-data-grid";
import React from "react";


const StatusInputValue = (props) => {

    const {item, applyValue} = props;

    const handleFilterChange = (event) => {
        const value = event.target.value;

        let noHire = false;
        let specValue = value;

        if (value === "-1") {
            specValue = "";
            noHire = true;
        }

        applyValue(
            {
                ...item,
                value: specValue,
                extra: [{
                    columnField: "no_hire",
                    type: "boolean",
                    value: String(noHire),
                    operatorValue: "is",
                    joinOperator: 'and'
                }],
            }
        );
    };

    return (
        <FormControl fullWidth>
            <InputLabel variant="standard" shrink>Values</InputLabel>
            <NativeSelect onChange={handleFilterChange} defaultValue={item.value}>
                <option value="">ANY</option>
                <option value={-1}>REJECTED</option>
                <option value={2}>NEW</option>
                <option value={3}>IN PROGRESS</option>
                <option value={4}>HIRE</option>
                <option value={5}>PARTIALLY PAID</option>
                <option value={6}>PAID</option>
            </NativeSelect>
        </FormControl>
    );
};

export const getStatusFilterOperators = () => {
    return getGridSingleSelectOperators().map(
        (operator) => ({
            ...operator,
            InputComponent: StatusInputValue,
        }),
    );
};
