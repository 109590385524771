import React, {Fragment, useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import {Divider, Input, ListItemText} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Box from "@mui/material/Box";
import {useAuthState} from "../../../../auth/auth";
import api from "../../../../api";
import {useTheme} from "@emotion/react";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import {userWithDate} from "../../../../shared/User/User";

const ReferralNotes = (props) => {
    const {
        reloadNotes,
        companyId, positionId, referralId
    } = props;

    const theme = useTheme();

    const [notes, setNotes] = useState([]);
    const [loadingNotes, setLoadingNotes] = useState(false);
    const [note, setNote] = useState("");
    const [savingNote, setSavingNote] = useState(false);

    const {authState} = useAuthState();
    const headers = authState?.headers;

    const saveNote = () => {
        const content = note.trim()
        if (savingNote || content === "") {
            return;
        }

        setSavingNote(true);

        api.referrals.addNote(companyId, positionId, referralId, content, headers)
            .then(resp => {
                setNote("");
                setNotes(resp.data.referral_notes);
            })
            .catch((err) => alert(err.toString()))
            .finally(() => setSavingNote(false));
    };

    useEffect(() => {
        if (loadingNotes || !referralId) {
            return;
        }

        setLoadingNotes(true);

        api.referrals.loadNotes(companyId, positionId, referralId, headers)
            .then(resp => setNotes(resp.data.referral_notes))
            .catch((err) => alert(err.toString()))
            .finally(() => setLoadingNotes(false));
    }, [referralId, reloadNotes])

    return (
        <Fragment>
            <Box sx={{p: 2, pt: 0}}>
                <Typography variant="h6">Notes</Typography>
                <List
                    sx={{
                        width: '100%',
                        backgroundColor: theme.palette.background.paper,
                        px: 1,
                        py: 0,
                        maxHeight: 300,

                        overflowY: 'auto',
                        overscrollBehaviorY: 'contain',
                        scrollSnapType: 'y',
                    }}
                    dense
                >
                    {notes.map((note) => (
                        <React.Fragment key={note.id}>
                            <ListItem sx={{py: 0}}>
                                <ListItemText primaryTypographyProps={{sx: {wordBreak: "break-word"}}} primary={note.content}
                                              secondary={userWithDate(note?.user, note.created_at)}/>
                            </ListItem>
                            <Divider/>
                        </React.Fragment>
                    ))}
                    <FormControl fullWidth variant="standard">
                        <Input
                            id="referral-add-note"
                            sx={{py: 2}}
                            fullWidth
                            multiline
                            onChange={(event) => setNote(event.target.value)}
                            value={note}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        disableRipple
                                        aria-label="add note"
                                        onClick={saveNote}
                                    >
                                        <AddCircleRoundedIcon sx={{color: 'action.active', my: 0.5}}/>
                                    </IconButton>
                                </InputAdornment>
                            }
                            aria-describedby="referral-add-note-helper-text"
                            inputProps={{
                                'aria-label': 'note',
                            }}
                        />
                        <FormHelperText id="referral-add-note-helper-text">Note</FormHelperText>
                    </FormControl>
                    <Box sx={{scrollSnapAlign: 'end'}}/>
                </List>
            </Box>
        </Fragment>
    );
};

export default ReferralNotes;
