
export const list = (history, companyId) => {
    return history.push(`/company/${companyId}/positions`)
}

export const preview = (history, companyId, positionId) => {
    return history.push(`/company/${companyId}/positions/${positionId}/preview`)
}

export const edit = (history, companyId, positionId) => {
    return history.push(`/company/${companyId}/positions/${positionId}`)
}
