import React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {PositionStatusLabel} from "./PositionStatusLabel";
import Paper from '@mui/material/Paper';
import CustomToolbar from "../../../shared/DataGrid/Toolbar/CustomToolbar";
import Button from "@mui/material/Button";
import {Box, IconButton, Popover} from "@mui/material";
import {useAuthState} from "../../../auth/auth";
import PositionActions from "./PositionActions";
import CustomNoRowsOverlay from '../../../shared/DataGrid/Overlay/CustomNoRowsOverlay';
import makeStyles from '@mui/styles/makeStyles';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CustomLoadingOverlay from "../../../shared/DataGrid/Overlay/CustomLoadingOverlay";

const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-iconSeparator': {
            color: '#737574',
        },
    },
});

export const positionColumns = [
    {
        field: 'id',
        headerName: 'Actions',
        type: 'number',
        headerClassName: 'position-header',
        width: 80,
        align: 'center',
        sortable: false,
        filterable: false,
        renderCell: (params) => {
            return (
                <Box>
                    <IconButton aria-label="actions">
                        <MenuRoundedIcon/>
                    </IconButton>
                </Box>
            )
        },
    },
    {
        field: 'title',
        headerName: 'Position Name',
        type: 'string',
        headerClassName: 'position-header',
        flex: 1,
        minWidth: 240,
        sortable: true,
    },
    {
        field: 'status',
        headerName: 'Status',
        type: 'number',
        headerClassName: 'position-header',
        width: 150,
        sortable: true,
        headerAlign: 'center',
        align: 'center',
        margin: 0,
        renderCell: (params) => {
            return (
                <PositionStatusLabel status={params.row.status}/>
            )
        },
    },
    {
        field: 'submitted_referrals_count',
        headerName: 'Total Referrals',
        align: 'center',
        type: 'number',
        headerClassName: 'position-header',
        headerAlign: 'center',
        // flex: 0.1,
        minWidth: 120,
        sortable: true,
    },
    {
        field: 'assigned_referrals_count',
        headerName: 'New Referrals',
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        headerClassName: 'position-header',
        minWidth: 180,
        sortable: true,
        valueGetter: (params) => params.row.submitted_referrals_count - params.row.assigned_referrals_count
    },
    {
        field: 'featured_image_url',
        headerName: 'Published Link Preview Image',
        type: 'bool',
        headerClassName: 'position-header',
        align: 'center',
        minWidth: 250,

        sortable: false,
        renderCell: (params) => {
            return (
                <Box
                    component='img'
                    src={params.row.featured_image_url}
                    sx={{
                        pt: 1, pb: 1,
                        height: '70px',
                        alignSelf: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: "center"
                    }}
                />
            )
        },
    },
];

const PositionsGrid = (props) => {
    const classes = useStyles();
    const {authState} = useAuthState();
    const companyId = authState?.currUserCompany?.company_id;

    const {
        onCreatePosition,
        positions, rowCount,
        sort,
        page, pageSize, onPageChange, onPageSizeChange,
        onSortChange,
        onFilterChange,
        onAction,
        loading
    } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedPosition, setSelectedPosition] = React.useState(null);

    const handleClick = (event, selectedPosition) => {
        setSelectedPosition(selectedPosition)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Paper sx={{
            height: '100%',
            '& .position-header': {
                backgroundColor: '#DEEBFF'
            },
            '& .MuiDataGrid-columnSeparator': {
                color: '#000000', borderColor: "blue"
            }
        }}
        >
            <DataGrid
                rows={positions}
                page={page}
                pageSize={pageSize}
                columns={positionColumns}
                rowCount={rowCount}
                //headerClassName={classes.root}
                sortModel={sort}
                onCellClick={(param, event) => {
                    event.defaultMuiPrevented = true;

                    handleClick(event, param.row);
                }}
                // sx={{
                //     '.MuiDataGrid-columnSeparator': {
                //       color: '#ffffff',
                //     },

                //   }}
                disableSelectionOnClick

                checkboxSelection={false}
                loading={loading}

                filterMode="server"
                sortingMode="server"
                paginationMode="server"

                onPageChange={onPageChange}
                onSortModelChange={(sort) => {
                    console.log(sort)
                    onSortChange(sort)
                }}

                rowsPerPageOptions={[10, 50, 100]}
                onPageSizeChange={onPageSizeChange}

                onFilterModelChange={onFilterChange} //#DEEBFF
                className={classes.root}
                // getRowClassName={(params) =>
                //     params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                //   }
                components={{
                    Toolbar: CustomToolbar,
                    LoadingOverlay: CustomLoadingOverlay,
                    NoRowsOverlay: CustomNoRowsOverlay,
                }}
                rowHeight={70}
                componentsProps={{
                    toolbar: {
                        title: 'Position Dashboard',
                        toolbarActions: (
                            <Button sx={{
                                bgcolor: "success.main", color: "#ffffff", ':hover': {
                                    bgcolor: '#4F916B',
                                    color: 'white'
                                }
                            }} onClick={onCreatePosition}>Create New Position</Button>
                        ),
                    },
                }}
            />
            <Popover
                id={selectedPosition?.id}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
            >
                <PositionActions selectedPosition={selectedPosition} companyId={companyId} onAction={() => {
                    handleClose();
                    onAction();
                }}/>
            </Popover>
        </Paper>
    );
};

export default PositionsGrid;

