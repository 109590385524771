import React from 'react';

import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import AwardDescription from './AwardDescription';

import {disbursementOptions} from '../../../../shared/enums/disbursement';

import makeStyles from '@mui/styles/makeStyles';
import Present from '../../../../images/present.png'
import Money from '../../../../images/money.png'
import TealLeft from '../../../../images/teal_left.png'
import TealConfetti from '../../../../images/tealconfetti.png'
import OrangeSmall from '../../../../images/orangesmall.png'
import TealSmall from '../../../../images/tealsmall.png'
import BlueConfetti from '../../../../images/blueconfetti.png'
import OrangeOutSmall from '../../../../images/orangeoutsmall.png'

// const disbursementActions = [
//     "Include as signing bonus for candidate",
//     "Make payment to me",
//     "Split Payment with others",
//     "Donate to charity",
// ];

const useStyles = makeStyles((theme) => ({
    disbursementActions: {
        marginTop: theme.spacing(2),
        color: 'black',
        backgroundColor: '#e5e5e5',
        // maxWidth: 410,
    },
    disbursementAction: {
        backgroundColor: 'white',
        margin: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
    },
    backgroundimages: {
        //width: '100px',
        position: 'absolute',
        // zIndex:1,
    }
}));

const ReferralAwardDetails = ({position}) => {
    const {position_content_published} = position;
    const {cold_award_cents, warm_award_cents, payout_description} = position_content_published;
    const awards = (cold_award_cents && warm_award_cents) ?
        "warm and cold" : (
            warm_award_cents ? "warm" : "cold"
        );
    const paymentText = (!cold_award_cents && !warm_award_cents) ?
        "isn't supporting any payment" :
        `is accepting ${awards} referrals and will pay $${warm_award_cents / 100} within 30 days of successful hire`;
    const disbursement = disbursementOptions.find(({value}) => value === payout_description)?.label;
    const classes = useStyles();
    return (
        <>
            {/* <Box component='img' sx={{top:'1013px', left: {
                        lg:'85%',
                        md:'90%',
                        sm:'95%',
                        xs:'99%',
                    },
                        width: {
                            xs:'65px'
                    }}} className={classes.backgroundimages} src={Money}/>
         <Box component='img' sx={{top:'1060px', left: {
                        lg:'83%',
                        md:'87%',
                        sm:'92%',
                        xs:'96%',
                    },
                        width: {
                            xs:'15px'
                    }}} className={classes.backgroundimages} src={OrangeOutSmall}/> */}

            <Card elevation={0} variant="outlined" sx={{position: 'relative'}}>

                <CardHeader sx={{pb: 0}}
                            title={
                                <Typography sx={{pb: 2, pt: 5, fontWeight: 400, color: 'text.primary', display: 'flex', justifyContent: 'center'}}
                                            component="h1"
                                            variant="h6"
                                >
                                    Referral Award Details
                                </Typography>
                            }
                />
                <CardContent sx={{py: 0, px: 2, pl: 5, pr: 5, pb: 3}}>
                    <Box component='img' sx={{
                        top: '10px', left: {
                            lg: '262px',
                            md: '262px',
                            sm: '262px',
                            xs: '150px'
                        },
                        width: {
                            md: '50px',
                            sm: '50px',
                            xs: '50px'
                        }
                    }} className={classes.backgroundimages} src={Present}/>
                    <Box component='img' sx={{
                        top: '60px', left: {
                            xs: '768px'
                        },
                        width: {
                            md: '20px',
                            sm: '20px',
                            xs: '20px'
                        }
                    }} className={classes.backgroundimages} src={TealLeft}/>
                    <Box component='img' sx={{
                        top: '52px', left: {
                            xs: '535px'
                        },
                        width: {
                            xs: '20px'
                        }
                    }} className={classes.backgroundimages} src={TealConfetti}/>
                    <Box component='img' sx={{
                        top: '75px', left: {
                            lg: '105px',
                            md: '105px',
                            sm: '105px',
                            xs: '105px'
                        },
                        width: {
                            md: '10px',
                            sm: '10px',
                            xs: '10px'
                        }
                    }} className={classes.backgroundimages} src={OrangeSmall}/>
                    <Box component='img' sx={{
                        top: '33px', left: {
                            xs: '436px'
                        },
                        width: {
                            xs: '18px'
                        }
                    }} className={classes.backgroundimages} src={TealSmall}/>
                    <Box component='img' sx={{
                        top: '51px', left: {
                            lg: '200px',
                            md: '200px',
                            sm: '200px',
                            xs: '200px'
                        },
                        width: {
                            xs: '20px'
                        }
                    }} className={classes.backgroundimages} src={BlueConfetti}/>
                    <Box component='img' sx={{
                        top: '70px', left: {
                            xs: '600px'
                        },
                        width: {
                            xs: '15px'
                        }
                    }} className={classes.backgroundimages} src={OrangeOutSmall}/>
                    <Box component='img' sx={{
                        top: '43px', left: {
                            xs: '662px'
                        },
                        width: {
                            xs: '65px'
                        }
                    }} className={classes.backgroundimages} src={Money}/>
                    <Typography variant="subtitle1" sx={{pb: 1}}>{position.company.name} {paymentText}</Typography>
                    <List>
                        {(!!warm_award_cents) && <AwardDescription awardCents={warm_award_cents} variant="warm"/>}
                        {(!!cold_award_cents) && <AwardDescription sx={{mt: 2}} awardCents={cold_award_cents} variant="cold"/>}
                    </List>
                    {disbursement && (
                        <Typography sx={{mt: 2}} variant="subtitle2">
                            Reward will be paid out at {disbursement}
                        </Typography>
                    )}
                    {/* <Typography sx={{mt: 1}}>
                    Note: the following options are available for payment disbursement
                </Typography>
                <Box className={classes.disbursementActions}>
                    <Typography variant='subtitle2' sx={{px: 1.5, pt: 1.5}}>
                        Disbursement Options
                    </Typography>
                    <List sx={{mb: 2}}>
                        {disbursementActions.map((action, idx) => (
                            <ListItem className={classes.disbursementAction} key={idx}>{idx + 1}) {action}</ListItem>
                        ))}
                    </List>
                </Box> */}
                </CardContent>
                <div/>
            </Card>
        </>
    )
}

export default ReferralAwardDetails;
