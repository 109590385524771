import React, {useState} from "react";
import axios from "axios";
import {useAuthState} from "../auth/auth";

export const getModulePreferences = (preferences, moduleName) => {
    if (preferences?.Modules) {
        const modules = preferences.Modules;
        const module = modules.find(x => x.Name.toLowerCase() === moduleName.toLowerCase());

        return module?.Values;
        // return preferences.Modules[moduleName];
    }
    return {};
}

export const updateModulePreferences = (moduleName, values, headers) => {

    // var item = JSON.parse(`{${moduleName}:${values}}`);
    const item = {name: moduleName, values: values};
    // item[moduleName] = values;
    axios.patch(`/api/users/preferences/module`, item, {headers: headers}).then(res => {
        return res.data;
    }).then(data => {
        if (data !== null || data != {}) {
            //setPositionDetails(data);
            // console.log(data);
        }
    });
}

const Preferences = (props) => {
    const {authState} = useAuthState();
    const headers = authState.headers;
    const [modulePreferences, setModulePreferences] = useState({});

    const modules = () => {

    }
}

export default Preferences;
