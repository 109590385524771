import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import api from "../api";

import { useAuthState } from "../auth/auth";
import { Box, Grid, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import UserPaymentInfoPage from "./UserPaymentInfoPage";
import UserProfile from '../components/user/UserProfile';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "95%",
        maxWidth: 800,

        display: 'grid',

        marginLeft: 'auto',
        marginRight: 'auto',

        [theme.breakpoints.up('xs')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },

    },
    grid: {
        padding: theme.spacing(2),

    },
    paper: {
        padding: theme.spacing(3),
        maxWidth: 900,
        borderRadius: '20px'
    },

}));

const UserAccountPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const { authState } = useAuthState();
    const [initialValues, setInitialValues] = useState({ first_name: '',
    second_name: '',
    email: ''});
    const [needToFinishRegistration, setNeedToFinishRegistration] = useState(false);
    const headers = authState?.headers;

    return (
        <Box sx={{ height: '100%', overflowY: 'scroll' }}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="center" rowSpacing={3}>
                <Grid item>
                    <Typography sx={{ pb: 1, fontWeight: 700, color: 'text.primary' }} component="h1" variant="h4" align="left">
                        Profile
                    </Typography>
                </Grid>
                <Grid item>
                    <Paper className={classes.paper}>
                        <UserProfile></UserProfile>
                    </Paper>
                </Grid>
                <Grid item sx={{mb: 4, width: "inherit"}}>
                    <UserPaymentInfoPage/>
                </Grid>
            </Grid>
        </Box>
    );
};

export default UserAccountPage;
