import React, {useEffect, useState} from 'react';
import {Redirect, useHistory, useLocation} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import {useAuthState} from "../auth/auth";
import {v4 as uuidv4} from 'uuid';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(2),
            padding: theme.spacing(1),
            //width: theme.spacing(16),
            //height: theme.spacing(16),
        },
    },
}));

const CreateAccount = () => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const {authState} = useAuthState();
    const isAuthenticated = authState.isAuthenticated;
    const headers = authState.headers;
    const location = useLocation();

    useEffect(() => {

        const queryString = require('query-string');
        var qs = queryString.parse(location.search);
        var refConfId = qs.confId;
        // store referral id in local storage and redirect to the sign up page
        var id = uuidv4();

        // check if we have a referral id in local storage, if we do

        let key = `refConfId-${id}`;
        localStorage.setItem(key, JSON.stringify({"referrerConfirmationId": refConfId}))
        authState.signIn({"screen_hint": "signup"})
        return;


    }, [headers])

    if (authState.isLoading) {
        return null;
    }

    if (authState.isAuthenticated === null) {
        return <React.Fragment>Unauthorized user home page</React.Fragment>
    } else if (authState.isAuthenticated === true) {
        return null;
    } else {
        return <Redirect to='/login'/>; //auth failed (wrong tokens) redirect to login page
    }
};
export default CreateAccount;
