import React, {useMemo} from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import InputAdornment from "@mui/material/InputAdornment";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import Skeleton from '@mui/material/Skeleton';
import {validateUrl} from "../../DataValidation/UrlValidation";
import ImageUpload from "./ImageUpload";
import 'react-quill/dist/quill.snow.css';
import StyledReactQuill from "../../shared/Quill/StyledReactQuill";
import {debounce} from "lodash";

const useStyles = makeStyles((theme) => (
        {
            darkDisabledText: {
                "& .MuiInputBase-root.Mui-disabled": {
                    color: "black"
                }
            },
        }
    )
);

const GeneralCompanyInfoForm = (props) => {
    const classes = useStyles();

    const {
        companyName, logoUrlString, adminEmail, summary, linkedInUrlString, facebookUrlString, glassdoorUrlString, isCreate,
        isPending, isSaving, logoFile,
        handleChange,
    } = props;

    const isCompanyNameValid = companyName === null || companyName !== "";
    const isSummaryValid = summary !== "" && summary !== "<p><br></p>";
    const linkedInUrl = validateUrl(linkedInUrlString, "linkedin.com");
    const facebookUrl = validateUrl(facebookUrlString, "facebook.com");
    const glassdoorUrl = validateUrl(glassdoorUrlString, "glassdoor.com");

    const handleDescriptionChange = (val) => {
        handleChange("summary", {"target": {"value": val}});
    };

    const debounceChangeHandler = useMemo(() => {
        const changeHandler = (value) => {
            handleDescriptionChange(value);
        };

        return debounce(changeHandler, 1000);
    }, []);

    const createSocialLinkInput = (id, label, urlString, url, adornment) => {
        return (
            <TextField
                id={id}
                name={id}
                label={label}
                fullWidth
                autoComplete={id}
                type="url"
                value={url.value}
                error={url.isError}
                helperText={url.error}
                onChange={(event) => handleChange(id, event)}
                InputProps={{
                    startAdornment: adornment,
                }}
            />
        );
    };

    const renderGroupAdminEmail = () => {
        return (
            <Grid item xs={12} sm={12}>
                <TextField
                    className={classes.darkDisabledText}
                    disabled
                    required
                    type="email"
                    id="companyAdministrator"
                    name="companyAdministrator"
                    label="Administrator"
                    fullWidth
                    value={adminEmail}
                />
            </Grid>
        );
    };

    if (isPending) {
        return <Skeleton variant="rectangular"/>;
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={10}>
                <TextField
                    className={classes.darkDisabledText}
                    disabled={!isCreate}
                    required
                    id="companyName"
                    name="companyName"
                    label="Company name"
                    fullWidth
                    value={companyName === null ? "" : companyName}
                    error={!isCompanyNameValid}
                    helperText={isCompanyNameValid ? "" : "required"}
                    onChange={(event) => handleChange('name', event)}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <ImageUpload companyName={companyName} selectedFile={logoUrlString} handleChange={handleChange}/>
            </Grid>
            {isCreate ? null : renderGroupAdminEmail()}
            <Grid item xs={12} sm={12}>
                <StyledReactQuill
                    isError={!isSummaryValid}
                    errorMessage="required"
                    value={summary}
                    placeholder="Company Summary *"
                    onChange={debounceChangeHandler}
                />
                {/* <TextField
                    id="companySummary"
                    name="companySummary"
                    label="Company Summary"
                    variant="outlined"
                    required
                    fullWidth
                    rows={4}
                    autoComplete="company-summary"
                    multiline
                    value={summary === null ? "" : summary}
                    error={!isSummaryValid}
                    helperText={isSummaryValid ? "" : "required"}
                    onChange={(event) => handleChange('summary', event)}
                /> */}
            </Grid>
            <Grid item xs={12} sm={12}>
                {
                    createSocialLinkInput(
                        "linked_in_url",
                        "LinkedIn",
                        linkedInUrlString,
                        linkedInUrl,
                        <InputAdornment position="start"><LinkedInIcon sx={{color: "#2867B2"}}/></InputAdornment>
                    )
                }
            </Grid>
            <Grid item xs={12} sm={12}>
                <Grid spacing={2} container direction="row" justifyContent="space-evenly" alignItems="center">
                    <Grid item xs={12} sm={6}>
                        {
                            createSocialLinkInput(
                                "facebook_url",
                                "Facebook",
                                facebookUrlString,
                                facebookUrl,
                                <InputAdornment position="start"><FacebookIcon sx={{color: "#1777F2"}}/></InputAdornment>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {
                            createSocialLinkInput(
                                "glassdoor_url",
                                "Glassdoor",
                                glassdoorUrlString,
                                glassdoorUrl,
                                <InputAdornment position="start" sx={{color: "#61A84B"}}><MeetingRoomIcon/></InputAdornment>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default GeneralCompanyInfoForm
