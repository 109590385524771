import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';


import { useAuthState } from "../../auth/auth";
import { Box, Grid, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import api from '../../api';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "95%",
        maxWidth: 800,

        display: 'grid',

        marginLeft: 'auto',
        marginRight: 'auto',

        [theme.breakpoints.up('xs')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },

    },
    grid: {
        padding: theme.spacing(2),

    },
    paper: {
        padding: theme.spacing(3),
        maxWidth: 900,
        borderRadius: '20px'
    },

}));

const validationSchema = yup.object({
    first_name: yup
        .string('Enter your first name')
        .required('First name is required'),
    second_name: yup
        .string('Enter your last name')
        .required('Last name is required'),
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
});

const UserProfile = (prop) => {
    const classes = useStyles();
    const history = useHistory();
    const { authState } = useAuthState();
    const [initialValues, setInitialValues] = useState({ first_name: '',
    second_name: '',
    email: ''});
    const [needToFinishRegistration, setNeedToFinishRegistration] = useState(prop.completeRegistration);
    const headers = authState?.headers;

    let formik = useFormik({
        initialValues: initialValues
        ,enableReinitialize:true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            api.users.updateMe(values,headers).then(data => {
                //history.push("/home");
                prop.saveProfileComplete();
            });
        },
    });

    useEffect(() => {
        api.users.meRetry(headers)
            .then((response) => {
                let u = {...response.data.user};
                setInitialValues({ first_name: u.first_name?u.first_name:'',
                second_name: u.second_name?u.second_name:'',
                email: u.email})
                if (!u.first_name || !u.second_name)
                    setNeedToFinishRegistration(true);
            });
    },[headers])


    return (
        <React.Fragment>
                        {!needToFinishRegistration?<Typography sx={{ pt: 1, pb: 2 }} variant="h6">Update your name</Typography>:""}
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    autoFocus={true}
                                    id="first_name"
                                    name="first_name"
                                    label="*First Name"
                                    value={formik.values.first_name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                    helperText={formik.touched.first_name && formik.errors.first_name}
                                />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id="second_name"
                                    name="second_name"
                                    label="*Last Name"
                                    value={formik.values.second_name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.second_name && Boolean(formik.errors.second_name)}
                                    helperText={formik.touched.second_name && formik.errors.second_name}
                                />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    type="email"
                                    label="*Email"
                                    disabled
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                                </Grid>
                                <Grid item sx={{marginLeft: 'auto'}}>
                                {/* <Button sx={{display:needToFinishRegistration?"none":null, color:"text.primary", bgcolor:"#ffffff", pl:4, pr:4, pt:1, pb:1}} onClick={() => {history.goBack();}}>Cancel</Button> */}
                                <Button type="submit" sx={{bgcolor:"success.main", color:"#ffffff",pl:4, pr:4, pt:1, pb:1}}>Save</Button>
                                </Grid>
                            </Grid>
                        </form>
                        </React.Fragment>
    );
};

export default UserProfile;
