import React from 'react';
import Card from '@mui/material/Card';
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import axios from "axios";
import {useHistory} from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import {useAuthState} from "../../auth/auth";
import CardHeader from "@mui/material/CardHeader";

const CompanyCard = ({company}) => {
    const history = useHistory();
    const {authState, saveAuthState} = useAuthState();

    const headers = authState.headers;

    const createCompany = () => {
        history.push("/company_create");
        // create company and assign current user as admin role and redirect to getting started page.
        // axios.post('/api/company/create', {}, {headers: headers})
        //     .then(response => {
        //         saveAuthState({userCompanies: response.data.user_companies});
        //         history.push(`company/${response.data.company.company_id}/edit`);
        //     })
        //     .catch(err => console.error(err));
    }

    return (
        <Card>
            <CardActionArea onClick={createCompany}>
                <CardHeader
                    sx={{minHeight: 150}}
                    title={
                        <Typography color="primary" gutterBottom variant="h6">
                            Create New Company
                        </Typography>
                    }
                    avatar={
                        <AddIcon color="primary" sx={{height: 64, width: 64}}/>
                    }
                />
            </CardActionArea>
        </Card>
    );
};

export default CompanyCard;
