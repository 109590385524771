import React, {useEffect, useState} from 'react';
import ReferralFormForCandidate from '../components/Referral/User/ReferralFormForCandidate';

import api from "../api";
import StyledSkeleton from "../shared/Skeleton/StyledSkeleton";
import {useAuthState} from "../auth/auth";
import {useHistory, useParams} from "react-router-dom";
import redirect from "../redirect";

const UserReferralPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [referral, setReferral] = useState(null);
    const [loadingError, setLoadingError] = useState(null);

    const history = useHistory();
    const {positionId, referralId} = useParams();
    const {authState} = useAuthState();

    const authLoading = authState?.loading
    const headers = authState?.headers;

    const createReferral = () => {
        setLoading(true);
        api.referrals.getOrCreateSelfReferral(positionId, headers, history)
            .then(({data: {referral}}) => setReferral(referral))
            .finally(() => setLoading(false));
        // api.referrals.getOrCreateSelfReferral(positionId, headers, history)
        //     .then(({data: {referral}}) => setReferral(referral))
        //     .catch(err => setLoadingError(err))
        //     .finally(() => setLoading(false));
        // setReferral({});
        // setLoading(false);
    }

    // const loadReferral = () => {
        
    //     setLoading(true);

    //     api.referrals.showToUser(positionId, referralId, headers)
    //         .then(({data: {referral}}) => {
    //             // if (referral.id !== referralId) {
    //             //     redirect.referrals.editselfreferral(history, positionId, referral.id);
    //             //     return;
    //             // }

    //             setReferral(referral);
    //         })
    //         .catch(err => setLoadingError(err))
    //         .finally(() => setLoading(false));
    // }

    useEffect(() => {
        
        if (loading) {
            return;
        }
        if (authLoading) {
            return;
        }
        // if (referral) {
        //     loadReferral();
        // } else {
            createReferral();
        // }
        
    }, [authLoading]);

    return (
        <React.Fragment>
            {
                referral != null
                    ? (
                        <ReferralFormForCandidate
                            referral={referral}
                            position={referral.position}
                            preventSubmit={false}
                        />
                    )
                    : (
                        <StyledSkeleton/>
                    )
            }
        </React.Fragment>
    );
}

export default UserReferralPage;
