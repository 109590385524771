import React from "react";

const ElevationScroll = (props) => {
    const {children, scrollTrigger} = props;

    return React.cloneElement(children, {
        elevation: scrollTrigger ? 3 : 0,
    });
};

export default ElevationScroll;
